import { ProvidersEnum } from '../../../shared/enums/provider-id.enum';
import { ProtectionProductsComponentService } from './../protection-products.component.service';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'productsfilter'
})
export class ProductsFilterPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(_change: number, isOwnPpType: boolean): AbstractControl[] {
        return _.filter((this.pPComponentService.protectionProductsForm.get('products') as FormArray).controls,
            (t: FormGroup) => (t.getRawValue().providerId === this.pPComponentService.ownPpProviderId) === isOwnPpType
        );
    }
}
