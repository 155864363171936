<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title class="capitalize">
            {{'LOCALIZATION.CAMPAIGNS.OFFER_TYPE' | translate}} : {{rateRule.offerTypeName ? rateRule.offerTypeName :
            rateRule.offerType}}
        </mat-panel-title>
        <mat-panel-title class="capitalize model-code">
            <span [matTooltip]="rateRule.modelCodeNames ? rateRule.modelCodeNames : rateRule.modelCode"
                matTooltipShowDelay="500">{{'LOCALIZATION.CAMPAIGNS.MODEL_CODE' | translate}} :
                {{rateRule.modelCodeNames ? rateRule.modelCodeNames : rateRule.modelCode}} </span>
        </mat-panel-title>
        <mat-panel-title class="capitalize">
            {{'LOCALIZATION.CAMPAIGNS.SALES_CLASS' | translate}} : {{rateRule.salesClassNames ? rateRule.salesClassNames
            : rateRule.salesClass}}
        </mat-panel-title>
        <ng-container *ngIf="!(disableActions$ | async)">
            <mat-panel-title class="justify-content-end"
                *ngIf="(campaign.endDate | getcampaignstatus : campaign.status) === campaignStatus.DRAFT">
                <div class="mx-2">
                    <span class="material-icons px-1" matTooltip="Edit Rate Rule" matTooltipShowDelay="500"
                        (click)="$event.stopPropagation(); editRateRule()">edit</span>
                </div>
                <div class="mx-2">
                    <span class="material-icons px-1" matTooltip="Delete Rate Rule" matTooltipShowDelay="500"
                        (click)="$event.stopPropagation(); deleteRateRule()">delete</span>
                </div>
            </mat-panel-title>
        </ng-container>
    </mat-expansion-panel-header>
    <div class="pos-relative">
        <span class="title-span fw-bold">{{'LOCALIZATION.CAMPAIGNS.RATE_TABLE' | translate}}</span>
        <div class="d-flex flex-column rate-table-container">
            <div>
                <table class="table w-100" aria-describedby="rate-table">
                    <thead>
                        <tr>
                            <th scope="col" class="minw-100px">
                                {{'LOCALIZATION.CAMPAIGNS.MIN_TERM' | translate}}
                            </th>
                            <th scope="col" class="minw-100px">
                                {{'LOCALIZATION.CAMPAIGNS.MAX_TERM' | translate}}<em
                                    class="max-term-info tmi tmi-info ml-1 p-0"
                                    matTooltip="{{'LOCALIZATION.CAMPAIGNS.MAX_TERM_TOOLTIP' | translate}}"></em>
                            </th>
                            <th scope="col" class="minw-100px">
                                {{'LOCALIZATION.CAMPAIGNS.MARKUP' | translate}}
                            </th>
                            <th scope="col" [attr.colspan]="(creditTiers$ | async)?.length + 2">
                                {{ isAdvPercentageBased ? '' : 'LOCALIZATION.CAMPAIGNS.BASE_RATE' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let termRate of rateRule.termRate, last as lastRow">
                            <tr [ngClass]="{'border-bottom': !lastRow}">
                                <td>
                                    {{termRate.term.min}}
                                </td>
                                <td>
                                    {{termRate.term.max}}
                                </td>
                                <td>
                                    <span *ngIf="termRate.markup.measure === 'amount'">$</span>
                                    {{termRate.markup.value}}
                                    <span *ngIf="termRate.markup.measure === 'percentage'">%</span>
                                </td>
                                <td [attr.colspan]="(creditTiers$ | async)?.length + 2">
                                    <div class="base-rate-table px-2 pt-2 pb-0">
                                        <table aria-describedby="term-adv-percentage" class="w-100">
                                            <thead>
                                                <tr *ngIf="isAdvPercentageBased">
                                                    <th colspan="2" scope="col">
                                                        {{'LOCALIZATION.CAMPAIGNS.ADVANCE_PERCENTAGE' | translate}}</th>
                                                    <th [attr.colspan]="(creditTiers$ | async)?.length + 1" scope="col">
                                                        {{'LOCALIZATION.CAMPAIGNS.BASE_RATE' | translate}}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th scope="col" class="maxw-120px minw-70px"
                                                        *ngIf="isAdvPercentageBased">
                                                        {{'LOCALIZATION.CAMPAIGNS.MIN' | translate}}(%)
                                                    </th>
                                                    <th scope="col" class="maxw-120px minw-70px"
                                                        *ngIf="isAdvPercentageBased">
                                                        {{'LOCALIZATION.CAMPAIGNS.MAX' | translate}}(%)
                                                    </th>
                                                    <th scope="col"
                                                        class="fs-13px maxw-120px minw-70px vertical-align-middle"
                                                        *ngFor="let creditTier of (creditTiers$ | async)">
                                                        {{creditTier.score}}<br />{{"(" + creditTier.range + ")"}}
                                                    </th>
                                                    <th scope="col" class="pr-2" *ngIf="isAdvPercentageBased">
                                                        {{'LOCALIZATION.CAMPAIGNS.DEFAULT' | translate}}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr [ngClass]="{'border-bottom': !lastAdvRow}"
                                                    *ngFor="let advPercentage of termRate.advancePercentage, last as lastAdvRow">
                                                    <td *ngIf="isAdvPercentageBased">
                                                        {{advPercentage.min}}
                                                    </td>
                                                    <td *ngIf="isAdvPercentageBased">
                                                        {{advPercentage.max}}
                                                    </td>
                                                    <td *ngFor="let creditTier of (creditTiers$ | async)">
                                                        {{creditTier.score | getbaseratefortier : advPercentage}}
                                                    </td>
                                                    <td *ngIf="isAdvPercentageBased">
                                                        <mat-icon class="pointer-events-none"
                                                            *ngIf="advPercentage.isDefault">check</mat-icon>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>

                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <app-pe-view-residuals *ngIf="rateRule.offerType === leaseOfferType" [rateRule]="rateRule"
        [campaign]="campaign"></app-pe-view-residuals>
</mat-expansion-panel>