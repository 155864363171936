import { Pipe, PipeTransform } from "@angular/core";
import { IAdvancePercentage } from "../../../shared/models/dealer-defined-campaigns.model";
import * as _ from 'lodash';

@Pipe({
    name: "getbaseratefortier"
})
export class GetBaseRateForTierPipe implements PipeTransform {

    transform(creditTier: string, advancePercentage: IAdvancePercentage): number {
        return _.find(advancePercentage.rates, { creditTier })?.baseRate || null;
    }

}