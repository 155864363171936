import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ChangeDetectorRef, Host, ViewChild, Injectable, Inject } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { Store } from "@ngrx/store";
import { filter, take } from "rxjs/operators";
import { DealerConfigurationState } from "../../../store/dealer-configuration/dealer-configuration.reducer";
import {
  selectDealerConfiguration,
  selectdealerDefinedOfferTerms,
  selectDisableActions
} from "../../../store/dealer-configuration/dealer-configuration.selectors";
import { BehaviorSubject, Observable } from "rxjs";
import { OfferTypes } from "../../../shared/enums/offer-types.enum";
import * as _ from "lodash";
import {
  IGlobalTermConfig,
} from "../../../shared/models/dealer-configuration.model";
import { IPaymentGridInfo } from "../../../shared/models/bank-configuration.model";
import { UserProfileService } from "@toyota/dd365-platform-library";
import {
  SubscriptionList,
  unsubscribeSubscriptions,
} from "../../../shared/services/util.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SalesClasses } from "../../../shared/enums/sales-classes.enum";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-pe-global-grid-config",
  templateUrl: "./global-grid-config.component.html",
  styleUrls: ["./global-grid-config.component.scss"]
})

export class GlobalGridConfigComponent implements OnInit, OnDestroy {
  dealerCode: string;
  @Input() tabDetails: string;
  @Input() getformGroupName: BehaviorSubject<string>;
  @Input() paymentGridConfig: IPaymentGridInfo;
  @Output() saveEvent = new EventEmitter<string>();
  @Output() resetEvent = new EventEmitter<string>();
  @Input() pgformValidEvent: string;
  offerDefaultFormGroup: FormGroup;
  loadingBankConfig$: Observable<boolean>;
  offerTerms: {
    new: { lease: number[]; finance: number[] };
    certified: { lease: number[]; finance: number[] };
    used: { lease: number[]; finance: number[] };
  };
  salesClasses = SalesClasses;
  configurableSalesClasses: string[] = [
    SalesClasses.NEW,
    SalesClasses.CERTIFIED,
    SalesClasses.USED,
  ];
  previousState: IGlobalTermConfig;
  disableActions$: Observable<boolean>;
  private subs: SubscriptionList = {};
  currentForm: string;
  constructor(
    private readonly userProfileService: UserProfileService,
    private readonly snackBar: MatSnackBar,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    readonly translateService: TranslateService,
    private rootFormGroup: FormGroupDirective,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.offerTerms = {
      new: { lease: [], finance: [] },
      certified: { lease: [], finance: [] },
      used: { lease: [], finance: [] },
    };
    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    this.disableActions$ = this.dealerConfigurationState.select(
      selectDisableActions
    );
    _.keys(this.offerTerms).forEach((salesClass) => {
      _.keys(this.offerTerms[salesClass]).forEach((offerType) => {
        this.dealerConfigurationState
          .select(selectdealerDefinedOfferTerms, { offerType, salesClass })
          .pipe(
            filter((t) => t.length > 0),
            take(1)
          )
          .subscribe((offerTerms) => {
            this.offerTerms[salesClass][offerType] = offerTerms;
          });
      });
    });
    this.setupDefaultTermsForm();
  }

  setupDefaultTermsForm() {
    this.changeDetectorRef.detectChanges();
    this.getformGroupName.subscribe((data) => {
      this.offerDefaultFormGroup = this.rootFormGroup.control.get(data) as FormGroup;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subs);
    this.getformGroupName.unsubscribe();
  }

  saveDefaultTerms() {
    this.dealerConfigurationState
      .select(selectDealerConfiguration)
      .pipe(take(1))
      .subscribe((dealerConfig) => {
        const dealerConfiguration = _.cloneDeep(dealerConfig);
        dealerConfiguration.paymentGridCustomization.global = this.offerDefaultFormGroup.value;
      });
    if (this.offerDefaultFormGroup.valid) {
      this.saveEvent.emit(this.offerDefaultFormGroup.status);
    } else {
      this.snackBar.open(
        this.translateService.instant(
          "LOCALIZATION.PAYMENT_GRID.INVALID_CONFIG"
        ),
        this.translateService.instant("LOCALIZATION.COMMON.DISMISS"),
        { duration: 4000 }
      );
    }
  }
 
  resetDefaultTerms() {
    this.resetEvent.emit(this.offerDefaultFormGroup.status);
    this.changeDetectorRef.detectChanges();
    this.setupDefaultTermsForm();

  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

}