import { FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { IProductConfiguration } from '../../../shared/models/protection-products.model';
import { ProtectionProductsComponentService } from '../protection-products.component.service';

@Pipe({
    name: 'getplansarray'
})
export class GetPlansArrayPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(product: IProductConfiguration, productForm: FormGroup): AbstractControl[] {
        const plansArray = productForm.get(['global', 'plans']) as FormArray;
        if (plansArray) {
            return plansArray.controls;
        }
        else {
            return [];
        }
    }
}

