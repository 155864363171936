import { OfferTypes } from './../../../shared/enums/offer-types.enum';
import { ProtectionProductsComponentService } from './../protection-products.component.service';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'offertypesfilter'
})
export class OfferTypesFilterPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(_code: string): string[] {
        if (this.pPComponentService.isTfsDealer) {
            return this.pPComponentService.offerTypes;
        }
        let offerTypes = this.pPComponentService.offerTypes;
        if (_.indexOf(this.pPComponentService.noCashOfferProducts, this.pPComponentService.selectedProductData?.code) >= 0) {
            offerTypes = _.filter(offerTypes, (t) => t != OfferTypes.CASH);
        }
        if (_.indexOf(this.pPComponentService.noFinanceOfferProducts, this.pPComponentService.selectedProductData?.code) >= 0) {
            offerTypes = _.filter(offerTypes, (t) => t != OfferTypes.FINANCE);
        }
        if (_.indexOf(this.pPComponentService.noLeaseOfferProducts, this.pPComponentService.selectedProductData?.code) >= 0) {
            offerTypes = _.filter(offerTypes, (t) => t != OfferTypes.LEASE);
        }
        return offerTypes;
    }
}
