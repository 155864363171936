<div class="copy-detail">
    <div class="copy-container">
        <div class="header text-cap">
            <div mat-dialog-title>
                {{'LOCALIZATION.PROTECTION_PRODUCTS.COPY_GPBS_CONFIGURATION' | translate:({"salesClass": data.salesClass, "offerType": data.offerType})}}
            </div>
        </div>
    </div>
    <div class="copy-container container-fluid p-0">
        <div [formGroup]="copyForm" *ngIf="copyForm && (pPComponentService.getSelectedProduct$ | async)">
            <ng-container *ngFor="let salesClass of (salesClasses$ | async)">
                <div *ngIf="((pPComponentService.getSelectedProduct$ | async).providerId == 'nontmis') || ((pPComponentService.getSelectedProduct$ | async).id | checksalesclassdataexistspipe : salesClass)"
                    class="col-12 mb-3 vehicle-type-div" [formGroupName]="salesClass">
                    <div class="vehicle-type-section-title text-cap">
                        {{'LOCALIZATION.PROTECTION_PRODUCTS.DEFAULT_HEADER_NOTE' | translate:({"salesClass": salesClass})}}
                    </div>
                    <ng-container *ngFor="let offerType of (offerTypes$ | async),let offerIndex = index">
                        <span *ngIf="(((pPComponentService.getSelectedProductConfig$ | async).providerId == 'nontmis') ||
                            ((pPComponentService.getSelectedProduct$ | async).id | checkOffertypeDataExists : offerType : salesClass)) &&
                            !(salesClass == data.salesClass && offerType == data.offerType)" 
                            class="text-cap mr-3">
                            <mat-checkbox [formControl]="copyForm.get(salesClass)?.get(offerType)">
                                {{offerType}}
                            </mat-checkbox>
                        </span>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class=" d-flex mt-2 mr-3">
    <button class="secondary-button ml-auto mr-3" #matDialogCloseBtn mat-dialog-close>
        {{'LOCALIZATION.COMMON.CANCEL' | translate}}
    </button>
    <button type="button" class="primary-button" (click)="copyOffers()">
        {{'LOCALIZATION.COMMON.COPY' | translate}}
    </button>
</div>