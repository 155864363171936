import { Pipe, PipeTransform } from '@angular/core';
import { Constants, Environment } from '../../models/constants/constants';
import { assetUrl } from '../../../single-spa/asset-url';

@Pipe({
  name: 'assetUrl'
})
export class AssetUrlPipe implements PipeTransform {
  environmentName: string = null;
  appName: string = '';

  constructor() {
    this.appName = Constants.APP_NAME;
    if (window.location && window.location.hostname) {
      const name = window.location.hostname.toLowerCase();
      this._setEnvironment(name);
    }
  }

  public transform(value: string, folder?: string): any {
    return assetUrl(value, folder, this.environmentName);
  }

  private _setEnvironment(hostname: string) {
    const domainSuffix = '.dealer.toyota.com';
    if (hostname === 'localhost') {
      this.environmentName = 'http://localhost:4200';
    } else if (hostname === 'dev.dealer.toyota.com' || hostname === 'dealer.dev.toyota.com') {
      this.environmentName = 'https://' + this.appName + '.' + Environment.DEV + domainSuffix;
    } else if (hostname === 'test.dealer.toyota.com' || hostname === 'dealer.test.toyota.com') {
      this.environmentName = 'https://' + this.appName + '.' + Environment.TEST + domainSuffix;
    } else if (hostname === 'e2e.dealer.toyota.com' || hostname === 'dealer.e2e.toyota.com') {
      this.environmentName = 'https://' + this.appName + '.' + Environment.E2E + domainSuffix;
    } else if (hostname === 'qa.dealer.toyota.com' || hostname === 'dealer.qa.toyota.com') {
      this.environmentName = 'https://' + this.appName + '.' + Environment.QA + domainSuffix;
    } else if (hostname === 'stage.dealer.toyota.com' || hostname === 'dealer.stage.toyota.com') {
      this.environmentName = 'https://' + this.appName + '.' + Environment.STAGE + domainSuffix;
    } else {
      this.environmentName = 'https://' + this.appName + domainSuffix;
    }
  }

}