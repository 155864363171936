import { Pipe, PipeTransform } from '@angular/core';
import { IIncentive } from '../../../shared/models/incentives.model';

@Pipe({
    name: 'getincentivesbysource'
})
export class GetIncentivesBySourcePipe implements PipeTransform {

    transform(incentives: IIncentive[], source: string): IIncentive[] {
        return incentives.filter(t => t.incentiveSource === source).reverse() || [];
    }

}