import { CommonModule } from "@angular/common";
import { GlobalModule } from "./../global/global.module";
import { ImageCropperComponent } from "./components/image-cropper/image-cropper.component";
import { NumberOnlyDirective } from "./directives/numbers-only.directive";
import { NoScrollDirective } from "./directives/no-scroll.directive";
import { NgModule } from "@angular/core";
import { MaterialModule } from "./modules/material-module";
import { FilterPipe } from "./pipes/filter.pipe";
import { GroupByPipe } from "./pipes/group.pipe";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { TabDirective } from "./directives/tab-directive";
import { SelectAllDirective } from "./directives/select-all.directive";

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ImageCropperComponent,
    FilterPipe,
    GroupByPipe,
    NumberOnlyDirective,
    TabDirective,
    SelectAllDirective,
    NoScrollDirective
  ],
  exports: [
    MaterialModule,
    ImageCropperModule,
    ConfirmDialogComponent,
    ImageCropperComponent,
    FilterPipe,
    GroupByPipe,
    NumberOnlyDirective,
    TabDirective,
    SelectAllDirective,
    NoScrollDirective
  ],
  imports: [MaterialModule, CommonModule, GlobalModule, ImageCropperModule],
  entryComponents: [],
  providers: [],
})
export class SharedModule { }
