import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { UserProfileService } from "@toyota/dd365-platform-library";
import _ from "lodash";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { OfferTypes } from "../../shared/enums/offer-types.enum";
import { IDealerConfiguration, IFeatureList, IParameter } from "../../shared/models/dealer-configuration.model";
import { IKeyNamePair } from "../../shared/models/utility/key-label-pair.model";
import { SubscriptionList, unsubscribeSubscriptions, } from "../../shared/services/util.service";
import * as dealerConfigurationActions from '../../store/dealer-configuration/dealer-configuration.actions';
import { DealerConfigurationState } from "../../store/dealer-configuration/dealer-configuration.reducer";
import { selectDealerConfiguration, selectDisableActions, selectIsLoading } from "../../store/dealer-configuration/dealer-configuration.selectors";
import { LeaseValueOptionsEnum } from "./enums/lease-value-options.enum";
import { ILeaseFormParameter, ILeaseSettings } from "./models/lease-settings.model";
import { IProcessingOrderPair } from "./models/processing-order-pair.model";

@Component({
  selector: "app-lease-setting",
  templateUrl: "./lease-setting.component.html",
  styleUrls: ["./lease-setting.component.scss"],
})
export class LeaseSettingComponent implements OnInit, OnDestroy {
  private subs: SubscriptionList = {};
  private dealerCode: string;
  leaseSettingsForm: FormGroup;
  loading$: Observable<boolean>;
  disableActions$: Observable<boolean>;
  subCount: number = 0;
  hideShowroomDefaultColumn: boolean = true;

  allOptionValue = 'all';
  baseParameterType = 'BaseParameters';
  leaseParameterType = 'LeaseTypeParameters';
  equityAllocationOptions: IKeyNamePair[] = [
    { id: 'ApplyCashDownToUpfront', name: 'CASHDOWN' },
    { id: 'ApplyNetTradeToUpfront', name: 'TRADE' },
    { id: 'ApplyRebateToUpfront', name: 'REBATE' }
  ];
  processingOrderProperty = 'CCRProcessingOrder';
  private readonly signAndDriveKey = 'signAndDrive';
  processingOrderOptions: IProcessingOrderPair[] = [];
  readonly regularLease = 'regular';
  readonly regularLeaseType = 'lease';
  leaseTypeControlOptions: IKeyNamePair[] = [
    { id: 'regular', name: 'REGULAR_LEASE' },
    { id: 'onePayLease', name: 'ONE_PAY_LEASE' },
    { id: this.signAndDriveKey, name: 'SIGN_AND_DRIVE_LEASE' }
  ];
  equityLeaseTypeFilterOptions = [this.regularLeaseType, this.allOptionValue];

  constructor(
    private readonly userProfileService: UserProfileService,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly formBuilder: FormBuilder
  ) { }

  processingOrderOptionsDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.processingOrderOptions,
      event.previousIndex,
      event.currentIndex
    );
  }

  ngOnInit(): void {
    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.LoadDealerConfiguration({ dealerCode: this.dealerCode }));
    this.subs.selectDealerConfigurationParametersSub =
      this.dealerConfigurationState.select(selectDealerConfiguration).subscribe(dealerConfig => {
        this.setupLeaseSettingsForm(dealerConfig);
      })
    this.loading$ = this.dealerConfigurationState.select(selectIsLoading);
    this.disableActions$ = this.dealerConfigurationState.select(selectDisableActions);
  }

  resetLeaseSettingsForm() {
    this.dealerConfigurationState.select(selectDealerConfiguration).pipe(take(1)).subscribe(dealerConfig => {
      this.setupLeaseSettingsForm(dealerConfig);
    });
  }

  setupLeaseSettingsForm(dealerConfig: IDealerConfiguration) {
    const parameters = dealerConfig.policy.global.parameters;
    const featureList = dealerConfig.features;
    this.hideShowroomDefaultColumn = !!(_.has(featureList, this.signAndDriveKey) && featureList[this.signAndDriveKey]);
    this.setupEquityProcessingOrder(parameters);
    this.leaseSettingsForm = this.formBuilder.group({
      equityAllocation: this.formBuilder.array([]),
      leaseTypeControls: this.formBuilder.array([])
    });
    const equityAllocationForm = this.leaseSettingsForm.get('equityAllocation') as FormArray;
    const leaseTypeControlsForm = this.leaseSettingsForm.get('leaseTypeControls') as FormArray;
    this.setupEquityAllocationForm(equityAllocationForm, parameters);
    this.setupLeaseTypeControlsForm(leaseTypeControlsForm, parameters, featureList);
  }

  setupEquityProcessingOrder(parameters: IParameter[]) {
    this.processingOrderOptions = [
      { id: 'CashDown', mappingKey: 'ApplyCashDownToUpfront' },
      { id: 'Trade', mappingKey: 'ApplyNetTradeToUpfront' },
      { id: 'Rebate', mappingKey: 'ApplyRebateToUpfront' }
    ];
    const processingOrderData = _.find(parameters, t =>
      t.name === this.processingOrderProperty && t.type === this.baseParameterType
      && t.offerType === OfferTypes.LEASE
      && _.includes(this.equityLeaseTypeFilterOptions, t.leaseType)
    )?.value.split('_');
    if (processingOrderData) {
      this.processingOrderOptions.sort((a, b) =>
        processingOrderData.indexOf(a.id) - processingOrderData.indexOf(b.id));
    }
  }

  setupEquityAllocationForm(equityAllocationForm: FormArray, parameters: IParameter[]) {
    this.equityAllocationOptions.forEach(option => {
      const optionData = _.find(parameters, t =>
        t.name === option.id && t.type === this.baseParameterType
        && t.offerType === OfferTypes.LEASE
        && _.includes(this.equityLeaseTypeFilterOptions, t.leaseType)
      );
      const leaseType = _.find(parameters, t =>
        t.name === option.id && t.type === this.baseParameterType
        && t.offerType === OfferTypes.LEASE
        && !_.includes(this.equityLeaseTypeFilterOptions, t.leaseType)
      ) ? this.regularLeaseType : this.allOptionValue;
      const optionForm = this.formBuilder.group({
        name: [option.id],
        label: [{ value: option.name, disabled: true }, []],
        offerType: [OfferTypes.LEASE],
        leaseType: [optionData?.leaseType || leaseType],
        type: [this.baseParameterType],
        value: [optionData ? this.parseValuetoBoolean(optionData.value) : true]
      });
      this.subs['eqiutyOptionValue' + this.subCount++] =
        optionForm.get(['value']).valueChanges.subscribe(enabled => {
          if (!enabled) {
            const index = _.findIndex(this.processingOrderOptions, { mappingKey: option.id });
            moveItemInArray(this.processingOrderOptions, index, this.processingOrderOptions.length - 1);
          }
        });
      equityAllocationForm.push(optionForm);
    });
  }

  setupLeaseTypeControlsForm(leaseTypeControlsForm: FormArray, parameters: IParameter[], featureList: IFeatureList[]) {
    this.leaseTypeControlOptions.forEach(option => {
      if (option.id === this.regularLease || featureList[option.id]) {
        const optionData = _.find(parameters, { name: option.id, type: this.leaseParameterType, offerType: OfferTypes.LEASE });
        const isRegularLease = option.id === this.regularLease;
        const isSignAndDrive = option.id === this.signAndDriveKey;
        const optionForm = this.formBuilder.group({
          name: [option.id],
          label: [{ value: option.name, disabled: true }, []],
          offerType: [OfferTypes.LEASE],
          leaseType: [optionData?.leaseType || this.allOptionValue],
          type: [this.leaseParameterType],
          value: [optionData ? this.parseValuetoBoolean(optionData.value) : isRegularLease],
          default: [optionData ? this.parseValuetoBoolean(optionData.default) : isRegularLease]
        });
        const optionValue = optionForm.get(['value']);
        const optionDefault = optionForm.get(['default']);
        this.subs['leaseTypeOptionValue' + this.subCount++] =
          optionValue.valueChanges.subscribe(enabled => {
            if (enabled) {
              optionDefault.enable({ emitEvent: false });
            } else {
              optionDefault.patchValue(false);
              optionDefault.disable({ emitEvent: false });
            }
          });
        this.subs['leaseTypeOptionDefault' + this.subCount++] =
          optionDefault.valueChanges.subscribe(enabled => {
            if (enabled) {
              this.disableOtherLeaseTypeDefaults(optionForm, leaseTypeControlsForm);
            }
            else if (isSignAndDrive) {
              this.enableRegularLeaseAsDefault(leaseTypeControlsForm);
            }
          });
        leaseTypeControlsForm.push(optionForm);
        optionValue.updateValueAndValidity();
        optionDefault.updateValueAndValidity();
      }
    });
  }

  disableOtherLeaseTypeDefaults(optionForm: FormGroup, leaseTypeControlsForm: FormArray) {
    optionForm.get(['default']).disable({ emitEvent: false });
    leaseTypeControlsForm.controls.forEach(leaseTypeForm => {
      if (!_.isEqual(optionForm, leaseTypeForm)) {
        leaseTypeForm.get(['default']).patchValue(false, { emitEvent: false });
        if (leaseTypeForm.get(['value']).value) {
          leaseTypeForm.get(['default']).enable({ emitEvent: false });
        }
      }
    });
  }

  enableRegularLeaseAsDefault(leaseTypeControlsForm: FormArray) {
    const leaseTypeControls: ILeaseFormParameter[] = leaseTypeControlsForm.value;
    if (_.findIndex(leaseTypeControls, { default: true }) < 0) {
      const regularLeaseDefaultControl =
        _.find(leaseTypeControlsForm.controls,
          (t: FormGroup) => t.value.name === this.regularLease).get(['default']);
      regularLeaseDefaultControl.patchValue(true);
    }
  }

  parseValuetoBoolean(value: string) {
    return value === LeaseValueOptionsEnum.YES;
  }

  parseValuetoString(value: boolean) {
    return value ? LeaseValueOptionsEnum.YES : LeaseValueOptionsEnum.No;
  }

  saveLeaseSettings() {
    const leaseSettings: ILeaseSettings = this.leaseSettingsForm.getRawValue();
    this.dealerConfigurationState.select(selectDealerConfiguration).pipe(take(1)).subscribe(dealerConfig => {
      const dealerConfiguration: IDealerConfiguration = _.cloneDeep(dealerConfig);
      this.parseFormToSave(leaseSettings, dealerConfiguration);
      this.dealerConfigurationState.dispatch(new dealerConfigurationActions.UpdateDealerConfiguration({
        dealerCode: this.dealerCode, dealerConfiguration
      }));
    });
  }

  parseFormToSave(leaseSettings: ILeaseSettings, dealerConfig: IDealerConfiguration) {
    this.updateDealerConfigParameters(leaseSettings.equityAllocation, dealerConfig, false);
    this.updateDealerConfigParameters(leaseSettings.leaseTypeControls, dealerConfig, true);
    this.updateEquityProcessingOrder(dealerConfig);
  }

  updateDealerConfigParameters(
    leaseFormParameters: ILeaseFormParameter[],
    dealerConfig: IDealerConfiguration, updateDefault: boolean
  ) {
    const parameters = dealerConfig.policy.global.parameters;
    leaseFormParameters.forEach(option => {
      const optionData = _.find(parameters, {
        name: option.name, type: option.type, offerType: option.offerType, leaseType: option.leaseType
      });
      const valueData = this.parseValuetoString(option.value);
      const defaultData = updateDefault ? this.parseValuetoString(option.default) : undefined;
      if (optionData) {
        optionData.value = valueData;
        optionData.default = defaultData;
      }
      else {
        dealerConfig.policy.global.parameters.push({
          name: option.name, offerType: option.offerType, type: option.type,
          leaseType: option.leaseType, value: valueData, default: defaultData
        });
      }
    });
  }

  updateEquityProcessingOrder(dealerConfig: IDealerConfiguration) {
    const parameters = dealerConfig.policy.global.parameters;
    const processingOrder = this.processingOrderOptions.map(t => t.id).join('_');
    const optionData = _.find(parameters, t =>
      t.name === this.processingOrderProperty && t.type === this.baseParameterType
      && t.offerType === OfferTypes.LEASE
      && _.includes(this.equityLeaseTypeFilterOptions, t.leaseType)
    );
    const leaseType = _.find(parameters, t =>
      t.name === this.processingOrderProperty && t.type === this.baseParameterType
      && t.offerType === OfferTypes.LEASE
      && !_.includes(this.equityLeaseTypeFilterOptions, t.leaseType)
    ) ? this.regularLeaseType : this.allOptionValue;
    if (optionData) {
      optionData.value = processingOrder;
    } else {
      dealerConfig.policy.global.parameters.push({
        name: this.processingOrderProperty, offerType: OfferTypes.LEASE,
        type: this.baseParameterType, leaseType, value: processingOrder
      });
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

}
