import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { GlobalModule } from "../../global/global.module";
import { SharedModule } from "../../shared/shared.module";
import { IncentivesComponent } from "./incentives.component";
import { IncentivesRoutingModule } from "./incentives-routing.module";
import { LuxonDateAdapter, MAT_LUXON_DATE_FORMATS } from '../../shared/services/luxon-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ListIncentivesComponent } from "./list-incentives/list-incentives.component";
import { ViewIncentiveComponent } from "./view-incentive/view-incentive.component";
import { ModifyIncentiveComponent } from "./modify-incentive/modify-incentive.component";
import { LenderDisplayNamePipe } from './pipes/lender-display-name.pipe';
import { GetIncentiveStatusPipe } from "./pipes/get-incentive-status.pipe";
import { GetIncentivesBySourcePipe } from "./pipes/get-incentives-by-source.pipe";
import { GetBrandSeriesPipe } from './pipes/get-brand-series.pipe';
import { FilterModelCodesPipe } from './pipes/filter-model-codes.pipe';
import { FilterBrandListPipe } from './pipes/filter-brand-list.pipe';
import { GetSalesclassesForIncentivePipe } from './pipes/get-salesclasses-for-incentive.pipe';
@NgModule({
  declarations: [
    IncentivesComponent,
    ListIncentivesComponent,
    ViewIncentiveComponent,
    ModifyIncentiveComponent,
    LenderDisplayNamePipe,
    GetIncentiveStatusPipe,
    GetIncentivesBySourcePipe,
    GetBrandSeriesPipe,
    FilterModelCodesPipe,
    FilterBrandListPipe,
    GetSalesclassesForIncentivePipe
  ],
  imports: [
    IncentivesRoutingModule,
    TranslateModule.forChild({ extend: true }),
    CommonModule, GlobalModule, SharedModule
  ],
  providers: [
    LuxonDateAdapter,
    FilterModelCodesPipe,
    GetBrandSeriesPipe,
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS }
  ]
})
export class IncentivesModule { }
