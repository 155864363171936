import { FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { IProductConfiguration } from '../../../shared/models/protection-products.model';
import { ProtectionProductsComponentService } from '../protection-products.component.service';

@Pipe({
    name: 'getpencildefaults'
})
export class GetPencilDefaultsPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(product: IProductConfiguration, productForm: FormGroup): AbstractControl[] {
        const pencilDefaults = productForm.get(['global', 'firstPencilDefaults']) as FormArray;
        if (pencilDefaults) {
            return pencilDefaults.controls;
        }
        else {
            return [];
        }
    }
}