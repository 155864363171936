import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { GenericSelections } from "../../../shared/enums/generic-selections.enum";

@Pipe({
    name: "disableonallselection"
})
export class DisableOnAllSelectionPipe implements PipeTransform {

    transform(values: string[]): boolean {
        return _.includes(values, GenericSelections.ALL);
    }

}