import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pe-deploy-job-summary',
  templateUrl: './deploy-job-summary.component.html',
  styleUrls: ['./deploy-job-summary.component.scss'],
})
export class DeployJobSummaryComponent implements OnInit, OnDestroy {

  public isOfferSummary = false;
  public isPPSummary = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data.jobId.includes('OFFER')) {
      this.isOfferSummary = true;
    } else {
      this.isPPSummary = true;
    }
  }

  ngOnDestroy(): void {
    this.isOfferSummary = false;
    this.isPPSummary = false;
  }
}
