import { DealerInfoService } from '../services/dealer-info.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { canActivateOnlyOnDev } from '../services/util.service';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class CanActivateOnlyOnDev implements CanActivate {
    constructor(private readonly router: Router) { }

    canActivate(): boolean {
        if (canActivateOnlyOnDev()) {
            return true;
        }
        else {
            this.router.navigate(['/pe-admin']);
            return false;
        }
    }

}