<dd-spinner *ngIf="loading">
</dd-spinner>
<div class="mx-5 mt-3" [formGroup]="auditFormGroup">
    <div class="row no-gutters">
        <div class="d-flex flex-column">
            <mat-label class="my-2 fw-bold required-val">{{'LOCALIZATION.AUDIT.MODULE' | translate}}</mat-label>
            <mat-select class="module-dropdown" multiple formControlName="module"
                [ngClass]="{'input-error': auditFormGroup.controls.module.invalid}">
                <mat-option *ngFor="let module of moduleList" [value]="module.value">
                    {{module.name}}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="d-flex flex-column pos-relative">
            <mat-label class="my-2 fw-bold required-val">{{'LOCALIZATION.AUDIT.FROM' | translate}}</mat-label>
            <input matInput autocomplete="off" class="product-input date-picker-input" [matDatepicker]="startDate"
                [ngClass]="{'input-error': auditFormGroup.controls.startDate.invalid || auditFormGroup.errors?.dateComparisonError}"
                formControlName="startDate">
            <mat-datepicker-toggle disableRipple matSuffix [for]="startDate"
                style="position: absolute; top: 25px; right: 0;">
                <mat-icon matDatepickerToggleIcon>
                    <em class="tmi tmi-date"></em>
                </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </div>
        <div class="d-flex flex-column pos-relative ml-5">
            <mat-label class="my-2 fw-bold required-val">{{'LOCALIZATION.AUDIT.TO' | translate}}</mat-label>
            <input matInput autocomplete="off" class="product-input date-picker-input" [matDatepicker]="endDate"
                [ngClass]="{'input-error': auditFormGroup.controls.endDate.invalid || auditFormGroup.errors?.dateComparisonError}"
                formControlName="endDate">
            <mat-datepicker-toggle disableRipple matSuffix [for]="endDate"
                style="position: absolute; top: 25px; right: 0;">
                <mat-icon matDatepickerToggleIcon>
                    <em class="tmi tmi-date"></em>
                </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </div>
        <div class="d-flex flex-column ml-5">
            <mat-label class="my-2 fw-bold">{{'LOCALIZATION.AUDIT.USER' | translate}}</mat-label>
            <input class="product-input" type="text" formControlName="userName" maxlength="50" />
        </div>
        <div class="d-flex flex-column ml-4 justify-content-end">
            <input type="button" class="primary-button search-btn" (click)="getLogs(true)" [value]="'LOCALIZATION.COMMON.SEARCH' | translate" />
        </div>
    </div>
    <div class="row no-gutters">
        <table aria-describedby="tableHd" class="table example-full-width mt-3">
            <thead>
                <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.MODULE' | translate}}</th>
                <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.TIMESTAMP' | translate}}</th>
                <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.USER' | translate}}</th>
                <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.ACTION' | translate}}</th>
            </thead>
            <tbody>
                <tr [ngClass]="{'border-bottom': !lastRow}"
                    *ngFor="let log of auditLogs, let i = index, last as lastRow, first as firstRow">
                    <td>
                        {{log.module}}
                    </td>
                    <td>
                        {{log.date}}
                    </td>
                    <td>
                        {{log.userName + ' (' + log.userId + ')'}}
                    </td>
                    <td>
                        <span *ngIf="log.description.length <= 1 else descriptionList">
                            {{log.description?.length ? log.description[0] : ''}}
                        </span>
                        <ng-template #descriptionList>
                            <ul class="ml-4">
                                <li *ngFor="let logDescItem of log.description">
                                    {{logDescItem}}
                                </li>
                            </ul>
                        </ng-template>
                    </td>
                </tr>
                <tr *ngIf="!auditLogs?.length">
                    <td colspan="4">
                        <mat-label class="d-flex pt-2 justify-content-center">{{'LOCALIZATION.AUDIT.NO_RECORDS' | translate}}</mat-label>
                    </td>
                </tr>
            </tbody>
        </table>
        <mat-paginator #auditPaginator class="w-100" [length]="totalItems" [pageSize]="pageSizeOptions[0]"
            [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
        </mat-paginator>
    </div>
</div>