import { Pipe, PipeTransform } from '@angular/core';
import { ProtectionProductsComponentService } from '../protection-products.component.service';
import * as _ from 'lodash';

@Pipe({
    name: 'getmutexplan'
})
export class GetMutexPlanPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(planId: string, formId: string, productCode: string, productId: string): any {
        const mutexPlans = this.pPComponentService.getMutexPlans(productCode, formId, planId);
        const conflictingPlans = this.pPComponentService.getConflictingPlans(productId, mutexPlans);
        if (conflictingPlans.length > 0) {
            return `The following conflicting plans have already been included.\r\n`
                + conflictingPlans.map((t, i) =>
                    `${i + 1}. ${t.productId} - ${!this.pPComponentService.isTfsDealer ? t.formId + ' - ' : ''}${t.planId}`
                ).join('\r\n');

        }
        else {
            return '';
        }
    }

}
