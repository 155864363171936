import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  IDeploymentList, IDeploymentJob, IDeployJobSummary, IDeploymentPlan, IDeployment
} from '../../../shared/models/deploy-job-management.model';
import { DeployJobSummaryComponent } from '../deploy-job-summary/deploy-job-summary.component'
import { MatDialog } from '@angular/material/dialog';
import { DeployJobManageService } from '../../../shared/services/deploy-job-manage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DeployJobManageState } from '../../../store/deploy-job-management/deploy-job-management.reducer';
import { selectDeployJobManagement, selectDeployConfiguration, selectAuditLogIsLoading } from '../../../store/deploy-job-management/deploy-job-management.selectors';
import { Observable } from 'rxjs';
import { SubscriptionList, canActivateOnlyOnDev, unsubscribeSubscriptions } from '../../../shared/services/util.service';
import { UserProfileService } from '@toyota/dd365-platform-library';
import _ from 'lodash';
import * as deployJobManagementActions from '../../../store/deploy-job-management/deploy-job-management.actions';
import { mockDeployJobManagement } from '../../../../test/deploy-job-management/deploy-job-management.mock';
import { IGetAuditLogsResponse } from '../../../shared/models/audit-log.model';
import { DeployJobPlanComponent } from '../deploy-job-plan/deploy-job-plan.component';
import { DeployJobAuditlogsComponent } from '../deploy-job-auditlogs/deploy-job-auditlogs.component';
import { take } from 'rxjs/operators';
import { DiscardConfirmationComponent } from '../discard-confirmation/discard-confirmation.component';
@Component({
  selector: 'app-pe-view-deploy-job',
  templateUrl: './view-deploy-job.component.html',
  styleUrls: ['./view-deploy-job.component.scss'],
})
export class ViewDeployJobComponent implements OnInit, OnDestroy {
  deployJobConfiguration$: Observable<IDeploymentList>;
  private subs: SubscriptionList = {};
  deployData: IDeploymentList;
  unfilterDeployment: Array<IDeployment>;
  deployConfig: IGetAuditLogsResponse;
  dealerCode: string;
  isLoading: boolean;
  isReloadDJConfig: boolean;
  refreshSpinner = false;
  deploymentEndStatus = ['complete', 'completed', 'failed', 'discarded'];
  plansToInclude = ['offer-cache', 'pp-cache']
  auditLoading$: Observable<boolean>;

  constructor(
    private readonly deployJobManageState: Store<DeployJobManageState>,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private translateService: TranslateService,
    private userProfileService: UserProfileService,
    private deployJobManageService: DeployJobManageService) {

  }

  ngOnInit(): void {
    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    this.auditLoading$ = this.deployJobManageState.select(
      selectAuditLogIsLoading
    );
    this.subs.selectDeployJobListSub =
      this.deployJobManageState.select(selectDeployJobManagement).subscribe(deployJob => {
        if (deployJob) {
          this.deployData = _.cloneDeep(deployJob);
          this.mapShowPlan();
        }
      })

    this.subs.selectDeployJobConfigSub =
      this.deployJobManageState.select(selectDeployConfiguration).subscribe(deployJobConfig => {
        if (deployJobConfig && deployJobConfig.trails) {
          this.deployConfig = deployJobConfig;
        }
      })
  }


  showSummary(job: IDeploymentJob) {
    const data = {
      jobId: job.jobId,
      summary: job.summaryReport
    }
    this.dialog.open(DeployJobSummaryComponent, {
      panelClass: ['deploy-job-summary'],
      minWidth: '64%',
      disableClose: true,
      data
    });
  }

  showPlan(event: any, plans: IDeploymentPlan[]) {
    event.stopPropagation();
    this.dialog.open(DeployJobPlanComponent, {
      panelClass: ['deploy-job-summary'],
      minWidth: '50%',
      disableClose: true,
      data: plans
    });
  }

  showAuditLogs(event: any, dep_id: string, comments?: string) {
    event.stopPropagation();
    this.dialog.open(DeployJobAuditlogsComponent, {
      panelClass: ['deploy-job-summary'],
      minWidth: '50%',
      maxHeight: '95vh',
      disableClose: true,
      data: { dep_id, comments }
    });
  }

  downloadDetail(job: IDeploymentJob) {
    this.isLoading = true;
    const detailQueryParam = {
      dealer: this.dealerCode,
      jobId: job.jobId
    }
    this.deployJobManageService.getJobDeploymentDetailCSV(detailQueryParam).subscribe((data: any) => {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = job.jobId;
      anchor.href = url;
      anchor.click();
      this.isLoading = false;
      this.snackBar.open(this.translateService.instant('LOCALIZATION.DEPLOYMENT_JOB.DOWNLOAD_DETAIL_SUCCESS'), this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 });
    }, (error) => {
      this.isLoading = false;
      this.snackBar.open(this.translateService.instant('LOCALIZATION.DEPLOYMENT_JOB.DOWNLOAD_DETAIL_ERROR'), this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 });
    })
  }

  discardDraftConfirmation() {
    const dialogRef = this.dialog.open(DiscardConfirmationComponent, {
      panelClass: [],
      minWidth: '500px',
      disableClose: true
    });
    const afterClosedSub = dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === 'discard') {
        this.discardDraftConfiguration(result?.comments);
      }
      afterClosedSub.unsubscribe();
    });
  }

  discardDraftConfiguration(comments?: string) {
    this.isLoading = true;
    this.deployJobManageService.discardDraftConfiguration(this.dealerCode, { comments }).pipe(take(1)).subscribe((data: any) => {
      this.isLoading = false;
      this.deployData.deployments.unshift(data);
      this.mapShowPlan();
      this.snackBar.open(this.translateService.instant('LOCALIZATION.DEPLOYMENT_JOB.DISCARD_TRIGGER_SUCCESS'), this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 });
    }, (error) => {
      this.isLoading = false;
      if (error.error && error.error.message) {
        this.snackBar.open(error.error.message, this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 });
      } else {
        this.snackBar.open(this.translateService.instant('LOCALIZATION.DEPLOYMENT_JOB.DEPLOYMENT_ERROR'), this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 });
      }
    })
  }

  deployConfigurations() {
    this.isLoading = true;
    const dealerCode = this.dealerCode
    const deployBody = {
      dealerCode
    }
    this.deployJobManageService.deployConfigurations(dealerCode).pipe(take(1)).subscribe((data: any) => {
      this.isLoading = false;
      this.deployData.deployments.unshift(data);
      this.mapShowPlan();
      this.snackBar.open(this.translateService.instant('LOCALIZATION.DEPLOYMENT_JOB.DEPLOYMENT_SUCCESS'), this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 });
    }, (error) => {
      this.isLoading = false;
      if (error.error && error.error.message) {
        this.snackBar.open(error.error.message, this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 });
      } else {
        this.snackBar.open(this.translateService.instant('LOCALIZATION.DEPLOYMENT_JOB.DISCARD_TRIGGER_FAILURE'), this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 });
      }
    })
  }

  reloadDeploymentItem(event: any, deploy: IDeployment, id: string) {
    event.stopPropagation();
    this.isReloadDJConfig = deploy.refreshSpinner = true;
    this.deployJobManageState.dispatch(
      new deployJobManagementActions.LoadDeployJobConfig({ dealerCode: this.dealerCode, isReloadDJConfig: this.isReloadDJConfig })
    );
    this.subs.selectDJConfigSub =
      this.deployJobManageState.select(selectDeployConfiguration).subscribe(deployJobConfig => {
        if (deployJobConfig && deployJobConfig.trails) {
          this.deployConfig = deployJobConfig;
        }
      })

    this.subs.getDeployIdSub = this.deployJobManageService.getDeploymentById(deploy.deploymentId, this.dealerCode).subscribe((data: any) => {
      const index = _.findIndex(this.deployData.deployments, { deploymentId: data.deploymentId });
      // Replace item at index using native splice
      this.deployData.deployments.splice(index, 1, data);
      this.mapShowPlan();
      this.isReloadDJConfig = deploy.refreshSpinner = false;
    }, (error) => {
      this.isReloadDJConfig = deploy.refreshSpinner = false;
      this.snackBar.open(this.translateService.instant('LOCALIZATION.DEPLOYMENT_JOB.DEPLOYMENT_ITEM_ERROR'), this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 });
    })
  }

  mapShowPlan() {
    _.map(this.deployData.deployments, item => {
      const plans = _.filter(item.plans, plan => this.plansToInclude.includes(plan.name));
      item.showPlan = plans && plans.length > 0 ? true : false;
    })
  }

  toggleNightlyDeploymentList(event) {
    if (event.checked) {
      this.unfilterDeployment = this.deployData.deployments;
      this.deployData.deployments = _.filter(this.deployData.deployments, item => item.source && item.source.toLocaleLowerCase() !== 'nightlyjob');
    } else {
      this.deployData.deployments = this.unfilterDeployment;
    }
  }

  canActivateOnlyOnDev() {
    return canActivateOnlyOnDev();
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }
}
