import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Guid, UserProfileService } from '@toyota/dd365-platform-library';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor{
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const requireToken: boolean = request.headers.has('set_token')
      ? request.headers.get('set_token').toLowerCase() === 'true'
      : false;
    //
    // Remove Header
    //
    const headers = request.headers.delete('set_token');
    let headersToSet = {};
    //
    // Set the Authorization Header
    //
    if (requireToken) {
      if (this._userProfileService.getProfile().token) {
        headersToSet = {
          Authorization: `Bearer ${this._userProfileService.getProfile().token}`,
          'X-B3-TraceId': `${Guid.UUID()}`,
          'X-B3-SpanId': `${Guid.UUID()}`
        };
      }
    }
    request = request.clone({
      headers,
      setHeaders: headersToSet
    });
    return next.handle(request);
  }

  /**
   * constructor()
   */
  constructor(private _userProfileService: UserProfileService) {}
}
