<div [formGroup]="showroomOnlineFormGroup" class="offset-lg-2 col-lg-8 offer-default mt-3"
    *ngIf="showroomOnlineFormGroup">
    <mat-label class="d-flex mb-3">{{'LOCALIZATION.PAYMENT_GRID_GLOBAL.HEADER_NOTE' | translate}}</mat-label>
    <mat-label class="default-terms-header">{{'LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.HEADER' |
        translate:({"gridType": selectedTab | titlecase})}}</mat-label>
    <div *ngFor="let salesClass of configurableSalesClasses, last as lastRow" class="bodyDiv container-fluid"
        [ngClass]="{'mb-0': lastRow}" [formGroupName]="salesClass">
        <mat-label class="bodyDivHeader">{{salesClass | titlecase}}</mat-label>
        <div [formGroupName]="offer" *ngFor="let offer of salesClass | offersForShowroomonline : selectedTab : true">
            <div class="d-flex">
                <div class="col-md-12">
                    <div class="d-flex flex-column"
                        *ngFor="let downPayment of showroomOnlineFormGroup.controls[salesClass].controls[offer].controls?.downPayment?.value; let i=index"
                        formArrayName="downPayment">
                        <span
                            *ngIf="downPayment.measure === showroomOnlineFormGroup.controls[salesClass].controls[offer].controls?.downPaymentMeasure?.value">
                            <mat-label class="my-2 fw-bold required-val w-275-px"
                                *ngIf="selectedTab === paymentGridTypes.SHOWROOM">
                                {{'LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.PAYMENTOPTIONS' | translate:({"offerType":
                                offer | titlecase})}}
                            </mat-label>
                            <mat-label class="my-2 fw-bold required-val w-275-px"
                                *ngIf="selectedTab===paymentGridTypes.ONLINE">
                                {{ 'LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.PAYMENTOPTIONS_ONLINE' | translate: {
                                'offers': configurableOffers[salesClass] | offersOnlineConfig | titlecase } }}
                            </mat-label>
                            <mat-chip-list #chipList aria-label="Down Payments" [multiple]="false"
                                [disabled]="disableFlag">
                                <mat-chip *ngFor="let downPaymentinfo of downPayment.downPaymentOptions;let j=index"
                                    (click)="downPaymentChanged(downPaymentinfo,salesClass,offer,i,disableFlag)"
                                    (removed)="remove(downPaymentinfo,salesClass,offer,i)"
                                    [ngClass]="{'selected-mat-chip': (downPaymentinfo === downPayment.defaultDownPayment), 'input-error bg-color-white': (downPayment.defaultDownPayment === null || downPayment.defaultDownPayment === '' || downPayment.defaultDownPayment === undefined)}">
                                    <span *ngIf="downPayment.measure === 'amount'">$</span>{{downPaymentinfo}}<span
                                        *ngIf="downPayment.measure === 'percentage'">%</span>
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input class=" down-payment-input no-number-arrow" type="number" step="any" title
                                    min="0" [ngClass]="{'input-error': downPayment.downPaymentOptions.length == 0 }"
                                    placeholder="Enter New Down Payment" title [matChipInputFor]="chipList"
                                    [matTooltip]="'LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.DOWNPAYMENT_INPUT_TOOLTIP' | translate"
                                    matTooltipShowDelay="500" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addDownpaymentValidation($event,salesClass,offer,getmaxRange(salesClass,offer),i)">
                            </mat-chip-list>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="d-flex col-md-6">
                    <div class="d-flex flex-column">
                        <mat-label class="my-2 fw-bold required-val w-275-px">
                            {{'LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.PAYMENTTYPE_NOTE' | translate }}
                        </mat-label>
                        <span class="paymentTypeButton">
                            <mat-button-toggle-group class="product-button-toggle" formControlName="downPaymentMeasure"
                                ngDefaultControl>
                                <mat-button-toggle [value]="grossProfitTypes.AMOUNT" aria-label="Text align left"> $
                                </mat-button-toggle>
                                <mat-button-toggle [value]="grossProfitTypes.PERCENT" aria-label="Text align center"> %
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </span>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="selectedTab === paymentGridTypes.SHOWROOM">
                    <div class="d-flex flex-column">
                        <mat-label class="my-2 fw-bold required-val w-275-px">
                            {{'LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.PAYMENTTYPE_OFFERRATETYPE' |
                            translate:({"offerType": offer | titlecase})}}
                        </mat-label>
                        <mat-select formControlName="defaultOfferType"
                            [ngClass]="{'input-error': showroomOnlineFormGroup.controls[salesClass].controls[offer].controls?.defaultOfferType?.value == '' }"
                            ngDefaultControl>
                            <mat-option value="">
                                {{'LOCALIZATION.COMMON.SELECT' | translate | titlecase}}
                            </mat-option>
                            <mat-option value="standard">
                                Standard
                            </mat-option>
                            <mat-option value="subvented">
                                Subvented
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div class="col-md-12">
                <div class="d-flex flex-column" (cdkDropListDropped)="processingOrderOptionsDrop($event,salesClass)"
                    cdkDropList>
                    <mat-label
                        class="my-2 fw-bold required-val w-275-px">{{'LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.ORDER_LABEL'
                        | translate}}</mat-label>
                    <div class="row clearfix" style="width: 100%;"
                        *ngFor="let orderOption of showroomOnlineFormGroup.controls[salesClass].controls.order.value; let i=index">
                        <mat-label style="width: 100%; padding-left: 10px;">
                            <span class="float-l dragSpan"
                                [ngClass]="{'disabled-dragBox': (selectedTab === paymentGridTypes.SHOWROOM && orderOption == 'cash')}">{{i+1}}.
                            </span>
                            <div class="dragBox float-l"
                                [ngClass]="{'disabled-dragBox': (selectedTab === paymentGridTypes.SHOWROOM && orderOption == 'cash') || disableFlag}"
                                [cdkDragDisabled]="(selectedTab === paymentGridTypes.SHOWROOM && orderOption == 'cash') || disableFlag"
                                cdkDrag>
                                <div class="tmi icon tmi-move">
                                </div> {{orderOption | titlecase}}
                            </div>
                        </mat-label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex mb-2" *ngIf="!(disableActions$ | async)">
        <input type="button" class="secondary-button mt-3" (click)="resetDefaultTerms()"
            [value]="translateService.instant('LOCALIZATION.COMMON.RESET')">
        <input type="button" [disabled]="pgformValidEvent == 'INVALID' || !showroomOnlineFormGroup.valid"
            class="primary-button mt-3 ml-3" (click)="savePaymentGrid()"
            [value]="translateService.instant('LOCALIZATION.COMMON.SAVE')" />
        <p class="mt-3 ml-1">
            {{'LOCALIZATION.PAYMENT_GRID.INVALID_CONFIG' | translate}}
        </p>
    </div>
    <div class="d-flex mb-1">
        <p class="mt-1 ml-1">
            <b style="color: red;">*</b> indicates a required field
        </p>
    </div>
</div>