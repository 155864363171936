import { MatSnackBar } from "@angular/material/snack-bar";
import { AccessLevels } from "./../../../shared/enums/access-levels.enum";
import { AccessControlService } from "./../../../shared/services/access-control.service";
import { ProtectionProductsComponentService } from "./../protection-products.component.service";
import { Observable } from "rxjs";
import { selectIsLoading } from "./../../../store/protection-products/protection-products.selectors";
import { ProtectionProductsState } from "./../../../store/protection-products/protection-products.reducer";
import * as protectionProductsActions from "../../../store/protection-products/protection-products.actions";
import {
  SubscriptionList,
  unsubscribeSubscriptions,
} from "./../../../shared/services/util.service";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { DealerConfigurationState } from "../../../store/dealer-configuration/dealer-configuration.reducer";
import { selectDisableActions } from "../../../store/dealer-configuration/dealer-configuration.selectors";

@Component({
  selector: "app-protection-products-list",
  templateUrl: "./protection-products-list.component.html",
  styleUrls: ["./protection-products-list.component.scss"],
})
export class ProtectionProductsListComponent implements OnInit {
  private subs: SubscriptionList = {};
  isLoading$: Observable<boolean>;

  constructor(
    private readonly protectionProductsState: Store<ProtectionProductsState>,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    readonly pPComponentService: ProtectionProductsComponentService,
    readonly accessControlService: AccessControlService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.pPComponentService.setSelectedProduct(undefined);
    const accessLevel = this.accessControlService.getProtectionProductsAccessLevel();
    this.isLoading$ = this.protectionProductsState.select(selectIsLoading);
    this.subs.selectDisableActionsSub = this.dealerConfigurationState.select(selectDisableActions).subscribe(disableActions => {
      this.setAccess(accessLevel);
      this.pPComponentService.isReadOnly = this.pPComponentService.isReadOnly || disableActions;
    });
    this.subs.setupFormSub = this.pPComponentService.setupForm();
  }

  setAccess(accessLevel: number) {
    this.pPComponentService.isReadOnly = true;
    this.pPComponentService.hasPacAccess = false;
    if (accessLevel >= AccessLevels.WRITE) {
      this.pPComponentService.isReadOnly = false;
    }
    if (accessLevel >= AccessLevels.FULL) {
      this.pPComponentService.hasPacAccess = true;
    }
  }

  submitForm() {
    if (
      this.pPComponentService.protectionProductsForm.valid &&
      !this.pPComponentService.isReadOnly
    ) {
      const protectionProducts =
        this.pPComponentService.protectionProductsForm.getRawValue();
      this.protectionProductsState.dispatch(
        new protectionProductsActions.UpdateProtectionProducts({
          dealerCode: this.pPComponentService.dealerCode,
          protectionProducts,
        })
      );
    } else {
      this.snackBar.open(
        "The protection products configuration is invalid. Errors are highlighted.",
        "Dismiss",
        { duration: 4000 }
      );
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }
}
