import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { GenericSelections } from "../../../shared/enums/generic-selections.enum";
import { IVehicleBrand, IVehicleModel } from "../../../shared/models/vehicle-config-master.model";

@Pipe({
    name: "filtermodelcodes"
})
export class FilterModelCodesPipe implements PipeTransform {

    transform(
        campaignMake: string, campaignModels: string[],
        campaignYears: string[], brandVehicleConfig: IVehicleBrand[]
    ): IVehicleModel[] {
        const vehicleConfig = _.find(brandVehicleConfig, { name: campaignMake });
        const seriesConfig = _.filter(vehicleConfig?.series || [], (series) =>
            campaignModels.includes(series.code)
        );
        const modelConfig: IVehicleModel[] = [];
        const showAllModel = _.indexOf(campaignYears, GenericSelections.ALL) >= 0;
        seriesConfig.forEach(series => {
            const models = _.filter(series.models, (model) => showAllModel || campaignYears.includes(model.year));
            modelConfig.push(...models);
        });
        return modelConfig;
    }

}