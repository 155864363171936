export enum AuditModuleList {
    Offer_Markup = 'Offer Markup',
    Lease_Settings = 'Lease Settings',
    Payment_Grid_Details = 'Payment Grid Details',
    Third_Party_Lender = '3rd Party Lender',
    Gross_Profit_Configuration = 'Gross Profit Configuration',
    Dealer_Incentive = 'Dealer Incentive',
    Vehicle_Pac = 'Vehicle Pac'
}

export enum PPAuditModuleList {
    Protection_Products = 'Protection Products'
}