import { Action } from '@ngrx/store';
import { IIncentive } from '../../shared/models/incentives.model';

export enum IncentivesActionTypes {
    LOAD_INCENTIVES = '[Incentives] Load Incentives',
    LOAD_INCENTIVES_SUCCESS = '[Incentives] Load Incentives success',
    LOAD_INCENTIVES_FAILURE = '[Incentives] Load Incentives failure',
    UPDATE_INCENTIVE = '[Incentives] Update Incentive',
    UPDATE_INCENTIVE_SUCCESS = '[Incentives] Update Incentive success',
    UPDATE_INCENTIVE_FAILURE = '[Incentives] Update Incentive failure',
    DELETE_INCENTIVE = '[Incentives] Delete Incentive',
    DELETE_INCENTIVE_SUCCESS = '[Incentives] Delete Incentive success',
    DELETE_INCENTIVE_FAILURE = '[Incentives] Delete Incentive failure'
}

export class LoadIncentives implements Action {
    readonly type = IncentivesActionTypes.LOAD_INCENTIVES;
    constructor(public payload: { dealerCode: string }) {
    }
}

export class LoadIncentivesSuccess implements Action {
    readonly type = IncentivesActionTypes.LOAD_INCENTIVES_SUCCESS;
    constructor(public payload: { incentives: IIncentive[] }) {
    }
}

export class LoadIncentivesFailure implements Action {
    readonly type = IncentivesActionTypes.LOAD_INCENTIVES_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class UpdateIncentive implements Action {
    readonly type = IncentivesActionTypes.UPDATE_INCENTIVE;
    constructor(public payload: { incentive: IIncentive }) {
    }
}

export class UpdateIncentiveSuccess implements Action {
    readonly type = IncentivesActionTypes.UPDATE_INCENTIVE_SUCCESS;
    constructor(public payload: { incentive: IIncentive }) {
    }
}

export class UpdateIncentiveFailure implements Action {
    readonly type = IncentivesActionTypes.UPDATE_INCENTIVE_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class DeleteIncentive implements Action {
    readonly type = IncentivesActionTypes.DELETE_INCENTIVE;
    constructor(public payload: { incentiveId: string, dealerCode: string }) {
    }
}

export class DeleteIncentiveSuccess implements Action {
    readonly type = IncentivesActionTypes.DELETE_INCENTIVE_SUCCESS;
    constructor(public payload: { incentiveId: string }) {
    }
}

export class DeleteIncentiveFailure implements Action {
    readonly type = IncentivesActionTypes.DELETE_INCENTIVE_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export type IncentivesActions =
    LoadIncentives
    | LoadIncentivesSuccess
    | LoadIncentivesFailure
    | UpdateIncentive
    | UpdateIncentiveSuccess
    | UpdateIncentiveFailure
    | DeleteIncentive
    | DeleteIncentiveSuccess
    | DeleteIncentiveFailure;
