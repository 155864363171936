import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { SalesClasses } from "../../../shared/enums/sales-classes.enum";

@Pipe({
    name: "checkmultipleaprclassesselected"
})
export class CheckMultipleAprClassesSelectedPipe implements PipeTransform {

    transform(salesClasses: string[]): boolean {
        return salesClasses.length > 1 && _.indexOf(salesClasses, SalesClasses.NEW) >= 0;
    }

}