import { ProtectionProductsComponentService } from './../protection-products.component.service';
import { IPennProduct, IPennProvider, IRateBookCode } from './../../../shared/models/product-offerings.model';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'pennratebookcode'
})
export class PennRateBookCodeFilterPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(provider: string, name: string, providerList: IPennProvider[]): IRateBookCode[] {
        if (provider && name) {
            const existingBookCodes = this.pPComponentService.getExistingBookCodesForProduct(provider, name);
            const pennProvider: IPennProvider = _.find(providerList, { name: provider });
            const pennProduct: IPennProduct = _.find(pennProvider.products, { name });
            return pennProduct?.rateBookCodes?.map(t => ({ ...t, disabled: _.includes(existingBookCodes, t.bookCode) }) as IRateBookCode) || [];
        }
        else {
            return [];
        }
    }
}
