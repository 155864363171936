import { createSelector, createFeatureSelector } from '@ngrx/store';
import { FeatureKeys } from '../app.reducer';
import { DealerDefinedCampaignsState } from './dealer-defined-campaigns.reducer';
import * as _ from 'lodash';
import { CampaignStatus } from '../../shared/enums/campaign-status.enum';
import { DateTime } from 'luxon';

const currentDateTime = DateTime.local();
const currentDate = DateTime.utc(currentDateTime.year, currentDateTime.month, currentDateTime.day);

export const selectDealerDefinedCampaignsFeature = createFeatureSelector<DealerDefinedCampaignsState>(FeatureKeys.DEALERDEFINEDCAMPAIGNS);

export const selectDealerDefinedCampaigns = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => state.campaigns
);

export const selectCampaignsComponentLoading = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => state.loading
);

export const selectCampaignLoading = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => state.loadingCampaign
);

export const selectCampaignLenders = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => {
        let reversedList = state.campaigns.map(t => t.lenderId).reverse();
        return _.uniq(reversedList);
    });

export const selectDefaultVariances = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => state.defaultVariances
);

export const selectDefaultMileage = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => state.defaultMileage
);

export const selectBrands = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => state.brandList
);

export const selectDdOfferTypes = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => state.offerTypes
);

export const selectDdSalesClasses = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => state.salesClasses
);

export const selectActiveCampaign = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => {
        return state.campaigns.filter(t => {
            if (t.status !== CampaignStatus.DRAFT) {
                const endDate = t.endDate ? DateTime.fromISO(t.endDate, { zone: 'UTC' }) : null;
                return !(endDate.diff(currentDate, ['days']).days < -1);
            }
            return t;
        });
    });

export const selectExpiredCampaign = createSelector(
    selectDealerDefinedCampaignsFeature,
    (state: DealerDefinedCampaignsState) => {
        return state.campaigns.filter(t => {
            if (t.status !== CampaignStatus.DRAFT) {
                const endDate = t.endDate ? DateTime.fromISO(t.endDate, { zone: 'UTC' }) : null;;
                return endDate.diff(currentDate, ['days']).days < -1;
            }
        });
    });