import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProtectionProductsState } from '../../../store/protection-products/protection-products.reducer';
import { selectOfferTypes, selectSalesClasses } from '../../../store/protection-products/protection-products.selectors';
import { ProtectionProductsComponentService } from '../protection-products.component.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-protection-product-copy-offer',
  templateUrl: './protection-product-copy-offer.component.html',
  styleUrls: ['./protection-product-copy-offer.component.scss'],
})
export class ProtectionProductCopyOfferComponent implements OnInit, OnDestroy {

  copyForm: FormGroup;
  salesClasses$: Observable<string[]>;
  offerTypes$: Observable<string[]>;
  salesClasses: string[] = [];
  offerTypes: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ProtectionProductCopyOfferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly protectionProductsState: Store<ProtectionProductsState>,
    readonly pPComponentService: ProtectionProductsComponentService,
    private fb: FormBuilder) {}

  ngOnInit(): void {
    this.copyForm = this.fb.group({});
    this.salesClasses$ = this.protectionProductsState.select(selectSalesClasses);
    this.salesClasses$.subscribe((salesClass) => {
      this.salesClasses = salesClass;
      this.updateFormStructure();
    });
    this.offerTypes$ = this.protectionProductsState.select(selectOfferTypes);
    this.offerTypes$.subscribe((offer) => {
      this.offerTypes = offer;
      this.updateFormStructure();
    });
  }

  private updateFormStructure() {
    if (this.salesClasses.length > 0 && this.offerTypes.length > 0) {
      const salesClassConfig = {};
      this.salesClasses.forEach(sales => {
        const offerTypesConfig = {};
        this.offerTypes.forEach(offers => {
          offerTypesConfig[offers] = [false];
        });
        salesClassConfig[sales] = this.fb.group(offerTypesConfig);
      });

      this.copyForm = this.fb.group(salesClassConfig);
    }
  }

  copyOffers () {
    const data = this.copyForm.getRawValue();
    this.dialogRef.close(data);
  }

  ngOnDestroy(): void {}
}
