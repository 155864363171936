import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LocalizedDatePipe} from "./localized-date";
import {AssetUrlPipe} from "./assets";
import { SortPipe } from './sort';

@NgModule({
  declarations: [LocalizedDatePipe, AssetUrlPipe, SortPipe],
  exports: [LocalizedDatePipe, AssetUrlPipe, SortPipe],
  imports: [
    CommonModule
  ]
})
export class AppPipesModule { }
