import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'ppoptionsfilter'
})
export class PpOptionsFilterPipe implements PipeTransform {
    transform(planId: number, formId: string, salesClass: string, offerType: string, items: any[]): any[] {
        if (!items) { return []; } else {
            return _.filter(items,
                (t) =>
                    t.formId === formId
                    && t.planId === planId
                    && (!t.salesClass || t.salesClass === salesClass)
                    && (!t.offerType || t.offerType === offerType)
            );
        }
    }
}
