<div class="img-cropper-dialog-container">
    <dd-spinner *ngIf="showLoader"></dd-spinner>
    <div class="d-flex flex-column img-controls-container">
        <div class="d-flex justify-content-center">
            <button class="secondary-button" (click)="rotateLeft()">
                <span>Rotate Left</span>
            </button>
            <button class="secondary-button ml-2" (click)="rotateRight()">
                <span>Rotate Right</span>
            </button>
        </div>
        <div class="d-flex justify-content-center img-cropper-container">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="maintainAspectRatio"
                [aspectRatio]="aspectRatio" [format]="format" [resizeToWidth]="resizeToWidth" onlyScaleDown="true"
                [resizeToHeight]="resizeToHeight" (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady($event)"
                (loadImageFailed)="loadImageFailed()" [canvasRotation]="canvasRotation" cropperMinWidth="16"
                cropperMinHeight="9"></image-cropper>

        </div>
        <div class="d-flex justify-content-center mt-auto">
            <button class="secondary-button" (click)="cancelUpload()">
                <span>Cancel</span>
            </button>
            <button class="primary-button ml-2" (click)="readyToUpload()">
                <span>Confirm</span>
            </button>
        </div>
    </div>
</div>