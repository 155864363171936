import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { IIncentive } from "../../../shared/models/incentives.model";
import { IncentivesComponentService } from "../incentives.component.service";

@Pipe({
    name: "getsalesclassesforincentive"
})
export class GetSalesclassesForIncentivePipe implements PipeTransform {

    constructor(private readonly incentiveService: IncentivesComponentService) { }

    transform(incentive: IIncentive): string[] {
        return this.incentiveService.getSalesClassForIncentive(incentive.make, incentive.year);
    }

}