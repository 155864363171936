import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { IAuditLog, IGetAuditLogsRequest } from '../../shared/models/audit-log.model';
import { PEAuditService } from '../../shared/services/pe-audit.service';
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { DateAdapter } from '@angular/material/core';
import { LuxonDateAdapter } from "../../shared/services/luxon-date-adapter";
import { UserProfileService } from '@toyota/dd365-platform-library';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DateTime } from 'luxon';
import { SubscriptionList, unsubscribeSubscriptions } from '../../shared/services/util.service';
import _ from 'lodash';
import { DealerInfoService } from '../../shared/services/dealer-info.service';
import { AuditModuleList, PPAuditModuleList } from '../../shared/enums/audit-module-list.enum';

@Component({
  selector: 'app-pe-audit',
  templateUrl: './pe-audit.component.html',
  styleUrls: ['./pe-audit.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: LuxonDateAdapter }
  ]
})
export class PEAuditComponent implements OnInit, OnDestroy {

  @ViewChild('auditPaginator', { static: false }) paginator: MatPaginator;

  dealerCode: string;

  moduleList = [];
  auditFormGroup: FormGroup;
  auditLogs: IAuditLog[] = [];
  totalItems: number = 0;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  loading: boolean;

  private subs: SubscriptionList = {};

  constructor(
    private readonly userProfileService: UserProfileService,
    private readonly dealerInfoService: DealerInfoService,
    private readonly auditService: PEAuditService,
    private readonly luxonDateAdapter: LuxonDateAdapter,
    private readonly formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.initialSetup();
  }

  initialSetup() {
    this.setupModuleList();
    this.auditLogs = [];
    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    this.auditFormGroup = this.formBuilder.group({
      startDate: [this.luxonDateAdapter.today(), Validators.required],
      endDate: [this.luxonDateAdapter.today(), Validators.required],
      itemsOnPage: [this.pageSizeOptions[0], Validators.required],
      page: [1, Validators.required],
      module: [this.moduleList.map(item => item.value), Validators.required],
      userName: ['']
    }, { validators: [this.compareStartAndEndDate] });
  }

  setupModuleList() {
    this.moduleList = Object.entries(AuditModuleList).map(([key, value]) => ({ name:value, value }));
    if (this.dealerInfoService.isPPFeatureEnabled) {
      this.moduleList = [...this.moduleList, ...Object.entries(PPAuditModuleList).map(([key, value]) => ({ name:value, value }))];
    }
  }

  getLogs(newSearch: boolean = false) {
    if (this.auditFormGroup.valid) {
      this.loading = true;
      const auditRequest: IGetAuditLogsRequest = _.clone(this.auditFormGroup.value);
      auditRequest.startDate = DateTime.fromISO(auditRequest.startDate).toFormat('MM-dd-yyyy');
      auditRequest.endDate = DateTime.fromISO(auditRequest.endDate).toFormat('MM-dd-yyyy');
      if (newSearch) {
        this.paginator.pageIndex = 0;
        auditRequest.page = 1;
      }
      this.auditService.getAuditLogs(this.dealerCode, auditRequest).pipe(take(1)).subscribe(logResponse => {
        this.auditLogs = logResponse.trails.items;
        this.totalItems = logResponse.trails.totalItems;
      }, (err) => {
        this.auditLogs = [];
        this.snackBar.open(err.error.message, 'Dismiss', { duration: 4000 });
      }, () => {
        this.loading = false;
      });
    }
    else if (this.auditFormGroup.errors?.dateComparisonError) {
      this.snackBar.open('From date should be less than To date.', 'Dismiss', { duration: 4000 });
    }
    else {
      this.snackBar.open('Please fill all required fields.', 'Dismiss', { duration: 4000 });
    }
  }

  compareStartAndEndDate(control: AbstractControl): ValidationErrors | null {
    if (control && control.get("startDate") && control.get("endDate")) {
      const startDate: DateTime = control.get("startDate").value;
      const endDate: DateTime = control.get("endDate").value;
      return (startDate && endDate && startDate.startOf("day") > endDate.startOf("day")) ? { dateComparisonError: true } : null;
    }
    return null;
  }

  pageChanged(pageEvent: PageEvent) {
    const auditFormValue: IGetAuditLogsRequest = { ...this.auditFormGroup.value, itemsOnPage: pageEvent.pageSize, page: pageEvent.pageIndex + 1 };
    this.auditFormGroup.setValue(auditFormValue);
    if (this.auditLogs.length) {
      this.getLogs();
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

}
