import { Pipe, PipeTransform } from "@angular/core";
import { IResidual } from "../../../shared/models/dealer-defined-campaigns.model";
import * as _ from 'lodash';

@Pipe({
    name: "getresidualforterm"
})
export class GetResidualForTermPipe implements PipeTransform {

    transform(term: number, residual: IResidual): number | string {
        return _.find(residual.terms, { term })?.value || '-';
    }

}