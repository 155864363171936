import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentGridRoutingModule } from './payment-grid-routing.module';
import { PaymentGridComponent } from './payment-grid.component';
import { GlobalGridConfigComponent } from './global-grid-config/global-grid-config.component';
import { ShowroomOnlineGridConfigComponent } from './showroom-online-grid-config/showroom-online-grid-config.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from "../../global/global.module";
import { SharedModule } from "../../shared/shared.module";
import { DisableSelectOptionPipe } from "./pipes/disable-select-option.pipe";
import { OffersForSalesClassPipe } from "./pipes/offers-for-salesclass.pipe";
import { MatChipsModule } from '@angular/material/chips';
import { OffersForShowroomonlinePipe } from './pipes/offers-for-showroomonline.pipe';
import { OffersOnlineConfigPipe } from './pipes/offers-online-config.pipe';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PaymentGridComponent,
    GlobalGridConfigComponent,
    ShowroomOnlineGridConfigComponent,
    DisableSelectOptionPipe,
    OffersForSalesClassPipe,
    OffersForShowroomonlinePipe,
    OffersOnlineConfigPipe
  ],
  imports: [
    CommonModule, GlobalModule, SharedModule,
    PaymentGridRoutingModule,
    MatTabsModule,
    MatChipsModule,
    TranslateModule.forChild({ extend: true }),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [OffersForSalesClassPipe, OffersForShowroomonlinePipe]
})
export class PaymentGridModule { }