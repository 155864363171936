import { Component, OnInit } from '@angular/core';
import { SubscriptionList, unsubscribeSubscriptions } from '../../shared/services/util.service';
import { Observable } from 'rxjs';
import * as dealerConfigurationActions from '../../store/dealer-configuration/dealer-configuration.actions';
import * as incentivesActions from '../../store/incentives/incentives.actions';
import { Store } from '@ngrx/store';
import { IncentivesState } from '../../store/incentives/incentives.reducer';
import { DealerConfigurationState } from '../../store/dealer-configuration/dealer-configuration.reducer';
import { VehicleConfigState } from '../../store/vehicle-config/vehicle-config.reducer';
import { IncentivesComponentService } from './incentives.component.service';
import { DealerInfoService } from '../../shared/services/dealer-info.service';
import { UserProfileService } from '@toyota/dd365-platform-library';
import { selectIncentives, selectIncentiveLoading, selectIncentiveSalesClasses, selectIncentivesComponentLoading } from '../../store/incentives/incentives.selectors';
import { selectIsLoading } from '../../store/dealer-configuration/dealer-configuration.selectors';
import { selectIsVehicleConfigLoading, selectVehicleConfig } from '../../store/vehicle-config/vehicle-config.selectors';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-pe-incentives',
  templateUrl: './incentives.component.html',
  styleUrls: ['./incentives.component.scss']
})
export class IncentivesComponent implements OnInit {

  private readonly subs: SubscriptionList = {};
  selectIncentivesComponentLoading$: Observable<boolean>;
  selectDealerConfigLoading$: Observable<boolean>;

  constructor(
    private readonly incentivesState: Store<IncentivesState>,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly vehicleConfigState: Store<VehicleConfigState>,
    private readonly incentiveService: IncentivesComponentService,
    private readonly dealerInfoService: DealerInfoService,
    private readonly userProfileService: UserProfileService
  ) { }

  ngOnInit() {

    this.selectIncentivesComponentLoading$ = this.incentivesState.select(selectIncentivesComponentLoading);
    this.selectDealerConfigLoading$ = this.dealerConfigurationState.select(selectIsLoading);
    this.incentiveService.incentiveLoading$ = this.incentivesState.select(selectIncentiveLoading);
    this.incentiveService.vehicleConfigLoading$ = this.vehicleConfigState.select(selectIsVehicleConfigLoading);
    this.incentivesState.select(selectIncentiveSalesClasses).pipe(take(1)).subscribe(salesClasses => {
      this.incentiveService.salesClasses = salesClasses;
    });
    this.subs.selectIncentivesSub = this.incentivesState.select(selectIncentives).subscribe(Incentives => {
      this.incentiveService.incentiveList = Incentives;
    });
    this.subs.selectVehicleConfigSub = this.vehicleConfigState.select(selectVehicleConfig).subscribe(vehicleConfig => {
      this.incentiveService.vehicleConfig = vehicleConfig;
    });

    const dealerCode = this.userProfileService.getProfile().dealerCd;
    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.LoadDealerConfiguration({ dealerCode }));
    this.incentivesState.dispatch(new incentivesActions.LoadIncentives({ dealerCode }));
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }
}
