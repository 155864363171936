import { Injectable } from "@angular/core";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Effect, ofType, Actions } from "@ngrx/effects";
import * as fromActions from "./lender-list-config.actions";
import { MatSnackBar } from "@angular/material/snack-bar";
import { loadInitiateState } from "./lender-list-config.adapter";
import { UserProfileService } from "@toyota/dd365-platform-library";
import { ILenderListConfig } from "../../shared/models/lender/lender-list-config.model";
import { LenderListService } from "../../../service/lender/lender-list.service";
import { lenderListData, easleyLenderListData } from "../../../mocks/lenderListMock";
import { DealerService } from "../../../../app/service/dealers/dealer.service";

@Injectable()
export class LenderListConfigEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly lenderListService: LenderListService,
    private readonly snackBar: MatSnackBar,
    private readonly userProfileService: UserProfileService,
    private readonly dealerService: DealerService
  ) { }

  @Effect()
  loadLenderListConfig: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadLenderListConfig>(
      fromActions.LenderListConfigActionTypes.LOAD_LENDER_LIST_CONFIG
    ),
    mergeMap((action: fromActions.LoadLenderListConfig) =>
      this.lenderListService.getLendListConfig(action.payload.dealerCode).pipe(
        map((lenderListConfig: ILenderListConfig[]) => {
          return new fromActions.LoadLenderListConfigSuccess({
            lenderListConfig,
          });
        }),
        catchError((err) => {
          if (err.status === 404) {
            let lenderListConfig;
            if (this.dealerService.getFinanceSourceOrg() === "SET") {
              lenderListConfig = action.payload.dealerCode == '39028' ?
                easleyLenderListData : lenderListData;  //mocksetdata
            } else {
              this.snackBar.open(err.error.message, "Dismiss", {
                duration: 5000,
              });
              lenderListConfig = loadInitiateState(this.userProfileService);
            }

            return of(
              new fromActions.LoadLenderListConfigSuccess({
                lenderListConfig,
              })
            );
          } else if (err.status === 403) {
            this.snackBar.open(err.error.message, "Dismiss", {
              duration: 5000,
            });
          } else {
            this.snackBar.open("Something went wrong.", "Dismiss", {
              duration: 5000,
            });
            return of(
              new fromActions.LoadLenderListConfigFailure({ error: err })
            );
          }
        })
      )
    )
  );
}
