import { Pipe, PipeTransform } from '@angular/core';
import { ProtectionProductsComponentService } from '../protection-products.component.service';

@Pipe({
    name: 'checkmutexproduct'
})
export class CheckMutexProductPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(productId: string): boolean {
        return this.pPComponentService.isMutexProduct(productId);
    }

}
