import { FormArray } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'getformarray'
})
export class GetFormArrayPipe implements PipeTransform {

    constructor() { }

    transform(product: any, property: string): FormArray {
        const properties: string[] = _.split(property, '|');
        let obj = product;
        const success = properties.every(prop => {
            if (!obj[prop]) {
                return false;
            }
            obj = obj[prop];
            return true;
        });
        return success ? obj : [];
    }
}
