import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserProfileService } from '@toyota/dd365-platform-library';
import { map } from 'rxjs/operators';
import * as uuid from 'uuid';
import { IRTIVehicleSeriesConfig } from '../models/rti/rti-vehicle-config.model';
import { environment } from './../../../../environments/environment';
import { DealerInfoService } from './dealer-info.service';

@Injectable({
    providedIn: 'root'
})
export class RTIVehicleConfigService {

    private readonly SYSTEM_CONFIG_API: string = environment.system_configuration_api;
    private readonly SUBSCRIBER_CODE = environment.x_subscriber_code;
    private httpOptions = {
        headers: new HttpHeaders()
    };

    constructor(
        private http: HttpClient,
        private readonly userProfileService: UserProfileService,
        private readonly dealerInfoService: DealerInfoService
    ) {
    }

    private setHeaderOptions() {
        this.httpOptions.headers = new HttpHeaders({});
        this.httpOptions.headers = this.httpOptions.headers
            .set('Authorization', `Bearer ${this.userProfileService.getProfile().token}`)
            .set('x-subscriber-code', this.SUBSCRIBER_CODE)
            .set('x-transaction-id', uuid.v4());
    }

    getVehicleConfig(brand: string) {
        this.setHeaderOptions();
        const financeSource = this.dealerInfoService.getDealerDistributor();
        return this.http.get(`${this.SYSTEM_CONFIG_API}vehicleConfig/${this.getRTIBrand(brand?.toLowerCase())}/${financeSource}`, this.httpOptions)
            .pipe(
                map((data: IRTIVehicleSeriesConfig[]) => {
                    return data;
                })
            );
    }

    private getRTIBrand(brand: string) {
        switch (brand) {
            case 'toyota':
                return 'T';
            case 'lexus':
                return 'L';
            default:
                return brand;
        }
    }
}