<dd-spinner *ngIf="(selectMasterDataLoading$ | async) || (pPComponentService.getImageUpdatingFlag$ | async)
    || (selectIsLoadingBankConfig$ | async)">
</dd-spinner>
<div *ngIf="!(selectBankConfigLoaded$ | async) || (selectMasterDataLoadFailed$ | async)" class="d-flex">
    <div class="d-flex mx-auto mt-4">
        <span>{{'LOCALIZATION.PROTECTION_PRODUCTS.UNABLE_FETCH_TEXT' | translate}}&nbsp;</span>
        <span class="reload-link" (click)="reload()">{{'LOCALIZATION.PROTECTION_PRODUCTS.RELOAD' |
            translate}}</span>
    </div>
</div>
<div *ngIf="(selectBankConfigLoaded$ | async) && !(selectMasterDataLoadFailed$ | async)">
    <div *ngIf="selectMasterDataReady$ | async">
        <app-protection-products-list></app-protection-products-list>
    </div>
</div>