import { AbstractControl } from "@angular/forms";
import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "disableselectoption",
})
export class DisableSelectOptionPipe implements PipeTransform {
  transform(
    _length: number,
    value: number,
    termControl: AbstractControl,
    limit: number
  ): boolean {
    const selectedValues: number[] = termControl.value;
    return selectedValues.length > limit && !_.includes(selectedValues, value);
  }
}
