import { UserProfileService } from "@toyota/dd365-platform-library";
import { ILenderListConfig } from "../../shared/models/lender/lender-list-config.model";

export function mapLenderListConfig(
  LenderListConfig: ILenderListConfig[]
): ILenderListConfig[] {
  const masterData: ILenderListConfig[] = LenderListConfig;
  return masterData;
}

export function loadInitiateState(
  userProfileService: UserProfileService
): ILenderListConfig[] {
  const response: ILenderListConfig[] = [
    {
      id: "",
      name: "",
      displayName: "",
      offerType: [],
    },
  ];
  return response;
}
