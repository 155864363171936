import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global/global.module';
import { SharedModule } from '../../shared/shared.module';
import { LeaseSettingRoutingModule } from './lease-setting-routing.module';
import { LeaseSettingComponent } from './lease-setting.component';
import { DisabledOptionPipe } from './pipes/disabled-option.pipe';

@NgModule({
  declarations: [
    LeaseSettingComponent,
    DisabledOptionPipe
  ],
  imports: [
    LeaseSettingRoutingModule,
    TranslateModule.forChild({ extend: true }),
    CommonModule, GlobalModule, SharedModule
  ]
})
export class LeaseSettingModule { }
