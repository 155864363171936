import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Store } from "@ngrx/store";
import { DealerInfoService } from "../../shared/services/dealer-info.service";
import { UserProfileService } from "@toyota/dd365-platform-library";
import { DeployJobManageState } from "../../store/deploy-job-management/deploy-job-management.reducer";
import { Observable } from "rxjs";
import * as deployJobManagementActions from "../../store/deploy-job-management/deploy-job-management.actions";
import {
  selectDeployJobIsLoading, selectDeployConfigIsLoading
} from "../../store/deploy-job-management/deploy-job-management.selectors";
@Component({
  selector: "app-pe-deploy-job-management",
  templateUrl: "./deploy-job-management.component.html",
  styleUrls: ["./deploy-job-management.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DeployJobManagementComponent implements OnInit {
  deployJobLoading$: Observable<boolean>;
  deployJobConfigLoading$: Observable<boolean>;
  constructor(
    private readonly deployJobManageState: Store<DeployJobManageState>,
    private readonly userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    const dealerCode = this.userProfileService.getProfile().dealerCd;
    
    this.deployJobLoading$ = this.deployJobManageState.select(
      selectDeployJobIsLoading
    );

    this.deployJobConfigLoading$ = this.deployJobManageState.select(
      selectDeployConfigIsLoading
    );

    this.deployJobManageState.dispatch(
      new deployJobManagementActions.LoadDeployJobManage({ dealerCode })
    );

    this.deployJobManageState.dispatch(
      new deployJobManagementActions.LoadDeployJobConfig({ dealerCode })
    );
  }
}
