import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { IVehicleBrand, IVehicleSeries } from "../../../shared/models/vehicle-config-master.model";

@Pipe({
    name: "getbrandseries"
})
export class GetBrandSeriesPipe implements PipeTransform {

    transform(brand: string, vehicleConfig: IVehicleBrand[]): IVehicleSeries[] {
        let result = _.find(vehicleConfig, { name: brand })?.series || [];
        result = _.sortBy(result, [(item) => item.name.toLowerCase()]);
        return result;
    }

}