import { Injectable } from '@angular/core';

import { IGrossProfitConfigList } from "../../shared/models/bank-configuration.model";
import { IGrossProfitList } from "../../shared/models/gross-profit.model";
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GrossProfitComponentService {

  constructor() {

  }
  buildGP(gpConfigData: IGrossProfitConfigList[], gpData: IGrossProfitList[]) {
    let result: IGrossProfitList[] = []
    _.map(gpConfigData, (n) => {
      const include = _.find(gpData, { id: n.id })?.include || false;
      result.push({ ...n, include })
    });
    return result;
  }

  buildGpObj(obj): IGrossProfitList {
    return {
      id: obj.id,
      name: obj.name,
      include: obj.include ? obj.include : false
    }
  }

}