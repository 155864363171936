import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { IVehicleBrand } from "../../../shared/models/vehicle-config-master.model";
import { GenericSelections } from '../../../shared/enums/generic-selections.enum';
@Pipe({
    name: "getseriesname"
})
export class GetSeriesNamePipe implements PipeTransform {
    transform(models: string[], vehicleConfig: IVehicleBrand[], brand: string): string[] {
        if (models.includes(GenericSelections.ALL)) {
            return [_.startCase(GenericSelections.ALL)]
        }
        const series = _.find(vehicleConfig, { name: brand })?.series || [];
        return _.map(_.filter(series, obj => models.includes(obj.code)), 'name');
    }
}