
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IBuilderOfferDetails, IBuilderResponse, IRatesFilter, IRateValue } from '../../shared/models/pe-builder.model';
import { FeatureKeys } from '../app.reducer';
import { DealValidationState } from './deal-validation.reducer';
import * as _ from 'lodash';
import { RateTypes } from '../../shared/enums/rate-types.enum';
import { getRateFieldName } from './deal-validation.adapter';

export const selectDealValidationFeature = createFeatureSelector<DealValidationState>(FeatureKeys.DEALVALIDATION);

export const selectDealerConfiguration = createSelector(
    selectDealValidationFeature,
    (state: DealValidationState) => state
);

export const selectdealOffersDetail = createSelector(
    selectDealValidationFeature,
    (state: DealValidationState) => state.dealOffersDetail
);

export const selectIsDealOffersLoaded = createSelector(
    selectDealValidationFeature,
    (state: DealValidationState) => !!state.dealOffersDetail
);

export const selectLoadingOffers = createSelector(
    selectDealValidationFeature,
    (state: DealValidationState) => state.loadingOffers
);

export const selectLoadingDeal = createSelector(
    selectDealValidationFeature,
    (state: DealValidationState) => state.loadingDeal
);

export const selectAvailableDealTypes = createSelector(
    selectDealValidationFeature,
    (state: DealValidationState) => _.keys(state.dealOffersDetail?.offers)
);
export const selectCalcResponse = createSelector(
    selectDealValidationFeature,
    (state: DealValidationState) => state.dealDetail
);
export const selectDealTerms = createSelector(
    selectdealOffersDetail,
    (dealOffersDetail: IBuilderResponse, offerType: string): number[] => {
        if (offerType) {
            const offersData: IBuilderOfferDetails[] = dealOffersDetail?.offers[offerType];
            return offersData ? [...new Set(offersData.reduce((prevTerms, t) => prevTerms.concat(t.term), []))].sort((a, b) => a - b) : [];
        }
        else {
            return [];
        }
    }
);

export const selectDealerDetails = createSelector(
    selectDealValidationFeature,
    (state: DealValidationState) => state.dealOffersDetail?.dealer
);

export const selectVehicleDetails = createSelector(
    selectDealValidationFeature,
    (state: DealValidationState) => state.dealOffersDetail?.vehicle
);
export const selectVehicleDetailsDD365 = createSelector(
    selectDealValidationFeature,
    (state: DealValidationState) => state.VehicleDetailsDD365
);

export const selectDealCreditTiers = createSelector(
    selectdealOffersDetail,
    (dealOffersDetail: IBuilderResponse, offerType: string): string[] => {
        if (offerType) {
            const offersData: IBuilderOfferDetails[] = dealOffersDetail?.offers[offerType];
            return offersData ? [...new Set(offersData.reduce((prevTerms, t) => prevTerms.concat(t.level), []))]
                .sort((a, b) => {
                    if (isNaN(+a) && isNaN(+b)) {
                        return a.localeCompare(b);
                    }
                    else {
                        return (+a || -b) - (+b || a);
                    }
                }) : [];
        }
        else {
            return [];
        }
    }
);

export const selectDealRates = createSelector(
    selectdealOffersDetail,
    (dealOffersDetail: IBuilderResponse, filter: IRatesFilter): IRateValue[] => {
        if (filter.offerType && filter.level && filter.term) {
            const rateField = getRateFieldName(filter.offerType);
            const offersData: IBuilderOfferDetails[] = dealOffersDetail?.offers[filter.offerType];
            return offersData ? _.filter(offersData, { level: filter.level, term: filter.term })
                .map((t: IBuilderOfferDetails) =>
                ({
                    rate: t[rateField],
                    isSubvented: t.isSubvented,
                    displayText: `${t[rateField]} (${t.isSubvented ? RateTypes.SPECIAL : RateTypes.STANDARD})`
                } as IRateValue))
                .sort((a, b) => a.rate - b.rate) : [];
        }
        else {
            return [];
        }
    }
);
