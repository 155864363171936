import produce from 'immer';
import { IIncentive } from '../../shared/models/incentives.model';
import * as fromActions from './incentives.actions';
import * as _ from 'lodash';
import { IBrand } from '../../shared/models/brand.model';
import { SalesClasses } from '../../shared/enums/sales-classes.enum';
import { OfferTypes } from '../../shared/enums/offer-types.enum';
import { brandList } from '../../../mocks/brands';

export interface IncentivesState {
    incentives: IIncentive[];
    loading: boolean;
    loadingIncentive: boolean;
    error: string;
    brandList: IBrand[];
    salesClasses: string[];
    offerTypes: string[];
}

export const incentivesInitialState: IncentivesState = {
    incentives: [],
    loading: false,
    loadingIncentive: false,
    error: '',
    brandList,
    salesClasses: [SalesClasses.NEW, SalesClasses.CERTIFIED, SalesClasses.USED],
    offerTypes: [OfferTypes.LEASE, OfferTypes.FINANCE],
};

export function incentivesReducer(
    state: IncentivesState = incentivesInitialState,
    action: fromActions.IncentivesActions
): IncentivesState {

    switch (action.type) {

        case fromActions.IncentivesActionTypes.LOAD_INCENTIVES: {
            return produce(state, draftState => {
                draftState.incentives = [];
                draftState.loading = true;
                draftState.error = '';
            });
        }
        case fromActions.IncentivesActionTypes.LOAD_INCENTIVES_SUCCESS: {
            return produce(state, draftState => {
                draftState.incentives = action instanceof fromActions.LoadIncentivesSuccess ? action.payload.incentives : [];
                draftState.loading = false;
                draftState.error = '';
            });
        }
        case fromActions.IncentivesActionTypes.LOAD_INCENTIVES_FAILURE: {
            return produce(state, draftState => {
                draftState.incentives = [];
                draftState.loading = false;
                draftState.error = action instanceof fromActions.LoadIncentivesFailure ? action.payload.error : '';
            });
        }
        case fromActions.IncentivesActionTypes.UPDATE_INCENTIVE:
        case fromActions.IncentivesActionTypes.DELETE_INCENTIVE: {
            return produce(state, draftState => {
                draftState.loadingIncentive = true;
                draftState.error = '';
            });
        }
        case fromActions.IncentivesActionTypes.UPDATE_INCENTIVE_SUCCESS: {
            return produce(state, draftState => {
                const incentive = action instanceof fromActions.UpdateIncentiveSuccess ? action.payload.incentive : null;
                const incentiveIndex = _.findIndex(draftState.incentives, { id: incentive.id });
                if (incentiveIndex >= 0) {
                    draftState.incentives[incentiveIndex] = incentive;
                } else {
                    draftState.incentives.push(incentive)
                }
                draftState.loadingIncentive = false;
                draftState.error = '';
            });
        }
        case fromActions.IncentivesActionTypes.UPDATE_INCENTIVE_FAILURE: {
            return produce(state, draftState => {
                draftState.loadingIncentive = false;
                draftState.error = action instanceof fromActions.UpdateIncentiveFailure ? action.payload.error : '';
            });
        }
        case fromActions.IncentivesActionTypes.DELETE_INCENTIVE_SUCCESS: {
            return produce(state, draftState => {
                const campaignId = action instanceof fromActions.DeleteIncentiveSuccess ? action.payload.incentiveId : null;
                const campaignIndex = _.findIndex(draftState.incentives, { id: campaignId });
                draftState.incentives.splice(campaignIndex, 1);
                draftState.loadingIncentive = false;
                draftState.error = '';
            });
        }
        case fromActions.IncentivesActionTypes.DELETE_INCENTIVE_FAILURE: {
            return produce(state, draftState => {
                draftState.loadingIncentive = false;
                draftState.error = action instanceof fromActions.DeleteIncentiveFailure ? action.payload.error : '';
            });
        }
        default:
            return state;
    }

}
