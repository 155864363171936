import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserProfileService } from "@toyota/dd365-platform-library";
import { environment } from "../../../environments/environment";
import * as uuid from "uuid";
import { map } from "rxjs/operators";
import { ILenderListConfig } from "../../components/shared/models/lender/lender-list-config.model";
@Injectable({
  providedIn: "root",
})
export class LenderListService {
  private readonly GET_DEALER_CONFIGURATION_API: string =
    environment.get_dealer_configuration_api;

  dealerCode: string;
  token: string;

  /**
   * Constructor
   *
   * @returns void
   */
  constructor(
    private http: HttpClient,
    private _userProfileService: UserProfileService
  ) {}

  /**
   * To get the list of Lenders
   *
   * @returns void
   */

  getLendListConfig(dealerCode: string) {
    const param = "/lenders";
    return this.http
      .get(this.GET_DEALER_CONFIGURATION_API + dealerCode + param, {
        headers: {
          Authorization:
            "Bearer " + this._userProfileService.getProfile().token,
          "x-subscriber-code": environment.x_subscriber_code,
          "x-transaction-id": uuid.v4(),
        },
      })
      .pipe(
        map((data: [ILenderListConfig]) => {
          return data;
        })
      );
  }
}
