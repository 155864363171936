import { IImageProps } from './../../models/utility/image-cropper-properties';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Inject, OnInit, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { base64ToFile, Dimensions, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import * as uuid from 'uuid';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit, OnDestroy, AfterViewInit {
    public aspectRatio: number; // = 16 / 9;
    public maintainAspectRatio: boolean; // = true;
    public format: string; // = 'png';
    public resizeToWidth: number; // = 1920;
    public resizeToHeight: number; // = 1080;
    public canvasRotation: number = 0;
    minWidth: number; // = 640;
    minHeight: number; // = 360;

    imageChangedEvent: any;
    croppedImage: string;
    loadedImage: LoadedImage;
    showLoader: boolean = true;

    constructor(
        public readonly dialogRef: MatDialogRef<ImageCropperComponent>,
        private cdRef: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public readonly data: { event: Event, imageProperties: IImageProps }
    ) {
    }

    ngOnInit(): void {
        this.setupDefaults();
    }

    ngAfterViewInit(): void {
        this.imageChangedEvent = this.data.event;
        this.cdRef.detectChanges();
    }

    setupDefaults() {
        this.aspectRatio = this.data?.imageProperties?.aspectRatio || this.aspectRatio;
        this.maintainAspectRatio = this.data?.imageProperties?.maintainAspectRatio || this.maintainAspectRatio;
        this.format = this.data?.imageProperties?.format || this.format;
        this.resizeToWidth = this.data?.imageProperties?.resizeToWidth || this.resizeToWidth;
        this.resizeToHeight = this.data?.imageProperties?.resizeToHeight || this.resizeToHeight;
        this.canvasRotation = this.data?.imageProperties?.canvasRotation || this.canvasRotation;
        this.minWidth = this.data?.imageProperties?.minWidth || this.minWidth;
        this.minHeight = this.data?.imageProperties?.minHeight || this.minHeight;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.showLoader = false;
    }

    imageLoaded(image: LoadedImage) {
        if (image.original.size.width < this.minWidth || image.original.size.height < this.minHeight) {
            this.snackBar.open('Image does not meet minimum resolution requirements.', 'Dismiss', { duration: 4000 });
            this.cancelUpload();
        }
        this.loadedImage = image;
        // show cropper
    }

    cropperReady(dimensions: Dimensions) {
        // cropper ready
    }

    loadImageFailed() {
        this.snackBar.open('Unable to load selected image.', 'Dismiss', { duration: 4000 });
        this.dialogRef.close({ action: 'failed' });
    }

    rotateLeft() {
        this.canvasRotation--;
    }

    rotateRight() {
        this.canvasRotation++;
    }

    readyToUpload() {
        const blob = base64ToFile(this.croppedImage);
        const file = new File([blob], `${uuid.v4()}.${this.format}`, { type: blob.type });
        this.dialogRef.close({ action: 'confirm', file });
    }

    cancelUpload() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.loadedImage = null;
        this.croppedImage = null;
    }
}