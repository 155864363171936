import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { IIncentive } from '../../../shared/models/incentives.model';
import { IncentivesState } from '../../../store/incentives/incentives.reducer';
import { DealerConfigurationState } from '../../../store/dealer-configuration/dealer-configuration.reducer';
import { IncentivesComponentService } from '../incentives.component.service';
import { selectActiveIncentive, selectExpiredIncentive, selectIncentiveSource, selectIncentiveLoading } from '../../../store/incentives/incentives.selectors';
import { ModifyIncentiveComponent } from '../modify-incentive/modify-incentive.component';
import { GenericActions } from '../../../shared/enums/operation-actions.enum';
import { finalize, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionList, unsubscribeSubscriptions } from '../../../shared/services/util.service';
@Component({
  selector: 'app-pe-list-incentives',
  templateUrl: './list-incentives.component.html',
  styleUrls: ['./list-incentives.component.scss']
})
export class ListIncentivesComponent implements OnInit {

  selectIncentiveLoading$: Observable<boolean>;
  incentiveSourceList$: Observable<string[]>;
  incentives$: Observable<IIncentive[]>;
  disableActions$: Observable<boolean>;
  isActive = true;
  private subs: SubscriptionList = {};

  constructor(
    private readonly incentivesState: Store<IncentivesState>,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly incentiveService: IncentivesComponentService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.selectIncentiveLoading$ = this.incentivesState.select(selectIncentiveLoading);
    this.incentiveSourceList$ = this.incentivesState.select(selectIncentiveSource);
    this.incentives$ = this.incentivesState.select(selectActiveIncentive);
    this.disableActions$ = of(false); // this.dealerConfigurationState.select(selectDisableActions);
    this.checkEmptyData();
  }

  addIncentive() {
    this.dialog.open(ModifyIncentiveComponent, {
      panelClass: ['modify-incentive-panel'],
      minWidth: '64%',
      disableClose: true,
      data: {
        action: GenericActions.ADD,
        incentive: this.incentiveService.buildDraftIncentive()
      }
    });
  }
  
  toggleActive() {
    this.isActive = !this.isActive;
    if (this.isActive) {
      this.incentives$ = this.incentivesState.select(selectActiveIncentive);
    } else {
      this.incentives$ = this.incentivesState.select(selectExpiredIncentive);
    }
    this.checkEmptyData();
  }

  showNotification() {
    this.isActive ? 
      this.snackBar.open(
        this.translateService.instant('LOCALIZATION.INCENTIVES.ACTIVE_NOT_FOUND',  ),
        this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 }
      ) :
      this.snackBar.open(
        this.translateService.instant('LOCALIZATION.INCENTIVES.EXPIRED_NOT_FOUND'),
        this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 }
      );
  }

  checkEmptyData() {
    this.subs.getIncentivesLength = this.incentives$.pipe(
      take(1),
      finalize(() => {})
    ).subscribe((item: IIncentive[]) => {
      if (item.length <= 0) {
        this.showNotification();
      }
    });
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }
}
