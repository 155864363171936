<div class="container">
    <div class="row header">
        <div mat-dialog-title class="col-md-12">
            Discard Changes
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row mt-2">
        <div class="col-md-12">
            <mat-label class="d-flex mb-1 fw-bold">
                Reason
            </mat-label>
            <textarea class="discard-reason form-outline" rows="5" placeholder="Enter reason for discarding changes"
                minlength="5" maxlength="500" [(ngModel)]="reason"></textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="action-con">
                <div (click)="close()">
                    <button class="secondary-button">
                        <span>Cancel</span>
                    </button>
                </div>
                <div (click)="confirm()">
                    <button class="primary-button">
                        <span>Discard</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>