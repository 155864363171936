import { Pipe, PipeTransform } from '@angular/core';
import { ProtectionProductsComponentService } from '../protection-products.component.service';

@Pipe({
    name: 'regulatedflag'
})
export class RegulatedFlagPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(productId: string): string {
        return this.pPComponentService.productOfferings.filter(t => t.id === productId)[0]?.isRegulated === 'Y' ? 'Yes' : 'No';
    }
}
