import { Pipe, PipeTransform } from "@angular/core";
import { IResidual } from "../../../shared/models/dealer-defined-campaigns.model";
import * as _ from 'lodash';

@Pipe({
    name: "getresidualvariances"
})
export class GetResidualVariancesPipe implements PipeTransform {

    transform(residuals: IResidual[]): number[] {
        let variances = _.reduce(residuals,
            (p: number[], t) =>
                p.concat(t.variances?.map(s => s.mileage) || []), []).sort((a, b) => a - b);
        return _.uniq(variances);
    }

}