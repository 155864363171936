import { Directive, HostListener } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { GenericSelections } from '../enums/generic-selections.enum';

@Directive({
    selector: '[selectAll]'
})
export class SelectAllDirective {

    public isAllChecked = false;
    constructor(private select: MatSelect) { }

    @HostListener('openedChange', ['$event'])
    onOpenedChange(isOpened: boolean): void {
        const value = this.select.value;
        if (value && value.includes(GenericSelections.ALL)) {
            this.isAllChecked = true;
            if (isOpened) {
                const allOptions = this.select.options.toArray();
                const selectedValues = allOptions.map(option => option.value);
                this.select.writeValue(selectedValues);
            } else {
                this.select.writeValue([GenericSelections.ALL]);
            }
        }
    }

    @HostListener('selectionChange', ['$event'])
    onSelectionChange(event: MatSelectChange): void {
        const value: string[] = event.value;
        const formControl = this.select.ngControl?.control as FormControl;
        if (value && value.includes(GenericSelections.ALL)) {
            this.handleSelectAll(formControl);
        } else if (value && value.length === (this.select.options.length-1)) {
            this.handleDeSelectAll(formControl);
        }
    }

    handleSelectAll (formControl) {
        if (this.isAllChecked) {
            // Deselect some options after selecting all
            this.isAllChecked = false;
            const allOption = this.select.options.find(option => option.value === GenericSelections.ALL);
            if (allOption) {
                allOption.deselect();
            }
        } else {
            // Selecting ALL, set the value to all options
            this.isAllChecked = true;
            const allOptions = this.select.options.toArray();
            const selectedValues = allOptions.map(option => option.value);
            formControl.setValue([GenericSelections.ALL]);
            this.select.writeValue(selectedValues);
        }
    }

    handleDeSelectAll(formControl) {
        if (this.isAllChecked) {
            // Deselecting all options, set the value to an empty array
            this.isAllChecked = false;
            formControl.setValue([]);
            this.select.writeValue(formControl.value);
        } else {
            // If user selects all available options then by defalut select All option and set value to all
            this.isAllChecked = true;
            const allOptions = this.select.options.toArray();
            const selectedValues = allOptions.map(option => option.value);
            formControl.setValue([GenericSelections.ALL]);
            this.select.writeValue(selectedValues);
        }
    }
}
