import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GlobalModule } from "../../global/global.module";
import { SharedModule } from "../../shared/shared.module";
import { DealValidationRoutingModule } from "./deal-validation-routing.module";
import { DealValidationComponent } from "./deal-validation.component";
import { DealValidationFormComponent } from './deal-validation-form/deal-validation-form.component';
import { DealValidationDetailsComponent } from './deal-validation-details/deal-validation-details.component';
import { RateTitlePipe } from "./pipes/rate-title.pipe";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    DealValidationComponent,
    DealValidationFormComponent,
    DealValidationDetailsComponent,
    RateTitlePipe
  ],
  imports: [
    DealValidationRoutingModule,
    TranslateModule.forChild({ extend: true }),
    CommonModule, GlobalModule, SharedModule
  ]
})
export class DealValidationModule { }
