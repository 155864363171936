import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class StaticStorageService {

    constructor(private readonly http: HttpClient) { }

    uploadFileToS3(preSignedUrl: string, file: File) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': file.type,
            })
        };
        return this.http.put(preSignedUrl, file, httpOptions);
    }

    uploadFileToS3Md5(preSignedUrl: string, file: File, md5Hash) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': file.type,
                'Content-Disposition': `attachment; filename="${file.name}"`,
                'Content-MD5': md5Hash
            })
        };
        return this.http.put(preSignedUrl, file, httpOptions);
    }
}
