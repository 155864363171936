import * as protectionProductsActions from "../../store/protection-products/protection-products.actions";
import {
  IForm,
  IPennProduct,
  IPennProvider,
  IPlan,
  IProduct
} from "../../shared/models/product-offerings.model";
import { LuxonDateAdapter } from "./../../shared/services/luxon-date-adapter";
import {
  IFieldConfig,
  IFieldItem,
  IGrossProfit,
  IPacManagement,
  IPencilDefaults,
  IPennProductDefaults,
  IProductProperties,
  IProtectionProducts,
} from "./../../shared/models/protection-products.model";
import { OfferTypes } from "./../../shared/enums/offer-types.enum";
import { Store } from "@ngrx/store";
import { ProtectionProductsState } from "./../../store/protection-products/protection-products.reducer";
import { DealerConfigurationState } from './../../store/dealer-configuration/dealer-configuration.reducer';
import * as dealerConfigurationActions from "../../store/dealer-configuration/dealer-configuration.actions";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
  AbstractControl,
  ValidatorFn,
  ValidationErrors
} from "@angular/forms";
import { Subject, BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import {
  selectProductOfferings,
  selectProtectionProducts,
  selectPennProductDefaultsList,
  selectPennProviders,
  selectTaxCategories,
  selectSalesClasses,
  selectOfferTypes,
  selectTermDurations,
  selectPennDynamicFieldsMasterData,
} from "../../store/protection-products/protection-products.selectors";
import { DateTime } from "luxon";
import { debounceTime, filter, take } from "rxjs/operators";
import { GrossProfitTypes } from "../../shared/enums/gp-types.enum";
import {
  IProductConfiguration,
  ITermDuration
} from "../../shared/models/protection-products.model";
import * as _ from "lodash";
import { ProvidersEnum } from "../../shared/enums/provider-id.enum";
import { getDynamicFieldsForProduct } from "../../store/protection-products/protection-products.adapter";
import { DynamicFieldTypes } from "../../shared/enums/dynamic-field-types.enum";
import { SalesClasses } from "../../shared/enums/sales-classes.enum";
import { IDisabledProduct } from "./models/disabled-product.model";
import { UserProfileService } from "@toyota/dd365-platform-library";
import { DealerInfoService } from "../../shared/services/dealer-info.service";
import {
  SubscriptionList,
  unsubscribeSubscriptions,
} from "../../shared/services/util.service";
import * as uuid from "uuid";
import { IPPCompatibilityMutexMatrix, IPPCompatibilityMutexPlan } from "../../shared/models/bank-configuration.model";
import { selectBankConfigLoaded, selectPpCompatibility } from "../../store/dealer-configuration/dealer-configuration.selectors";
import { VehicleConfigState } from '../../store/vehicle-config/vehicle-config.reducer';
import { Brands } from '../../shared/enums/brands.enum';
import { IBrand } from '../../shared/models/brand.model';
import * as vehicleConfigActions from '../../store/vehicle-config/vehicle-config.actions';
import { selectVehicleBrands } from '../../store/vehicle-config/vehicle-config.selectors';
import { IVehicleBrand } from '../../shared/models/vehicle-config-master.model';
@Injectable({
  providedIn: "root",
})
export class ProtectionProductsComponentService {
  salesClasses: string[];
  offerTypes: string[];
  pennProviders: IPennProvider[];
  termDurations: ITermDuration[];
  dynamicFieldsMasterData: IFieldConfig;
  isTfsDealer: boolean;
  isGstDealer: boolean;
  isSetDealer: boolean;
  isReadOnly: boolean;
  hasPacAccess: boolean;
  selectedProductData: IProduct;
  ownPpCount: number = 0;
  dealerCode: string;
  currentDate: DateTime;
  ownPpProviderId: string;
  productMasterData: IProduct;
  oldProductCode: string;
  subscription: Subscription;
  grossProfitTypes = GrossProfitTypes;

  constructor(
    private readonly userProfileService: UserProfileService,
    readonly dealerInfoService: DealerInfoService,
    private readonly protectionProductsState: Store<ProtectionProductsState>,
    private readonly dealerConfigState: Store<DealerConfigurationState>,
    private readonly formBuilder: FormBuilder,
    private readonly luxonDateAdapter: LuxonDateAdapter,
    private readonly vehicleConfigState: Store<VehicleConfigState>
  ) {
    const currentDateTime = DateTime.local();
    this.currentDate = DateTime.utc(
      currentDateTime.year,
      currentDateTime.month,
      currentDateTime.day
    );
  }

  productOfferings: IProduct[];
  ppCompatibility: IPPCompatibilityMutexMatrix[];
  brandList: IBrand[];
  protectionProductsForm: FormGroup;
  selectedProductDetailsForm: FormGroup;
  // used for removing Cash Offer for TLP and EWT
  noCashOfferProducts: string[] = [];
  noFinanceOfferProducts: string[] = [];
  noLeaseOfferProducts: string[] = [];
  disabledProductList: IDisabledProduct[] = [];
  selectedProductData$: BehaviorSubject<IProduct> = new BehaviorSubject<
    IProduct
  >(undefined);
  selectedProductConfig$: BehaviorSubject<
    IProductConfiguration
  > = new BehaviorSubject<IProductConfiguration>(undefined);
  orderChangeDetector: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  editDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  changeOrder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  addingProduct: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  resetSelectedProduct: Subject<void> = new Subject<void>();
  selectedProductConfigString: string;
  imageUpdatingFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  subs: SubscriptionList = {};
  formSubs: SubscriptionList = {};
  rtiBrands: string[] = [Brands.TOYOTA, Brands.LEXUS];
  vehicleConfig: IVehicleBrand[] = [];
  productsData: IProtectionProducts;
  names: string[] = [];
  hasMutexProduct = false;

  initPpService(draftStatus: boolean) {
    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    this.isGstDealer = this.dealerInfoService.isGSTDealer();
    this.isSetDealer = this.dealerInfoService.isSETDealer();
    this.isTfsDealer = this.dealerInfoService.isTFSDealer() || this.isGstDealer;
    this.ownPpProviderId = this.getOwnPpProviderId();
    this.updateInvalidProductForOffers();
    this.disabledProductList = [];
    this.protectionProductsState.dispatch(
      new protectionProductsActions.UpdateIsSETDealer({
        isSetDealer: this.isSetDealer
      })
    );
    this.protectionProductsState.dispatch(
      new protectionProductsActions.ResetMasterDataLoadFailedFlag({
        loadFailed: false,
      })
    );
    this.protectionProductsState.dispatch(
      new protectionProductsActions.LoadProductOfferings({
        dealerCode: this.dealerCode, draftStatus
      })
    );

    this.vehicleConfigState.select(selectVehicleBrands).pipe(take(1)).subscribe(_brands => {
      const brand: string = Brands.TOYOTA;
      this.vehicleConfigState.dispatch(new vehicleConfigActions.LoadRTIVehicleConfig({ brand }));
    });


    this.dealerConfigState.select(selectBankConfigLoaded).pipe(take(1), filter((t) => !t))
      .subscribe(() => {
        this.dealerConfigState.dispatch(
          new dealerConfigurationActions.LoadBankConfiguration(
            {
              distributor: this.dealerInfoService.getDealerBankDistributor()
            }));
      });
    this.protectionProductsState.select(selectPennProviders)
      .pipe(take(1), filter((t) => t.length === 0))
      .subscribe(() => {
        this.protectionProductsState.dispatch(
          new protectionProductsActions.LoadPennProviders({
            dealerCode: this.dealerCode,
          })
        );
      });
    this.protectionProductsState.select(selectPennProviders)
      .pipe(filter((t) => t.length > 0), take(1))
      .subscribe((t) => {
        this.pennProviders = t;
      });
    this.protectionProductsState.select(selectTaxCategories)
      .pipe(take(1), filter((t) => t.length === 0))
      .subscribe(() => {
        this.protectionProductsState.dispatch(
          new protectionProductsActions.LoadTaxCategories({
            dealerCode: this.dealerCode,
          })
        );
      });
    this.subs.salesClassesSub = this.protectionProductsState
      .select(selectSalesClasses)
      .subscribe((t) => {
        this.salesClasses = t;
      });
    this.subs.offerTypesSub = this.protectionProductsState
      .select(selectOfferTypes)
      .subscribe((t) => {
        this.offerTypes = t;
      });
    this.subs.termDurationsSub = this.protectionProductsState
      .select(selectTermDurations)
      .subscribe((t) => {
        this.termDurations = t;
      });
    this.subs.dynamicFieldsMasterDataSub = this.protectionProductsState
      .select(selectPennDynamicFieldsMasterData)
      .subscribe((t) => {
        this.dynamicFieldsMasterData = t;
      });
  }

  /* istanbul ignore next */
  destroySubs() {
    unsubscribeSubscriptions(this.subs);
    this.subscription.unsubscribe();

  }

  /* istanbul ignore next */
  clearFormSubs() {
    unsubscribeSubscriptions(this.formSubs);
    this.formSubs = {};
  }

  get getSelectedProduct$() {
    return this.selectedProductData$.asObservable();
  }

  get getOrderChangeDetector$() {
    return this.orderChangeDetector.asObservable();
  }

  get getSelectedProductConfig$() {
    return this.selectedProductConfig$.asObservable();
  }

  get getEditDisabled$() {
    return this.editDisabled.asObservable();
  }

  get getChangeOrder$() {
    return this.changeOrder.asObservable();
  }

  get getAddingProduct$() {
    return this.addingProduct.asObservable();
  }

  get getResetSelectedProduct$() {
    return this.resetSelectedProduct.asObservable();
  }

  /* istanbul ignore next */
  get getImageUpdatingFlag$() {
    return this.imageUpdatingFlag.asObservable();
  }

  triggerSelectedProductReset() {
    return this.resetSelectedProduct.next();
  }

  setEditDisabled(val: boolean) {
    return this.editDisabled.next(val);
  }

  setChangeOrder(val: boolean) {
    return this.changeOrder.next(val);
  }

  setAddingProduct(val: boolean) {
    return this.addingProduct.next(val);
  }

  setImageUpdatingFlag(val: boolean) {
    return this.imageUpdatingFlag.next(val);
  }

  updateOrderChangeDetector() {
    this.orderChangeDetector.next(this.orderChangeDetector.value + 1);
  }

  getOwnPpProviderId() {
    if (this.isGstDealer) {
      return ProvidersEnum.GSFS;
    }
    else if (this.isSetDealer) {
      return ProvidersEnum.JMNA
    } else if (this.isTfsDealer) {
      return ProvidersEnum.TMIS;
    } else {
      return ProvidersEnum.TMIS;
    }
  }

  updateInvalidProductForOffers(): void {
    if (this.isSetDealer) {
      this.noCashOfferProducts = ["TLP", "EWT"];
      this.noFinanceOfferProducts = ["EWT"];
      this.noLeaseOfferProducts = [];
    } else {
      this.noCashOfferProducts = ["GAP", "Wear and Tear"];
      this.noFinanceOfferProducts = ["Wear and Tear"];
      this.noLeaseOfferProducts = [];
    }
  }

  /* istanbul ignore next */
  setSelectedProduct(
    id: string,
    isNewProduct: boolean = false,
    productData?: Partial<IPennProductDefaults>
  ) {
    if (!id && !isNewProduct) {
      this.selectedProductData = this.selectedProductDetailsForm = this.selectedProductConfigString = undefined;
      this.selectedProductData$.next(undefined);
    } else if (isNewProduct) {
      this.selectedProductData = productData.masterData;
      this.selectedProductDetailsForm = this.generateFormForProduct(
        productData.masterData,
        this.productOfferings.length
      );
      if (productData.configurationData) {
        this.getInitialProductValue(
          this.selectedProductDetailsForm,
          productData.configurationData,
          productData.masterData,
          isNewProduct
        );
      }
    } else {
      this.selectedProductData = _.find(this.productOfferings, { id });
      if (id) {
        this.selectedProductDetailsForm = (this.protectionProductsForm.get(
          "products"
        ) as FormArray).controls.filter(
          (t: FormGroup) => t.getRawValue().id === id
        )[0] as FormGroup;
      }
    }
    this.selectedProductData$.next(this.selectedProductData);
  }

  resetForm() {
    this.protectionProductsState.dispatch(
      new protectionProductsActions.ResetAndRebuildPp()
    );
  }

  setupForm() {
    return combineLatest([
      this.protectionProductsState.select(selectPennProviders),
      this.protectionProductsState.select(selectProductOfferings),
      this.protectionProductsState.select(selectProtectionProducts),
      this.protectionProductsState.select(selectTaxCategories),
      this.dealerConfigState.select(selectBankConfigLoaded),
      this.dealerConfigState.select(selectPpCompatibility)
    ])
      .pipe(
        filter(
          ([pennProviders, offerings, productsData, taxCategories, bankConfigLoaded, ppCompatibility]) =>
            (pennProviders.length > 0) && (taxCategories.length > 0) && bankConfigLoaded
        ),
        debounceTime(10)
      )
      .subscribe(([pennProviders, offerings, productsData, taxCategories, bankConfigLoaded, ppCompatibility]) => {
        this.ppCompatibility = ppCompatibility;
        if (offerings) {
          this.setChangeOrder(false);
          this.setEditDisabled(false);
          this.setAddingProduct(false);
          this.clearFormSubs();
          this.productOfferings = offerings;
          this.ownPpCount = this.productOfferings.filter(
            (t) => t.providerId === this.ownPpProviderId
          ).length;
          this.protectionProductsForm = this.formBuilder.group({
            maxVisiblePP: [this.productOfferings?.length || 0],
            products: this.formBuilder.array([]),
          });
          this.productsData = productsData;
          this.productOfferings.forEach((ProductMasterData, index) => {
            (this.protectionProductsForm.get("products") as FormArray).push(
              this.generateFormForProduct(ProductMasterData, index)
            );
          });

          if (productsData.maxVisiblePP != 0) {
            (this.protectionProductsForm.get(
              "maxVisiblePP"
            ) as FormControl).setValue(this.productsData.maxVisiblePP);
          }

          (this.protectionProductsForm.get(
            "products"
          ) as FormArray).controls.forEach((productItem) => {
            const productConfig = _.find(this.productsData.products, {
              id: productItem.value.id,
            });
            this.hasMutexProduct = this.ppCompatibility.map(obj => obj.productId)
              .every(productId => offerings.map(catlog => catlog.code).includes(productId));

            this.productMasterData = _.find(offerings, {
              id: productItem.value.id,
            });
            if (this.productMasterData && productConfig) {
              this.getInitialProductValue(
                productItem,
                productConfig,
                this.productMasterData
              );
            }


          });
          const sortedPPForm = (this.protectionProductsForm.get(
            "products"
          ) as FormArray).controls;
          sortedPPForm.sort(this.customOrderComparer(this.ownPpProviderId));
          let selectedPrdtId = this.selectedProductData$.value?.id;
          if (
            !selectedPrdtId ||
            !_.find(this.productOfferings, (t) => t.id === selectedPrdtId)
          ) {
            selectedPrdtId = sortedPPForm[0]?.value.id;
          }
          this.protectionProductsForm.updateValueAndValidity();
          this.setSelectedProduct(selectedPrdtId);

          this.updateOrderChangeDetector();
        }
      });
  }

  getEmptyProduct(provider: string = ""): Partial<IPennProductDefaults> {
    return {
      masterData: {
        id: "",
        providerId: undefined,
        code: "",
        description: "",
        isRegulated: "N",
        usePAC: false,
        forms: [
          {
            code: "default",
            description: "",
            plans: [],
            options: [],
            sortOrder: 0,
          },
        ],
      },
      configurationData: {
        global: {
          taxable: "true",
          hide: false,
          capitalized: "true",
          order: 0,
          gp: {
            type: GrossProfitTypes.AMOUNT, value: 0,
            adjustments: this.isTfsDealer ? {
              new: { cash: [], finance: [], lease: [] },
              certified: { cash: [], finance: [], lease: [] },
              used: { cash: [], finance: [], lease: [] },
            } : undefined
          },
          maxPrice: 0,
          retailAmount: 0,
          defaults: {
            new: { cash: [], finance: [], lease: [] },
            certified: { cash: [], finance: [], lease: [] },
            used: { cash: [], finance: [], lease: [] },
          },
          isRegulated: "N",
          dynamicFields: {},
          brochureUrl: undefined,
          imageUrl: undefined,
          pacManagement: undefined,
          firstPencilDefaults: undefined,
          dealerCost: undefined,
          plans: [],
          productTitle: "",
          description: "",
          productTypes: "",
          bookCode: "",
          provider,
          providerId: undefined,
          taxClassificationId: "",
        },
        id: "",
        offers: {
          cash: { enabled: true },
          finance: { enabled: true },
          lease: { enabled: true },
        },
      },
    };
  }

  generateFormForProduct(productItem: IProduct, index: number) {
    const isJMNAProduct = productItem.providerId === ProvidersEnum.JMNA;
    const formItem = this.formBuilder.group({
      id: [productItem.id],
      providerId: [productItem.providerId],
      global: this.formBuilder.group({
        hide: [false],
        taxable: [true],
        defaults: this.formBuilder.group({}),
        capitalized: [true],
        isRegulated: ['N'],
        taxClassificationId: [""],
        plans: this.formBuilder.array([]),
        gp: this.formBuilder.group({
          type: [
            {
              value: GrossProfitTypes.AMOUNT,
              disabled: productItem.isRegulated === "Y"
            },
          ],
          value: [
            { value: 0, disabled: productItem.isRegulated === "Y" },
            [Validators.required, Validators.pattern("^[0-9]*$")],
          ]
        }),
        order: [index],
      }),
      offers: this.formBuilder.group({}),
    });
    const globalForm = formItem.get("global") as FormGroup;
    const gpForm = formItem.get(['global', 'gp']) as FormGroup;
    globalForm.addControl(
      "retailAmount",
      this.formBuilder.control(
        { value: 0, disabled: productItem.isRegulated === "Y" },
        [Validators.required, Validators.pattern("^[0-9]*$")]
      )
    );
    if (this.isTfsDealer) {
      gpForm.addControl('adjustments', this.formBuilder.group({}));
    }
    if (!isJMNAProduct) {
      globalForm.addControl(
        "maxPrice",
        this.formBuilder.control(
          0,
          [Validators.pattern("^[0-9]*$")]
        )
      );
    }
    globalForm.addControl("providerId", this.formBuilder.control(productItem.providerId));
    if (productItem.providerId != this.ownPpProviderId) {
      globalForm
        .get(["taxClassificationId"])
        .setValidators([Validators.required]);
      globalForm.addControl(
        "provider",
        this.formBuilder.control("", [Validators.required])
      );
      globalForm.addControl(
        "productTitle",
        this.formBuilder.control("", [
          Validators.required,
          this.checkUniqueProductTitle(formItem),
        ])
      );
      globalForm.addControl(
        "productTypes",
        this.formBuilder.control("", [Validators.required])
      );
    }
    if (productItem.providerId === ProvidersEnum.PENN) {
      globalForm.addControl(
        "bookCode",
        this.formBuilder.control("", [Validators.required])
      );
    }
    this.addGrossProfitValidation(globalForm);
    this.buildDefaultsForm(globalForm, productItem);
    this.buildOffersEnabledForm(
      formItem.get("offers") as FormGroup,
      productItem
    );
    this.buildPlansForm(
      formItem.get(["global", "plans"]) as FormArray,
      productItem
    );
    if (this.isTfsDealer) {
      this.buildAdjustmentsForm(globalForm, productItem);
    }
    return formItem;
  }

  setProductItemTfsDealer(
    productItem: AbstractControl,
    productData: IProductConfiguration,
    isNewProduct: boolean
  ) {
    if ((productData.global.providerId || productData.providerId) !== ProvidersEnum.JMNA) {
      productItem
        .get(["global", "maxPrice"])
        .patchValue(productData.global.maxPrice || null);
    }
    if (productData.global.pacManagement) {
      this.buildPacMgmt(productItem, productData);
    }
    if (productData.global.providerId === ProvidersEnum.PENN) {
      if (
        this.checkBookCodeBasedProduct(
          productData.global.provider,
          productData.global.productTypes
        ) ||
        productData.global.bookCode
      ) {
        productItem
          .get(["global", "bookCode"])
          .patchValue(productData.global.bookCode);
        if (!isNewProduct) {
          productItem.get(["global", "bookCode"]).disable({ emitEvent: false });
        }
      } else {
        (productItem.get(["global"]) as FormGroup).removeControl("bookCode");
      }
    }

    if ((productData.global.providerId || productData.providerId) != this.ownPpProviderId) {
      productItem
        .get(["global", "provider"])
        .patchValue(productData.global.provider);
      productItem
        .get(["global", "productTitle"])
        .patchValue(productData.global.productTitle);
      productItem
        .get(["global", "productTypes"])
        .patchValue(productData.global.productTypes);
      if (!isNewProduct) {
        productItem.get(["global", "provider"]).disable({ emitEvent: false });
        productItem
          .get(["global", "productTypes"])
          .disable({ emitEvent: false });
      }
      this.buildDynamicFields(productItem, productData);
      this.setDealerCostField(productItem, productData);
      this.buildMarketingContentFields(productItem, productData);
    }
  }

  getInitialProductValue(
    productItem: AbstractControl,
    productData: IProductConfiguration,
    productMasterData: IProduct,
    isNewProduct: boolean = false
  ) {
    productItem
      .get(["global", "capitalized"])
      .patchValue(
        typeof productData.global.capitalized === "undefined"
          ? true
          : productData.global.capitalized
      );
    productItem
      .get(["global", "isRegulated"])
      .patchValue(
        productData.global.isRegulated || 'N'
      );
    productItem
      .get(["global", "defaults"])
      .patchValue(productData.global.defaults);
    this.updateDefaultsForm(productItem, productMasterData);
    productItem
      .get(["global", "gp"])
      .patchValue(
        productData.global.gp ||
        ({ type: GrossProfitTypes.AMOUNT, value: 0 } as IGrossProfit)
      );
    productItem
      .get(["global", "hide"])
      .patchValue(productData.global.hide || false);
    productItem
      .get(["global", "order"])
      .patchValue(productData.global.order || 0);
    productItem
      .get(["global", "taxable"])
      .patchValue(
        typeof productData.global.taxable === "undefined"
          ? true
          : productData.global.taxable
      );
    productItem
      .get(["global", "taxClassificationId"])
      .patchValue(productData.global.taxClassificationId || "");
    this.setProductItemTfsDealer(productItem, productData, isNewProduct);
    this.buildFirstPencilDefaults(productItem, productMasterData);
    if (productData.global.firstPencilDefaults) {
      this.updateFirstPencilDefaults(
        productItem.get(["global", "firstPencilDefaults"]) as FormArray,
        productData.global.firstPencilDefaults
      );
    }
    this.updatePlansForm(productItem, productData, productMasterData);
    this.updateAdjustments(productItem, productData);
    productItem.get(["offers"]).patchValue(productData.offers);
    this.updateOrderChangeDetector();
  }

  updateFirstPencilDefaults(
    pencilDefaultsForm: FormArray,
    firstPencilDefaults: IPencilDefaults[]
  ) {
    pencilDefaultsForm.controls.forEach((pencilDefault) => {
      const salesClass = pencilDefault.get("salesClass").value;
      const offerType = pencilDefault.get("offerType").value;
      const result = _.find(firstPencilDefaults, { salesClass, offerType });
      if (result) {
        pencilDefault.get("enabled").patchValue(result.enabled);
      }
    });
  }

  updatePlansForm(
    productItem: AbstractControl,
    productData: IProductConfiguration,
    productMasterData: IProduct
  ) {
    if (productMasterData.providerId !== ProvidersEnum.JMNA) {
      (productItem.get(["global"]) as FormGroup).removeControl("plans");
      (productItem.get(["global"]) as FormGroup).addControl(
        "plans",
        this.formBuilder.array([])
      );
      const plansFormArray = productItem.get(["global", "plans"]) as FormArray;
      productData.global.plans.forEach((plan) => {
        const hasMutexPlans =
          _.some(this.ppCompatibility, { productId: productMasterData.code, formId: plan.formId, planId: plan.id });
        const planItem = this.formBuilder.group({
          id: [plan.id],
          formId: [plan.formId],
          salesClass: [plan.salesClass],
          visible: [plan.visible],
        });
        if (hasMutexPlans && this.hasMutexProduct) {
          (planItem as FormGroup).addControl('includePlan', this.formBuilder.control(plan.includePlan ?? false));
        }
        plansFormArray.push(planItem);
      });
      plansFormArray.controls.sort(this.salesClassComparer);
      plansFormArray.updateValueAndValidity();
    } else {
      (productItem.get(["global", "plans"]) as FormArray).controls.forEach(
        (planItem) => {
          const planData = productData.global.plans.filter(
            (t) =>
              t.id === planItem.get("id").value &&
              t.formId === planItem.get("formId").value
          );
          if (planData.length > 0) {
            planItem.get(["visible"]).patchValue(planData[0].visible);
          }
        }
      );
    }
  }

  buildFirstPencilDefaults(
    productItem: AbstractControl,
    productMasterData: IProduct
  ) {
    (productItem.get(["global"]) as FormGroup).removeControl(
      "firstPencilDefaults"
    );
    (productItem.get(["global"]) as FormGroup).addControl(
      "firstPencilDefaults",
      this.formBuilder.array([])
    );
    const productCode = this.getProductCodeOrType(
      productItem,
      productMasterData
    );
    this.salesClasses.forEach((salesClass) => {
      this.offerTypes.forEach((offerType) => {
        // IF JMNA Product, check only if offer is valid
        // If TFS/GST dealers and tmis/gsfs/penn product check only  if defaults exists for the salesClass and offerType
        // If TFS/GST dealer and nontmis product check only if offer is valid
        if (
          ((productMasterData.providerId === ProvidersEnum.JMNA ||
            productMasterData.providerId === ProvidersEnum.NONTMIS)
            && this.checkOfferValid(offerType, productCode)) ||
          this.calculateTermList(productMasterData, salesClass, offerType)
            .length > 0
        ) {
          const record = this.formBuilder.group({
            salesClass: this.formBuilder.control(
              salesClass.toLocaleLowerCase()
            ),
            offerType: this.formBuilder.control(offerType.toLocaleLowerCase()),
            enabled: this.formBuilder.control(false),
            enabledForMSTC: this.formBuilder.control({
              value: false,
              disabled: true,
            }),
          });
          (productItem.get([
            "global",
            "firstPencilDefaults",
          ]) as FormArray).push(record);
        }
      });
    });
  }

  getProductCodeOrType(
    productItem: AbstractControl,
    productMasterData: IProduct
  ): string {
    if (productMasterData.providerId !== ProvidersEnum.JMNA) {
      return (
        productItem.get(["global", "productTypes"])?.value ||
        productMasterData.code
      );
    } else {
      return productMasterData.code;
    }
  }

  buildPacMgmt(
    productItem: AbstractControl,
    productData: IProductConfiguration
  ) {
    (productItem.get(["global"]) as FormGroup).addControl(
      "pacManagement",
      this.formBuilder.group({
        amount: [
          productData.global.pacManagement.amount,
          [Validators.pattern("^[0-9]*$")],
        ],
        effectiveDate: [
          productData.global.pacManagement?.effectiveDate
            ? DateTime.fromISO(
              productData.global.pacManagement.effectiveDate.substring(0, 10)
            )
            : null,
        ],
      })
    );
    const pacManagementForm = productItem.get([
      "global",
      "pacManagement",
    ]) as FormGroup;
    pacManagementForm.setValidators(
      this.checkPacEffectiveDateValidator(
        productData.global.pacManagement,
        pacManagementForm
      )
    );
  }

  buildDynamicFields(
    productItem: AbstractControl,
    productData: IProductConfiguration
  ) {
    (productItem.get(["global"]) as FormGroup).removeControl("dynamicFields");
    (productItem.get(["global"]) as FormGroup).addControl(
      "dynamicFields",
      this.formBuilder.group({})
    );
    const dynamicFields = getDynamicFieldsForProduct(
      this.dynamicFieldsMasterData,
      this.pennProviders,
      productData.global.provider,
      productData.global.productTypes
    );
    const dynamicFieldsForm = productItem.get([
      "global",
      "dynamicFields",
    ]) as FormGroup;
    dynamicFields.forEach((field) => {
      if (typeof productData.global[field.fieldId] === "undefined") {
        const fieldControl = this.getDynamicFieldValue(field, productData);
        if (fieldControl) {
          dynamicFieldsForm.addControl(field.fieldId, fieldControl);
          if (
            productData.global.providerId.toLowerCase() !==
            ProvidersEnum.NONTMIS
          ) {
            dynamicFieldsForm.get(field.fieldId).disable({ emitEvent: false });
          }
        }
      }
    });
  }

  buildMarketingContentFields(
    productItem: AbstractControl,
    productData: IProductConfiguration
  ) {
    const pennProductMasterData = this.getPennProductMasterData(productData);
    const productGlobal = productItem.get(["global"]) as FormGroup;
    if (!pennProductMasterData?.marketingContent?.description) {
      productGlobal.addControl(
        "description",
        this.formBuilder.control(productData.global.description, [
          Validators.required,
        ])
      );
    }
    if (!pennProductMasterData?.marketingContent?.imageUrl) {
      productGlobal.addControl(
        "imageUrl",
        this.formBuilder.control(productData.global.imageUrl || "", [
          Validators.required,
        ])
      );
    }
  }

  getDynamicFieldValue(
    field: IFieldItem,
    productData: IProductConfiguration
  ): FormControl {
    if (field.fieldType.toLowerCase() === DynamicFieldTypes.TEXTBOX) {
      return this.formBuilder.control(
        productData.global?.dynamicFields[field.fieldId] || "",
        [Validators.maxLength(+field.fieldLength || 10)]
      );
    } else if (field.fieldType.toLowerCase() === DynamicFieldTypes.DROPDOWN) {
      return this.formBuilder.control(
        productData.global?.dynamicFields[field.fieldId] || ""
      );
    } else if (field.fieldType.toLowerCase() === DynamicFieldTypes.CHECKBOX) {
      return this.formBuilder.control(
        productData.global?.dynamicFields[field.fieldId] || false
      );
    } else if (field.fieldType.toLowerCase() === DynamicFieldTypes.CALENDAR) {
      return this.formBuilder.control(
        productData.global?.dynamicFields[field.fieldId] || "" // this.luxonDateAdapter.toIso8601(this.luxonDateAdapter.today())
      );
    }
  }

  buildAdjustmentsForm(globalForm: FormGroup, productItem: IProduct) {
    const grossProfitForm = globalForm.get(["gp"]) as FormGroup;
    grossProfitForm.removeControl("adjustments");
    grossProfitForm.addControl("adjustments", this.formBuilder.group({}));
    this.salesClasses.forEach((salesClass) => {
      (grossProfitForm.get("adjustments") as FormGroup).addControl(
        salesClass,
        this.formBuilder.group({})
      );
      this.offerTypes.forEach((offerType) => {
        (grossProfitForm.get(["adjustments", salesClass]) as FormGroup).addControl(
          offerType,
          this.formBuilder.array([
          ])
        );

        /*const adjustmentForm = grossProfitForm.get([
          "adjustments",
          salesClass,
          offerType,
        ]) as FormArray;

        const group = this.formBuilder.group({
          make: [],
          series: [],
          adjustment: [],
          measure: null
        });
        group.setValidators(this.validateAdjustmentForm(salesClass, offerType, grossProfitForm));
        adjustmentForm.push(group);*/
      });
    });
  }

  buildDefaultsForm(globalForm: FormGroup, productItem: IProduct) {
    globalForm.removeControl("defaults");
    globalForm.addControl("defaults", this.formBuilder.group({}));
    this.salesClasses.forEach((salesClass) => {
      (globalForm.get("defaults") as FormGroup).addControl(
        salesClass,
        this.formBuilder.group({})
      );
      this.offerTypes.forEach((offerType) => {
        // removing Cash Offer for TLP and EWT
        (globalForm.get(["defaults", salesClass]) as FormGroup).addControl(
          offerType,
          this.formBuilder.array([])
        );
        // check if offer is valid only for JM&A products
        if (
          productItem.providerId !== ProvidersEnum.JMNA ||
          this.checkOfferValid(offerType, productItem.code)
        ) {
          this.setOfferFormJmaDealers(
            globalForm, productItem, salesClass, offerType
          );
        }
      });
    });
  }

  setOfferFormJmaDealers(
    globalForm: FormGroup,
    productItem: IProduct,
    salesClass: string,
    offerType: string
  ) {
    const termList: ITermDuration[] = this.calculateTermList(
      productItem,
      salesClass,
      offerType
    );
    const offerForm = globalForm.get([
      "defaults",
      salesClass,
      offerType,
    ]) as FormArray;
    let defaultFormId = "";
    let defaultPlanId = "";
    let defaultDeductible = "";
    let defaultCoverageOption = "";
    if (productItem.forms && productItem.forms.length > 0) {
      const defaultFormData = productItem.forms[0];
      defaultFormId = defaultFormData.code;
      const plansList = defaultFormData.plans.filter(
        (t) =>
          (!t.salesClass || t.salesClass === salesClass) &&
          (!t.offerType || t.offerType === offerType)
      );
      if (plansList.length > 0) {
        defaultPlanId = plansList[0].code;
        const defaultCoverageOptionList = plansList.filter(
          (t) => t.code === defaultPlanId
        );
        if (
          defaultCoverageOptionList &&
          defaultCoverageOptionList.length > 0 &&
          defaultCoverageOptionList[0].coverageOptions &&
          defaultCoverageOptionList[0].coverageOptions.length > 0
        ) {
          defaultCoverageOption =
            defaultCoverageOptionList[0].coverageOptions[0].code;
        }
        const defaultDeductibleList = plansList.filter(
          (t) => t.code === defaultPlanId
        );
        if (
          defaultDeductibleList &&
          defaultDeductibleList.length > 0 &&
          defaultDeductibleList[0].deductibles &&
          defaultDeductibleList[0].deductibles.length > 0
        ) {
          defaultDeductible = defaultDeductibleList[0].deductibles[0].code;
        }
        const isJMNAProduct = productItem.providerId === ProvidersEnum.JMNA;
        termList.forEach((term) => {
          offerForm.push(
            this.formBuilder.group({
              minTerm: [term.minTerm],
              maxTerm: [term.maxTerm],
              formId: [defaultFormId],
              months: [this.calculateTermMonth(isJMNAProduct, term)],
              visible: [true],
              planId: [defaultPlanId],
              selectedDeductible: [defaultDeductible],
              selectedTimeMileage: [defaultCoverageOption],
            })
          );
        });
      }
    }
  }

  updateDefaultsForm(
    productForm: AbstractControl,
    productMasterData: IProduct
  ) {
    this.salesClasses.forEach((salesClass) => {
      const salesClassDefaults = productForm.get([
        "global",
        "defaults",
        salesClass,
      ]);
      if (salesClassDefaults) {
        this.offerTypes.forEach((offerType) => {
          const offerTypeDefaults = salesClassDefaults.get(
            offerType
          ) as FormArray;
          offerTypeDefaults?.controls.forEach((termDefaultForm) => {
            this.checkIfFormsExists(
              termDefaultForm,
              productMasterData,
              salesClass,
              offerType
            );
          });
        });
      }
    });
  }

  checkIfFormsExists(
    termDefaultForm: AbstractControl,
    productMasterData: IProduct,
    salesClass: string,
    offerType: string
  ) {
    const termDefault: IProductProperties = termDefaultForm.value;
    const selectedForm = _.find(productMasterData.forms, {
      code: termDefault.formId,
    });
    if (selectedForm) {
      this.checkIfPlanExists(
        productMasterData,
        termDefaultForm,
        selectedForm,
        salesClass,
        offerType
      );
    } else {
      termDefault.formId = termDefault.planId = termDefault.selectedDeductible = termDefault.selectedTimeMileage =
        "";
      termDefaultForm.setValue(termDefault);
    }
  }

  checkIfPlanExists(
    productMasterData: IProduct,
    termDefaultForm: AbstractControl,
    selectedForm: IForm,
    salesClass: string,
    offerType: string
  ) {
    const termDefault: IProductProperties = termDefaultForm.value;
    const selectedPlan = _.find(
      selectedForm.plans,
      (t) =>
        t.code === termDefault.planId &&
        (productMasterData.providerId === ProvidersEnum.JMNA ||
          (t.salesClass === salesClass && t.offerType === offerType))
    );
    if (selectedPlan) {
      this.checkIfDeductibleExists(
        termDefaultForm,
        selectedPlan,
        termDefault.selectedDeductible
      );
      this.checkIfTimeMileageExists(
        termDefaultForm,
        selectedPlan,
        termDefault.selectedTimeMileage
      );
    } else {
      termDefault.planId = termDefault.selectedDeductible = termDefault.selectedTimeMileage =
        "";
      termDefaultForm.setValue(termDefault);
    }
  }

  checkIfDeductibleExists(
    termDefaultForm: AbstractControl,
    selectedPlan: IPlan,
    deductible: string
  ) {
    if (!_.find(selectedPlan.deductibles, { code: deductible })) {
      termDefaultForm.get("selectedDeductible").setValue("");
    }
  }

  checkIfTimeMileageExists(
    termDefaultForm: AbstractControl,
    selectedPlan: IPlan,
    timeMileage: string
  ) {
    if (!_.find(selectedPlan.coverageOptions, { code: timeMileage })) {
      termDefaultForm.get("selectedTimeMileage").setValue("");
    }
  }

  setDealerCostField(
    productItem: AbstractControl,
    productData: IProductConfiguration
  ) {
    if (
      productData.global.providerId?.toLowerCase() === ProvidersEnum.NONTMIS
    ) {
      (productItem.get(["global"]) as FormGroup).removeControl("dealerCost");
      (productItem.get(["global"]) as FormGroup).addControl(
        "dealerCost",
        this.formBuilder.control(productData.global.dealerCost || 0)
      );
    } else {
      (productItem.get(["global"]) as FormGroup).removeControl("dealerCost");
    }
  }

  updateNewProductForm(productItem: FormGroup) {
    this.protectionProductsState
      .select(selectPennProductDefaultsList)
      .pipe(take(1))
      .subscribe((productList) => {
        const providerName: string = productItem.get(["global", "provider"])
          .value;
        const productName = productItem.get(["global", "productTypes"]).value;
        const productData = this.checkIfProductDefaultsExists(
          productList,
          providerName,
          productName
        );
        if (productData) {
          this.setSelectedProduct("", true, productData);
        } else {
          const providerData = _.find(this.pennProviders, {
            name: providerName,
          });
          const productId = this.getPennProductId(providerData, productName);
          this.protectionProductsState.dispatch(
            new protectionProductsActions.LoadPennProductDefaults({
              dealerCode: this.dealerCode,
              providerName,
              productId,
              productName,
            })
          );
        }
      });
  }

  checkIfProductDefaultsExists(
    productList: IPennProductDefaults[],
    providerName: string,
    productName: string
  ) {
    return _.find(productList, { providerName, productName });
  }
  customOrderComparer(ownPpProviderId) {
    return (a1, b1) => {
      const a = a1.value.global;
      const b = b1.value.global;
      if (a.providerId === b.providerId) {
        return a.order - b.order;
      } else if (a.providerId === ownPpProviderId) {
        return -1;
      } else if (b.providerId === ownPpProviderId) {
        return 1;
      }
      else {
        return a.order - b.order;
      }
    };
  }

  salesClassComparer(a, b) {
    const defaultSortVal = 9;
    const salesClassMap = {};
    salesClassMap[SalesClasses.NEW] = 0;
    salesClassMap[SalesClasses.CERTIFIED] = 1;
    salesClassMap[SalesClasses.USED] = 2;
    return (
      (salesClassMap[(b.value.salesClass || "").toLowerCase()] ||
        defaultSortVal) -
      (salesClassMap[(a.value.salesClass || "").toLowerCase()] ||
        defaultSortVal)
    );
  }

  stringCompare(a, b) {
    return a.localeCompare(b);
  }

  calculateTermList(
    productItem: IProduct,
    salesClass: string,
    offerType: string
  ) {
    if (productItem.providerId !== ProvidersEnum.JMNA) {
      const termsArray = [];
      _.filter(productItem.forms[0].plans, { salesClass, offerType }).forEach(
        (t) => {
          termsArray.push(...t.terms);
        }
      );
      return [...new Set(termsArray)].sort(this.stringCompare);
    } else if (offerType === OfferTypes.CASH) {
      return [{ minTerm: 0, maxTerm: 0 } as ITermDuration];
    } else {
      return this.termDurations;
    }
  }

  calculateTermMonth(isJMNAProduct: boolean, term) {
    if (!isJMNAProduct) {
      return term;
    } else if (term.minTerm === 0 && term.maxTerm === 0) {
      return "";
    } else {
      return `${term.minTerm}${term.maxTerm === 0 ? " +" : "-" + term.maxTerm}`;
    }
  }

  buildOffersEnabledForm(offersForm: FormGroup, productItem: IProduct) {
    this.offerTypes.forEach((t) => {
      const isEnabled = this.checkOfferValid(t, productItem.code);
      offersForm.addControl(
        t,
        this.formBuilder.group({
          enabled: [isEnabled],
          valid: [isEnabled],
        })
      );
    });
  }

  checkOfferValid(offerType: string, productCode: string) {
    return (
      (this.noCashOfferProducts.indexOf(productCode) < 0 ||
        offerType !== OfferTypes.CASH) &&
      (this.noFinanceOfferProducts.indexOf(productCode) < 0 ||
        offerType !== OfferTypes.FINANCE) &&
      (this.noLeaseOfferProducts.indexOf(productCode) < 0 ||
        offerType !== OfferTypes.LEASE)
    );
  }

  buildPlansForm(plansFormArray: FormArray, productItem: IProduct) {
    if (productItem.providerId === ProvidersEnum.JMNA) {
      productItem.forms.forEach((formVal) => {
        formVal.plans.forEach((plan) => {
          const planItem = this.formBuilder.group({
            id: [plan.code],
            formId: [formVal.code],
            salesClass: [plan.salesClass],
            visible: [true],
          });
          plansFormArray.push(planItem);
        });
      });
    }
  }

  /* istanbul ignore case */
  beginAddNewProduct(
    provider: string = "",
    productList: IPennProductDefaults[] = []
  ) {
    this.setChangeOrder(false);
    this.setEditDisabled(true);
    this.setAddingProduct(true);
    this.protectionProductsForm.disable({ emitEvent: false });
    this.setSelectedProduct("", true, this.getEmptyProduct(provider));
  }

  cancelAddNewProduct() {
    this.setEditDisabled(false);
    this.setAddingProduct(false);
    this.resetForm();
  }

  getExistingAndDisabledProductsForProvider(provider: string) {
    const disabledProducts = this.disabledProductList
      .filter((t) => t.providerName === provider)
      .map((t) => t.productName);
    // Disable dealer defined product if already created
    // if (provider?.toLocaleLowerCase() === 'other') {
    //     const productList: IProductConfiguration[] = (this.protectionProductsForm.get('products') as FormGroup).getRawValue();
    //     const existingProducts = productList.filter(t => _.indexOf([this.ownPpProviderId, ProvidersEnum.PENN], t.global.providerId) < 0 && t.global.provider === provider).map(t => t.global.productTypes);
    //     return [...existingProducts, ...disabledProducts];
    // }
    return disabledProducts;
  }

  checkBookCodeBasedProduct(provider: string, product: string) {
    const pennProvider: IPennProvider = _.find(this.pennProviders, {
      name: provider,
    });
    return (
      _.find(pennProvider?.products || [], { name: product })?.rateBookCodes
        ?.length > 0
    );
  }

  getExistingBookCodesForProduct(provider: string, product: string) {
    const productList: IProductConfiguration[] = (this.protectionProductsForm.get(
      "products"
    ) as FormGroup).getRawValue();
    return productList
      .filter(
        (t) =>
          t.global.providerId !== this.ownPpProviderId &&
          t.global.provider === provider &&
          t.global.productTypes === product
      )
      .map((t) => t.global.bookCode);
  }

  getPennProductId(provider: IPennProvider, productName: string) {
    return _.find(provider.products || [], { name: productName })?.id;
  }

  getPennProductMasterData(productConfig: IProductConfiguration): IPennProduct {
    if (productConfig.global.providerId !== this.ownPpProviderId) {
      const pennProvider: IPennProvider = _.find(this.pennProviders, {
        name: productConfig.global.provider,
      });
      return _.find(pennProvider?.products || [], {
        name: productConfig.global.productTypes,
      });
    }
    return undefined;
  }

  addToDisabledProductList(providerName: string, productName: string) {
    const disabledProduct: IDisabledProduct = {
      providerName,
      productName,
    };
    this.disabledProductList.push(disabledProduct);
  }

  addGrossProfitValidation(globalForm: FormGroup) {
    const grossProfitForm = globalForm.get(["gp"]) as FormGroup;
    const maxPriceForm = globalForm.get(["maxPrice"]) as FormControl;
    if (maxPriceForm) {
      grossProfitForm.setValidators([
        this.checkMaxPriceLessThanGrossProfit(globalForm)

      ]);
      grossProfitForm.updateValueAndValidity();
      this.formSubs[`${uuid.v4()}`] = grossProfitForm.valueChanges.subscribe(
        (t) => {
          maxPriceForm.updateValueAndValidity({ emitEvent: false });
        }
      );
      maxPriceForm.setValidators([
        this.checkMaxPriceLessThanGrossProfit(globalForm),
        Validators.pattern("^[0-9]*$"),
      ]);
      maxPriceForm.updateValueAndValidity();
      globalForm.setValidators([this.checkMaxPriceValid(globalForm)]);
      globalForm.updateValueAndValidity();
      this.formSubs[`${uuid.v4()}`] = maxPriceForm.valueChanges.subscribe(
        (t) => {
          grossProfitForm.updateValueAndValidity({ emitEvent: false });
        }
      );
    }
  }

  checkMaxPriceLessThanGrossProfit(globalForm: FormGroup): ValidatorFn {
    return (_control: FormControl) => {
      const grossProfit = globalForm.get(["gp", "value"]).value;
      const grossProfitType = globalForm.get(["gp", "type"]).value;
      const maxPrice = globalForm.get(["maxPrice"])?.value;
      return grossProfitType === GrossProfitTypes.AMOUNT &&
        grossProfit &&
        maxPrice &&
        grossProfit > maxPrice
        ? {
          gpExceedsMaxPrice: true,
          gpExceedsMaxPriceMsg: "Gross Profit should be less than Max Price.",
        }
        : null;
    };
  }

  checkMaxPriceValid(globalForm: FormGroup): ValidatorFn {
    return (_control: FormControl) => {
      const grossProfit = globalForm.get(["gp", "value"]).value;
      const providerId = globalForm.get(["providerId"])?.value;
      const grossProfitType = globalForm.get(["gp", "type"]).value;
      const maxPrice = globalForm.get("maxPrice")?.value;
      const pacManagement = globalForm.get(["pacManagement"])?.value;
      let errorMsg = "Dealer Markup";
      let totalGpDcPm;
      if (providerId === ProvidersEnum.NONTMIS) {
        errorMsg = errorMsg + " and Dealer Cost";
        const dealerCost = globalForm.get("dealerCost")?.value;
        if (grossProfitType === GrossProfitTypes.PERCENT) {
          totalGpDcPm = (grossProfit / 100) * dealerCost + dealerCost;
        } else {
          totalGpDcPm = grossProfit + dealerCost;
        }
      } else {
        totalGpDcPm = grossProfit;
      }
      if (pacManagement && pacManagement.effectiveDate <= this.currentDate) {
        totalGpDcPm = totalGpDcPm + pacManagement.amount;
      }
      if (providerId === ProvidersEnum.NONTMIS) {
        return totalGpDcPm && maxPrice && totalGpDcPm > maxPrice
          ? {
            gpExceedsMP: true,
            gpExceedsMPMsg: errorMsg + " cannot exceed Max Price.",
          }
          : null;
      } else {
        return grossProfitType === GrossProfitTypes.AMOUNT &&
          totalGpDcPm &&
          maxPrice &&
          totalGpDcPm > maxPrice
          ? {
            gpExceedsMP: true,
            gpExceedsMPMsg: errorMsg + " cannot exceed Max Price.",
          }
          : null;
      }
    };
  }

  checkPacEffectiveDateValidator(
    pacManagementData: IPacManagement,
    pacManagementForm: FormGroup
  ): ValidatorFn {
    return (group: FormGroup) => {
      const pacAmount = group.get(["amount"]).value;
      const pacEffectiveDate = group.get(["effectiveDate"]).value;
      const pacEffectiveDateString = this.getFormattedPacEffectiveDate(
        pacEffectiveDate
      );
      const initialPacDateString = this.getFormattedPacEffectiveDate(
        pacManagementData.effectiveDate
      );
      if (
        pacEffectiveDate >= this.currentDate ||
        (pacManagementData?.amount === pacAmount &&
          initialPacDateString === pacEffectiveDateString)
      ) {
        return null;
      } else {
        return { invalidPacEffectiveDate: true };
      }
    };
  }

  getFormattedPacEffectiveDate(pacEffectiveDate: string | DateTime) {
    if (pacEffectiveDate) {
      if (typeof pacEffectiveDate === "string") {
        return pacEffectiveDate.substring(0, 10);
      } else {
        return pacEffectiveDate.toISODate();
      }
    }
    return null;
  }

  checkUniqueProductTitle(productForm: FormGroup): ValidatorFn {
    return (_control: FormControl) => {
      if (productForm != this.selectedProductDetailsForm) {
        return null;
      }
      const productId: string = (productForm.getRawValue() as IProductConfiguration)
        ?.id;
      const productList: IProductConfiguration[] = (this.protectionProductsForm.get(
        "products"
      ) as FormGroup).getRawValue();
      const productTitles = productList
        .filter(
          (t) =>
            [
              ProvidersEnum.PENN.toString(),
              ProvidersEnum.NONTMIS.toString(),
            ].includes(t.global.providerId?.toLowerCase()) && t.id !== productId
        )
        .map((t) => t.global.productTitle?.toLowerCase());
      return productTitles.indexOf(_control.value?.trim().toLowerCase()) >= 0
        ? {
          productTitleNotUnique: true,
          productTitleNotUniqueMsg:
            "A product with the same name already exists.",
        }
        : null;
    };
  }

  getMutexPlans(productId: string, formId: string, planId: string): IPPCompatibilityMutexPlan[] {
    const mutexPlans = [];
    _.filter(this.ppCompatibility, { productId, formId, planId })
      .forEach(t => {
        mutexPlans.push(...t.mutexPlans);
      })
    return mutexPlans;
  }

  getEnabledMutexPlans(excludeProductId: string) {
    const productList: IProductConfiguration[] =
      (this.protectionProductsForm.get("products") as FormGroup).getRawValue();
    const enabledMutexPlans: IPPCompatibilityMutexPlan[] = [];
    (productList.filter((t) => t.id !== excludeProductId && t.global.providerId === this.getOwnPpProviderId()))
      .forEach((t) => {
        const productId = this.productOfferings.find(productMaster => productMaster.id === t.id).code;
        t.global.plans.forEach(p => {
          if (p.includePlan) {
            enabledMutexPlans.push({
              productId,
              formId: p.formId,
              planId: p.id
            });
          }
        })
      });
    return enabledMutexPlans;
  }

  getConflictingPlans(excludeProductId: string, mutexPlans: IPPCompatibilityMutexPlan[]) {
    let conflictingPlans: IPPCompatibilityMutexPlan[] = [];
    const productList: IProductConfiguration[] =
      (this.protectionProductsForm.get("products") as FormGroup).getRawValue();
    const enabledMutexPlans: IPPCompatibilityMutexPlan[] = [];
    _.some(productList.filter((t) =>
      t.id !== excludeProductId && t.global.providerId === this.getOwnPpProviderId()),
      (product: IProductConfiguration) => {
        const productId = this.productOfferings.find(productMaster => productMaster.id === product.id).code;
        const conflictingPlansInProduct = _.intersectionWith(mutexPlans, product.global.plans, (a, b) =>
          a.productId === productId &&
          a.formId === b.formId &&
          a.planId === b.id &&
          b.includePlan
        );
        conflictingPlans.push(...conflictingPlansInProduct);
      });
    return conflictingPlans;
  }

  isMutexProduct(productId: string) {
    return _.findIndex(this.ppCompatibility, { productId }) > -1 && this.hasMutexProduct;
  }

  /* Code Controllers For NGPE-6849 VPP Gross Profit Pricing Begins */

  /**
   * NGPE-6849
   * This builds "Gross profit by series" empty form 
   * based on salesclass and offertype
   * @returns void
   */
  updateAdjustments(
    productItem: AbstractControl,
    productData: IProductConfiguration) {
    const grossProfitForm = productItem.get(["global", "gp"]) as FormGroup;
    grossProfitForm.removeControl("adjustments");
    grossProfitForm.addControl("adjustments", this.formBuilder.group({}));
    this.salesClasses.forEach((salesClass) => {
      (grossProfitForm.get("adjustments") as FormGroup).addControl(
        salesClass,
        this.formBuilder.group({})
      );
      this.offerTypes.forEach((offerType) => {
        (grossProfitForm.get(["adjustments", salesClass]) as FormGroup).addControl(
          offerType,
          this.formBuilder.array([
          ])
        );
        // setTimeout(() => {
        if (typeof (productData?.global?.gp?.adjustments) !== 'undefined' && Object.keys(productData.global.gp?.adjustments).length > 0) {
          this.setAdjustmentsform(productItem, grossProfitForm,
            productData, salesClass, offerType, productData.global.gp.type);
          this.detectAdjustmentsFormChanges(productItem);
        }
        // }, 0)
      });
    });
  }

  /**
     * NGPE-6849
     * This detects changes on GP form
     * @returns void
     */
  detectAdjustmentsFormChanges(productItem: AbstractControl) {
    const grossProfitForm = productItem.get(["global", "gp"]) as FormGroup;
    this.salesClasses.forEach((salesClass) => {
      this.offerTypes.forEach((offerType) => {
        const grossProfitFormAdjustment = (grossProfitForm.get([
          "adjustments",
          salesClass,
          offerType,
        ]) as FormArray)
        if (grossProfitFormAdjustment) {
          grossProfitFormAdjustment.valueChanges.subscribe(() => {
            (grossProfitForm.get([
              "adjustments",
              salesClass,
              offerType,
            ]) as FormArray).controls.forEach((x) => {
              x.markAsDirty();
              x.updateValueAndValidity({ emitEvent: false });
            });
            (grossProfitForm.get([
              "adjustments",
              salesClass,
              offerType,
            ]) as FormArray).updateValueAndValidity({ emitEvent: false });
          });
        }
      });
    });
  }

  /**
   * NGPE-6849
   * This loads data for the form "Gross profit by series" generated by 
   * updateAdjustment function
   * @returns void
   */

  setAdjustmentsform(_globalForm: AbstractControl, grossProfitForm: FormGroup,
    productConfig: IProductConfiguration, salesClass: string, offerType: string, gpType: string) {
    const adjustmentForm = grossProfitForm.get([
      "adjustments",
      salesClass,
      offerType,
    ]) as FormArray;

    if (typeof (productConfig?.global?.gp?.adjustments) !== 'undefined' && Object.keys(productConfig.global.gp?.adjustments).length > 0) {
      productConfig.global.gp.adjustments[salesClass][offerType].forEach((adjustmentData) => {
        const group = this.formBuilder.group({
          make: [adjustmentData.make],
          series: [adjustmentData.series, [Validators.required]],
          adjustment: [adjustmentData.adjustment, [Validators.required]],
          measure: gpType
        })
        group.setValidators(this.validateAdjustmentForm(salesClass, offerType, grossProfitForm));
        adjustmentForm.push(group);
      });
    }
    else {
      const group = this.formBuilder.group({
        make: ['Toyota'],
        series: [['Tundra'], [Validators.required]],
        adjustment: [0, [Validators.required]],
        measure: gpType
      });
      group.setValidators(this.validateAdjustmentForm(salesClass, offerType, grossProfitForm));
      adjustmentForm.push(group);

    }
  }

  /**
   * NGPE-6849
   * This method triggered when GrossProfit measure(type) ($/%) changes
   * @returns void
   */

  updateGpMeasure(type: string) {
    (this.protectionProductsForm.get("products") as FormArray).controls.forEach((productItem) => {
      const productConfig = _.find(this.productsData.products, {
        id: productItem.value.id,
      });
      if (productConfig && (this.selectedProductData.id === productConfig.id)) {
        this.oldProductCode = productConfig.id;
        this.updateGpForm(productItem, this.selectedProductData, type);
      }
    });
  }

  /**
   * NGPE-6849
   * This builds "Gross profit by series" form 
   * based on grosprofit type changes(%/$)
   * @returns void
   */

  updateGpForm(globalForm: AbstractControl, selectedForm: IProduct, checkType: string) {

    this.selectedProductData = selectedForm;
    const selectedProductConfig = _.filter(this.productsData.products, (item) => {
      return item.id === selectedForm.id;
    }) as IProductConfiguration;
    const selectedProductConfigNew = _.cloneDeep(selectedProductConfig);
    if (selectedProductConfigNew[0].global) {
      if (checkType === 'add') {
        selectedProductConfigNew[0].global.gp.adjustments = {};
        selectedProductConfigNew[0].global.gp.value = 0;
        globalForm.get(['global', 'gp', 'value']).setValue(0, { emitEvent: false });
      }
      else {
        globalForm.get(['global', 'gp', 'value']).setValue(selectedProductConfigNew[0].global.gp.value, { emitEvent: false });
      }
    }
    this.updateAdjustments(globalForm, selectedProductConfigNew[0])
  }

  /**
   * NGPE-6849
   * This validates field value  in "Gross profit by series" form 
   * Conditions checked: duplicated make and model(series)
   * @returns void
   */

  validateAdjustmentForm(salesClass: string, offerType: string, globalForm: FormGroup): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const make = group.get("make").value;
      const series = group.get("series").value;
      const markups = (globalForm.get(['adjustments', salesClass, offerType]) as FormArray).controls.filter((x) => {
        return !_.isEqual(x, group)
      });
      const filteredAdjustments = markups.filter(
        (x) => {
          return (
            (x.value.make === make) &&
            (
              _.intersection(x.value.series, series).length !== 0
              || x.value.series?.includes('all')
              || series?.includes('all')
            )
          )
        });
      return filteredAdjustments?.length ? { notEquivalent: true } : undefined;
    }
  }

  /* Code Controllers For NGPE-6849 VPP Gross Profit Pricing Ends */

  checkAndfetchVehicleConfig(brand: string, vehicleConfigState: Store<VehicleConfigState>) {
    vehicleConfigState.select(selectVehicleBrands).pipe(take(1)).subscribe(brands => {
      if (_.indexOf(brands, brand) < 0) {
        vehicleConfigState.dispatch(new vehicleConfigActions.LoadRTIVehicleConfig({ brand }));
      }
    });
  }

  /**
   * NGPE-12466
   * This builds "Regulated products requirement changes in F&I AdminUI for Penn and Non TMIS Products" form
   * @returns void
   */
  setRegulateVal(e: any) {
    const global = this.selectedProductDetailsForm.get(["global"]) as FormGroup;
    const gp = this.selectedProductDetailsForm.get(["global", "gp"]) as FormGroup;
    //this.isRegulated = this.selectedProductDetailsForm.get(["global", "isRegulated"]).value;
    if (e === 'Y') {
      global.get(["gp"]).patchValue({
        "type": this.grossProfitTypes.AMOUNT,
        "value": 0
      });
      global.get(["isRegulated"]).patchValue('Y');
      global.get(["maxPrice"]).patchValue(0);
      if (global.get(["pacManagement"])) {
        global.get(["pacManagement"]).patchValue({
          "amount": 0,
          "effectiveDate": null
        });
      }
      this.patchAdjustments(gp);
      global.get(["retailAmount"]).enable({ emitEvent: false });
      global.get(["retailAmount"]).patchValue('');
      this.subs.changeRetailAmountForm = this.selectedProductDetailsForm.get(['global', 'retailAmount']).valueChanges.subscribe((_data) => {
        setTimeout(() => {
          this.selectedProductConfig$.next(this.selectedProductDetailsForm.getRawValue());
        }, 0)
      });
      this.selectedProductConfig$.next(this.selectedProductDetailsForm.getRawValue());
    } else {
      global.get(["isRegulated"]).patchValue('N');
      global.get(["gp"]).patchValue({
        "type": this.grossProfitTypes.AMOUNT,
        "value": 0
      });
      global.get(["maxPrice"]).patchValue(0);
      global.get(["retailAmount"]).patchValue(undefined);
      global.get(["retailAmount"]).disable({ emitEvent: false });
      this.patchAdjustments(gp);
      this.subs.changeRetailAmountForm = this.selectedProductDetailsForm.get(['global', 'retailAmount']).valueChanges.subscribe((_data) => {
        setTimeout(() => {
          this.selectedProductConfig$.next(this.selectedProductDetailsForm.getRawValue());
        }, 0)
      });
      this.subs.changeMaxPriceForm = this.selectedProductDetailsForm.get(['global', 'maxPrice']).valueChanges.subscribe((_data) => {
        setTimeout(() => {
          this.selectedProductConfig$.next(this.selectedProductDetailsForm.getRawValue());
        }, 0)
      });
      this.selectedProductConfig$.next(this.selectedProductDetailsForm.getRawValue());
    }
  }

  patchAdjustments(gp: FormGroup) {
    gp.removeControl("adjustments");
    gp.addControl("adjustments", this.formBuilder.group({}));
    this.salesClasses.forEach((salesClass) => {
      (gp.get("adjustments") as FormGroup).addControl(
        salesClass,
        this.formBuilder.group({})
      );
      this.offerTypes.forEach((offerType) => {
        (gp.get(["adjustments", salesClass]) as FormGroup).addControl(
          offerType,
          this.formBuilder.array([
          ])
        );

      })
    });
    //this.selectedProductConfig$.next(this.selectedProductDetailsForm.value);
  }
}
