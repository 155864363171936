import { Pipe, PipeTransform } from "@angular/core";
import { OfferTypes } from "../../../shared/enums/offer-types.enum";
import { TranslateService } from "@ngx-translate/core";
@Pipe({
    name: 'ratetitle'
})
export class RateTitlePipe implements PipeTransform {
    constructor(readonly translateService: TranslateService) { }
    transform(offerType: string): string {
        let rateTitle;
        switch (offerType) {
            case OfferTypes.LEASE:
                rateTitle = this.translateService.instant('LOCALIZATION.DEAL_VALIDATION.RCF');
                break;
            case OfferTypes.FINANCE:
            default:
                rateTitle = this.translateService.instant('LOCALIZATION.DEAL_VALIDATION.RATE');
                break;
        }
        return rateTitle;
    }

}
