import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { OfferTypes } from "../../../shared/enums/offer-types.enum";
import { IValueRange } from "../../../shared/models/utility/value-range.model";
import { DealerDefinedCampaignsComponentService } from "../dealer-defined-campaigns.component.service";
import { Observable } from 'rxjs';
import { IRangeByOffer } from "../models/range-by-offer.model";
import { Store } from '@ngrx/store';
import { DealerDefinedCampaignsState } from "../../../store/dealer-defined-campaigns/dealer-defined-campaigns.reducer";
import { SubscriptionList } from './../../../shared/services/util.service';
@Pipe({
    name: "getrangeforproperty"
})
export class GetRangeForPropertyPipe implements PipeTransform {
    markupRange$: Observable<IRangeByOffer[]>;
    markupRange: IRangeByOffer[];
    private subs: SubscriptionList = {};
    constructor(
        private readonly dDCCService: DealerDefinedCampaignsComponentService,
        private readonly dealerDefinedCampaignsState: Store<DealerDefinedCampaignsState>,
    ) { }

    transform(property: string, offerType: string = '', salesClass: string[] = [], markupRange: IRangeByOffer[] = []): IValueRange {
        switch (property) {
            case 'advancePercentage':
                return this.dDCCService.advPercentageRange;
            case 'markup':
                return _.find(markupRange, { offerType });
            case 'baseRate':
                return offerType === OfferTypes.FINANCE ?
                    { min: 0, max: this.dDCCService.getMaxStateApr(salesClass) } : this.dDCCService.leaseBaserate;
            case 'residual':
                return this.dDCCService.residualRange;
            default:
                return;
        }
    }

}