import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DealerDefinedCampaignsComponent } from './dealer-defined-campaigns.component';
import { ListCampaignComponent } from './list-campaign/list-campaign.component';
import { ModifyCampaignComponent } from './modify-campaign/modify-campaign.component';
import { ModifyRateRuleComponent } from './modify-rate-rule/modify-rate-rule.component';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalModule } from '../../global/global.module';
import { SharedModule } from '../../shared/shared.module';
import { DealerDefinedCampaignsRoutingModule } from './dealer-defined-campaigns-routing.module';
import { LenderDisplayNamePipe } from './pipes/lender-display-name.pipe';
import { GetCampaignsByLenderPipe } from './pipes/get-campaigns-by-lender.pipe';
import { GetCampaignStatusPipe } from './pipes/get-campaign-status.pipe';
import { ViewCampaignComponent } from './view-campaign/view-campaign.component';
import { ViewRateRuleComponent } from './view-rate-rule/view-rate-rule.component';
import { GetBaseRateForTierPipe } from './pipes/get-base-rate-for-tier.pipe';
import { ViewResidualsComponent } from './view-residuals/view-residuals.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { GetResidualTermsPipe } from './pipes/get-residual-terms.pipe';
import { GetResidualVariancesPipe } from './pipes/get-residual-variances.pipe';
import { GetVariaceValuePipe } from './pipes/get-variance-value.pipe';
import { GetResidualForTermPipe } from './pipes/get-residual-for-term.pipe';
import { LuxonDateAdapter, MAT_LUXON_DATE_FORMATS } from '../../shared/services/luxon-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { GetLenderOfferTypesPipe } from './pipes/get-lender-offer-types.pipe';
import { GetBrandSeriesPipe } from './pipes/get-brand-series.pipe';
import { FilterModelCodesPipe } from './pipes/filter-model-codes.pipe';
import { GetSalesclassesForCampaignPipe } from './pipes/get-salesclasses-for-campaign.pipe';
import { DisableOnAllSelectionPipe } from './pipes/disable-on-all-selection.pipe';
import { GetRangeForPropertyPipe } from './pipes/get-range-by-offer-and-class.pipe';
import { CheckMultipleAprClassesSelectedPipe } from './pipes/check-multiple-apr-classes-selected';
import { FilterBrandListPipe } from './pipes/filter-brand-list.pipe';


@NgModule({
  declarations: [
    DealerDefinedCampaignsComponent,
    ListCampaignComponent,
    ModifyCampaignComponent,
    ModifyRateRuleComponent,
    ViewCampaignComponent,
    ViewRateRuleComponent,
    ViewResidualsComponent,
    LenderDisplayNamePipe,
    GetCampaignsByLenderPipe,
    GetCampaignStatusPipe,
    GetBaseRateForTierPipe,
    GetResidualVariancesPipe,
    GetResidualTermsPipe,
    GetVariaceValuePipe,
    GetResidualForTermPipe,
    GetLenderOfferTypesPipe,
    GetBrandSeriesPipe,
    FilterModelCodesPipe,
    GetSalesclassesForCampaignPipe,
    DisableOnAllSelectionPipe,
    GetRangeForPropertyPipe,
    CheckMultipleAprClassesSelectedPipe,
    ShortNumberPipe,
    FilterBrandListPipe
  ],
  imports: [
    DealerDefinedCampaignsRoutingModule,
    TranslateModule.forChild({ extend: true }),
    CommonModule, GlobalModule, SharedModule
  ],
  providers: [
    LuxonDateAdapter,
    FilterModelCodesPipe,
    GetBrandSeriesPipe,
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS }
  ]
})
export class DealerDefinedCampaignsModule { }
