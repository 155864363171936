import { Pipe, PipeTransform } from '@angular/core';
import { DeployJobManageState } from "../../../store/deploy-job-management/deploy-job-management.reducer";
import { Store } from "@ngrx/store";
import { selectDeployConfiguration } from '../../../store/deploy-job-management/deploy-job-management.selectors';
import { BehaviorSubject } from 'rxjs';
@Pipe({
    name: 'checkpendingJobs'
})
export class CheckPendingJobsPipe implements PipeTransform {
    isJobsPending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(private readonly deployJobManageState: Store<DeployJobManageState>) { }
    transform(moduleName: string, screenName: string): BehaviorSubject<boolean> {
        let ispendingJobs;
        this.deployJobManageState.select(selectDeployConfiguration).subscribe(deployJobConfig => {
            if (deployJobConfig && deployJobConfig.trails) {
                ispendingJobs = deployJobConfig.trails.items.some(x => x.module == moduleName);
                this.isJobsPending$.next(ispendingJobs);
            }
        })
        return this.isJobsPending$;
    }
}
