import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { DateTime } from "luxon";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GenericActions } from '../../../shared/enums/operation-actions.enum';
import { Brands } from '../../../shared/enums/brands.enum';
import { IBrand } from '../../../shared/models/brand.model';
import { ILenderInfo } from '../../../shared/models/dealer-configuration.model';
import { IDealerDefinedCampaign, IRateRule, IResidual } from '../../../shared/models/dealer-defined-campaigns.model';
import { IVehicleBrand } from '../../../shared/models/vehicle-config-master.model';
import { LuxonDateAdapter } from '../../../shared/services/luxon-date-adapter';
import { SubscriptionList, unsubscribeSubscriptions } from "../../../shared/services/util.service";
import { DealerConfigurationState } from '../../../store/dealer-configuration/dealer-configuration.reducer';
import { selectLenderList } from '../../../store/dealer-configuration/dealer-configuration.selectors';
import * as dealerDefinedCampaignsActions from "../../../store/dealer-defined-campaigns/dealer-defined-campaigns.actions";
import { getCampaignUpdateComplete$ } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.adapter';
import { DealerDefinedCampaignsState } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.reducer';
import { selectBrands } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.selectors';
import { VehicleConfigState } from '../../../store/vehicle-config/vehicle-config.reducer';
import { selectVehicleConfig, selectVehicleConfigLoadCounter, selectYearMasterList } from '../../../store/vehicle-config/vehicle-config.selectors';
import { DealerDefinedCampaignsComponentService } from '../dealer-defined-campaigns.component.service';
import { GenericSelections } from '../../../shared/enums/generic-selections.enum';
import { FilterModelCodesPipe } from '../pipes/filter-model-codes.pipe';
import { OfferTypes } from '../../../shared/enums/offer-types.enum';
import { TranslateService } from '@ngx-translate/core';
import { GetBrandSeriesPipe } from '../pipes/get-brand-series.pipe';

@Component({
  selector: 'app-pe-modify-campaign',
  templateUrl: './modify-campaign.component.html',
  styleUrls: ['./modify-campaign.component.scss'],
  providers: [{ provide: DateAdapter, useClass: LuxonDateAdapter }]
})
export class ModifyCampaignComponent implements OnInit, OnDestroy {

  private subs: SubscriptionList = {};
  activeLenderList: ILenderInfo[];
  brandList$: Observable<IBrand[]>;
  vehicleConfig$: Observable<IVehicleBrand[]>;
  yearMasterList$: Observable<string[]>;
  currentDateTime = DateTime.local();
  filteredLenderList$: Observable<ILenderInfo[]>;
  campaignForm: FormGroup;
  ignoreFirstLenderFilterChange: boolean;
  rtiBrands: string[] = [Brands.TOYOTA, Brands.LEXUS];
  allOptionValue = GenericSelections.ALL;
  makeChange: boolean = false;

  @ViewChild('matDialogCloseBtn') matDialogCloseBtn: ElementRef<HTMLElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { action: GenericActions, campaign: IDealerDefinedCampaign },
    private readonly dealerDefinedCampaignsState: Store<DealerDefinedCampaignsState>,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly vehicleConfigState: Store<VehicleConfigState>,
    readonly dDCCService: DealerDefinedCampaignsComponentService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly filterModelCodesPipe: FilterModelCodesPipe,
    private readonly formBuilder: FormBuilder,
    private translateService: TranslateService,
    private readonly getBrandSeriesPipe: GetBrandSeriesPipe
  ) {

  }

  ngOnInit() {
    this.subs.selectLenderList = this.dealerConfigurationState.select(selectLenderList).subscribe(lenderList => {
      this.activeLenderList = lenderList;
    });
    this.brandList$ = this.dealerDefinedCampaignsState.select(selectBrands);
    this.vehicleConfig$ = this.vehicleConfigState.select(selectVehicleConfig);
    this.yearMasterList$ = this.vehicleConfigState.select(selectYearMasterList);
    this.buildCampaignForm();
    this.triggerConflictWithRulesAndResiduals();
    this.ignoreFirstLenderFilterChange = !!this.data.campaign.id;
    this.setLenderFilter();
    this.onMakeChange(this.data.campaign.make, false);
    this.setupCloseModalSubscription();
    this.changeDetector.detectChanges();
  }

  /* istanbul ignore next */
  saveCampaign() {
    if (this.campaignForm.valid) {
      const make = this.campaignForm.get(['make']).value;
      const makeName = this.dDCCService.getBrandById(make).name;
      const modelNames: string[] = [];
      const series = this.getBrandSeriesPipe.transform(make, this.dDCCService.vehicleConfig);
      this.campaignForm.get(['model']).value.forEach(seriesId => {
        modelNames.push(seriesId === this.allOptionValue
          ? _.startCase(this.allOptionValue)
          : this.dDCCService.getModelByCode(series, seriesId).name);
      });
      const yearNames: string[] = [];
      this.campaignForm.get(['year']).value.forEach(year => {
        yearNames.push(year === this.allOptionValue ? _.startCase(this.allOptionValue) : year)
      });
      const startDate = (this.campaignForm.get(['startDate']).value as DateTime).toISODate();
      const endDate = (this.campaignForm.get(['endDate']).value as DateTime).toISODate();
      const campaign: IDealerDefinedCampaign = {
        ...this.campaignForm.getRawValue(),
        startDate,
        endDate,
        makeName,
        modelNames,
        yearNames
      };
      this.dealerDefinedCampaignsState.dispatch(new dealerDefinedCampaignsActions.UpdateDealerDefinedCampaign({ campaign }));
    }
  }

  setupCloseModalSubscription() {
    this.subs.getCampaignUpdateComplete = getCampaignUpdateComplete$().subscribe(() => {
      this.matDialogCloseBtn.nativeElement.click();
    });
  }

  triggerConflictWithRulesAndResiduals() {
    this.subs.selectVehicleConfigLoadCounterSub =
      this.vehicleConfigState.select(selectVehicleConfigLoadCounter).subscribe(() => {
        this.campaignForm.updateValueAndValidity({ emitEvent: false });
      });
  }

  cloneMakeStatus(): boolean {
    if (_.includes(this.data.campaign.model, this.allOptionValue) && _.includes(this.rtiBrands, this.data.campaign.make)) {
      this.makeChange = false;
      return false;
    } else {
      this.makeChange = true;
      return true;
    }
  }

  isDisabledMake(): boolean {
    if (this.data.action === GenericActions.CLONE) {
      return this.cloneMakeStatus();
    } else {
      this.makeChange = true;
      return false;
    }
  }

  buildCampaignForm() {
    const campaign = this.data.campaign;
    this.campaignForm = this.formBuilder.group({
      id: [campaign.id],
      dealerCode: [campaign.dealerCode],
      title: [campaign.title, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      make: [{ value: campaign.make, disabled: this.isDisabledMake() }, [Validators.required]],
      year: [{ value: campaign.year, disabled: this.data.action === GenericActions.CLONE }, [Validators.required]],
      model: [{ value: campaign.model, disabled: this.data.action === GenericActions.CLONE }, [Validators.required]],
      startDate: [this.dDCCService.getLuxonUTCDate(campaign.startDate), [Validators.required]],
      endDate: [this.dDCCService.getLuxonUTCDate(campaign.endDate), [Validators.required]],
      incentiveAmount: [campaign.incentiveAmount, [Validators.min(0)]],
      lenderId: [campaign.lenderId, [Validators.required, this.isValidLender()]],
      lenderName: [{ value: campaign.lenderName, disabled: this.data.action === GenericActions.CLONE }, [Validators.required]],
      maskedLenderName: [campaign.maskedLenderName, [Validators.required]],
      dndText: [campaign.dndText, [Validators.required, Validators.maxLength(2000)]],
      otherRestrictions: [campaign.otherRestrictions, [Validators.maxLength(2000)]],
      status: [campaign.status],
      rateRules: [campaign.rateRules],
      residuals: [campaign.residuals]
    }, {
      validators: [
        this.startAndEndDateValidator(),
        this.conflictWithRulesAndResiduals()]
    });
  }

  /* istanbul ignore next */
  startAndEndDateValidator(): ValidatorFn {
    return (_group: FormGroup) => {
      const startDate: DateTime = _group.get(['startDate']).value;
      const endDate: DateTime = _group.get(['endDate']).value;
      return this.dDCCService.checkInvalidStartAndEndDate(startDate, endDate)
        ? { InvalidDates: true } : null;
    }
  }

  /* istanbul ignore next */
  conflictWithRulesAndResiduals(): ValidatorFn {
    return (_group: FormGroup) => {
      let hasConflict = false;
      const rateRules = this.data.campaign.rateRules;
      const residuals = this.data.campaign.residuals;
      if (rateRules.length > 0) {
        const make: string = _group.get(['make']).value;
        const model: string[] = _group.get(['model']).value;
        const year: string[] = _group.get(['year']).value;
        const lenderId: string = _group.get(['lenderId']).value;
        const lenderOffers = this.dDCCService.getLenderOffers(lenderId);
        const salesClasses = this.dDCCService.getSalesClassForCampaign(make, year);
        const modelCodes =
          this.filterModelCodesPipe.transform(make, model, year, this.dDCCService.vehicleConfig)
            .map(t => t.code);
        if (this.checkRateRulesConflict(rateRules, lenderOffers, salesClasses, modelCodes) ||
          !_.every(rateRules, rule => this.hasAllResidualsForRule(rule, residuals, year))) {
          hasConflict = true;
        }
      }
      return hasConflict ? { ConflictingRules: true } : null;
    }
  }

  hasAllResidualsForRule(rateRule: IRateRule, residuals: IResidual[], campaignYears: string[]) {
    return rateRule.offerType === OfferTypes.FINANCE ||
      rateRule.modelCode.every(modelCode =>
        campaignYears.every(modelYear =>
          rateRule.salesClass.every(salesClass =>
            _.some(residuals, { modelCode, modelYear, salesClass })
          )));
  }

  checkRateRulesConflict(rateRules: IRateRule[], lenderOffers: string[], salesClasses: string[], modelCodes: string[]) {
    return _.some(rateRules, (t) =>
      _.indexOf(lenderOffers, t.offerType) < 0 ||
      _.difference(t.salesClass, salesClasses).length !== 0 ||
      (_.indexOf(t.modelCode, this.allOptionValue) < 0 &&
        _.difference(t.modelCode, modelCodes).length !== 0));
  }

  isValidLender(): ValidatorFn {
    return (_control: FormControl) => {
      const id: string = _control.value;
      return _.some(this.activeLenderList, { id }) ? null : { invalidLender: true };
    }
  }

  private _lenderFilter(name: string): ILenderInfo[] {
    const filterValue = name.toLowerCase();
    return this.activeLenderList.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  /* istanbul ignore next */
  setLenderFilter() {
    this.filteredLenderList$ = this.campaignForm.get(['lenderName']).valueChanges.pipe(
      startWith(''),
      map((lender: ILenderInfo | string) => {
        if (this.ignoreFirstLenderFilterChange) {
          this.ignoreFirstLenderFilterChange = false;
          return this.activeLenderList.slice();
        }
        const name = typeof lender === 'string' ? lender : lender?.name;
        const lenderId = this.campaignForm.get(['lenderId']);
        const maskedLenderName = this.campaignForm.get(['maskedLenderName']);
        const lenderData = _.find(this.activeLenderList, { name });
        lenderId.setValue(lenderData?.id || '');
        maskedLenderName.setValue(lenderData?.maskedLenderName || '');
        return name ? this._lenderFilter(name) : this.activeLenderList.slice();
      })
    );
  }

  onMakeChange(brand: string, reset: boolean = true) {
    if (reset) {
      this.campaignForm.get(['model']).setValue([]);
    }
    this.dDCCService.checkAndfetchVehicleConfig(brand, this.vehicleConfigState);
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subs);
    this.dDCCService.destroySubs();
  }

}
