import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DealValidationComponent } from './deal-validation.component';

const routes: Routes = [
    {
        path: '',
        component: DealValidationComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DealValidationRoutingModule { }