import { createSelector, createFeatureSelector } from '@ngrx/store';
import { FeatureKeys } from '../app.reducer';
import { IncentivesState } from './incentives.reducer';
import * as _ from 'lodash';
import { IncentiveStatus } from '../../shared/enums/incentive-status.enum';
import { DateTime } from 'luxon';

const currentDateTime = DateTime.local();
const currentDate = DateTime.utc(currentDateTime.year,currentDateTime.month,currentDateTime.day);
    
export const selectDealerDefinedIncentivesFeature = createFeatureSelector<IncentivesState>(FeatureKeys.INCENTIVES);

export const selectIncentives = createSelector(
    selectDealerDefinedIncentivesFeature,
    (state: IncentivesState) => state.incentives
);

export const selectIncentivesComponentLoading = createSelector(
    selectDealerDefinedIncentivesFeature,
    (state: IncentivesState) => state.loading
);

export const selectIncentiveLoading = createSelector(
    selectDealerDefinedIncentivesFeature,
    (state: IncentivesState) => state.loadingIncentive
);

export const selectBrands = createSelector(
    selectDealerDefinedIncentivesFeature,
    (state: IncentivesState) => state.brandList
);

export const selectIncentiveOfferTypes = createSelector(
    selectDealerDefinedIncentivesFeature,
    (state: IncentivesState) => state.offerTypes
);

export const selectIncentiveSalesClasses = createSelector(
    selectDealerDefinedIncentivesFeature,
    (state: IncentivesState) => state.salesClasses
);

export const selectIncentiveSource = createSelector(
    selectDealerDefinedIncentivesFeature,
    (state: IncentivesState) => {
        const reversedList = state.incentives.map(t => t.incentiveSource).reverse();
        return _.uniq(reversedList);
});

export const selectActiveIncentive = createSelector(
    selectDealerDefinedIncentivesFeature,
    (state: IncentivesState) => {
        return state.incentives.filter(t => {
            if (t.status !== IncentiveStatus.DRAFT) {
                const endDate = t.endDate ? DateTime.fromISO(t.endDate, { zone: 'UTC' }) : null;
                return !(endDate.diff(currentDate, ['days']).days < -1);
            }
            return t;
        });
});

export const selectExpiredIncentive = createSelector(
    selectDealerDefinedIncentivesFeature,
    (state: IncentivesState) => {
        return state.incentives.filter(t => {
            if (t.status !== IncentiveStatus.DRAFT) {
                const endDate = t.endDate ? DateTime.fromISO(t.endDate, { zone: 'UTC' }) : null;;
                return endDate.diff(currentDate, ['days']).days < -1 ;
            }
        });
});