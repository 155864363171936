import { Pipe, PipeTransform } from "@angular/core";
import { IResidual } from "../../../shared/models/dealer-defined-campaigns.model";
import * as _ from 'lodash';

@Pipe({
    name: "getresidualterms"
})
export class GetResidualTermsPipe implements PipeTransform {

    transform(residuals: IResidual[]): number[] {
        let terms = _.reduce(residuals,
            (p: number[], t) =>
                p.concat(t.terms?.map(s => s.term) || []), []).sort((a, b) => a - b);
        return _.uniq(terms);
    }

}