export enum GrossProfitTypes {
    AMOUNT = 'amount',
    PERCENT = 'percent'
}

export enum TfsGrossProfitTypes {
    AMOUNT = 'AMOUNT',
    PERCENTAGE = 'PERCENTAGE'
}

export enum paymentgridProfitTypes {
    AMOUNT = 'amount',
    PERCENT = 'percentage'
}

export enum markupGPTypes {
    AMOUNT = 'amount',
    PERCENT = 'percentage'
}