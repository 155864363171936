<div class="close-dialog-icon-container d-flex">
    <mat-icon class="close-dialog-icon" mat-dialog-close>close</mat-icon>
</div>
<div class="summary-detail">
    <div class="modify-campaign-container">
        <div class="header">
            <div mat-dialog-title>
                {{'LOCALIZATION.DEPLOYMENT_JOB.SUMMARY_TITLE' | translate}}
            </div>
        </div>
    </div>
    <div class="summary-detail-container container-fluid p-3">
        <table *ngIf="isPPSummary" class="table w-100" aria-describedby="rate-table">
            <thead>
                <tr>
                    <th scope="col" class="minw-100px">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.SUMMARY.NAME' | translate}}
                    </th>
                    <th scope="col">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.SUMMARY.COMPLETED' | translate}}
                    </th>
                    <th scope="col">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.SUMMARY.DELETED' | translate}}
                    </th>
                    <th scope="col">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.SUMMARY.UNAVAILABLE' | translate}}
                    </th>
                    <th scope="col">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.SUMMARY.PARTIAL' | translate}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data.summary;  let i = index">
                    <td>{{item.name}}</td>
                    <td>{{item.complete}}</td>
                    <td>{{item.deleted}}</td>
                    <td>{{item.unavailable}}</td>
                    <td>{{item.partial}}</td>
                </tr>
            </tbody>
        </table>

        <table *ngIf="isOfferSummary" class="table w-100" aria-describedby="rate-table">
            <thead>
                <tr>
                    <th scope="col" class="minw-100px">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.SUMMARY.NAME' | translate}}
                    </th>
                    <th scope="col">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.SUMMARY.SUCCESS' | translate}}
                    </th>
                    <th scope="col">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.SUMMARY.FAILED' | translate}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data.summary;  let i = index">
                    <td>{{item.name}}</td>
                    <td>{{item.success}}</td>
                    <td>{{item.failed}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>