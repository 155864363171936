import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { UserProfileService } from "@toyota/dd365-platform-library";
import _ from "lodash";
import { Observable } from "rxjs";
import { DealerConfigurationState } from "../../store/dealer-configuration/dealer-configuration.reducer";
import { selectDealerConfiguration, selectDisableActions, selectIsLoading, selectIsLoadingBankConfig, selectGrossProfitConfig } from "../../store/dealer-configuration/dealer-configuration.selectors";
import * as dealerConfigurationActions from '../../store/dealer-configuration/dealer-configuration.actions';
import { SubscriptionList, unsubscribeSubscriptions, } from "../../shared/services/util.service";
import { IGrossProfit } from "../../shared/models/gross-profit.model";
import { IGPConfig } from "../../shared/models/bank-configuration.model";
import { take } from "rxjs/operators";
import { IDealerConfiguration } from "../../shared/models/dealer-configuration.model";
import { DealerInfoService } from '../../shared/services/dealer-info.service';
import { GrossProfitComponentService } from './gross-profit.component.service';

@Component({
  selector: 'app-pe-gross-profit',
  templateUrl: './gross-profit.component.html',
  styleUrls: ['./gross-profit.component.scss']
})
export class GrossProfitComponent implements OnInit, OnDestroy {
  grossProfit: IGrossProfit;
  grossProfitConfig: IGPConfig;
  private subs: SubscriptionList = {};
  private dealerCode: string;
  loadingGP$: Observable<boolean>;
  disableActions$: Observable<boolean>;
  selectBankConfigLoading$: Observable<boolean>;
  gPformatted: IGrossProfit;
  constructor(readonly translateService: TranslateService,
    private readonly userProfileService: UserProfileService,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly dealerInfoService: DealerInfoService,
    private readonly gpCService: GrossProfitComponentService,
  ) { }


  ngOnInit(): void {
    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.LoadDealerConfiguration({ dealerCode: this.dealerCode }));
    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.LoadBankConfiguration({ distributor: this.dealerInfoService.getDealerBankDistributor() }));
    this.subs.selectDealerConfig =
      this.dealerConfigurationState.select(selectDealerConfiguration).subscribe(dealerConfig => {
        const dealerConfiguration: IDealerConfiguration = _.cloneDeep(dealerConfig);
        this.grossProfit = dealerConfiguration.grossProfitConfiguration;
        const selectGrossProfitConfigSub = this.dealerConfigurationState.select(selectGrossProfitConfig)
          .subscribe((grossProfit) => {
            if (grossProfit) {
              this.grossProfitConfig = grossProfit;
              this.gPformatted = _.cloneDeep(this.grossProfit);
              this.formatGp();
              selectGrossProfitConfigSub.unsubscribe();
            }
          });
      });
    this.loadingGP$ = this.dealerConfigurationState.select(selectIsLoading);
    this.disableActions$ = this.dealerConfigurationState.select(selectDisableActions);
    this.selectBankConfigLoading$ = this.dealerConfigurationState.select(selectIsLoadingBankConfig);
  }


  saveConfig(): void {
    const grossProfit: IGrossProfit = _.cloneDeep(this.gPformatted);
    if (grossProfit?.backEnd) {
      grossProfit.backEnd = _.map(grossProfit.backEnd, (obj) => _.omit(obj, 'description'));
    }
    if (grossProfit?.frontEnd) {
      grossProfit.frontEnd = _.map(grossProfit.frontEnd, (obj) => _.omit(obj, 'description'));
    }
    this.dealerConfigurationState.select(selectDealerConfiguration).pipe(take(1)).subscribe(dealerConfig => {
      const dealerConfiguration: IDealerConfiguration = _.cloneDeep(dealerConfig);
      dealerConfiguration.grossProfitConfiguration = grossProfit;
      this.dealerConfigurationState.dispatch(new dealerConfigurationActions.UpdateDealerConfiguration({
        dealerCode: this.dealerCode, dealerConfiguration
      }));
    });
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

  resetConfig(): void {
    this.dealerConfigurationState.select(selectDealerConfiguration).pipe(take(1)).subscribe(dealerConfig => {
      const dealerConfiguration: IDealerConfiguration = _.cloneDeep(dealerConfig);
      this.gPformatted = dealerConfiguration.grossProfitConfiguration;
      this.formatGp();
    });
  }

  formatGp() {
    this.gPformatted.frontEnd = this.gpCService.buildGP(this.grossProfitConfig.frontEnd, this.gPformatted.frontEnd);
    this.gPformatted.backEnd = this.gpCService.buildGP(this.grossProfitConfig.backEnd, this.gPformatted.backEnd);
  }

}
