import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import * as fromActions from './vehicle-config.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IRTIVehicleSeriesConfig } from '../../shared/models/rti/rti-vehicle-config.model';
import { RTIVehicleConfigService } from '../../shared/services/rti-vehicle-config.service';
import { NHTSAVehicleService } from '../../shared/services/nhtsa-vehicle.service';
import { INHTSAModel } from '../../shared/models/nhtsa/nhtsa.model';
import { VehicleConfigState } from './vehicle-config.reducer';
import { selectBrandsLoading } from './vehicle-config.selectors';

@Injectable()
export class VehicleConfigEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly rtiVehicleConfigService: RTIVehicleConfigService,
        private readonly nhtsaVehicleService: NHTSAVehicleService,
        private readonly snackBar: MatSnackBar,
        private readonly vehicleConfigState: Store<VehicleConfigState>
    ) { }

    loadRTIVehicleConfig: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.LoadRTIVehicleConfig>(
            fromActions.VehicleConfigActionTypes.LOAD_RTI_VEHICLE_CONFIG
        ),
        withLatestFrom(this.vehicleConfigState.select(selectBrandsLoading)),
        mergeMap(([action, brandsLoading]) => {
            if (brandsLoading.indexOf(action.payload.brand) < 0) {
                this.vehicleConfigState.dispatch(new fromActions.UpdateBrandsLoading({ brand: action.payload.brand }));
                return this.rtiVehicleConfigService.getVehicleConfig(action.payload.brand).pipe(
                    map((rtiVehicleConfig: IRTIVehicleSeriesConfig[]) => {
                        return new fromActions.LoadRTIVehicleConfigSuccess({ brand: action.payload.brand, rtiVehicleConfig });
                    }),
                    catchError(err => {
                        this.snackBar.open(err.error.message, 'Dismiss', { duration: 5000 });
                        return of(new fromActions.LoadRTIVehicleConfigFailure({ brand: action.payload.brand, error: err }));
                    })
                );
            }
            return new Observable<any>();
        })
    ));

    loadNHTSAVehicleConfig: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.LoadNHTSAVehicleConfig>(
            fromActions.VehicleConfigActionTypes.LOAD_NHTSA_VEHICLE_CONFIG
        ),
        withLatestFrom(this.vehicleConfigState.select(selectBrandsLoading)),
        mergeMap(([action, brandsLoading]) => {
            if (brandsLoading.indexOf(action.payload.brand) < 0) {
                this.vehicleConfigState.dispatch(new fromActions.UpdateBrandsLoading({ brand: action.payload.brand }));
                return this.nhtsaVehicleService.getModelsForMake(action.payload.brand).pipe(
                    map((nhtsaVehicleConfig: INHTSAModel[]) => {
                        return new fromActions.LoadNHTSAVehicleConfigSuccess({ brand: action.payload.brand, nhtsaVehicleConfig });
                    }),
                    catchError(err => {
                        this.snackBar.open(err.error.message, 'Dismiss', { duration: 5000 });
                        return of(new fromActions.LoadNHTSAVehicleConfigFailure({ brand: action.payload.brand, error: err }));
                    })
                );
            }
            return new Observable<any>();
        })
    ));
}
