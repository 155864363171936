import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  AfterContentChecked,
  Output, EventEmitter, SimpleChanges
} from "@angular/core";
import { FormArray, FormGroup, FormGroupDirective } from "@angular/forms";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { DealerConfigurationState } from "../../../store/dealer-configuration/dealer-configuration.reducer";
import {
  selectDealerConfiguration,
  selectDisableActions,
  selectOfferTypesdealerConfig,
  selectSalesClassesdealerConfig,
  selectpaymentGridRange,
  selectIsLoadingBankConfig,
} from "../../../store/dealer-configuration/dealer-configuration.selectors";
import { BehaviorSubject, Observable } from "rxjs";
import { OfferTypes } from "../../../shared/enums/offer-types.enum";
import { PaymentGridTypes } from '../../../shared/enums/pg-tab-type.enum';
import * as _ from "lodash";
import { IShowroomOnlineTermConfig } from "../../../shared/models/dealer-configuration.model";
import { IDownPaymentRangeInfo } from "../../../shared/models/bank-configuration.model";
import { UserProfileService } from "@toyota/dd365-platform-library";
import {
  SubscriptionList,
  unsubscribeSubscriptions,
} from "../../../shared/services/util.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SalesClasses } from "../../../shared/enums/sales-classes.enum";
import { TranslateService } from "@ngx-translate/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { paymentgridProfitTypes } from "./../../../shared/enums/gp-types.enum";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
@Component({
  selector: "app-pe-showroom-online-grid-config",
  templateUrl: "./showroom-online-grid-config.component.html",
  styleUrls: ["./showroom-online-grid-config.component.scss"]
})

export class ShowroomOnlineGridConfigComponent implements OnInit, OnDestroy, OnChanges, AfterContentChecked {
  @Output() saveEvent = new EventEmitter<string>();
  @Output() resetEvent = new EventEmitter<string>();
  @Input() getformGroupName: BehaviorSubject<string>;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  dealerCode: string;
  @Input() selectedTab: string;
  @Input() pgformValidEvent: string;
  showroomOnlineFormGroup: FormGroup;
  offerTerms: {
    new: { lease: number[]; finance: number[]; order: string[] };
    certified: { lease: number[]; finance: number[]; order: string[] };
    used: { lease: number[]; finance: number[]; order: string[] };
  };
  loading$: Observable<boolean>;
  loadingBankConfig$: Observable<boolean>;
  offerTypes$: Observable<string[]>;
  salesClasses$: Observable<string[]>;
  configurableSalesClasses: string[] = [
    SalesClasses.NEW,
    SalesClasses.CERTIFIED,
    SalesClasses.USED,
  ];
  configurableOffers = {
    [SalesClasses.NEW]: [OfferTypes.FINANCE, OfferTypes.LEASE],
    [SalesClasses.CERTIFIED]: [OfferTypes.FINANCE, OfferTypes.LEASE],
    [SalesClasses.USED]: [OfferTypes.FINANCE]
  }
  previousState: IShowroomOnlineTermConfig;
  disableActions$: Observable<boolean>;
  private subs: SubscriptionList = {};
  grossProfitTypes = paymentgridProfitTypes;
  maxRange: number;
  paymentGridTypes = PaymentGridTypes;
  offerTypes = OfferTypes;
  tempArray: string[];
  currentForm: string;
  disableFlag: boolean = false;
  constructor(
    private readonly userProfileService: UserProfileService,
    private readonly snackBar: MatSnackBar,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    readonly translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private rootFormGroup: FormGroupDirective
  ) { }

  ngOnInit() {
    this.salesClasses$ = this.dealerConfigurationState.select(
      selectSalesClassesdealerConfig
    );
    this.offerTypes$ = this.dealerConfigurationState.select(
      selectOfferTypesdealerConfig
    );
    this.offerTerms = {
      new: { lease: [], finance: [], order: [] },
      certified: { lease: [], finance: [], order: [] },
      used: { lease: [], finance: [], order: [] },
    };
    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    this.disableActions$ = this.dealerConfigurationState.select(
      selectDisableActions
    );
    this.loadingBankConfig$ = this.dealerConfigurationState.select(
      selectIsLoadingBankConfig
    );
    this.setshowroomOnlineForm();
    this.disableActions$.subscribe((disable: boolean) => {
      this.disableFlag = disable;
    })
  }
  setshowroomOnlineForm() {
    this.changeDetectorRef.detectChanges();
    this.getformGroupName.subscribe((data) => {
      if (data == 'showroom') {
        this.currentForm = data;
        this.showroomOnlineFormGroup = this.rootFormGroup.control.get(this.currentForm) as FormGroup;
        this.changeDetectorRef.detectChanges();
      }
      if (data == 'online') {
        this.currentForm = data;
        this.showroomOnlineFormGroup = this.rootFormGroup.control.get(this.currentForm) as FormGroup;
        this.changeDetectorRef.detectChanges();
      }
    });

  }


  ngOnDestroy() {
    unsubscribeSubscriptions(this.subs);
    this.getformGroupName.unsubscribe();
  }


  savePaymentGrid() {
    this.dealerConfigurationState
      .select(selectDealerConfiguration)
      .pipe(take(1))
      .subscribe((dealerConfig) => {
        const dealerConfiguration = _.cloneDeep(dealerConfig);
        dealerConfiguration.paymentGridCustomization[
          this.selectedTab
        ] = this.showroomOnlineFormGroup.value;
      });

    if (this.showroomOnlineFormGroup.valid) {
      const showroomOnlineValue = _.cloneDeep(this.showroomOnlineFormGroup.value);
      this.saveEvent.emit(this.showroomOnlineFormGroup.status);
    } else {
      this.snackBar.open(
        this.translateService.instant(
          "LOCALIZATION.PAYMENT_GRID.INVALID_CONFIG"
        ),
        this.translateService.instant("LOCALIZATION.COMMON.DISMISS"),
        { duration: 4000 }
      );
    }
  }


  resetDefaultTerms() {
    this.resetEvent.emit(this.showroomOnlineFormGroup.status);
    this.changeDetectorRef.detectChanges();
    this.setshowroomOnlineForm();
  }


  processingOrderOptionsDrop(
    event: CdkDragDrop<string[]>,
    salesClass: string
  ): void {
    if (
      this.selectedTab === PaymentGridTypes.SHOWROOM &&
      (event.previousIndex === 2 || event.currentIndex === 2)
    ) {
    } else {
      const array = this.showroomOnlineFormGroup
        .get([salesClass])
        .get("order")
        .getRawValue()
        .toString();
      const myArray = array.split(",");
      moveItemInArray(myArray, event.previousIndex, event.currentIndex);
      this.showroomOnlineFormGroup
        .get([salesClass])
        .get("order")
        .setValue(myArray);
    }
  }

  addDownpaymentValidation(
    event: MatChipInputEvent,
    salesClass: string,
    offer: string,
    maxvalue: number,
    addIndex: number
  ): void {
    const downPaymentformControl = <FormArray>this.showroomOnlineFormGroup
      .get([salesClass])
      .get([offer])
      .get("downPayment");
    downPaymentformControl.controls.forEach((element: FormGroup, valindex) => {
      if (valindex === addIndex) {
        if (
          this.selectedTab === PaymentGridTypes.SHOWROOM &&
          element.controls.downPaymentOptions.value.length >= 3
        ) {
          //event.chipInput!.clear();
          if (event.value) {
            event.value = '';
          }
          this.snackBar.open(
            this.translateService.instant(
              "LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.CHIP_MAX_LIMIT"
            ),
            this.translateService.instant("LOCALIZATION.COMMON.DISMISS"),
            { duration: 4000 }
          );
        } else if (
          this.selectedTab === PaymentGridTypes.ONLINE &&
          element.controls.downPaymentOptions.value.length >= 1
        ) {
          if (event.value) {
            event.value = '';
          }
          this.snackBar.open(
            this.translateService.instant(
              "LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.CHIP_MAX_LIMIT"
            ),
            this.translateService.instant("LOCALIZATION.COMMON.DISMISS"),
            { duration: 4000 }
          );
        } else {
          this.addDownpayment(event, salesClass, offer, maxvalue, addIndex);
        }
      }
    });
  }

  addDownpayment(
    event: MatChipInputEvent,
    salesClass: string,
    offer: string,
    maxvalue: number,
    addIndex: number
  ): void {
    const downPaymentformControl = <FormArray>this.showroomOnlineFormGroup
      .get([salesClass])
      .get([offer])
      .get("downPayment");
    downPaymentformControl.controls.forEach((element: FormGroup, valindex) => {
      /* istanbul ignore if */
      if (valindex === addIndex) {
        if (
          event.value != null &&
          event.value != "NaN" &&
          event.value != "" &&
          !element.controls.downPaymentOptions.value.includes(
            parseInt(event.value)
          )
        ) {
          if (parseInt(event.value) <= maxvalue) {
            element.controls.downPaymentOptions.setValue([
              ...element.controls.downPaymentOptions.value,
              parseInt(event.value),
            ]);
            element.controls.downPaymentOptions.setValue(
              _.orderBy(element.controls.downPaymentOptions.value, [], ["asc"])
            );
            this.triggerDownPaymentChange(Number(event.value), salesClass, offer, valindex);
            this.changeDetectorRef.detectChanges();
            if (event.value) {
              event.value = '';
            }
          } else {
            this.snackBar.open(
              this.translateService.instant(
                "LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.DOWNPAYMENT_RANGE_VALIDATION_ERROR",
                { max: maxvalue }
              ),
              this.translateService.instant("LOCALIZATION.COMMON.DISMISS"),
              { duration: 4000 }
            );
          }
        } else {
          this.snackBar.open(
            this.translateService.instant(
              "LOCALIZATION.PAYMENT_GRID_SHOWROOM_ONLINE.DOWNPAYMENT_DATA_VALIDATION_ERROR"
            ),
            this.translateService.instant("LOCALIZATION.COMMON.DISMISS"),
            { duration: 4000 }
          );
        }
      }
    });
    if (event.value) {
      event.value = '';
    }
  }

  triggerDownPaymentChange(value, salesClass, offer, valindex) {
    if (this.selectedTab === 'online') {
      this.downPaymentChanged(value, salesClass, offer, valindex, this.disableFlag);
    }
  }

  downPaymentChanged(
    value: number,
    salesClass: string,
    offer: string,
    paymentChangedIndex: number,
    disableFlag?: boolean
  ) {
    if (disableFlag) {
      return;
    }
    const downPaymentformControl = <FormArray>this.showroomOnlineFormGroup
      .get([salesClass])
      .get([offer])
      .get("downPayment");
    downPaymentformControl.controls.forEach((element: FormGroup, valindex) => {
      if (valindex === paymentChangedIndex) {
        element.controls.defaultDownPayment.setValue(value);
      }
    });

  }

  remove(
    value: number,
    salesClass: string,
    offer: string,
    removeIndex: number
  ): void {
    const downPaymentformControl = <FormArray>this.showroomOnlineFormGroup
      .get([salesClass])
      .get([offer])
      .get("downPayment");
    downPaymentformControl.controls.forEach((element: FormGroup, valindex) => {
      /* istanbul ignore if */
      if (valindex === removeIndex) {
        const selectedFormValue = element.controls.downPaymentOptions.value;
        const index = selectedFormValue.indexOf(value);

        if (index >= 0) {
          let removableValue = [...selectedFormValue];
          removableValue.splice(index, 1);
          element.controls.downPaymentOptions.setValue(removableValue);
          if (value === element.controls.defaultDownPayment.value) {
            element.controls.defaultDownPayment.setValue(null);
          }
        }
      }
    });
  }

  getmaxRange(salesClass: string, offer: string) {
    const salesClassDefaultFormGroup = this.showroomOnlineFormGroup.get([
      salesClass,
    ]) as FormGroup;
    const OffertypeDefaultFormGroup = salesClassDefaultFormGroup.get([
      offer,
    ]) as FormGroup;
    this.subs.paymentGridSystemSub = this.dealerConfigurationState
      .select(selectpaymentGridRange)
      .subscribe((config: IDownPaymentRangeInfo[]) => {
        config.forEach((rangeInfo) => {
          if (
            rangeInfo?.downPaymentMeasure ==
            OffertypeDefaultFormGroup.value.downPaymentMeasure
          ) {
            this.maxRange = rangeInfo?.max;
          }
        });
      });
    return this.maxRange;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

}