import { Pipe, PipeTransform } from "@angular/core";
import { CampaignStatus } from "../../../shared/enums/campaign-status.enum";
import { DealerDefinedCampaignsComponentService } from "../dealer-defined-campaigns.component.service";

@Pipe({
    name: "getcampaignstatus"
})
export class GetCampaignStatusPipe implements PipeTransform {

    constructor(
        private readonly dDCCService: DealerDefinedCampaignsComponentService
    ) { }

    transform(endDateString: string, status: string): string {
        if (status === CampaignStatus.DRAFT) {
            return status;
        }
        const endDate = this.dDCCService.getLuxonUTCDate(endDateString);
        const expired = endDate.diff(this.dDCCService.currentDate, ['days']).days < -1;
        return expired ? CampaignStatus.EXPIRED : status;
    }

}