import { Action } from "@ngrx/store";
import { IGetBankConfiguration } from "../../shared/models/bank-configuration.model";
import { IGetSystemConfiguration } from "../../shared/models/system-configuration.model";
import { IDealerConfiguration } from "../../shared/models/dealer-configuration.model";
import { IDealerFeatureConfig } from "../../shared/models/rti/rti-feature-config.model";

export enum DealerConfigurationActionTypes {
  LOAD_DEALER_CONFIGURATION = "[DealerConfiguration] Load Dealer Configuration",
  LOAD_DEALER_CONFIGURATION_SUCCESS = "[DealerConfiguration] Load Dealer Configuration success",
  LOAD_DEALER_CONFIGURATION_FAILURE = "[DealerConfiguration] Load Dealer Configuration failure",
  UPDATE_DEALER_CONFIGURATION = "[DealerConfiguration] Update Dealer Configuration",
  UPDATE_DEALER_CONFIGURATION_SUCCESS = "[DealerConfiguration] Update Dealer Configuration success",
  UPDATE_DEALER_CONFIGURATION_FAILURE = "[DealerConfiguration] Update Dealer Configuration failure",
  UPDATE_DD_RATES = "[DealerConfiguration] Update Dealer Configuration - DD Rates",
  UPDATE_DD_RATES_SUCCESS = "[DealerConfiguration] Update Dealer Configuration - DD Rates success",
  UPDATE_DD_RATES_FAILURE = "[DealerConfiguration] Update Dealer Configuration - DD Rates failure",
  LOAD_RESIDUE_CONFIGURATION = "[ResidueConfiguration] Load Residue Configuration",
  LOAD_RESIDUE_CONFIGURATION_SUCCESS = "[ResidueConfiguration] Load Residue Configuration success",
  LOAD_RESIDUE_CONFIGURATION_FAILURE = "[ResidueConfiguration] Load Residue Configuration failure",
  LOAD_DEALER_DEFINED_RATES_CONFIGURATION = "[DealerConfiguration] Load Dealer Defined Rates Configuration",
  LOAD_DEALER_DEFINED_RATES_CONFIGURATION_SUCCESS = "[DealerConfiguration] Load Dealer Defined Rates Configuration success",
  LOAD_DEALER_DEFINED_RATES_CONFIGURATION_FAILURE = "[DealerConfiguration] Load Dealer Defined Rates Configuration failure",
  LOAD_DEALER_FEATURE_CONFIGURATION = "[DealerConfiguration] Load Dealer Feature Configuration",
  LOAD_DEALER_FEATURE_CONFIGURATION_SUCCESS = "[DealerConfiguration] Load Dealer Feature Configuration success",
  LOAD_DEALER_FEATURE_CONFIGURATION_FAILURE = "[DealerConfiguration] Load Dealer Feature Configuration failure",
  LOAD_BANK_CONFIGURATION = "[DealerConfiguration] Load Bank Configuration",
  LOAD_BANK_CONFIGURATION_SUCCESS = "[DealerConfiguration] Load Bank Configuration success",
  LOAD_BANK_CONFIGURATION_FAILURE = "[DealerConfiguration] Load Bank Configuration failure",
  LOAD_SYSTEM_CONFIGURATION = "[DealerConfiguration] Load System Configuration",
  LOAD_SYSTEM_CONFIGURATION_SUCCESS = "[DealerConfiguration] Load System Configuration success",
  LOAD_SYSTEM_CONFIGURATION_FAILURE = "[DealerConfiguration] Load System Configuration failure",
  LOAD_GLOBAL_CONFIGURATION = "[DealerConfiguration] Load Global Configuration",
  LOAD_GLOBAL_CONFIGURATION_SUCCESS = "[DealerConfiguration] Load Global Configuration success",
  LOAD_GLOBAL_CONFIGURATION_FAILURE = "[DealerConfiguration] Load Global Configuration failure",
  SET_DRAFT_STATUS = "[DealerConfiguration] Set draft status",
}

export class LoadDealerConfiguration implements Action {
  type: string = DealerConfigurationActionTypes.LOAD_DEALER_CONFIGURATION;
  constructor(public payload: { dealerCode: string }) { }
}

export class LoadDealerConfigurationSuccess implements Action {
  type: string =
    DealerConfigurationActionTypes.LOAD_DEALER_CONFIGURATION_SUCCESS;
  constructor(public payload: { dealerConfiguration: IDealerConfiguration }) { }
}

export class LoadDealerConfigurationFailure implements Action {
  type: string =
    DealerConfigurationActionTypes.LOAD_DEALER_CONFIGURATION_FAILURE;
  constructor(
    public payload: { error: string; dealerConfiguration: IDealerConfiguration }
  ) { }
}

export class UpdateDealerConfiguration implements Action {
  type: string = DealerConfigurationActionTypes.UPDATE_DEALER_CONFIGURATION;
  constructor(
    public payload: {
      dealerCode: string;
      dealerConfiguration: IDealerConfiguration;
    },
    public showSuccessMessage: Boolean = true
  ) { }
}

export class UpdateDealerConfigurationSuccess implements Action {
  type: string =
    DealerConfigurationActionTypes.UPDATE_DEALER_CONFIGURATION_SUCCESS;
  constructor(public payload: { dealerConfiguration: IDealerConfiguration }) { }
}

export class UpdateDealerConfigurationFailure implements Action {
  type: string =
    DealerConfigurationActionTypes.UPDATE_DEALER_CONFIGURATION_FAILURE;
  constructor(
    public payload: { error: string; dealerConfiguration: IDealerConfiguration }
  ) { }
}

export class LoadDealerFeatureConfiguration implements Action {
  type: string =
    DealerConfigurationActionTypes.LOAD_DEALER_FEATURE_CONFIGURATION;
  constructor(public payload: { dealerCode: string }) { }
}

export class LoadDealerFeatureConfigurationSuccess implements Action {
  type: string =
    DealerConfigurationActionTypes.LOAD_DEALER_FEATURE_CONFIGURATION_SUCCESS;
  constructor(
    public payload: { dealerFeatureConfig: IDealerFeatureConfig[] }
  ) { }
}

export class LoadDealerFeatureConfigurationFailure implements Action {
  type: string =
    DealerConfigurationActionTypes.LOAD_DEALER_FEATURE_CONFIGURATION_FAILURE;
  constructor(public payload: { error: string }) { }
}

export class LoadBankConfiguration implements Action {
  type: string = DealerConfigurationActionTypes.LOAD_BANK_CONFIGURATION;
  constructor(public payload: { distributor: string }) { }
}

export class LoadBankConfigurationSuccess implements Action {
  type: string = DealerConfigurationActionTypes.LOAD_BANK_CONFIGURATION_SUCCESS;
  constructor(public payload: { bankConfiguration: IGetBankConfiguration }) { }
}

export class LoadBankConfigurationFailure implements Action {
  type: string = DealerConfigurationActionTypes.LOAD_BANK_CONFIGURATION_FAILURE;
  constructor(public payload: { error: string }) { }
}

export class LoadSystemConfiguration implements Action {
  type: string = DealerConfigurationActionTypes.LOAD_SYSTEM_CONFIGURATION;
  constructor(public payload: { distributor: string }) { }
}

export class LoadSystemConfigurationSuccess implements Action {
  type: string = DealerConfigurationActionTypes.LOAD_SYSTEM_CONFIGURATION_SUCCESS;
  constructor(public payload: { systemConfiguration: IGetSystemConfiguration }) { }
}

export class LoadSystemConfigurationFailure implements Action {
  type: string = DealerConfigurationActionTypes.LOAD_SYSTEM_CONFIGURATION_FAILURE;
  constructor(public payload: { error: string }) { }
}

export class LoadGlobalConfiguration implements Action {
  type: string = DealerConfigurationActionTypes.LOAD_GLOBAL_CONFIGURATION;
}

export class LoadGlobalConfigurationSuccess implements Action {
  type: string =
    DealerConfigurationActionTypes.LOAD_GLOBAL_CONFIGURATION_SUCCESS;
  constructor(public payload: { bankConfiguration: IGetBankConfiguration }) { }
}

export class LoadGlobalConfigurationFailure implements Action {
  type: string =
    DealerConfigurationActionTypes.LOAD_GLOBAL_CONFIGURATION_FAILURE;
  constructor(public payload: { error: string }) { }
}

export class SetDraftStatus implements Action {
  readonly type = DealerConfigurationActionTypes.SET_DRAFT_STATUS;
  constructor(public payload: { draftState: boolean }) { }
}

export type DealerConfigurationActions =
  | LoadDealerConfiguration
  | LoadDealerConfigurationSuccess
  | LoadDealerConfigurationFailure
  | UpdateDealerConfiguration
  | UpdateDealerConfigurationSuccess
  | UpdateDealerConfigurationFailure
  | LoadDealerFeatureConfiguration
  | LoadDealerFeatureConfigurationSuccess
  | LoadDealerFeatureConfigurationFailure
  | LoadBankConfiguration
  | LoadBankConfigurationSuccess
  | LoadBankConfigurationFailure
  | LoadSystemConfiguration
  | LoadSystemConfigurationSuccess
  | LoadSystemConfigurationFailure
  | LoadGlobalConfiguration
  | LoadGlobalConfigurationSuccess
  | LoadGlobalConfigurationFailure
  | SetDraftStatus;
