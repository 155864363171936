import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PercentageMaskDirective } from './percentage-mask.directive';
import { OnlynumberDirective } from './only-numbers.directive';
import { FiveDigitDecimalDirective } from './only-five-digit-decimal.directive';
import { TwoDigitDecimalDirective } from './only-two-digit-decimal.directive';
import { VarDirective } from './ng-var.directive';
import { InputRestrictionSymbolsDirective } from './restrict-symbols.directive';

@NgModule({
  declarations: [PercentageMaskDirective, OnlynumberDirective, FiveDigitDecimalDirective, TwoDigitDecimalDirective, VarDirective, InputRestrictionSymbolsDirective],
  exports: [PercentageMaskDirective, OnlynumberDirective, FiveDigitDecimalDirective, TwoDigitDecimalDirective, VarDirective, InputRestrictionSymbolsDirective],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
