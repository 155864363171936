<div class="incentive-container m-2">
    <div class="d-flex justify-content-between mb-3">
        <div class="incentive-details">
            <div class="d-flex justify-content-between mb-3">
                <span>{{'LOCALIZATION.INCENTIVES.MAKE' | translate}} : {{incentive.makeName ? incentive.makeName : incentive.make}}
                </span>
                <span>{{'LOCALIZATION.INCENTIVES.YEAR' | translate}} : {{incentive.yearNames ? incentive.yearNames : incentive.year}}
                </span>
                <span class="model-name" [matTooltip]="incentive.modelNames ? incentive.modelNames : incentive.model"
                    matTooltipShowDelay="500">
                    {{'LOCALIZATION.INCENTIVES.MODEL' | translate}} : {{incentive.modelNames ? incentive.modelNames :
                    incentive.model}}
                </span>
            </div>
            <div class="d-flex justify-content-between">
                <span>{{'LOCALIZATION.INCENTIVES.SALES_CLASS' | translate}} : {{incentive.salesClassNames ? incentive.salesClassNames : incentive.salesClass}}
                </span>
                <span>{{'LOCALIZATION.INCENTIVES.OFFER_TYPE' | translate}} : {{incentive.offerTypeNames ? incentive.offerTypeNames : incentive.offerType}}
                </span>
                <span>{{'LOCALIZATION.INCENTIVES.INCENTIVE_AMOUNT' | translate}} : {{incentive.incentiveAmount}}
                </span>
            </div>
        </div>
        <div *ngIf="(incentive.endDate | getincentivestatus : incentive.status) !== incentiveStatus.EXPIRED">
            {{'LOCALIZATION.INCENTIVES.STATUS' | translate}}
            <mat-slide-toggle [class.pointer-events-none]="disableActions$ | async" disableRipple="true"
                [formControl]="incentiveStatusToggle">
                {{'LOCALIZATION.INCENTIVES.ACTIVATE_DEACTIVATE' | translate}}
            </mat-slide-toggle>
        </div>
        <div *ngIf="!(disableActions$ | async)">
            <div *ngIf="(incentive.endDate | getincentivestatus : incentive.status) as currentCampaignStatus"
                class="d-flex">
                <div class="mx-2">
                    <span class="material-icons px-1" [matTooltip]="'LOCALIZATION.INCENTIVES.CLONE_INCENTIVE' | translate"
                        matTooltipShowDelay="500" (click)="cloneIncentive()">control_point_duplicate</span>
                </div>
                <div *ngIf="currentCampaignStatus === incentiveStatus.DRAFT" class="mx-2">
                    <span class="material-icons px-1" [matTooltip]="'LOCALIZATION.INCENTIVES.EDIT_INCENTIVE' | translate"
                        matTooltipShowDelay="500" (click)="editIncentive()">edit</span>
                </div>
                <div *ngIf="currentCampaignStatus === incentiveStatus.DRAFT" class="mx-2">
                    <span class="material-icons px-1"
                        [matTooltip]="'LOCALIZATION.INCENTIVES.DELETE_INCENTIVE' | translate" matTooltipShowDelay="500"
                        (click)="deleteIncentive()">delete</span>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-between mb-3">
        <div>{{'LOCALIZATION.INCENTIVES.DEALER_FUNDED' | translate}} : 
            {{( 'LOCALIZATION.COMMON.' + (incentive.dealerFunded ? 'YES' : 'NO')) | translate}}</div>
    </div>
    <div class="d-flex justify-content-between mb-3" *ngIf="incentive.disclosure">
        <div>{{'LOCALIZATION.INCENTIVES.DISCLOSURE' | translate}} : {{incentive.disclosure}}</div>
    </div>
    <div class="d-flex justify-content-between m-other" *ngIf="incentive.description">
        <div>{{'LOCALIZATION.INCENTIVES.DESCRIPTION' | translate}} : {{incentive.description}}</div>
    </div>
</div>