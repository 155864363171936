import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { DateTime } from "luxon";
import { Observable } from 'rxjs';
import { GenericActions } from '../../../shared/enums/operation-actions.enum';
import { Brands } from '../../../shared/enums/brands.enum';
import { IBrand } from '../../../shared/models/brand.model';
import { ILenderInfo } from '../../../shared/models/dealer-configuration.model';
import { IIncentive } from '../../../shared/models/incentives.model';
import { IncentivesState } from '../../../store/incentives/incentives.reducer';
import * as incentiveActions from "../../../store/incentives/incentives.actions";
import { getIncentiveUpdateComplete$ } from '../../../store/incentives/incentives.adapter';
import { IVehicleBrand } from '../../../shared/models/vehicle-config-master.model';
import { LuxonDateAdapter } from '../../../shared/services/luxon-date-adapter';
import { SubscriptionList, unsubscribeSubscriptions } from "../../../shared/services/util.service";
import { selectBrands } from '../../../store/incentives/incentives.selectors';
import { VehicleConfigState } from '../../../store/vehicle-config/vehicle-config.reducer';
import { IncentivesComponentService } from '../incentives.component.service';
import { selectVehicleConfig, selectYearMasterList } from '../../../store/vehicle-config/vehicle-config.selectors';
import { GenericSelections } from '../../../shared/enums/generic-selections.enum';
import { OfferTypes } from '../../../shared/enums/offer-types.enum';
import { GetBrandSeriesPipe } from '../pipes/get-brand-series.pipe';

@Component({
  selector: "app-pe-modify-incentive",
  templateUrl: "./modify-incentive.component.html",
  styleUrls: ["./modify-incentive.component.scss"],
  providers: [{ provide: DateAdapter, useClass: LuxonDateAdapter }]
})
export class ModifyIncentiveComponent implements OnInit, OnDestroy {
  private subs: SubscriptionList = {};
  activeLenderList: ILenderInfo[];
  activeIncentiveSource: ILenderInfo[];
  brandList$: Observable<IBrand[]>;
  vehicleConfig$: Observable<IVehicleBrand[]>;
  yearMasterList$: Observable<string[]>;
  currentDateTime = DateTime.local();
  filteredLenderList$: Observable<ILenderInfo[]>;
  incentiveForm: FormGroup;
  ignoreFirstLenderFilterChange: boolean;
  rtiBrands: string[] = [Brands.TOYOTA, Brands.LEXUS];
  allOptionValue = GenericSelections.ALL;
  makeChange: boolean = true;
  offerTypeList = [];
  @ViewChild('matDialogCloseBtn') matDialogCloseBtn: ElementRef<HTMLElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: { action: GenericActions, incentive: IIncentive },
    private readonly incentivesState: Store<IncentivesState>,
    private readonly vehicleConfigState: Store<VehicleConfigState>,
    readonly incentiveService: IncentivesComponentService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly getBrandSeriesPipe: GetBrandSeriesPipe) {
  }

  ngOnInit(): void {
    this.brandList$ = this.incentivesState.select(selectBrands);
    this.vehicleConfig$ = this.vehicleConfigState.select(selectVehicleConfig);
    this.yearMasterList$ = this.vehicleConfigState.select(selectYearMasterList);
    this.buildIncentiveForm();
    this.onMakeChange(this.data.incentive.make, false);
    this.setupCloseModalSubscription();
    this.offerTypeList = Object.entries(OfferTypes).map(([key, value]) => (value));
    this.changeDetector.detectChanges();
  }

  buildIncentiveForm() {
    const incentive = this.data.incentive;
    this.incentiveForm = this.formBuilder.group({
      id: [incentive.id],
      dealerCode: [incentive.dealerCode],
      title: [incentive.title, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      make: [{ value: incentive.make, disabled: this.data.action === GenericActions.CLONE }, [Validators.required]],
      year: [{ value: incentive.year, disabled: this.data.action === GenericActions.CLONE }, [Validators.required]],
      model: [{ value: incentive.model, disabled: this.data.action === GenericActions.CLONE }, [Validators.required]],
      startDate: [this.incentiveService.getLuxonUTCDate(incentive.startDate), [Validators.required]],
      endDate: [this.incentiveService.getLuxonUTCDate(incentive.endDate), [Validators.required]],
      salesClass: [incentive.salesClass, [Validators.required]],
      offerType: [incentive.offerType, [Validators.required]],
      incentiveSource: [{ value: incentive.incentiveSource, disabled: incentive.dealerFunded }, [Validators.required]],
      dealerFunded: [incentive.dealerFunded, [Validators.required]],
      incentiveAmount: [incentive.incentiveAmount, [Validators.required, Validators.min(1), Validators.max(99999)]],
      disclosure: [incentive.disclosure, [Validators.maxLength(2000)]],
      description: [incentive.description, [Validators.required, Validators.maxLength(2000)]],
      status: [incentive.status]
    }, {
      validators: [
        this.startAndEndDateValidator()]
    });
    this.subs.dealerFundedSub = this.incentiveForm.get(['dealerFunded'])
      .valueChanges.subscribe((dealerFunded: boolean) => {
        const incentiveSource = this.incentiveForm.get(['incentiveSource']);
        if (dealerFunded) {
          incentiveSource.disable();
          incentiveSource.setValue('Dealer');
        }
        else {
          incentiveSource.enable();
        }
      });
  }

  startAndEndDateValidator(): ValidatorFn {
    return (_group: FormGroup) => {
      const startDate: DateTime = _group.get(['startDate']).value;
      const endDate: DateTime = _group.get(['endDate']).value;
      return this.incentiveService.checkInvalidStartAndEndDate(startDate, endDate)
        ? { InvalidDates: true } : null;
    }
  }

  saveIncentive() {
    if (this.incentiveForm.valid) {
      const make = this.incentiveForm.get(['make']).value;
      const makeName = this.incentiveService.getBrandById(make).name;
      const modelNames: string[] = [];
      const series = this.getBrandSeriesPipe.transform(make, this.incentiveService.vehicleConfig);
      this.incentiveForm.get(['model']).value.forEach(seriesId => {
        modelNames.push(seriesId === this.allOptionValue
          ? _.startCase(this.allOptionValue)
          : this.incentiveService.getModelByCode(series, seriesId).name);
      });
      const yearNames: string[] = [];
      this.incentiveForm.get(['year']).value.forEach(year => {
        yearNames.push(year === this.allOptionValue ? _.startCase(this.allOptionValue) : year)
      });
      const salesClassNames: string[] = [];
      this.incentiveForm.get(['salesClass']).value.forEach(salesClass => salesClassNames.push(_.startCase(salesClass)));
      const offerTypeNames: string[] = [];
      this.incentiveForm.get(['offerType']).value.forEach(offerType => offerTypeNames.push(_.startCase(offerType)));
      const startDate = (this.incentiveForm.get(['startDate']).value as DateTime).toISODate();
      const endDate = (this.incentiveForm.get(['endDate']).value as DateTime).toISODate();
      const incentive: IIncentive = {
        ...this.incentiveForm.getRawValue(),
        startDate,
        endDate,
        makeName,
        modelNames,
        yearNames,
        salesClassNames,
        offerTypeNames
      };
      console.log("incentive", JSON.stringify(incentive));
      this.incentivesState.dispatch(new incentiveActions.UpdateIncentive({ incentive }));
    }
  }

  setupCloseModalSubscription() {
    this.subs.getIncentiveUpdateComplete = getIncentiveUpdateComplete$().subscribe(() => {
      this.matDialogCloseBtn.nativeElement.click();
    });
  }

  onMakeChange(brand: string, reset: boolean = true) {
    if (reset) {
      this.incentiveForm.get(['model']).setValue([]);
      this.incentiveForm.get(['salesClass']).setValue([]);
    }
    this.incentiveService.checkAndfetchVehicleConfig(brand, this.vehicleConfigState);
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subs);
    this.incentiveService.destroySubs();
  }
}
