import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IDeploymentPlan } from "../../../shared/models/deploy-job-management.model";
import _ from 'lodash';

@Component({
  selector: "app-pe-deploy-job-plan",
  templateUrl: "./deploy-job-plan.component.html",
  styleUrls: ["./deploy-job-plan.component.scss"],
})
export class DeployJobPlanComponent implements OnInit, OnDestroy {

  public plans: IDeploymentPlan[] = [];
  public plansToInclude = ['offer-cache', 'pp-cache']

  constructor(@Inject(MAT_DIALOG_DATA) public data: IDeploymentPlan[]) {}

  ngOnInit(): void {
    this.plans = _.filter(this.data, plan => this.plansToInclude.includes(plan.name));
  }

  ngOnDestroy(): void {}
}
