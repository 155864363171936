import { ProtectionProductsEffects } from "./protection-products/protection-products.effects";
import { DealerConfigurationEffects } from "./dealer-configuration/dealer-configuration.effects";
import { VehicleConfigEffects } from "./vehicle-config/vehicle-config.effects";
import { DealValidationEffects } from "./deal-validation/deal-validation.effects";
import { LenderListConfigEffects } from "./lender-list-config/lender-list-config.effects";
import { DealerDefinedCampaignsEffects } from "./dealer-defined-campaigns/dealer-defined-campaigns.effects";
import { DeployJobManagementEffects } from "./deploy-job-management/deploy-job-management.effects";
import { IncentivesEffects } from "./incentives/incentives.effects";

export const appEffects: any[] = [
  ProtectionProductsEffects,
  DealerConfigurationEffects,
  VehicleConfigEffects,
  DealValidationEffects,
  LenderListConfigEffects,
  DealerDefinedCampaignsEffects,
  DeployJobManagementEffects,
  IncentivesEffects
];
