import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { IPlan } from '../../../shared/models/product-offerings.model';

@Pipe({
    name: 'plansfilter'
})
export class PlansFilterPipe implements PipeTransform {
    transform(items: IPlan[], formId: string, salesClass: string, offerType: string, term: string): any[] {
        if (!items) { return []; } else {
            return _.filter(items,
                (t: IPlan) =>
                    t.formId === formId
                    && (!t.salesClass || t.salesClass === salesClass)
                    && (!t.offerType || t.offerType === offerType)
                    && (!t.terms || _.includes(t.terms, term))
            );
        }
    }
}
