import { ProvidersEnum } from '../../../shared/enums/provider-id.enum';
import { IPlan } from '../../../shared/models/product-offerings.model';
import { ProtectionProductsComponentService } from '../protection-products.component.service';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'checksalesclassdataexistspipe'
})
export class CheckSalesClassDataExistsPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(id: string, salesClass: string): boolean {
        return this.pPComponentService.selectedProductData?.providerId === ProvidersEnum.JMNA || _.some((this.pPComponentService.selectedProductData?.forms[0].plans), (t: IPlan) => t.salesClass === salesClass && t.terms.length > 0);
    }
    
}
