<div class="campaign-container m-2">
    <div class="d-flex justify-content-between">
        <div>{{'LOCALIZATION.CAMPAIGNS.MAKE' | translate}} : {{campaign.makeName ? campaign.makeName : campaign.make}}
        </div>
        <div>{{'LOCALIZATION.CAMPAIGNS.YEAR' | translate}} : {{campaign.yearNames ? campaign.yearNames : campaign.year}}
        </div>
        <div class="model-name" [matTooltip]="campaign.modelNames ? campaign.modelNames : campaign.model"
            matTooltipShowDelay="500">
            {{'LOCALIZATION.CAMPAIGNS.MODEL' | translate}} : {{campaign.modelNames ? campaign.modelNames :
            campaign.model}}
        </div>
        <div>{{'LOCALIZATION.CAMPAIGNS.LENDER_INCENTIVE' | translate}} : {{campaign.incentiveAmount}}</div>
        <div *ngIf="(campaign.endDate | getcampaignstatus : campaign.status) !== campaignStatus.EXPIRED">
            {{'LOCALIZATION.CAMPAIGNS.STATUS' | translate}}
            <mat-slide-toggle [class.pointer-events-none]="disableActions$ | async" disableRipple="true"
                [formControl]="campaignStatusToggle">
                {{'LOCALIZATION.CAMPAIGNS.ACTIVATE_DEACTIVATE' | translate}}
            </mat-slide-toggle>
        </div>
        <ng-container *ngIf="!(disableActions$ | async)">
            <div *ngIf="(campaign.endDate | getcampaignstatus : campaign.status) as currentCampaignStatus"
                class="d-flex">
                <div class="mx-2">
                    <span class="material-icons px-1" [matTooltip]="'LOCALIZATION.CAMPAIGNS.CLONE_CAMPAIGN' | translate"
                        matTooltipShowDelay="500" (click)="cloneCampaign()">control_point_duplicate</span>
                </div>
                <div *ngIf="currentCampaignStatus === campaignStatus.DRAFT" class="mx-2">
                    <span class="material-icons px-1" [matTooltip]="'LOCALIZATION.CAMPAIGNS.EDIT_CAMPAIGN' | translate"
                        matTooltipShowDelay="500" (click)="editCampaign()">edit</span>
                </div>
                <div *ngIf="currentCampaignStatus === campaignStatus.DRAFT" class="mx-2">
                    <span class="material-icons px-1"
                        [matTooltip]="'LOCALIZATION.CAMPAIGNS.DELETE_CAMPAIGN' | translate" matTooltipShowDelay="500"
                        (click)="deleteCampaign()">delete</span>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="d-flex justify-content-between" *ngIf="campaign.dndText">
        <div>{{'LOCALIZATION.CAMPAIGNS.DISCLAIMER' | translate}} : {{campaign.dndText}}</div>
    </div>
    <div class="d-flex justify-content-between m-other" *ngIf="campaign.otherRestrictions">
        <div>{{'LOCALIZATION.CAMPAIGNS.OTHER_RESTRICTIONS' | translate}} : {{campaign.otherRestrictions}}</div>
    </div>

    <div>
        <strong class="d-block mb-1">Rate Rules:</strong>
        <mat-accordion *ngIf="campaign.rateRules?.length" class="list-rate-rule-container">
            <ng-container *ngFor="let rateRule of campaign.rateRules, index as ruleIndex">
                <app-pe-view-rate-rule [rateRule]="rateRule" [campaign]="campaign"
                    [ruleIndex]="ruleIndex"></app-pe-view-rate-rule>
            </ng-container>
        </mat-accordion>
        <div *ngIf="!campaign.rateRules?.length" class="text-align-center">
            {{'LOCALIZATION.CAMPAIGNS.NO_RECORDS' | translate}}
        </div>
    </div>
    <div *ngIf="!(disableActions$ | async) && (campaign.endDate | getcampaignstatus : campaign.status) === campaignStatus.DRAFT"
        class="d-flex">
        <input type="button" class="primary-button small-button ml-auto mt-4 mr-4" (click)="addRateRule()"
            value="{{'LOCALIZATION.CAMPAIGNS.ADD_RATE_RULE' | translate}}" />
    </div>
</div>