import {
  IBuilderRequest,
  IBuilderResponse,
} from "./../../shared/models/pe-builder.model";
import { IVehicleDetailsDD365Request } from "./../../shared/models/pe-dd365-vehicledetails.model";
import { Action } from "@ngrx/store";
import { IVehicleDetailsDD365Response } from "./../../shared/models/pe-dd365-vehicledetails.model";
import {
  ICalculatorRequest,
  ICalculatorResponse,
} from "../../shared/models/pe-calculator.model";

export enum DealValidationActionTypes {
  LOAD_OFFERS_FROM_BUILDER = "[DealValidation] Load Offers from Builder",
  LOAD_OFFERS_FROM_BUILDER_SUCCESS = "[DealValidation] Load Offers from Builder success",
  LOAD_OFFERS_FROM_BUILDER_FAILURE = "[DealValidation] Load Offers from Builder failure",
  LOAD_VEHDETAILS_FROM_DD365 = "[DealValidation] Load Offers from Builder",
  LOAD_VEHDETAILS_FROM_DD365_SUCCESS = "[DealValidation] Load Vehicle Details from DD365 success",
  LOAD_VEHDETAILS_FROM_DD365_FAILURE = "[DealValidation] Load Vehicle Details from DD365 failure",
  LOAD_DEAL = "[DealValidation] Load Deal from Calculator",
  LOAD_DEAL_SUCCESS = "[DealValidation] Load Deal from Calculator success",
  LOAD_DEAL_FAILURE = "[DealValidation] Load Deal from Calculator failure",
}

export class LoadOffersFromBuilder implements Action {
  type: string = DealValidationActionTypes.LOAD_OFFERS_FROM_BUILDER;
  constructor(public payload: { quoteRequest: IBuilderRequest }) {}
}
export class LoadVehicleDetailsFromDD365 implements Action {
  type: string = DealValidationActionTypes.LOAD_VEHDETAILS_FROM_DD365;
  constructor(public payload: { vehicleRequest: string }) {}
}
export class LoadVehicleDetailsFromDD365Success implements Action {
  type: string = DealValidationActionTypes.LOAD_VEHDETAILS_FROM_DD365_SUCCESS;
  constructor(
    public payload: { vehicleDetails: IVehicleDetailsDD365Response }
  ) {}
}
export class LoadVehicleDetailsFromDD365Failure implements Action {
  type: string = DealValidationActionTypes.LOAD_VEHDETAILS_FROM_DD365_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadOffersFromBuilderSuccess implements Action {
  type: string = DealValidationActionTypes.LOAD_OFFERS_FROM_BUILDER_SUCCESS;
  constructor(public payload: { quoteDetails: IBuilderResponse }) {}
}

export class LoadOffersFromBuilderFailure implements Action {
  type: string = DealValidationActionTypes.LOAD_OFFERS_FROM_BUILDER_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadDeal implements Action {
  type: string = DealValidationActionTypes.LOAD_DEAL;
  constructor(
    public payload: { offerType: string; dealRequest: ICalculatorRequest }
  ) {}
}

export class LoadDealSuccess implements Action {
  type: string = DealValidationActionTypes.LOAD_DEAL_SUCCESS;
  constructor(public payload: { dealDetails: ICalculatorResponse }) {}
}

export class LoadDealFailure implements Action {
  type: string = DealValidationActionTypes.LOAD_DEAL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export type DealValidationActions =
  | LoadOffersFromBuilder
  | LoadOffersFromBuilderSuccess
  | LoadOffersFromBuilderFailure
  | LoadDeal
  | LoadDealSuccess
  | LoadDealFailure
  | LoadVehicleDetailsFromDD365
  | LoadVehicleDetailsFromDD365Success
  | LoadVehicleDetailsFromDD365Failure;
