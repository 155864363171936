import { IImageProps } from "./../../../shared/models/utility/image-cropper-properties";
import { ImageCropperComponent } from "./../../../shared/components/image-cropper/image-cropper.component";
import { StaticStorageService } from "./../../../shared/services/static-storage.service";
import {
  IGetPresignedUrlRequest,
  IGetPresignedUrlResponse,
} from "./../../../shared/models/s3-file-upload.model";
import { ProtectionProductsService } from "./../../../shared/services/protection-products.service";
import { GrossProfitTypes } from "./../../../shared/enums/gp-types.enum";
import { ConfirmDialogComponent } from "./../../../shared/components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ProvidersEnum } from "./../../../shared/enums/provider-id.enum";
import { LuxonDateAdapter } from "./../../../shared/services/luxon-date-adapter";
import { DateAdapter } from "@angular/material/core";
import {
  IPennProduct,
  IPennProvider,
  IProduct,
  IPlan,
  IDeductible,
  ICoverageOption,
} from "./../../../shared/models/product-offerings.model";
import {
  IProductProperties,
  ISaveProducts,
  IUpdateProductConfig,
  IPreferenceOrder,
  IPennProductDefaults,
  IProductConfiguration,
  IInstruction,
  IPacChangelog,
  ITaxCategory,
  IPencilDefaults,
  IGetPennProductDefaults,
  IProtectionProducts,
  IPlanConfig,
} from "./../../../shared/models/protection-products.model";
import { PpDefaultNames } from "./../../../shared/enums/defaults-names.enum";
import { ProtectionProductsComponentService } from "./../protection-products.component.service";
import {
  selectSalesClasses,
  selectOfferTypes,
  selectPennProviders,
  selectPennProductDefaultsList,
  selectPacChangelogs,
  selectTaxCategories,
  selectLastPennProductLoaded,
  selectProtectionProducts,
  selectBrands, selectIsLoading
} from "./../../../store/protection-products/protection-products.selectors";
import * as protectionProductsActions from "../../../store/protection-products/protection-products.actions";
import { Observable } from "rxjs";
import { ProtectionProductsState } from "./../../../store/protection-products/protection-products.reducer";
import {
  canActivateOnlyOnDev,
  generateRandomQueryParam,
  SubscriptionList,
  unsubscribeSubscriptions,
} from "./../../../shared/services/util.service";
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { FormGroup, FormArray, AbstractControl, FormControl, Validators, FormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import { take, filter, distinctUntilChanged } from "rxjs/operators";
import * as _ from "lodash";
import { DateTime } from "luxon";
import * as SparkMD5 from "spark-md5";
import { DynamicFieldTypes } from "../../../shared/enums/dynamic-field-types.enum";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SavePpApiInstruction } from "../../../shared/enums/pp-save-api-instruction.enum";
import { TranslateService } from "@ngx-translate/core";
import { GenericSelections } from '../../../shared/enums/generic-selections.enum';
import { VehicleConfigState } from '../../../store/vehicle-config/vehicle-config.reducer';
import { IVehicleBrand } from '../../../shared/models/vehicle-config-master.model';
import { IBrand } from '../../../shared/models/brand.model';
import { selectVehicleConfig } from '../../../store/vehicle-config/vehicle-config.selectors';
import { selectDisableActions } from "../../../store/dealer-configuration/dealer-configuration.selectors";
import { DealerConfigurationState } from "../../../store/dealer-configuration/dealer-configuration.reducer";
import { ProtectionProductCopyOfferComponent } from "../protection-product-copy-offer/protection-product-copy-offer.component";
import { Brands } from "../../../shared/enums/brands.enum";

@Component({
  selector: "app-protection-product-details",
  templateUrl: "./protection-product-details.component.html",
  styleUrls: ["./protection-product-details.component.scss"],
  providers: [{ provide: DateAdapter, useClass: LuxonDateAdapter }],
})
export class ProtectionProductDetailsComponent implements OnInit, OnDestroy {
  @ViewChildren("Adjusted_GP_Input") adjustedGpValueArray: QueryList<ElementRef>;
  isLoading$: Observable<boolean>;
  productDetailsForm: FormGroup;
  salesClasses$: Observable<string[]>;
  offerTypes$: Observable<string[]>;
  pennProviders$: Observable<IPennProvider[]>;
  protectionProductsData$: Observable<IProtectionProducts>;
  pennProductDefaultsList$: Observable<IPennProductDefaults[]>;
  pacChangelogs$: Observable<IPacChangelog[]>;
  taxCategories$: Observable<ITaxCategory[]>;
  plans: IPlan[] = [];
  deductibles: IDeductible[] = [];
  coverageOptions: ICoverageOption[] = [];
  previousProductSelection: string;
  selectedOfferTypeTabIndex: number;
  selectedDetailsTabIndex: number = 0;
  autofill: boolean;
  private subs: SubscriptionList = {};
  hasSingleDefaultForm: boolean = false;
  dynamicFieldTypeEnum = DynamicFieldTypes;
  jmnaProviderId = ProvidersEnum.JMNA;
  pennProviderId = ProvidersEnum.PENN;
  nontmisProviderId = ProvidersEnum.NONTMIS;
  grossProfitTypes = GrossProfitTypes;
  showDetails = false;
  showDynamicFields = true;
  savePpApiInstruction = SavePpApiInstruction;
  pennProductDetails: IPennProduct;
  productImageUrl: string;
  displayPacChangelogs: boolean;
  imageUploadProperties: IImageProps = {
    aspectRatio: 16 / 9,
    maintainAspectRatio: true,
    format: "jpeg",
    resizeToWidth: 1920,
    resizeToHeight: 1080,
    canvasRotation: 0,
    minWidth: 0,
    minHeight: 0,
  };
  initialPacEffectiveDate: DateTime | string;
  initialPacAmount: number;
  brandTmisList$: Observable<IBrand[]>;
  vehicleConfig$: Observable<IVehicleBrand[]>;
  model: string[] | [];
  allOptionValue = GenericSelections.ALL;
  initialConfig: any;
  dialogRef: any;
  initialProductConfig: IProductConfiguration;
  blockPopup: boolean;
  isDialogOpened: boolean;
  adjustmentValue: number;
  adjustedGP: number;
  localCommonDismiss: string;
  disableActions$: Observable<boolean>;
  isRegulated: string;
  salesClasses: string[];
  offerTypes: string[];
  canActivateOnlyOnDev() {
    return canActivateOnlyOnDev();
  }

  constructor(
    private readonly protectionProductsState: Store<ProtectionProductsState>,
    readonly pPComponentService: ProtectionProductsComponentService,
    private readonly protectionProductsService: ProtectionProductsService,
    private readonly staticStorageService: StaticStorageService,
    private snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    readonly translateService: TranslateService,
    private readonly vehicleConfigState: Store<VehicleConfigState>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.autofill = false;
    this.localCommonDismiss = "LOCALIZATION.COMMON.DISMISS";
    this.showDetails = this.showDynamicFields = true;
    this.selectedDetailsTabIndex = 0;
    this.salesClasses$ = this.protectionProductsState.select(
      selectSalesClasses
    );
    this.offerTypes$ = this.protectionProductsState.select(selectOfferTypes);
    this.protectionProductsData$ = this.protectionProductsState.select(selectProtectionProducts);
    this.pennProviders$ = this.protectionProductsState.select(
      selectPennProviders
    );
    this.pennProductDefaultsList$ = this.protectionProductsState.select(
      selectPennProductDefaultsList
    );
    this.pacChangelogs$ = this.protectionProductsState.select(
      selectPacChangelogs
    );
    this.taxCategories$ = this.protectionProductsState.select(
      selectTaxCategories
    );
    this.salesClasses$.pipe(take(1)).subscribe((vehTypes) => {
      this.pPComponentService.salesClasses = vehTypes;
    });
    this.offerTypes$.pipe(take(1)).subscribe((ofrTypes) => {
      this.pPComponentService.offerTypes = ofrTypes;
    });
    this.brandTmisList$ = this.protectionProductsState.select(selectBrands);
    this.vehicleConfig$ = this.vehicleConfigState.select(selectVehicleConfig);
    this.subs.salesClassesSub = this.protectionProductsState
      .select(selectSalesClasses)
      .subscribe((t) => {
        this.salesClasses = t;
      });
    this.subs.offerTypesSub = this.protectionProductsState
      .select(selectOfferTypes)
      .subscribe((t) => {
        this.offerTypes = t;
      });

    /* istanbul ignore next */
    this.subs.selectedProductSub = this.pPComponentService.getSelectedProduct$.subscribe(
      (t) => {
        if (t) {
          this.setSelection(t.id);
          this.isRegulated = t.isRegulated;
        }
      }
    );
    this.subs.getResetSelectedProductSub = this.pPComponentService.getResetSelectedProduct$.subscribe(
      (t) => {
        this.resetProductDetails();
      }
    );

    this.subs.selectLastPennProductLoadedSub = this.protectionProductsState
      .select(selectLastPennProductLoaded)
      .pipe(
        filter(
          (t) => t?.configurationData?.length > 0 && !!t.configurationData[0].id
        )
      )
      .subscribe((t) => {
        this.productChange(t.configurationData[0].id);
        const pennProducts: IGetPennProductDefaults = {
          configurationData: [],
          masterData: [],
        };
        this.protectionProductsState.dispatch(
          new protectionProductsActions.ResetLastPennProductLoaded({
            pennProducts,
          })
        );
      });
    this.isLoading$ = this.protectionProductsState.select(selectIsLoading);
    this.disableActions$ = this.dealerConfigurationState.select(selectDisableActions);
  }

  setSelection(productId: string) {
    if (!productId || this.previousProductSelection != productId) {
      this.selectedOfferTypeTabIndex = this.getOfferTypeTabIndex();
    }
    this.previousProductSelection = productId;
    this.showDynamicFields = this.displayPacChangelogs = false;
    this.showDetails = false;
    this.productDetailsForm = this.pPComponentService.selectedProductDetailsForm;
    const initialProductConfig: IProductConfiguration = this.productDetailsForm.getRawValue();
    this.pPComponentService.selectedProductConfigString = JSON.stringify(
      initialProductConfig
    );
    this.pPComponentService.selectedProductConfig$.next(initialProductConfig);
    this.initialProductConfig = initialProductConfig;
    this.plans = [];
    this.deductibles = [];
    this.coverageOptions = [];
    this.pPComponentService.selectedProductData?.forms.forEach((t) => {
      t.plans.forEach((p) => {
        this.plans.push({ formId: t.code, ...p } as IPlan);
        this.deductibles.push(
          ...p.deductibles.map(
            (d) =>
            ({
              formId: t.code,
              planId: p.code,
              salesClass: p.salesClass,
              offerType: p.offerType,
              ...d,
            } as IDeductible)
          )
        );
        this.coverageOptions.push(
          ...p.coverageOptions.map(
            (c) =>
            ({
              formId: t.code,
              planId: p.code,
              salesClass: p.salesClass,
              offerType: p.offerType,
              ...c,
            } as ICoverageOption)
          )
        );
      });
    });
    this.updateFirstPencilState();
    this.updateMutexPlansState();
    if (this.pPComponentService.isTfsDealer) {
      this.detectGpChanges();
    }
    this.pennProductDetails = this.pPComponentService.getPennProductMasterData(
      initialProductConfig
    );
    this.initialPacEffectiveDate =
      initialProductConfig.global.pacManagement?.effectiveDate || null;
    this.initialPacAmount = initialProductConfig.global.pacManagement?.amount;
    this.productImageUrl = generateRandomQueryParam(
      initialProductConfig?.global?.imageUrl
    );
    this.hasSingleDefaultForm =
      this.pPComponentService.selectedProductData?.forms.length === 1 &&
      this.pPComponentService.selectedProductData.forms[0].code ===
      PpDefaultNames.DEFAULT_FORM_NAME;
    setTimeout(() => {
      this.showDynamicFields = true;
      this.setSelectedTab(initialProductConfig);
    }, 0);
    setTimeout(() => {
      this.showDetails = true;
    }, 0);
    const gpValue = (this.productDetailsForm.get(['global', 'gp']) as FormGroup).getRawValue();
    this.adjustedGP = gpValue.value;
  }

  /* istanbul ignore next */
  updateFirstPencilState() {
    const pencilDefaults = this.productDetailsForm.get([
      "global",
      "firstPencilDefaults",
    ]) as FormArray;
    if (pencilDefaults) {
      const productList: IProductConfiguration[] = (this.pPComponentService.protectionProductsForm.get(
        "products"
      ) as FormGroup).getRawValue();
      const productId = this.productDetailsForm.get("id").value;
      const productsPencilDefaults = productList
        .filter((t) => t.id !== productId)
        .map((t) => t.global.firstPencilDefaults);
      pencilDefaults.controls.forEach((pencilControl) => {
        const pencilValue: IPencilDefaults = pencilControl.value;
        if (
          productsPencilDefaults.filter(
            (s) =>
              s.filter(
                (t) =>
                  t.offerType === pencilValue.offerType &&
                  t.salesClass === pencilValue.salesClass &&
                  t.enabled
              ).length > 0
          ).length >= 3
        ) {
          pencilControl.disable({ emitEvent: false });
        } else {
          pencilControl.enable({ emitEvent: false });
        }
      });
    }
  }

  updateMutexPlansState() {
    const plansFormArray = this.productDetailsForm.get(["global", "plans"]) as FormArray;
    const productCode = this.pPComponentService.selectedProductData.code;
    if (this.pPComponentService.isMutexProduct(productCode)) {
      const enabledMutexPlans = this.pPComponentService.getEnabledMutexPlans(this.productDetailsForm.get('id').value);
      plansFormArray.controls.forEach((planControl) => {
        const planValue: IPlanConfig = planControl.value;
        if (typeof (planValue.includePlan) !== 'undefined' && !planValue.includePlan) {
          const mutexPlans = this.pPComponentService.getMutexPlans(productCode, planValue.formId, planValue.id);
          if (_.intersectionWith(mutexPlans, enabledMutexPlans, _.isEqual).length > 0) {
            planControl.get('includePlan').disable({ emitEvent: false });
          }
          else {
            planControl.get('includePlan').enable({ emitEvent: false });
          }
        }
      });
    }
  }

  getOfferTypeTabIndex() {
    let offerTabIndex = 0;
    this.pPComponentService.offerTypes.some((offerType, index) => {
      const hasEntries = this.pPComponentService.selectedProductData?.forms.some(
        (t) => t.plans.some((s) => s.offerType === offerType)
      );
      if (hasEntries) {
        offerTabIndex = index;
        return true;
      }
    });
    return offerTabIndex;
  }

  /* istanbul ignore next */
  setSelectedTab(initialProductConfig: IProductConfiguration) {
    if (this.pPComponentService.addingProduct.value) {
      this.selectedDetailsTabIndex = 0;
    } else {
      this.selectedDetailsTabIndex =
        initialProductConfig.global.providerId ===
          this.pPComponentService.ownPpProviderId
          ? 1
          : 0;
    }
  }

  /* istanbul ignore next */
  getFormGroupName(salesClass: string, offerType: string) {
    return this.productDetailsForm.get([
      "global",
      "defaults",
      salesClass,
      offerType,
    ]) as FormArray;
  }

  setTermValue(
    term: IProductProperties,
    termControl: AbstractControl,
    formId: string,
    salesClass: string,
    offerType: string,
    plan
  ) {
    const planId = plan[0]?.code || "";
    termControl.get("planId").patchValue(planId);
    term.planId = planId;
    const optionsFilterFunction = (t) =>
      t.formId === formId &&
      t.planId === planId &&
      (!t.salesClass || t.salesClass === salesClass) &&
      (!t.offerType || t.offerType === offerType);
    const deductibleCodes = _.filter(
      this.deductibles,
      optionsFilterFunction
    ).map((t) => t.code);
    const coverageOptionCodes = _.filter(
      this.coverageOptions,
      optionsFilterFunction
    ).map((t) => t.code);
    if (!deductibleCodes) {
      termControl.get("selectedDeductible").patchValue("");
      term.selectedDeductible = "";
    } else if (
      !_.includes(deductibleCodes, termControl.get("selectedDeductible").value)
    ) {
      termControl.get("selectedDeductible").patchValue(deductibleCodes[0]);
      term.selectedDeductible = deductibleCodes[0];
    }
    if (!coverageOptionCodes) {
      termControl.get("selectedTimeMileage").patchValue("");
      term.selectedTimeMileage = "";
    } else if (
      !_.includes(
        coverageOptionCodes,
        termControl.get("selectedTimeMileage").value
      )
    ) {
      termControl.get("selectedTimeMileage").patchValue(coverageOptionCodes[0]);
      term.selectedTimeMileage = coverageOptionCodes[0];
    }
    termControl.updateValueAndValidity({ emitEvent: false });
  }

  /* istanbul ignore next */
  setCascadingValue(
    formId: string,
    term: IProductProperties,
    termControl: AbstractControl,
    salesClass: string,
    offerType: string,
    planVal?: string
  ) {
    const plan = _.filter(
      this.plans,
      (t: IPlan) =>
        t.formId === formId &&
        (!planVal || t.code === planVal) &&
        (!t.salesClass || t.salesClass === salesClass) &&
        (!t.offerType || t.offerType === offerType) &&
        (!t.terms || _.includes(t.terms, termControl.get("months").value))
    );
    if (plan.length > 0) {
      this.setTermValue(term, termControl, formId, salesClass, offerType, plan);
    }
  }

  /* istanbul ignore next */
  setFormValues(
    updateField: string,
    updateId: string | boolean,
    term: IProductProperties,
    termControl: AbstractControl,
    salesClass: string,
    offerType: string,
    formId: string,
    planId?: string
  ) {
    if (this.autofill) {
      const defaultsForm = this.productDetailsForm.get([
        "global",
        "defaults",
      ]) as FormGroup;
      this.pPComponentService.salesClasses.forEach((salesCls) => {
        this.pPComponentService.offerTypes
          .filter((t) => t === offerType)
          .forEach((offer) => {
            const offerFormArr = defaultsForm.get([
              salesCls,
              offer,
            ]) as FormArray;
            const terms: IProductProperties[] = this.pPComponentService
              .selectedProductConfig$.value.global.defaults[salesCls][offer];
            offerFormArr.controls.forEach((updateControl) => {
              const updateTerm = _.find(terms, {
                months: updateControl.value.months,
              });
              this.setValues(
                updateTerm,
                termControl,
                updateControl,
                updateField,
                updateId,
                formId,
                salesCls,
                offer,
                planId
              );
            });
          });
      });
      defaultsForm.updateValueAndValidity();
    } else if (updateField === "formId") {
      this.setCascadingValue(
        updateId as string,
        term,
        termControl,
        salesClass,
        offerType
      );
      term.formId = updateId as string;
    } else if (updateField === "planId") {
      this.setCascadingValue(
        formId,
        term,
        termControl,
        salesClass,
        offerType,
        updateId as string
      );
    }
  }

  /* istanbul ignore next */
  setValues(
    term: IProductProperties,
    termControl: AbstractControl,
    control: AbstractControl,
    updateField: string,
    updateId: string | boolean,
    formId: string,
    salesClass: string,
    offerType: string,
    planId?: string
  ) {
    if (updateField === "formId") {
      control.get(updateField).patchValue(updateId);
      term[updateField] = updateId as string;
      this.setCascadingValue(
        updateId as string,
        term,
        control,
        salesClass,
        offerType
      );
    } else if (
      updateField === "planId" &&
      control.get("formId").value === formId
    ) {
      this.setCascadingValue(
        formId,
        term,
        control,
        salesClass,
        offerType,
        updateId as string
      );
    } else if (
      !_.isEqual(termControl, control) &&
      control.get("formId").value === formId &&
      control.get("planId").value === planId
    ) {
      control.get(updateField).patchValue(updateId);
      term[updateField] = updateId;
    }
  }

  /* istanbul ignore next */
  resetProductDetails() {
    const initialConfig = JSON.parse(
      this.pPComponentService.selectedProductConfigString
    );
    this.pPComponentService.updateAdjustments(this.productDetailsForm, initialConfig);
    this.productDetailsForm.patchValue(initialConfig, { emitEvent: false });
    this.productDetailsForm.updateValueAndValidity({ emitEvent: false });
    this.productImageUrl = generateRandomQueryParam(
      this.productDetailsForm.get(["global", "imageUrl"])?.value
    );
    this.productDetailsForm.markAsPristine();
    this.productDetailsForm.markAsUntouched();
    this.pPComponentService.selectedProductConfig$.next(initialConfig);
    // this.pPComponentService.setupForm();

  }

  /* istanbul ignore next */
  deleteProductConfirmed() {
    const productMasterData: IProduct[] = [
      this.pPComponentService.selectedProductData,
    ];
    const productConfigData: IUpdateProductConfig[] = [];
    const rawProductConfig: IUpdateProductConfig = this.productDetailsForm.getRawValue();
    if (rawProductConfig.providerId !== ProvidersEnum.JMNA) {
      rawProductConfig.instruction = {
        name: SavePpApiInstruction.DELETE,
      } as IInstruction;
    }
    productConfigData.push(rawProductConfig);
    const preferenceOrder: IPreferenceOrder[] = _.filter(
      (this.pPComponentService.protectionProductsForm.get(
        "products"
      ) as FormArray).controls,
      (product, index) => product.value.id !== rawProductConfig.id
    ).map((product, index) => {
      return {
        objectId: product.value.id,
        preferenceOrder: index,
        providerId: product.value.providerId,
      } as IPreferenceOrder;
    });

    const deleteData: ISaveProducts = {
      masterData: productMasterData,
      configurationData: productConfigData,
      preferenceOrder,
    };

    this.protectionProductsState.dispatch(
      new protectionProductsActions.UpdateTfsProtectionProducts({
        dealerCode: this.pPComponentService.dealerCode,
        protectionProducts: deleteData,
      })
    );
  }

  /* istanbul ignore next */
  deleteProduct() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "400px",
      data: {
        popupBodyText: this.translateService.instant(
          "LOCALIZATION.PROTECTION_PRODUCTS.DELETE_CONFIRM_TITLE"
        ),
        confirmButtonText: this.translateService.instant(
          "LOCALIZATION.COMMON.DELETE"
        ),
      },
    });
    const afterClosedSub = dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === "confirm") {
        this.deleteProductConfirmed();
      }
      afterClosedSub.unsubscribe();
    });
  }

  /* istanbul ignore next */
  saveProductDetails(instructionName: SavePpApiInstruction) {
    //console.log("this.productDetailsForm.value", this.productDetailsForm.value);
    if (
      this.productDetailsForm.get(["global", "gp"])?.errors?.gpExceedsMaxPrice
    ) {
      this.snackBar.open(
        this.productDetailsForm.get(["global", "gp"]).errors
          .gpExceedsMaxPriceMsg,
        this.translateService.instant(this.localCommonDismiss),
        { duration: 4000 }
      );
      return;
    }
    if (this.productDetailsForm.get(["global"])?.errors?.gpExceedsMP) {
      this.snackBar.open(
        this.productDetailsForm.get(["global"]).errors.gpExceedsMPMsg,
        this.translateService.instant(this.localCommonDismiss),
        { duration: 4000 }
      );
      return;
    }
    const gpType = this.productDetailsForm.get(['global', 'gp', 'type']).getRawValue();
    const maxPrice = this.productDetailsForm.get(["global", "maxPrice"])?.getRawValue();
    if (this.adjustedGpValueArray.toArray().length > 0) {
      for (const agpValue of this.adjustedGpValueArray.toArray()) {
        if (agpValue.nativeElement.value < 0) {
          this.snackBar.open(
            this.translateService.instant('LOCALIZATION.PROTECTION_PRODUCTS.ADJUSTED_GP_NEGATIVE_VALIDATION'),
            this.translateService.instant(this.localCommonDismiss),
            { duration: 4000 }
          );
          return;
        }
        if (maxPrice && gpType == this.grossProfitTypes.AMOUNT && agpValue.nativeElement.value > maxPrice) {
          this.snackBar.open(
            this.translateService.instant('LOCALIZATION.PROTECTION_PRODUCTS.ADJUSTED_GP_VALIDATION_ERROR'),
            this.translateService.instant(this.localCommonDismiss),
            { duration: 4000 }
          );
          return;
        }
      }
    }
    if (this.productDetailsForm.get(["global"])?.errors?.AgpExceedsMP) {
      this.snackBar.open(
        this.translateService.instant('LOCALIZATION.PROTECTION_PRODUCTS.ADJUSTED_GP_VALIDATION_ERROR'),
        this.translateService.instant(this.localCommonDismiss),
        { duration: 4000 }
      );
      return;
    }
    if (this.productDetailsForm.get(["global", "pacManagement"])?.errors?.invalidPacEffectiveDate) {
      this.snackBar.open(
        this.translateService.instant('LOCALIZATION.PROTECTION_PRODUCTS.PAC_EFFECTIVE_DATE_VALIDATION_ERROR'),
        this.translateService.instant(this.localCommonDismiss),
        { duration: 4000 }
      );
      return;
    }
    if (this.productDetailsForm.valid && !this.pPComponentService.isReadOnly) {
      this.updatedSaveProductDetails(instructionName)
    } else {
      this.snackBar.open(
        this.translateService.instant(
          "LOCALIZATION.PROTECTION_PRODUCTS.CONFIG_INVALID_TEXT"
        ),
        this.translateService.instant(this.localCommonDismiss),
        { duration: 4000 }
      );
    }
  }
  
  updatedSaveProductDetails(instructionName: SavePpApiInstruction) {
    const productMasterData: IProduct[] = [
      _.cloneDeep(this.pPComponentService.selectedProductData),
    ];
    productMasterData[0].isRegulated = this.productDetailsForm.get(["global", "isRegulated",]).value;
    const productConfigData: IUpdateProductConfig[] = [];
    //console.log(this.productDetailsForm.getRawValue());
    const rawProductConfig: IUpdateProductConfig = this.productDetailsForm.getRawValue();
    const initialConfig: IProductConfiguration = JSON.parse(
      this.pPComponentService.selectedProductConfigString
    );
    if (rawProductConfig.providerId !== ProvidersEnum.JMNA) {
      rawProductConfig.instruction = {
        name: instructionName,
        includeImage:
          initialConfig.global.imageUrl !== rawProductConfig.global.imageUrl,
        pacUpdated:
          initialConfig.global.pacManagement?.amount !==
          rawProductConfig.global.pacManagement?.amount ||
          initialConfig.global.pacManagement?.effectiveDate !==
          rawProductConfig.global.pacManagement?.effectiveDate,
      } as IInstruction;
    }
    // Set default value of dealerCost to 0 for dealer defined products
    if (rawProductConfig.global.providerId === ProvidersEnum.NONTMIS) {
      rawProductConfig.global.dealerCost =
        rawProductConfig.global.dealerCost || 0;
    }

    if (instructionName === this.savePpApiInstruction.CREATE) {
      delete productMasterData[0].id;
      delete rawProductConfig.id;
      rawProductConfig.global.order = (this.pPComponentService.protectionProductsForm.get(
        "products"
      ) as FormArray).controls.length;
    }

    productConfigData.push(rawProductConfig);
    const saveData: ISaveProducts = {
      masterData: productMasterData,
      configurationData: productConfigData,
    };
    //console.log("saveData", saveData);
    this.protectionProductsState.dispatch(
      new protectionProductsActions.UpdateTfsProtectionProducts({
        dealerCode: this.pPComponentService.dealerCode,
        protectionProducts: saveData,
      })
    );

  }

  /* istanbul ignore next */
  onProductImageSelected(event: Event) {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      maxWidth: "90%",
      maxHeight: "90%",
      panelClass: "img-cropper-panel-class",
      data: { event, imageProperties: this.imageUploadProperties },
    });
    const afterClosedSub = dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === "confirm") {
        this.onProductImageReady(result.file as any);
      }
      (event.target as HTMLInputElement).value = "";
      afterClosedSub.unsubscribe();
    });
  }

  /* istanbul ignore next */
  onProductImageReady(file: File) {
    if (file) {
      if (!this.isImageType(file)) {
        this.snackBar.open(
          this.translateService.instant(
            "LOCALIZATION.PROTECTION_PRODUCTS.IMG_INVALID_TEXT"
          ),
          this.translateService.instant(this.localCommonDismiss),
          { duration: 4000 }
        );
        return;
      }
      if (file.size / 1024 > 5120) {
        this.snackBar.open(
          this.translateService.instant(
            "LOCALIZATION.PROTECTION_PRODUCTS.IMG_SIZE_EXCEEDS_TEXT"
          ),
          this.translateService.instant(this.localCommonDismiss),
          { duration: 4000 }
        );
      }
      if (typeof FileReader !== "undefined") {
        // reader for generating md5 hash
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileName = file.name;
          const md5hashCode = SparkMD5.ArrayBuffer.hash(e.target.result);
          const fileDetails: IGetPresignedUrlRequest = {
            fileName,
            md5hashCode,
          };
          this.pPComponentService.setImageUpdatingFlag(true);
          this.protectionProductsService
            .getPresignedUrl(this.pPComponentService.dealerCode, fileDetails)
            .pipe(take(1))
            .subscribe(
              (urlDetails: IGetPresignedUrlResponse) => {
                this.staticStorageService
                  .uploadFileToS3Md5(
                    urlDetails.url,
                    file,
                    urlDetails.md5hashCodeBuffer
                  )
                  .pipe(take(1))
                  .subscribe(
                    (t) => {
                      this.addProductImage(urlDetails.fileName);
                      this.snackBar.open(
                        this.translateService.instant(
                          "LOCALIZATION.PROTECTION_PRODUCTS.IMG_READY_SAVE"
                        ),
                        this.translateService.instant(this.localCommonDismiss),
                        { duration: 4000 }
                      );
                    },
                    (err) => {
                      this.removeProductImage();
                      this.pPComponentService.setImageUpdatingFlag(false);
                      this.snackBar.open(
                        this.translateService.instant(
                          "LOCALIZATION.PROTECTION_PRODUCTS.IMG_UNABLE_PROCESS"
                        ),
                        this.translateService.instant(this.localCommonDismiss),
                        { duration: 4000 }
                      );
                    },
                    () => {
                      this.pPComponentService.setImageUpdatingFlag(false);
                    }
                  );
              },
              (err) => {
                this.pPComponentService.setImageUpdatingFlag(false);
                this.removeProductImage();
                this.snackBar.open(
                  this.translateService.instant(
                    "LOCALIZATION.PROTECTION_PRODUCTS.IMG_UNABLE_PROCESS"
                  ),
                  this.translateService.instant(this.localCommonDismiss),
                  { duration: 4000 }
                );
              }
            );
        };
        reader.readAsArrayBuffer(file);

        // reader for displaying preview
        const urlReader = new FileReader();
        urlReader.onload = (e: any) => {
          this.productImageUrl = e.target.result;
        };
        urlReader.readAsDataURL(file);
      }
    }
  }

  isImageType(file: File) {
    return file.type.indexOf("image/") >= 0;
  }

  removeProductImage() {
    this.productImageUrl = "";
    const productImageUrlForm = this.productDetailsForm.get([
      "global",
      "imageUrl",
    ]);
    productImageUrlForm.patchValue(this.productImageUrl);
    productImageUrlForm.updateValueAndValidity();
  }

  addProductImage(imageUrl: string) {
    const productImageUrlForm = this.productDetailsForm.get([
      "global",
      "imageUrl",
    ]);
    productImageUrlForm.patchValue(imageUrl);
    productImageUrlForm.updateValueAndValidity();
  }
  /* istanbul ignore next */
  providerChange(provider: string) {
    const productTypes = this.productDetailsForm.get([
      "global",
      "productTypes",
    ]);
    productTypes.setValue("");
    this.pPComponentService.beginAddNewProduct(provider);
  }

  /* istanbul ignore next */
  productChange(product: string) {
    this.showDynamicFields = false;
    if (!product) {
      const provider = this.productDetailsForm.get(["global", "provider"])
        .value;
      this.pPComponentService.beginAddNewProduct(provider);
    } else {
      this.pPComponentService.updateNewProductForm(this.productDetailsForm);
    }
  }

  /* istanbul ignore next */
  showPacChangelogs() {
    this.protectionProductsState.dispatch(
      new protectionProductsActions.LoadPacChangelogs({
        dealerCode: this.pPComponentService.dealerCode,
        productName: this.getProductName(),
        providerId: this.pPComponentService.selectedProductData.providerId,
      })
    );
    this.displayPacChangelogs = true;
  }

  /* istanbul ignore next */
  getProductName(): string {
    const productConfig = this.pPComponentService.selectedProductConfig$.value;
    return productConfig.global.providerId ===
      this.pPComponentService.ownPpProviderId
      ? this.pPComponentService.selectedProductData.code
      : productConfig.global.productTypes;
  }

  /* istanbul ignore next */
  currentDateFilter = (d: DateTime): boolean => {
    const newPacAmount = this.productDetailsForm.get([
      "global",
      "pacManagement",
      "amount",
    ]).value;
    return (
      this.pPComponentService.currentDate <= d ||
      (this.initialPacAmount === newPacAmount &&
        (typeof this.initialPacEffectiveDate !== 'string' ?
          this.initialPacEffectiveDate?.toISODate() === d?.toISODate() : false))
    );
  };

  /* Code Controllers For NGPE-6849 VPP Gross Profit Pricing Begins */

  /**
   * NGPE-6849
   * This loads series(model) field based on "Make" field in
   * "Gross profit by series" form 
   * 
   * @returns void
   */

  onMakeChange(brand: string, salesClass?: string, offerType?: string, adjustment?: any, reset = true) {
    const index = (
      this.productDetailsForm.get(['global', 'gp', 'adjustments', salesClass, offerType]) as FormArray
    ).controls.findIndex((x) => _.isEqual(x, adjustment));
    if (reset) {
      (this.productDetailsForm.get(['global', 'gp', 'adjustments', salesClass, offerType]) as FormArray)
        .at(index)
        .get("series")
        .setValue("");
    }
    this.pPComponentService.checkAndfetchVehicleConfig(brand, this.vehicleConfigState);
  };

  /**
  * NGPE-6849
  * This create new adjustment row with required fields in
  * "Gross profit by series" form 
  * 
  * @returns void
  */

  addFieldValue(offerType: string, salesClass: string): void {
    const gpType = this.productDetailsForm.get(['global', 'gp', 'type']).value;
    const addAdjustmentRow = new FormGroup({
      make: new FormControl('Toyota'),
      series: new FormControl('', [Validators.required]),
      adjustment: new FormControl('', [Validators.required]),
      measure: new FormControl(gpType)
    }) as FormGroup;
    addAdjustmentRow.markAsDirty();
    this.pPComponentService.detectAdjustmentsFormChanges((this.productDetailsForm));
    addAdjustmentRow.setValidators(this.pPComponentService.validateAdjustmentForm(salesClass, offerType, (this.productDetailsForm.get(['global', 'gp']) as FormGroup)));
    const newAdjustmentFG = this.productDetailsForm.get(['global', 'gp', 'adjustments', salesClass, offerType]) as FormArray;
    newAdjustmentFG.push(addAdjustmentRow);
  }

  copyTo(event: Event, offerType: string, salesClass: string): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ProtectionProductCopyOfferComponent, {
      panelClass: ['copy-offers'],
      minWidth: '500px',
      disableClose: false,
      data: {
        offerType, salesClass
      }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && !_.isEmpty(data)) {
        const currentControl = this.productDetailsForm.get(['global', 'gp', 'adjustments', salesClass, offerType]) as FormArray;
        const currentValue = currentControl.value;

        for (const sales in data) {
          for (const offer in data[sales]) {
            if (data[sales][offer]) {
              const newAdjustmentFG = this.productDetailsForm.get(['global', 'gp', 'adjustments', sales, offer]) as FormArray;
              if (newAdjustmentFG) {
                newAdjustmentFG.clear();
              }
              let updatedValue = currentValue;
              if (sales !== 'used') {
                updatedValue = currentValue.filter(value => value.make !== Brands.ALL_NTL);
              }
              if (updatedValue.length > 0) {
                updatedValue.forEach(value => {
                  this.addFieldValue(offer, sales);
                });
                newAdjustmentFG.setValue(updatedValue);
              }
            }
          }
        }
      }
    });
  }

  /**
  * NGPE-6849
  * This validates fields in "Gross profit by series" form 
  * 
  * @returns void
  */

  validateFormGroup(group: FormGroup) {
    return (!group.valid && group.dirty) ? "inputBoxRed" : "inputBoxBlack";
  }

  /**
   * NGPE-6849
   * This deletes complete adjustment row in "Gross profit by series" form 
   * 
   * @returns void
   */

  deleteFieldValue(offerType: string, salesClass: string, adjustment: any): void {
    const index = (
      this.productDetailsForm.get(['global', 'gp', 'adjustments', salesClass, offerType]) as FormArray
    ).controls.findIndex((x) => _.isEqual(x, adjustment));
    (this.productDetailsForm.get(['global', 'gp', 'adjustments', salesClass, offerType]) as FormArray).removeAt(index);
  }

  /**
  * NGPE-6849
  * This changes AGP field and adjustment field sign in "Gross profit by series" form 
  * based on grossprofit type changes(%/$) 
  * @returns void
  */

  checkgptype(_salesClass: string, _offerType: string) {
    const gptypevalue = this.productDetailsForm.get(['global', 'gp']).value;
    const gptypeChange = (this.productDetailsForm.get(['global', 'gp']) as FormGroup);
    let currentTypeValue;
    if (gptypevalue) {
      currentTypeValue = gptypevalue.type;
      gptypeChange.valueChanges.subscribe((data) => {
        currentTypeValue = data.type;
        return currentTypeValue;
      });
    }
    return currentTypeValue;
  }

  /**
   * NGPE-6849
   * This calculates field "AdjustedGrossProfit" (not under formgroup)
   *  in "Gross profit by series" form 
   * 
   * @returns void
   */

  change_adjusted_gp(val: any, adjusmentGpVal: number, salesClass: string, offerType: string, adjustment: FormGroup, maxprice: number) {
    this.adjustedGP = val.target.value;
    const index = (
      this.productDetailsForm.get(['global', 'gp', 'adjustments', salesClass, offerType]) as FormArray
    ).controls.findIndex((x) => _.isEqual(x, adjustment));
    this.adjustmentValue = this.adjustedGP - adjusmentGpVal;
    this.productDetailsForm.get(['global', 'gp', 'value']).valueChanges.subscribe((data) => {
      this.adjustmentValue = this.adjustedGP - data;
      (this.productDetailsForm.get(['global', 'gp', 'adjustments', salesClass, offerType]) as FormArray)
        .at(index)
        .get("adjustment")
        .setValue((this.adjustmentValue));
    });
    (this.productDetailsForm.get(['global', 'gp', 'adjustments', salesClass, offerType]) as FormArray)
      .at(index)
      .get("adjustment")
      .setValue((this.adjustmentValue));
    if (val > maxprice) {
      this.productDetailsForm.setErrors({ 'invalid': true });
      this.productDetailsForm.updateValueAndValidity();
    }
    else {
      this.productDetailsForm.setErrors(null);
      this.productDetailsForm.updateValueAndValidity();
    }
  }

  /**
 * NGPE-6849
 * This detects changes in GPtype(%/$) , GPvalue and MAXprice in form
 * @returns void
 */

  detectGpChanges() {
    this.subs.changeGpTypeForm = this.productDetailsForm.get(['global', 'gp', 'type']).valueChanges.subscribe((data) => {
      if (data !== this.initialProductConfig.global.gp.type && !this.isDialogOpened) {
        this.changeGpMeasure('add', data);
      }
      else {
        setTimeout(() => {
          if (this.pPComponentService.selectedProductData.id === this.pPComponentService.oldProductCode) {
            this.changeGpMeasure('remove', data);
          }
        }, 0)
      }
    });
    this.subs.changeGpValueForm = this.productDetailsForm.get(['global', 'gp', 'value']).valueChanges.subscribe((_data) => {
      setTimeout(() => {
        this.pPComponentService.selectedProductConfig$.next(this.productDetailsForm.getRawValue());
      }, 0)
    });
    this.subs.changeMaxPriceForm = this.productDetailsForm.get(['global', 'maxPrice']).valueChanges.subscribe((_data) => {
      setTimeout(() => {
        this.pPComponentService.selectedProductConfig$.next(this.productDetailsForm.getRawValue());
      }, 0)
    });
  }

  /**
 * NGPE-6849
 * This triggers confirmation Popup on grossprofit type(%/$) changes
 * @returns void
 */

  changeGpMeasure(type: string, data: string) {
    this.isDialogOpened = true;
    if (this.dialog.openDialogs.length === 0) {
      this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: { popupTitle: this.translateService.instant('LOCALIZATION.PROTECTION_PRODUCTS.EDIT_CONFIRM_TITLE'), popupBodyText: this.translateService.instant('LOCALIZATION.PROTECTION_PRODUCTS.EDIT_CONFIRM_GP'), cancelButtonText: this.translateService.instant('LOCALIZATION.COMMON.NO'), confirmButtonText: this.translateService.instant('LOCALIZATION.COMMON.YES') },
        disableClose: true
      });
    }
    const afterClosedSub = this.dialogRef.afterClosed().pipe(distinctUntilChanged()).subscribe((result) => {
      if (result.action === 'confirm') {
        setTimeout(() => {
          this.pPComponentService.updateGpMeasure(type);
          this.pPComponentService.selectedProductConfig$.next(this.productDetailsForm.getRawValue());
        }, 0)
      }
      else {
        if (data === 'percent') {
          this.productDetailsForm.get(['global', 'gp', 'type']).setValue('amount', { emitEvent: false });
        }
        else {
          this.productDetailsForm.get(['global', 'gp', 'type']).setValue('percent', { emitEvent: false });
        }
      }
      this.isDialogOpened = false;
      afterClosedSub.unsubscribe();
    })
  }
  /* Code Controllers For NGPE-6849 VPP Gross Profit Pricing Ends */

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subs);
  }
}
