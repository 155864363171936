import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { TranslateModule } from "@ngx-translate/core";
import { GlobalModule } from "../../global/global.module";
import { LuxonDateAdapter, MAT_LUXON_DATE_FORMATS } from "../../shared/services/luxon-date-adapter";
import { SharedModule } from "../../shared/shared.module";
import { PEAuditRoutingModule } from "./pe-audit-routing.module";
import { PEAuditComponent } from "./pe-audit.component";

@NgModule({
  declarations: [
    PEAuditComponent
  ],
  imports: [
    PEAuditRoutingModule,
    TranslateModule.forChild({ extend: true }),
    CommonModule, GlobalModule, SharedModule
  ],
  providers: [
    LuxonDateAdapter,
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS }
  ]
})
export class PEAuditModule { }
