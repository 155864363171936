import { Action } from "@ngrx/store";
import { IDeploymentList } from "../../shared/models/deploy-job-management.model";
import { IGetAuditLogsResponse, IGetDJAuditLogsResponse } from "../../shared/models/audit-log.model";
import { IDJAuditLogs } from "../../shared/models/audit-log.model";
export enum DeployJobManageActionTypes {
  LOAD_DJ_MANAGEMENT = "[DeployJobManagement] Load Deploy Job Management",
  LOAD_DJ_MANAGEMENT_SUCCESS = "[DeployJobManagement] Load Deploy Job Management success",
  LOAD_DJ_MANAGEMENT_FAILURE = "[DeployJobManagement] Load Deploy Job Management failure",
  LOAD_DJ_CONFIGURATION = "[DeployJobManagement] Load Deploy Job Configuration",
  LOAD_DJ_CONFIGURATION_SUCCESS = "[DeployJobManagement] Load Deploy Job Configuration success",
  LOAD_DJ_CONFIGURATION_FAILURE = "[DeployJobManagement] Load Deploy Job Configuration failure",
  LOAD_DJ_AUDITLOG = "[DeployJobManagement] Load Audit Logs",
  LOAD_DJ_AUDITLOG_SUCCESS = "[DeployJobManagement] Load Audit Logs success",
  LOAD_DJ_AUDITLOG_FAILURE = "[DeployJobManagement] Load Audit Logs failure",
}

export class LoadDeployJobManage implements Action {
  readonly type = DeployJobManageActionTypes.LOAD_DJ_MANAGEMENT;
  constructor(public payload: { dealerCode: string }) { }
}

export class LoadDeployJobManageSuccess implements Action {
  readonly type = DeployJobManageActionTypes.LOAD_DJ_MANAGEMENT_SUCCESS;
  constructor(public payload: { deployJob: IDeploymentList }) { }
}

export class LoadDeployJobManageFailure implements Action {
  readonly type = DeployJobManageActionTypes.LOAD_DJ_MANAGEMENT_FAILURE;
  constructor(public payload: { error: string, deployJob: IDeploymentList }) { }
}

export class LoadAuditLog implements Action {
  readonly type = DeployJobManageActionTypes.LOAD_DJ_AUDITLOG;
  constructor(public payload: { dealerCode: string, deploymentId: string }) { }
}

export class LoadAuditLogSuccess implements Action {
  readonly type = DeployJobManageActionTypes.LOAD_DJ_AUDITLOG_SUCCESS;
  constructor(public payload: { auditLogs: IGetDJAuditLogsResponse }) { }
}

export class LoadAuditLogFailure implements Action {
  readonly type = DeployJobManageActionTypes.LOAD_DJ_AUDITLOG_FAILURE;
  constructor(public payload: { error: string, auditLogs: IGetDJAuditLogsResponse }) { }
}

export class LoadDeployJobConfig implements Action {
  readonly type = DeployJobManageActionTypes.LOAD_DJ_CONFIGURATION;
  constructor(public payload: { dealerCode: string, isReloadDJConfig?: boolean }) { }
}

export class LoadDeployJobConfigSuccess implements Action {
  readonly type = DeployJobManageActionTypes.LOAD_DJ_CONFIGURATION_SUCCESS;
  constructor(public payload: { deployJobConfig: IGetAuditLogsResponse }) { }
}

export class LoadDeployJobConfigFailure implements Action {
  readonly type = DeployJobManageActionTypes.LOAD_DJ_CONFIGURATION_FAILURE;
  constructor(public payload: { error: string, deployJobConfig: IGetAuditLogsResponse }) { }
}

export type DeployJobManageActions =
  | LoadDeployJobManage
  | LoadDeployJobManageSuccess
  | LoadDeployJobManageFailure
  | LoadDeployJobConfig
  | LoadAuditLog
  | LoadAuditLogSuccess
  | LoadAuditLogFailure
  | LoadDeployJobConfigSuccess
  | LoadDeployJobConfigFailure;
