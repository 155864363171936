import { Pipe, PipeTransform } from "@angular/core";
import { IncentivesComponentService } from "../incentives.component.service";
import { IncentiveStatus } from "../../../shared/enums/incentive-status.enum";

@Pipe({
    name: "getincentivestatus"
})
export class GetIncentiveStatusPipe implements PipeTransform {

    constructor(
        private readonly incentiveService: IncentivesComponentService
    ) { }

    transform(endDateString: string, status: string): string {
        if (status === IncentiveStatus.DRAFT) {
            return status;
        }
        const endDate = this.incentiveService.getLuxonUTCDate(endDateString);
        const expired = endDate.diff(this.incentiveService.currentDate, ['days']).days < -1;
        return expired ? IncentiveStatus.EXPIRED : status;
    }

}