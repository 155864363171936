import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { IProductConfiguration } from '../../../shared/models/protection-products.model';

@Pipe({
    name: 'hasexcludedplans'
})
export class HasExcludedPlansPipe implements PipeTransform {

    transform(productConfig: IProductConfiguration): boolean {
        return _.some(productConfig?.global?.plans || [], { includePlan: false });
    }

}
