<div class="pos-relative">
    <div class="title-span">
        <span class="fw-bold">
            {{'LOCALIZATION.CAMPAIGNS.RESIDUAL_TABLE' | translate}}
            ({{'LOCALIZATION.CAMPAIGNS.IN_PER' | translate}})
        </span>
        <span class="note-span">
            {{'LOCALIZATION.CAMPAIGNS.DEFAULT_MILEAGE_NOTE' | translate : ({"mileage": defaultMileage$ | async})}}
        </span>
    </div>
    <div class="d-flex flex-column residual-table-container">
        <div>
            <table class="table w-100" aria-describedby="rate-table">
                <thead>
                    <tr>
                        <th scope="col" class="minw-100px">{{'LOCALIZATION.CAMPAIGNS.MODEL_CODE' | translate}}</th>
                        <th scope="col" class="minw-100px">{{'LOCALIZATION.CAMPAIGNS.MODEL_YEAR' | translate}}</th>
                        <th scope="col" class="minw-100px">{{'LOCALIZATION.CAMPAIGNS.SALES_CLASS' | translate}}</th>
                        <ng-container *ngFor="let variance of (residuals$ | async) | getresidualvariances">
                            <th scope="col" class="minw-100px" *ngIf="variance !== (defaultMileage$ | async)">
                                {{(variance | shortNumber) + ' ' +
                                (variance > (defaultMileage$ | async) ? 'HMR' : 'LMB')}}
                            </th>
                        </ng-container>
                        <th scope="col" class="fs-14px minw-100px"
                            *ngFor="let term of (residuals$ | async) | getresidualterms">
                            {{term}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let residual of (residuals$ | async), last as lastRow"
                        [ngClass]="{'border-bottom': !lastRow}">
                        <td>{{residual.modelCode}}</td>
                        <td>{{residual.modelYear}}</td>
                        <td>{{residual.salesClass | titlecase}}</td>
                        <ng-container *ngFor="let variance of (residuals$ | async) | getresidualvariances">
                            <td *ngIf="variance !== (defaultMileage$ | async)">
                                {{variance | getvariancevalue : residual}}
                            </td>
                        </ng-container>
                        <td *ngFor="let term of (residuals$ | async) | getresidualterms">
                            {{term | getresidualforterm : residual}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>