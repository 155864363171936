import { UserProfileService } from "@toyota/dd365-platform-library";
import * as _ from "lodash";
import {
  IDealerConfiguration,
  IMaxStateApr,
  IPaymentGridConfig,
  IVehiclePacConfig,
} from "../../shared/models/dealer-configuration.model";
import { DealerService } from "../../../../app/service/dealers/dealer.service";
import { IPPCompatibility, IPPCompatibilityMutexMatrix } from "../../shared/models/bank-configuration.model";

export function mapDealerConfiguration(
  dealerConfiguration: IDealerConfiguration
): IDealerConfiguration {
  const masterData = { ...dealerConfiguration };

  return masterData;
}

export function loadInitiateState(
  userProfileService: UserProfileService,
  dealerService: DealerService
) {
  const user = userProfileService.getProfile();
  const financeSourceName = dealerService.getFinanceSource();
  const response: IDealerConfiguration = {
    policy: {
      global: {
        financeSource: [
          {
            name: financeSourceName,
            daysToFirstPayment: 0,
            markups: [],
            rates: [],
          },
        ],
        fees: [],
        parameters: [],
      },
      states: {},
    },
    dealerDefinedCampaigns: [],
    daInfo: [
      {
        make: user.dealer.brand.name.toLocaleLowerCase().toString(),
        distributor: financeSourceName,
        code: user.dealer.tdaCode,
      },
    ],
    ppProviders: [],
    canarySettings: [],
    enableExcludeFromOutput: false,
    lender: [],
    code: user.dealer.code,
    address: {
      zipCode: user.dealer.address.zip,
      city: user.dealer.address.city,
      state: user.dealer.address.state,
    },
    region: user.dealer.region.code,
    name: user.dealer.name,
    maxStateApr: {} as IMaxStateApr,
    paymentGridCustomization: {} as IPaymentGridConfig,
    vehiclePac: {} as IVehiclePacConfig
  };
  return response;
}

export function denormalizePPCompatibilityMatrix(ppCompatibility: IPPCompatibility[]): IPPCompatibilityMutexMatrix[] {
  const ppMutexMatrix: IPPCompatibilityMutexMatrix[] = [];
  _.forEach(ppCompatibility, (product) =>
    _.forEach(product.forms, (form) =>
      _.forEach(form.plans, (plan) =>
        ppMutexMatrix.push({
          productId: product.productId,
          formId: form.formId,
          planId: plan.planId,
          preference: plan.preference,
          mutexPlans: plan.mutexPlans
        })
      )
    )
  );
  return ppMutexMatrix;
}