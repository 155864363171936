import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserProfileService } from "@toyota/dd365-platform-library";
import * as uuid from "uuid";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { IDealerConfiguration } from "../../../app/components/shared/models/dealer-configuration.model";
import { IDealerDefinedCampaign } from "../../components/shared/models/dealer-defined-campaigns.model";
import { FinanceSources } from "../../../app/models/enums";
import { DealerConfigVersion } from "../../components/shared/enums/dc-version.enum";
@Injectable({
  providedIn: "root",
})
export class DealerService {
  private readonly GET_ALL_DEALERS_API: string =
    environment.get_all_dealers_api;
  private readonly GET_DEALER_BY_ID_API: string =
    environment.get_dealer_by_id_api;
  private readonly GET_DEALER_CONFIGURATION_API: string =
    environment.get_dealer_configuration_api;
  private readonly GET_ALL_DEALER_CAMPAIGNS_API: string =
    environment.dealer_configuration_api;
  private readonly GET_BUILDER_API: string = environment.get_builder_api;
  private readonly GET_PE_CALC_API: string = environment.get_calc_api;
  private readonly GET_JMA_DETAILS_API: string =
    environment.get_product_offerings;
  private readonly GET_VEHICLE_DETAILS_DD365_API: string =
    environment.rti_new_vehicle_search_api;
  dealerCode: string;
  nameOfFeeDetail: any;
  TittleData: any;
  productTC: any;
  applyTaxFetchFromJMA: boolean = false;
  existedResidualOverridenValues: any;
  httpOptions = {
    headers: new HttpHeaders(),
  };

  /**
   * Constructor
   *
   * @returns void
   */
  constructor(
    private readonly http: HttpClient,
    private readonly _userProfileService: UserProfileService
  ) { }

  setHeaderOptions() {
    this.httpOptions.headers = new HttpHeaders({});
    this.httpOptions.headers = this.httpOptions.headers
      .set(
        "Authorization",
        `Bearer ${this._userProfileService.getProfile().token}`
      )
      .set("x-subscriber-code", environment.x_subscriber_code)
      .set("x-transaction-id", uuid.v4());
  }

  setHeaderOptionsDD365() {
    this.httpOptions.headers = new HttpHeaders({});
    this.httpOptions.headers = this.httpOptions.headers
      .set(
        "Authorization",
        `Bearer ${this._userProfileService.getProfile().token}`
      )
      .set("x-b3-spanid", environment.x_subscriber_code)
      .set("x-b3-traceid", uuid.v4());
  }

  /**
   * To get all dealers
   *
   * @returns response
   */
  getDealers() {
    return this.http.get(this.GET_ALL_DEALERS_API, {
      headers: {
        "x-api-key": "arWxuE2IUp3tMlsphQmHr5vE1rh8UFSc54z879eh",
      },
    });
  }

  /**
   * To get dealer info by dealer code (id)
   *
   * @returns response
   */
  getDealerById() {
    this.dealerCode = this._userProfileService.getProfile().dealerCd;
    return this.http.get(this.GET_DEALER_BY_ID_API + this.dealerCode, {
      headers: {
        "x-api-key": "arWxuE2IUp3tMlsphQmHr5vE1rh8UFSc54z879eh",
      },
    });
  }

  /**
   * To get dealer configuration with dealerCode
   *
   * @returns response
   */
  getDealerConfigurationPipe(dealerCode: string, version: DealerConfigVersion) {
    return this.http
      .get(`${this.GET_DEALER_CONFIGURATION_API}${dealerCode}?version=${version}`, {
        headers: {
          Authorization:
            `Bearer ${this._userProfileService.getProfile().token}`,
          "x-subscriber-code": environment.x_subscriber_code,
          "x-transaction-id": uuid.v4(),
        },
      })
      .pipe(
        map((data: IDealerConfiguration) => {
          return data;
        })
      );
  }

  /**
   * To get dealer configuration with dealerCode
   *
   * @returns response
   */
  getDealerConfiguration(dealerCode) {
    return this.http.get(this.GET_DEALER_CONFIGURATION_API + dealerCode, {
      headers: {
        Authorization: "Bearer " + this._userProfileService.getProfile().token,
        "x-subscriber-code": environment.x_subscriber_code,
        "x-transaction-id": uuid.v4(),
      },
    });
  }

  getJMADetails() {
    this.dealerCode = this._userProfileService.getProfile().dealerCd;
    return this.http.get(
      this.GET_DEALER_CONFIGURATION_API +
      this.dealerCode +
      "/" +
      "productOfferings",
      {
        headers: {
          Authorization:
            "Bearer " + this._userProfileService.getProfile().token,
          "x-subscriber-code": environment.x_subscriber_code,
          "x-transaction-id": uuid.v4(),
        },
      }
    );
  }

  getTFSDetails(offerType, saleClass) {
    return this.http.get(
      this.GET_DEALER_CONFIGURATION_API +
      this.dealerCode +
      "/" +
      "productOfferings" +
      "?dealerType=tfs&offerType=" +
      offerType +
      "&saleClass=" +
      saleClass,
      {
        headers: {
          Authorization:
            "Bearer " + this._userProfileService.getProfile().token,
          "x-subscriber-code": environment.x_subscriber_code,
          "x-transaction-id": uuid.v4(),
        },
      }
    );
  }

  getJMARetrieveAll() {
    this.dealerCode = this._userProfileService.getProfile().dealerCd;
    return this.http.get(
      this.GET_DEALER_CONFIGURATION_API +
      this.dealerCode +
      "/" +
      "protectionProducts",
      {
        headers: {
          Authorization:
            "Bearer " + this._userProfileService.getProfile().token,
          "x-subscriber-code": environment.x_subscriber_code,
          "x-transaction-id": uuid.v4(),
        },
      }
    );
  }

  /**
   *
   * @param body
   * @returns
   */
  addOrUpdateJMAProtectionProducts(body) {
    this.dealerCode = this._userProfileService.getProfile().dealerCd;
    return this.http.post(
      this.GET_DEALER_CONFIGURATION_API +
      this.dealerCode +
      "/protectionProducts/",
      body,
      {
        headers: {
          Authorization:
            "Bearer " + this._userProfileService.getProfile().token,
          "x-subscriber-code": environment.x_subscriber_code,
          "x-transaction-id": uuid.v4(),
        },
      }
    );
  }

  /**
   * To create or update the dealer configuration with dealerCode
   *
   * @returns response
   */
  createOrUpdateDealerConfiguration(dealerCode: string, body: any) {
    return this.http.post(
      this.GET_DEALER_CONFIGURATION_API + dealerCode,
      body,
      {
        headers: {
          Authorization:
            "Bearer " + this._userProfileService.getProfile().token,
          "x-subscriber-code": environment.x_subscriber_code,
          "x-transaction-id": uuid.v4(),
        },
      }
    );
  }

  /**
   *
   * @param body
   * @returns
   */
  getQuoteFromBuilder(body: any) {
    this.setHeaderOptions();
    return this.http.post(this.GET_BUILDER_API, body, this.httpOptions);
  }
  /**
   *
   * @param body
   * @returns
   */
  getVehicleDetailsFromDD365(body: any) {
    this.setHeaderOptionsDD365();
    return this.http.get(
      this.GET_VEHICLE_DETAILS_DD365_API + "/" + body + "?isInquiry=true",
      this.httpOptions
    );
  }

  /**
   *
   * @param offerType
   * @param body
   * @returns
   */
  getDealFromCalculator(offerType: string, body: any) {
    this.setHeaderOptions();
    return this.http.post(
      this.GET_PE_CALC_API + offerType,
      body,
      this.httpOptions
    );
  }

  getFinanceSource(): string {
    const regNo = parseInt(
      this._userProfileService.getProfile().dealer.region.code
    );
    let financeSource;
    if (regNo >= FinanceSources.MIN_SET && regNo <= FinanceSources.MAX_SET) {
      financeSource = FinanceSources.SET;
    } else if (
      regNo >= FinanceSources.MIN_GST &&
      regNo <= FinanceSources.MAX_GST
    ) {
      financeSource = FinanceSources.GST;
    } else if (
      regNo >= FinanceSources.MIN_LEXUS &&
      regNo <= FinanceSources.MAX_LEXUS
    ) {
      financeSource = FinanceSources.LEXUS;
    } else if (
      regNo === FinanceSources.TMNA_ADDITIONAL_REGION ||
      (regNo >= FinanceSources.MIN_TMNA &&
        regNo <= FinanceSources.MAX_TMNA)
    ) {
      financeSource = FinanceSources.TMNA;
    }
    return financeSource;
  }

  getFinanceSourceOrg(): string {
    const regNo = parseInt(
      this._userProfileService.getProfile().dealer.region.code
    );
    let org;
    if (regNo >= FinanceSources.MIN_SET && regNo <= FinanceSources.MAX_SET) {
      org = FinanceSources.SET_ORG;
    } else if (
      regNo >= FinanceSources.MIN_GST &&
      regNo <= FinanceSources.MAX_GST
    ) {
      org = FinanceSources.GST_ORG;
    } else if (
      regNo >= FinanceSources.MIN_LEXUS &&
      regNo <= FinanceSources.MAX_LEXUS
    ) {
      org = FinanceSources.LEXUS_ORG;
    } else if (
      regNo >= FinanceSources.MIN_TMNA &&
      regNo <= FinanceSources.MAX_TMNA
    ) {
      org = FinanceSources.TMNA_ORG;
    }
    return org;
  }

  /**Dealer Defined Campaigns Service Starts */

  getDealerDefinedCampaignsConfigurationPipe(dealerCode: string) {
    return this.http
      .get(
        this.GET_ALL_DEALER_CAMPAIGNS_API +
        "campaigns?dealerCode=" +
        dealerCode,
        {
          headers: {
            Authorization:
              "Bearer " + this._userProfileService.getProfile().token,
            "x-subscriber-code": environment.x_subscriber_code,
            "x-transaction-id": uuid.v4(),
          },
        }
      )
      .pipe(
        map((data: IDealerDefinedCampaign[]) => {
          return data;
        })
      );
  }

  /**
   * To create or update the dealer configuration dd campaigns with dealerCode
   *
   * @returns response
   */
  createOrUpdateDdCampaigns(
    dealerCode: string,
    ddCampaignId: any,
    body: IDealerDefinedCampaign
  ) {
    let params = "";
    if (ddCampaignId === -1) {
      //ADD
      params = "campaigns";
    } else {
      params = "campaigns?" + ddCampaignId;
    }
    return this.http.post(this.GET_ALL_DEALER_CAMPAIGNS_API + params, body, {
      headers: {
        Authorization: "Bearer " + this._userProfileService.getProfile().token,
        "x-subscriber-code": environment.x_subscriber_code,
        "x-transaction-id": uuid.v4(),
      },
    });
  }
  /**Dealer Defined Campaigns Service Ends */

  /**
   * Delete dd campaigns
   *
   * @returns response
   */

  deleteDdCampaign(dealerCode: string, ddCampaignId: any) {
    return this.http.delete(
      this.GET_ALL_DEALER_CAMPAIGNS_API + "campaigns/" + ddCampaignId,
      {
        headers: {
          Authorization:
            "Bearer " + this._userProfileService.getProfile().token,
          "x-subscriber-code": environment.x_subscriber_code,
          "x-transaction-id": uuid.v4(),
        },
      }
    );
  }
}
