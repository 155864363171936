import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
import { IGetModelsForMake } from "../models/nhtsa/nhtsa.model";

@Injectable({
    providedIn: "root",
})
export class NHTSAVehicleService {
    private readonly NHTSA_VEHICLE_API: string = environment.vpic_nhtsa_vehicle_api;

    constructor(private readonly http: HttpClient) { }

    getModelsForMake(brand: string) {
        return this.http.get(`${this.NHTSA_VEHICLE_API}getmodelsformake/${brand}?format=json`)
            .pipe(
                map((data: IGetModelsForMake) => {
                    return data.Results;
                })
            );
    }

}
