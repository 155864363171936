<div [formGroup]="offerDefaultFormGroup" class="offset-lg-2 col-lg-8 offer-default mt-3" *ngIf="offerDefaultFormGroup">
    <mat-label class="d-flex mb-3">{{'LOCALIZATION.PAYMENT_GRID_GLOBAL.HEADER_NOTE' | translate}}</mat-label>
    <mat-label class="default-terms-header">{{'LOCALIZATION.PAYMENT_GRID_GLOBAL.HEADER' | translate}}</mat-label>
    <div *ngFor="let salesClass of configurableSalesClasses, last as lastRow" class="bodyDiv container-fluid"
        [ngClass]="{'mb-0': lastRow}" [formGroupName]="salesClass">
        <mat-label class="bodyDivHeader">{{salesClass | titlecase}}</mat-label>
        <div [formGroupName]="offer" class="d-flex" *ngFor="let offer of salesClass | offersforsalesclass">
            <div class="d-flex flex-column">
                <mat-label class="my-2 fw-bold required-val w-375-px">
                    {{'LOCALIZATION.PAYMENT_GRID_GLOBAL.SELECT_TERMS' | translate:({"offerType": offer | titlecase})}}
                </mat-label>
                <mat-select class="module-dropdown" multiple formControlName="terms"
                    [ngClass]="{'input-error': offerDefaultFormGroup.controls[salesClass].controls[offer].controls.terms.invalid}"
                    ngDefaultControl>
                    <mat-select-trigger class="ml-1">
                        {{'LOCALIZATION.COMMON.SELECT' | translate | lowercase}}
                    </mat-select-trigger>
                    <mat-option *ngFor="let term of offerTerms[salesClass][offer]" [value]="term"
                        [disabled]="offerDefaultFormGroup.controls[salesClass].controls[offer].controls.terms.value.length | disableselectoption : term : offerDefaultFormGroup.controls[salesClass].controls[offer].controls.terms : 2"
                        [title]="(offerDefaultFormGroup.controls[salesClass].controls[offer].controls.terms.value.length | disableselectoption : term : offerDefaultFormGroup.controls[salesClass].controls[offer].controls.terms: 2) ?
                        translateService.instant('LOCALIZATION.PAYMENT_GRID_GLOBAL.MAX_TERMS_REACHED') : ''">
                        {{term}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="d-flex flex-column ml-2"
                *ngIf="offerDefaultFormGroup.controls[salesClass].controls[offer].controls.terms.value.length">
                <mat-label class="my-auto fw-bold mx-2 required-val">
                    {{'LOCALIZATION.PAYMENT_GRID_GLOBAL.SELECT_FEATURED_TERM' | translate:({"offerType": offer |
                    titlecase})}}
                </mat-label>
                <mat-button-toggle-group class="ml-2 mr-auto" formControlName="startTerm"
                    [ngClass]="{'input-error': offerDefaultFormGroup.controls[salesClass].controls[offer].controls.startTerm.invalid}"
                    ngDefaultControl>
                    <mat-button-toggle
                        *ngFor="let term of offerDefaultFormGroup.controls[salesClass].controls[offer].controls.terms.value"
                        [value]="term">{{term}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="d-flex" *ngIf="paymentGridConfig && salesClass !== salesClasses.USED">
            <div class="d-flex flex-column">
                <mat-label class="my-2 fw-bold required-val w-375-px">
                    {{'LOCALIZATION.PAYMENT_GRID_GLOBAL.SELECT_MILEAGE_OPTIONS' | translate }}
                </mat-label>
                <mat-select class="module-dropdown" multiple formControlName="defaultMileageOptions"
                    [ngClass]="{'input-error': offerDefaultFormGroup.controls[salesClass].controls['defaultMileageOptions'].invalid}"
                    ngDefaultControl>
                    <mat-select-trigger class="ml-1">
                        {{'LOCALIZATION.COMMON.SELECT' | translate | lowercase}}
                    </mat-select-trigger>
                    <mat-option *ngFor="let mileageOption of paymentGridConfig.defaultMileageOptions"
                        [value]="mileageOption"
                        [disabled]="offerDefaultFormGroup.controls[salesClass].controls['defaultMileageOptions'].value.length | disableselectoption : mileageOption : offerDefaultFormGroup.controls[salesClass].controls['defaultMileageOptions'] : 3"
                        [title]="(offerDefaultFormGroup.controls[salesClass].controls['defaultMileageOptions'].value.length | disableselectoption : mileageOption : offerDefaultFormGroup.controls[salesClass].controls['defaultMileageOptions']: 3) ?
                        translateService.instant('LOCALIZATION.PAYMENT_GRID_GLOBAL.MAX_MILEAGE_REACHED') : ''">
                        {{mileageOption}} </mat-option>
                </mat-select>
            </div>
            <div class=" d-flex flex-column ml-2"
                *ngIf="offerDefaultFormGroup.controls[salesClass].controls['defaultMileageOptions'].value.length">
                <mat-label class="my-auto fw-bold mx-2 required-val">
                    {{'LOCALIZATION.PAYMENT_GRID_GLOBAL.SELECT_FEATURED_MILEAGE' | translate }}
                </mat-label>
                <mat-button-toggle-group class="ml-2 mr-auto" formControlName="defaultMileage"
                    [ngClass]="{'input-error': offerDefaultFormGroup.controls[salesClass].controls['defaultMileage'].invalid}"
                    ngDefaultControl>
                    <mat-button-toggle
                        *ngFor="let mileageOption of offerDefaultFormGroup.controls[salesClass].controls['defaultMileageOptions'].value"
                        [value]="mileageOption">{{mileageOption}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
    <div class="d-flex mb-2" *ngIf="!(disableActions$ | async)">
        <input type="button" class="secondary-button mt-3" (click)="resetDefaultTerms()"
            [value]="translateService.instant('LOCALIZATION.COMMON.RESET')">
        <input type="button" class="primary-button mt-3 ml-3" [disabled]="pgformValidEvent == 'INVALID'"
            (click)="saveDefaultTerms()" [value]="translateService.instant('LOCALIZATION.COMMON.SAVE')" />
        <p class="mt-3 ml-1">
            {{'LOCALIZATION.PAYMENT_GRID.INVALID_CONFIG' | translate}}
        </p>
    </div>
    <div class="d-flex mb-1">
        <p class="mt-1 ml-1">
            <b style="color: red;">*</b> indicates a required field
        </p>
    </div>
</div>