import { IFieldItem, IProductConfiguration } from '../../../shared/models/protection-products.model';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ProtectionProductsComponentService } from '../protection-products.component.service';
import { getDynamicFieldsForProduct } from '../../../store/protection-products/protection-products.adapter';

@Pipe({
    name: 'getorupdateproductform'
})
export class GetOrUpdateProductFormPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(productTypes: string, provider: string, productConfig: IProductConfiguration): IFieldItem[] {
        const dynamicFields = getDynamicFieldsForProduct(this.pPComponentService.dynamicFieldsMasterData, this.pPComponentService.pennProviders, provider, productTypes);
        return _.filter(dynamicFields, (field) => typeof (productConfig.global[field.fieldId]) === 'undefined');
    }
}
