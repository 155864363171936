import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CampaignStatus } from '../../../shared/enums/campaign-status.enum';
import { IDealerDefinedCampaign } from '../../../shared/models/dealer-defined-campaigns.model';
import { ModifyCampaignComponent } from '../modify-campaign/modify-campaign.component';
import { DealerDefinedCampaignsState } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.reducer';
import * as dealerDefinedCampaignsActions from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.actions';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { DealerDefinedCampaignsComponentService } from '../dealer-defined-campaigns.component.service';
import { ModifyRateRuleComponent } from '../modify-rate-rule/modify-rate-rule.component';
import { FormControl } from '@angular/forms';
import { SubscriptionList, unsubscribeSubscriptions } from '../../../shared/services/util.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GenericActions } from '../../../shared/enums/operation-actions.enum';
import { Observable, of } from 'rxjs';
import { DealerConfigurationState } from '../../../store/dealer-configuration/dealer-configuration.reducer';
import { selectDisableActions } from '../../../store/dealer-configuration/dealer-configuration.selectors';

@Component({
  selector: 'app-pe-view-campaign',
  templateUrl: './view-campaign.component.html',
  styleUrls: ['./view-campaign.component.scss']
})
export class ViewCampaignComponent implements OnInit, OnDestroy {

  campaignStatus = CampaignStatus;
  campaignStatusToggle: FormControl;
  private readonly subs: SubscriptionList = {};
  disableActions$: Observable<boolean>;

  @Input() campaign: IDealerDefinedCampaign;

  constructor(
    private readonly dealerDefinedCampaignsState: Store<DealerDefinedCampaignsState>,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly dDCCService: DealerDefinedCampaignsComponentService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.disableActions$ = of(false);// this.dealerConfigurationState.select(selectDisableActions);
    this.subs.selectDisableActionsSub = this.disableActions$.subscribe(disableActions => {
      if (disableActions) {
        this.campaignStatusToggle.disable({ emitEvent: false });
      }
    });
    this.setCampaignStatusChange();
  }

  editCampaign() {
    this.dialog.open(ModifyCampaignComponent, {
      panelClass: ['modify-campaign-panel'],
      minWidth: '64%',
      disableClose: true,
      data: {
        action: GenericActions.UPDATE,
        campaign: this.campaign
      }
    });
  }

  cloneCampaign() {
    this.dialog.open(ModifyCampaignComponent, {
      panelClass: ['modify-campaign-panel'],
      minWidth: '64%',
      disableClose: true,
      data: {
        action: GenericActions.CLONE,
        campaign: {
          ..._.cloneDeep(this.campaign), id: uuid.v4(), title: '',
          startDate: null, endDate: null, status: this.campaignStatus.DRAFT
        }
      }
    });
  }

  deleteCampaignConfirmed() {
    this.dealerDefinedCampaignsState.dispatch(
      new dealerDefinedCampaignsActions.DeleteDealerDefinedCampaign({ campaignId: this.campaign.id, dealerCode: this.campaign.dealerCode })
    );
  }

  /* istanbul ignore next */
  deleteCampaign() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        popupBodyText: this.translateService.instant(
          'LOCALIZATION.CAMPAIGNS.DELETE_CONFIRM_TEXT'
        ),
        confirmButtonText: this.translateService.instant(
          'LOCALIZATION.COMMON.DELETE'
        ),
      },
    });
    const afterClosedSub = dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === 'confirm') {
        this.deleteCampaignConfirmed();
      }
      afterClosedSub.unsubscribe();
    });
  }

  addRateRule() {
    const lenderOffers = this.dDCCService.getLenderOffers(this.campaign.lenderId);
    this.dialog.open(ModifyRateRuleComponent, {
      panelClass: ['modify-rate-rule-panel'],
      disableClose: true,
      data: {
        action: GenericActions.ADD,
        campaign: this.campaign,
        rateRule: this.dDCCService.buildDraftRateRule(_.first(lenderOffers) || ''),
        ruleIndex: this.campaign.rateRules.length
      }
    });
  }

  /* istanbul ignore next */
  setCampaignStatusChange() {
    this.campaignStatusToggle = new FormControl(this.campaign.status === this.campaignStatus.ACTIVE);
    this.subs.campaignStatusToggleChange = this.campaignStatusToggle.valueChanges.subscribe(active => {
      const campaign = _.cloneDeep(this.campaign);
      if (active) {
        const startDate = this.dDCCService.getLuxonUTCDate(this.campaign.startDate);
        const endDate = this.dDCCService.getLuxonUTCDate(this.campaign.endDate);
        if (
          this.campaign.status !== this.campaignStatus.DRAFT ||
          (this.dDCCService.currentAndFutureDateCheck(startDate) &&
            this.dDCCService.currentAndFutureDateCheck(endDate) &&
            !this.dDCCService.checkInvalidStartAndEndDate(startDate, endDate) &&
            this.campaign.rateRules.length > 0)
        ) {
          const conflictingCampaigns = this.dDCCService.findConflictingCampaigns(this.campaign);
          if (conflictingCampaigns.length > 0) {
            this.snackBar.open(
              this.translateService.instant(
                'LOCALIZATION.CAMPAIGNS.DUPLICATE_CAMPAIGN_ALERT',
                { campaigns: conflictingCampaigns.reduce((prev, current, i) => prev.concat(`${i + 1}. ${current}\n`), '') }
              ),
              this.translateService.instant('LOCALIZATION.COMMON.DISMISS'),
              { duration: 4000, panelClass: ['translated-newline'] }
            );
            setTimeout(() => {
              this.campaignStatusToggle.patchValue(false, { emitEvent: false });
            }, 0);
            return;
          }
          campaign.status = this.campaignStatus.ACTIVE;
        }
        else {
          const errorType = this.campaign.rateRules.length === 0 ?
            'UNABLE_TO_ACTIVATE_NO_RULES' : 'UNABLE_TO_ACTIVATE_PREDATED';
          this.snackBar.open(
            this.translateService.instant(`LOCALIZATION.CAMPAIGNS.${errorType}`),
            this.translateService.instant('LOCALIZATION.COMMON.DISMISS'),
            { duration: 4000 }
          );
          setTimeout(() => {
            this.campaignStatusToggle.patchValue(false, { emitEvent: false });
          }, 0);
          return;
        }
      } else {
        campaign.status = this.campaignStatus.INACTIVE;
      }
      this.updateCampaignStatus(active, campaign);
    });
  }

  updateCampaignConfirmed(campaign: IDealerDefinedCampaign) {
    this.dealerDefinedCampaignsState.dispatch(
      new dealerDefinedCampaignsActions.UpdateDealerDefinedCampaign({ campaign })
    );
  }

  /* istanbul ignore next */
  updateCampaignStatus(active: boolean, campaign: IDealerDefinedCampaign) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        popupBodyText: this.translateService.instant(
          `LOCALIZATION.CAMPAIGNS.${active ? 'ACTIVATE_CONFIRM' : 'DEACTIVATE_CONFIRM'}`),
        confirmButtonText: this.translateService.instant('LOCALIZATION.COMMON.YES'),
      },
    });
    const afterClosedSub = dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === 'confirm') {
        this.updateCampaignConfirmed(campaign);
      }
      else {
        this.campaignStatusToggle.patchValue(!active, { emitEvent: false });
      }
      afterClosedSub.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

}
