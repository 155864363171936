import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { ILenderInfo } from "../../../shared/models/dealer-configuration.model";

@Pipe({
    name: "getlenderoffertypes"
})
export class GetLenderOfferTypesPipe implements PipeTransform {

    transform(id: string, lenderList: ILenderInfo[]): string[] {
        return _.find(lenderList, { id })?.offerType || [];
    }

}