import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { IDealerDefinedCampaign } from "../../../shared/models/dealer-defined-campaigns.model";
import { DealerDefinedCampaignsComponentService } from "../dealer-defined-campaigns.component.service";

@Pipe({
    name: "getsalesclassesforcampaign"
})
export class GetSalesclassesForCampaignPipe implements PipeTransform {

    constructor(private readonly dDCCService: DealerDefinedCampaignsComponentService) { }

    transform(campaign: IDealerDefinedCampaign): string[] {
        return this.dDCCService.getSalesClassForCampaign(campaign.make, campaign.year);
    }

}