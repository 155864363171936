import { Pipe, PipeTransform, Inject } from "@angular/core";
import * as _ from 'lodash';
import { IBrand } from '../../../shared/models/brand.model';
import { Brands } from '../../../shared/enums/brands.enum';
import { GenericActions } from '../../../shared/enums/operation-actions.enum';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IIncentive } from '../../../shared/models/incentives.model';
import { GenericSelections } from '../../../shared/enums/generic-selections.enum';
@Pipe({
    name: "filterbrandlist"
})
export class FilterBrandListPipe implements PipeTransform {
    rtiBrands: string[] = [Brands.TOYOTA, Brands.LEXUS];
    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: { action: GenericActions, incentive: IIncentive }
    ) {
    }
    transform(brandList: IBrand[]): IBrand[] {
        if (this.data.action === GenericActions.CLONE &&
            _.includes(this.data.incentive.model, GenericSelections.ALL) &&
            _.includes(this.rtiBrands, this.data.incentive.make)) {
            brandList = brandList.filter(make => _.includes(this.rtiBrands, make.id));
        }
        return brandList;
    }

}