import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { RouterModule } from '@angular/router';
import { ddSpinner } from './dd-spinner/dd-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../shared/modules/material-module';
import { CheckPendingJobsPipe } from './main-nav/pipes/check-pending-jobs.pipe';

@NgModule({
  declarations: [SideNavComponent, MainNavComponent, ddSpinner, CheckPendingJobsPipe],
  exports: [SideNavComponent, MainNavComponent, ddSpinner, CheckPendingJobsPipe],
  imports: [
    TranslateModule.forChild({ extend: true }),
    CommonModule,
    RouterModule,
    MaterialModule
  ],
})
export class GlobalModule { }
