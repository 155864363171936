import { Component, Input, OnInit } from '@angular/core';
import { IDealerDefinedCampaign, IRateRule, IResidual } from '../../../shared/models/dealer-defined-campaigns.model';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { DealerDefinedCampaignsState } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.reducer';
import { Store } from '@ngrx/store';
import { selectDefaultMileage } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.selectors';

@Component({
  selector: 'app-pe-view-residuals',
  templateUrl: './view-residuals.component.html',
  styleUrls: ['./view-residuals.component.scss']
})
export class ViewResidualsComponent implements OnInit {

  @Input() campaign: IDealerDefinedCampaign;
  @Input() rateRule: IRateRule;

  residuals$: BehaviorSubject<IResidual[]> = new BehaviorSubject<IResidual[]>([]);
  termsList: number[] = [];
  defaultMileage$: Observable<number>;

  constructor(
    private readonly dealerDefinedCampaignsState: Store<DealerDefinedCampaignsState>
  ) { }

  ngOnInit() {
    this.defaultMileage$ = this.dealerDefinedCampaignsState.select(selectDefaultMileage);
    this.residuals$.next(this.getRuleResiduals());
  }

  getRuleResiduals() {
    return _.filter(this.campaign.residuals,
      (t) =>
        this.rateRule.modelCode.indexOf(t.modelCode) >= 0 &&
        this.rateRule.salesClass.indexOf(t.salesClass) >= 0 &&
        this.campaign.year.indexOf(t.modelYear) >= 0
    );
  }


}
