import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { OfferTypes } from '../../../shared/enums/offer-types.enum';
import { SalesClasses } from '../../../shared/enums/sales-classes.enum';
import { PaymentGridTypes } from '../../../shared/enums/pg-tab-type.enum';


@Pipe({
  name: 'offersForShowroomonline'
})
export class OffersForShowroomonlinePipe implements PipeTransform {

  transform(value: string, selectedTab: string, ignoreLeaseForUsed: boolean = false): string[] {
    const name: string[] = [];
    switch (value) {
      case SalesClasses.NEW:
        (selectedTab === PaymentGridTypes.ONLINE) ? name.push(...[OfferTypes.FINANCE]) : name.push(...[OfferTypes.FINANCE, OfferTypes.LEASE]);
        break;
      case SalesClasses.CERTIFIED:

        (selectedTab === PaymentGridTypes.ONLINE) ? name.push(...[OfferTypes.FINANCE]) : name.push(...[OfferTypes.FINANCE, OfferTypes.LEASE]);
        break;
      case SalesClasses.USED:
        (selectedTab === PaymentGridTypes.ONLINE) || ignoreLeaseForUsed ? name.push(...[OfferTypes.FINANCE]) : name.push(...[OfferTypes.FINANCE, OfferTypes.LEASE]);
        break;
    }
    return name;
  }

}
