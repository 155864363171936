import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { IUserProfile, UserProfileService } from '@toyota/dd365-platform-library';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppProps } from 'single-spa';
import { AssetUrlPipe } from '../../../../app/pipes/assets';
import {
  SingleSpaProps,
  singleSpaPropsSubject
} from '../../../../single-spa/single-spa-props';

@Injectable({
  providedIn: 'root',
})
export class TranslateLoaderFactoryService implements TranslateLoader, OnDestroy {
  private readonly folderPath = 'assets/i18n';
  private response = {};

  private readonly subscriptions: Subscription[] = [];
  private singleSpaProps: AppProps = null;

  private userProfileInitialized = false;
  constructor(private readonly httpClient: HttpClient, private readonly userProfileService: UserProfileService,
    private readonly assetUrlPipe: AssetUrlPipe) {

  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  public getTranslation(localization: string): Observable<any> {
    if (localization.indexOf('-') === -1) {
      return of(null);
    } else {
      const localizationTemp = localization.split('-');
      const lang = localizationTemp[0];
      const locale = localizationTemp[1];
      return new Observable((observer) => {
        const profile = this.userProfileService.getProfile();
        if (!profile) {
          this.init().then(() => {
            this.downloadTranslation(lang, locale).subscribe(
              (data) => {
                observer.next(data);
              }
            );
          });
        } else {
          this.userProfileInitialized = true;
          this.downloadTranslation(lang, locale).subscribe(
            (data) => {
              observer.next(data);
            }
          );
        }

      });
    }
  }

  private downloadTranslation(lang: string, locale: string) {
    return forkJoin([
      this.getLanguageFile(lang),
      this.getLocaleFile(locale),
    ]).pipe(
      catchError(error => {
        return of(null);
      }),
      map(
        (data: any[]) => {
          if (data && data.length > 0) {
            const out = { ...data[0], ...data[1] };
            return out;
          }
          return null;
        })
    );
  }

  private getLanguageFile(lang: string): Observable<any> {
    if (this.response[lang]) {
      return of(this.response[lang]);
    }
    const ts = new Date().getTime().toString();
    const url = `${this.assetUrlPipe.transform(lang + '.json?_t=' + ts, this.folderPath)}`;
    return this.httpClient.get(url).pipe(
      map((data) => {
        this.response[lang] = data;
        return data;
      })
    );
  }
  private getLocaleFile(locale: string) {
    if (this.response[locale]) {
      return of(this.response[locale]);
    }
    const ts = new Date().getTime().toString();
    const url = `${this.assetUrlPipe.transform(locale + '.json?_t=' + ts, this.folderPath)}`;
    return this.httpClient.get(url).pipe(
      map((data) => {
        this.response[locale] = data;
        return data;
      })
    );
  }

  private init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Single Spa Props subscription will only get triggered when the app bootstraps
      this.subscriptions.push(
        singleSpaPropsSubject.subscribe((props: SingleSpaProps) => {
          this.singleSpaProps = props;
          // User profile subscription will get triggered any time the UI Gateway broadcasts a user profile update
          this.subscriptions.push(
            this.singleSpaProps['userProfile'].subscribe(
              (profile: IUserProfile) => {
                if (!this.userProfileInitialized) {
                  this.userProfileService.updateProfile(profile);
                  this.userProfileInitialized = true;
                  resolve(true);
                }
              }
            )
          );
        })
      );
    });
  }
}
