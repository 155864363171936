import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from "../../global/global.module";
import { SharedModule } from '../../shared/shared.module';
import { GrossProfitRoutingModule } from './gross-profit.routing.module';
import { GrossProfitComponent } from './gross-profit.component';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { DisableDealerCashPipe } from './pipes/disable-dealer-cash.pipe';
@NgModule({
  declarations: [
    GrossProfitComponent,
    DisableDealerCashPipe
  ],
  imports: [
    CommonModule,
    GlobalModule,
    SharedModule,
    TranslateModule.forChild({ extend: true }),
    MatInputModule,
    GrossProfitRoutingModule
  ]
})
export class GrossProfitModule { }
