import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './incentives.actions';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { IncentivesService } from '../../shared/services/incentives.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IIncentive } from '../../shared/models/incentives.model';
import { TranslateService } from '@ngx-translate/core';
import { incentiveUpdateComplete$ } from './incentives.adapter';

@Injectable()
export class IncentivesEffects {

    private readonly dismissText = 'LOCALIZATION.COMMON.DISMISS';

    constructor(
        private readonly actions$: Actions,
        private readonly incentivesService: IncentivesService,
        private readonly translateService: TranslateService,
        private readonly snackBar: MatSnackBar
    ) {
    }

    loadIncentives: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.LoadIncentives>(
            fromActions.IncentivesActionTypes.LOAD_INCENTIVES
        ),
        mergeMap((action: fromActions.LoadIncentives) =>
            this.incentivesService.loadIncentives(action.payload.dealerCode).pipe(
                map((incentives: any[]) => {
                    return new fromActions.LoadIncentivesSuccess({ incentives })
                }),
                catchError(err => {
                    this.snackBar.open(err.error.message, this.translateService.instant(this.dismissText), { duration: 4000 });
                    return of(new fromActions.LoadIncentivesFailure({ error: err.error.message }));
                })
            )
        )
    ));

    updateIncentive: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.UpdateIncentive>(
            fromActions.IncentivesActionTypes.UPDATE_INCENTIVE
        ),
        mergeMap((action: fromActions.UpdateIncentive) =>
            this.incentivesService.updateIncentive(action.payload.incentive).pipe(
                map((incentive: IIncentive) => {
                    incentiveUpdateComplete$.next();
                    this.snackBar.open(this.translateService.instant('LOCALIZATION.INCENTIVES.UPDATE_CONFIRM'),
                        this.translateService.instant(this.dismissText), { duration: 4000 });
                    return new fromActions.UpdateIncentiveSuccess({ incentive });
                }),
                catchError(err => {
                    this.snackBar.open(err.error.message, this.translateService.instant(this.dismissText), { duration: 4000 });
                    return of(new fromActions.UpdateIncentiveFailure({ error: err.error.message }));
                })
            )
        )
    ));

    deleteIncentive: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.DeleteIncentive>(
            fromActions.IncentivesActionTypes.DELETE_INCENTIVE
        ),
        mergeMap((action: fromActions.DeleteIncentive) =>
            this.incentivesService.deleteIncentive(action.payload.incentiveId, action.payload.dealerCode).pipe(
                map(() => {
                    this.snackBar.open(this.translateService.instant('LOCALIZATION.INCENTIVES.DELETE_CONFIRM'),
                        this.translateService.instant(this.dismissText), { duration: 4000 });
                    return new fromActions.DeleteIncentiveSuccess({ incentiveId: action.payload.incentiveId });
                }),
                catchError(err => {
                    this.snackBar.open(err.error.message, this.translateService.instant(this.dismissText), { duration: 4000 });
                    return of(new fromActions.DeleteIncentiveFailure({ error: err.error.message }));
                })
            )
        )
    ));

}

