import { IPlan } from "../../../shared/models/product-offerings.model";
import { Pipe, PipeTransform } from "@angular/core";
import { ProtectionProductsComponentService } from "../protection-products.component.service";
import { ProvidersEnum } from "../../../shared/enums/provider-id.enum";

@Pipe({
  name: "plandescription",
})
export class PlanDescriptionPipe implements PipeTransform {
  constructor(
    private readonly pPComponentService: ProtectionProductsComponentService
  ) { }

  transform(planId: string, formId: string, plans: IPlan[], providerId: string): string {
    if (providerId === ProvidersEnum.JMNA) {
      const plan = plans.filter(
        (t) => t.formId === formId && t.code === planId
      )[0];
      const formDesc = this.pPComponentService.selectedProductData?.forms.filter(
        (t) => t.code === formId
      )[0]?.description;
      return `${formDesc} - ${plan?.description} - ${plan?.code}`;
    }
    else {
      return planId;
    }
  }
}
