//require ('dotenv').config();

export const Constants = {
  APP_NAME: 'pe-admin',
  // environment: process.env.RTI_ENVIRONMENT,
  // region: process.env.REGION,
  // logLevel: process.env.LOG_LEVEL || 'info',
  DSP2ClientId: '838f819a-8a34-40fc-9e51-2c67babed2fe',
  DSP2ClientAssertionType: 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
  DSP2Resource: 'https://ddoa-rs.qa.toyota.com',
  assertion_token: 'eyJhbGciOiJSUzI1NiIsIng1dCI6IjErUDRYYVdLbHBDZWpEOVAxNUJFRnlOWDN4RT0iLCJ0eXAiOiJKV1QifQ.eyJleHAiOjE2MjQyODIxOTksInN1YiI6IjgzOGY4MTlhLThhMzQtNDBmYy05ZTUxLTJjNjdiYWJlZDJmZSIsIm5iZiI6MTUxNDM2NjE2NiwiYXVkIjoiaHR0cHM6Ly9sb2dpbi5taWNyb3NvZnRvbmxpbmUuY29tL3RtbmFiMmNxYS5vbm1pY3Jvc29mdC5jb20vb2F1dGgyL3Rva2VuIiwiaXNzIjoiODM4ZjgxOWEtOGEzNC00MGZjLTllNTEtMmM2N2JhYmVkMmZlIiwianRpIjoiMTExODhiNzItNDMwYi00MjYyLTg1YTAtMDBhOWQ5MjM0MzExIn0.mtMzy7jNv6DE5bVMEO4N5fpl9FW4oCKnCEOOlEEfOB3fi5V02B037B_aABfG3ictJUBfH7pSZetakIaAK2YdpYgaKZFEAsTIP9vZCOa9ytYzLPfHM4rbw3aMfkb7lhAxzlNmtITK8bae8EG4HEzZIe3HmhU_D5_YqZzIxpQpQ9lPlbt3yaju9U4DZgUfi0Oj1nVj-ScU_NoP2YhinE7jDmp_hVr40eSc_EOwsTdWJHqTcux96xXqIh-cnuGB2YfggPfLsd6fZYMnxqkoPUtOCxoC2mtsnrEwb1op_LwxRxj2N1fvg1qon5aVrRnxb7l2ehgSJUv6eGhdITdY-DhToDNr9aBgftULbIaTCC6l4zGsZVDma-FxNqInsTQLLwiOwnKCGN2Q83MduNLDiNbbbUIM3AS2f_H2P0OVrnnjnyX3ocFEAaw_8HwU-CSTcvolBkmH9EMhsyV5ljNYnu6AXEj1jyFD2mzKCEzlJgARMJn9NoZL1E2UyrLMd9ZBidZdmSUtjbrolXksBebzCMg9RQSTkC0ifMRQ9EcnioZfMNl1UjHQLEK44KiDZNnwAyBJcrMrkaukI08Rbot_gn9K4hE3vpBjeKpa132_YYids0Vs5pNEri1qsy3WPg0qvzBCOh3hsbofzRPQFnM3KG_eXcrvIt3SgtrsRoZTnfmDiUc'
};

/**
 * Environment constants.  Can be utilized to write environment specific logic.
 */
export const Environment = {
  DEV: 'dev',
  TEST: 'test',
  E2E: 'e2e',
  QA: 'qa',
  STAGE: 'stage',
  PROD: 'prod',
  LOCAL: 'local'
};
