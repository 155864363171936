export const lenderListData = [
  {
    name: "Westlake Financial",
    displayName: "Westlake Financial",
    id: "F000WL",
    offerType: ["finance", "lease"]
  },
  {
    name: "Mid-Florida Credit Union",
    displayName: "Mid-Florida Credit Union",
    id: "F0TC03",
    offerType: ["finance"]
  },
  {
    name: "Space Coast Credit Union",
    displayName: "Space Coast Credit Union",
    id: "F2ML54",
    offerType: ["finance"]
  },
  {
    name: "Ally-eC FS",
    displayName: "Ally-eC FS",
    id: "F0EC11",
    offerType: ["finance", "lease"]
  }
];

export const easleyLenderListData = [
  {
    name: "Carolina Finance",
    displayName: "Carolina Finance",
    id: "F0DF29",
    offerType: ["finance"]
  },
  {
    name: "First Citizens Bank",
    displayName: "First Citizens Bank",
    id: "F0AP33",
    offerType: ["finance"]
  }
];