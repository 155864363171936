import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { UserProfileService } from "@toyota/dd365-platform-library";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { DealerConfigurationState } from "../../store/dealer-configuration/dealer-configuration.reducer";
import { DealerInfoService } from "../../shared/services/dealer-info.service";
import * as dealerConfigurationActions from "../../store/dealer-configuration/dealer-configuration.actions";
import {
  selectDealerConfiguration,
  selectdealerDefinedOfferTerms,
  selectDisableActions,
  selectIsLoading,
  selectIsLoadingBankConfig,
  selectOfferConfig,
  selectPaymentGridConfig,
  selectPaymentGridData,
} from "../../store/dealer-configuration/dealer-configuration.selectors";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PaymentGridTypes } from "../../shared/enums/pg-tab-type.enum";
import { filter, take } from "rxjs/operators";
import * as _ from "lodash";
import { SalesClasses } from "../../shared/enums/sales-classes.enum";
import { OfferTypes } from "../../shared/enums/offer-types.enum";
import { GlobalGridConfigComponent } from './global-grid-config/global-grid-config.component';
import { ShowroomOnlineGridConfigComponent } from './showroom-online-grid-config/showroom-online-grid-config.component';
import { IDefaultTerms, IGlobalTermConfig, IPaymentGridConfig, IShowroomOnlineTermConfig } from "../../shared/models/dealer-configuration.model";
import { IPaymentGridInfo } from "../../shared/models/bank-configuration.model";
import {
  SubscriptionList
} from "../../shared/services/util.service";
import { OffersForSalesClassPipe } from "./pipes/offers-for-salesclass.pipe";
import { OffersForShowroomonlinePipe } from "./pipes/offers-for-showroomonline.pipe";
@Component({
  selector: "app-pe-payment-grid",
  templateUrl: "./payment-grid.component.html",
  styleUrls: ["./payment-grid.component.scss"],
})
export class PaymentGridComponent implements OnInit {
  @ViewChild(GlobalGridConfigComponent) public globalComp?: GlobalGridConfigComponent;
  @ViewChild(ShowroomOnlineGridConfigComponent) public globalComp1?: ShowroomOnlineGridConfigComponent;
  globalchildNotifier = new BehaviorSubject<string>('');
  showroomchildNotifier = new BehaviorSubject<string>('');
  onlinechildNotifier = new BehaviorSubject<string>('');
  loading$: Observable<boolean>;
  loadingBankConfig$: Observable<boolean>;
  paymentGridForm: FormGroup;
  selectDealerConfigLoading$: Observable<boolean>;
  selectBankConfigLoading$: Observable<boolean>;
  selectedPGTabIndex: number = 0;
  paymentGridTypes = PaymentGridTypes;
  dealerCode: string;
  offerDefaultFormGroup: FormGroup;
  showroomOnlineFormGroup: FormGroup;
  configurableSalesClasses: string[] = [
    SalesClasses.NEW,
    SalesClasses.CERTIFIED,
    SalesClasses.USED,
  ];
  offerTerms: {
    new: { lease: number[]; finance: number[] };
    certified: { lease: number[]; finance: number[] };
    used: { lease: number[]; finance: number[] };
  };
  previousState: IPaymentGridConfig;
  disableActions$: Observable<boolean>;
  paymentGridConfig: IPaymentGridInfo;
  private subs: SubscriptionList = {};
  private pgFormChanges: Subscription;
  tempArray: string[];
  currentFormValue: string;
  constructor(
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly userProfileService: UserProfileService,
    private readonly dealerInfoService: DealerInfoService,
    private readonly offersForSalesClassPipe: OffersForSalesClassPipe,
    private readonly offersForShowroomonlinePipe: OffersForShowroomonlinePipe,
    private fb: FormBuilder,
    private readonly cdref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.tempArray = ['global', 'showroom', 'online'];
    this.globalchildNotifier.next('global');
    this.showroomchildNotifier.next('showroom');
    this.onlinechildNotifier.next('online');
    this.offerTerms = {
      new: { lease: [], finance: [] },
      certified: { lease: [], finance: [] },
      used: { lease: [], finance: [] },
    };
    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    this.dealerConfigurationState.dispatch(
      new dealerConfigurationActions.LoadDealerConfiguration({ dealerCode: this.dealerCode })
    );
    this.disableActions$ = this.dealerConfigurationState.select(
      selectDisableActions
    );
    _.keys(this.offerTerms).forEach((salesClass) => {
      _.keys(this.offerTerms[salesClass]).forEach((offerType) => {
        this.dealerConfigurationState
          .select(selectdealerDefinedOfferTerms, { offerType, salesClass })
          .pipe(
            filter((t) => t.length > 0),
            take(1)
          )
          .subscribe((offerTerms) => {
            this.offerTerms[salesClass][offerType] = offerTerms;
          });
      });
    });
    this.dealerConfigurationState
      .select(selectOfferConfig)
      .pipe(
        take(1),
        filter((t) => !t)
      )
      .subscribe(() => {
        this.dealerConfigurationState.dispatch(
          new dealerConfigurationActions.LoadBankConfiguration({
            distributor: this.dealerInfoService.getDealerBankDistributor(),
          })
        );
      });
    this.subs.selectPGConfigSub = this.dealerConfigurationState
      .select(selectPaymentGridConfig)
      .subscribe((paymentGridConfig) => {
        if (paymentGridConfig) {
          this.paymentGridConfig = paymentGridConfig;
        }
      });
    this.loading$ = this.dealerConfigurationState.select(selectIsLoading);
    this.loadingBankConfig$ = this.dealerConfigurationState.select(selectIsLoadingBankConfig);
    this.subs.selectPGData = this.dealerConfigurationState
      .select(selectPaymentGridData)
      .subscribe((paymentGrid) => {
        /* istanbul ignore if */
        if (paymentGrid) {
          this.previousState = paymentGrid;
          this.paymentGridForm = this.fb.group({});
          this.setupDefaultTermsForm(paymentGrid.global);
          this.setshowroomOnlineForm(paymentGrid.showroom, this.paymentGridTypes.SHOWROOM);
          this.setshowroomOnlineForm(paymentGrid.online, this.paymentGridTypes.ONLINE);
          this.globalchildNotifier.next('global');
          this.cdref.detectChanges();
          this.globalComp.setupDefaultTermsForm();
          this.showroomchildNotifier.next('showroom');
          this.cdref.detectChanges();
          this.globalComp1.setshowroomOnlineForm();
          this.onlinechildNotifier.next('online');
          this.cdref.detectChanges();
          this.globalComp1.setshowroomOnlineForm();


        }
      });
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedPGTabIndex = event.index;
  }

  setupDefaultTermsForm(defaultTerms: IGlobalTermConfig) {
    this.paymentGridForm.addControl(
      'global',
      this.fb.group({})
    );
    this.offerDefaultFormGroup = this.paymentGridForm.get('global') as FormGroup;
    this.configurableSalesClasses.forEach((salesClass) => {
      this.offerDefaultFormGroup.addControl(
        salesClass,
        this.fb.group({})
      );
      this.offersForSalesClassPipe.transform(salesClass).forEach((offer) => {
        const salesClassDefaultFormGroup = this.offerDefaultFormGroup.get([
          salesClass,
        ]) as FormGroup;
        const offerDefault: IDefaultTerms = defaultTerms?.[salesClass]?.[offer];
        const mileageOptionsDefault =
          defaultTerms?.[salesClass]?.["defaultMileageOptions"];
        salesClassDefaultFormGroup.addControl(
          offer,
          this.fb.group({
            terms: [
              offerDefault?.terms || [],
              [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(3),
              ],
            ],
            startTerm: [offerDefault?.startTerm, [Validators.required]],
          })
        );
        salesClassDefaultFormGroup.addControl(
          "defaultMileageOptions",
          this.fb.control(mileageOptionsDefault || [], [
            Validators.required,
          ])
        );
        salesClassDefaultFormGroup.addControl(
          "defaultMileage",
          this.fb.control(defaultTerms?.[salesClass]?.defaultMileage, [
            Validators.required,
          ])
        );
        /** */
        const defaultMileageOptGroup = salesClassDefaultFormGroup.get([
          "defaultMileageOptions",
        ]);
        this.subs.defaultMileageSub = defaultMileageOptGroup.valueChanges.subscribe(
          (mileageOpts: number[]) => {
            const defaultMileage = salesClassDefaultFormGroup.get([
              "defaultMileage",
            ]);
            if (!_.includes(mileageOpts, defaultMileage.value)) {
              defaultMileage.setValue(undefined);
            }
          }
        );
        const offerGroup = salesClassDefaultFormGroup.get([offer]);
        this.subs[`${offer}Sub`] = offerGroup
          .get(["terms"])
          .valueChanges.subscribe((terms: number[]) => {
            const offerStartTerm = offerGroup.get(["startTerm"]);
            if (!_.includes(terms, offerStartTerm.value)) {
              offerStartTerm.setValue(undefined);
            }
          });
      });
    });
    this.disableActions();

  }

  disableActions() {
    this.disableActions$.subscribe((disable: boolean) => {
      if (disable) {
        this.paymentGridForm.disable();
      } else {
        this.paymentGridForm.enable();
      }
    })
  }

  private getOrderBySalesClass(salesClass: string, showroomOnlineTermConfig: IShowroomOnlineTermConfig) {
    if (salesClass !== SalesClasses.USED)
      return showroomOnlineTermConfig?.[salesClass].order;
    else {
      return _.filter(showroomOnlineTermConfig?.[salesClass].order || [], offer => offer !== OfferTypes.LEASE);
    }
  }

  setshowroomOnlineForm(defaultTerms: IShowroomOnlineTermConfig, tab: string) {
    this.paymentGridForm.addControl(
      tab,
      this.fb.group({})
    );
    this.showroomOnlineFormGroup = this.paymentGridForm.get(tab) as FormGroup;
    this.configurableSalesClasses.forEach((salesClass) => {
      this.showroomOnlineFormGroup.addControl(
        salesClass,
        this.fb.group({
          order: [
            this.getOrderBySalesClass(salesClass, defaultTerms),
            [Validators.required],
          ],
        })
      );
      this.offersForShowroomonlinePipe.transform(salesClass, tab).forEach((offer) => {
        const salesClassDefaultFormGroup = this.showroomOnlineFormGroup.get([
          salesClass,
        ]) as FormGroup;
        const offerDefault = defaultTerms?.[salesClass]?.[offer];
        if (tab === this.paymentGridTypes.SHOWROOM) {
          salesClassDefaultFormGroup.addControl(
            offer,
            this.fb.group({
              downPayment: this.fb.array([]),
              defaultOfferType: [
                offerDefault?.defaultOfferType,
                [Validators.required],
              ],
              downPaymentMeasure: [
                offerDefault?.downPaymentMeasure,
                [Validators.required],
              ],
            })
          );
        }
        if (tab === this.paymentGridTypes.ONLINE) {
          salesClassDefaultFormGroup.addControl(
            offer,
            this.fb.group({
              downPayment: this.fb.array([]),
              downPaymentMeasure: [
                offerDefault?.downPaymentMeasure,
                [Validators.required],
              ],
            })
          );
        }

        const downPaymentformControl = <FormArray>this.showroomOnlineFormGroup
          .get([salesClass])
          .get([offer])
          .get("downPayment");
        offerDefault.downPayment.forEach((data) => {
          downPaymentformControl.push(
            this.fb.group({
              measure: [data.measure, [Validators.required]],
              downPaymentOptions: [
                data.downPaymentOptions,
                [Validators.required],
              ],
              defaultDownPayment: [
                data.defaultDownPayment,
                [Validators.required],
              ],
            })
          );
        });
      });
    });
    this.disableActions();
  }

  saveEventHandler($event: any, tab: string) {
    this.dealerConfigurationState
      .select(selectDealerConfiguration)
      .pipe(take(1))
      .subscribe((dealerConfig) => {
        const dealerConfiguration = _.cloneDeep(dealerConfig);
        dealerConfiguration.paymentGridCustomization = this.paymentGridForm.value;
      });
    const paymentgridCustomizationValue = _.cloneDeep(this.paymentGridForm.value);
    if (paymentgridCustomizationValue.online) {
      this.configurableSalesClasses.forEach((salesClass) => {
        paymentgridCustomizationValue.online[salesClass][OfferTypes.LEASE] = paymentgridCustomizationValue.online[salesClass][OfferTypes.FINANCE];
      });
    }
    this.dealerConfigurationState
      .select(selectDealerConfiguration)
      .pipe(take(1))
      .subscribe((dealerConfig) => {
        const dealerConfiguration = _.cloneDeep(dealerConfig);
        dealerConfiguration.paymentGridCustomization = paymentgridCustomizationValue;
        this.dealerConfigurationState.dispatch(
          new dealerConfigurationActions.UpdateDealerConfiguration({
            dealerCode: this.dealerCode,
            dealerConfiguration,
          })
        );
      });
  }
  /* istanbul ignore next */
  resetEventHander($event: any) {
    this.paymentGridForm = this.fb.group({});
    this.setupDefaultTermsForm(this.previousState.global);
    this.setshowroomOnlineForm(this.previousState.showroom, this.paymentGridTypes.SHOWROOM);
    this.setshowroomOnlineForm(this.previousState.online, this.paymentGridTypes.ONLINE);
    this.globalchildNotifier.next('global');
    this.cdref.detectChanges();
    this.globalComp.setupDefaultTermsForm();
    this.showroomchildNotifier.next('showroom');
    this.cdref.detectChanges();
    this.globalComp1.setshowroomOnlineForm();
    this.onlinechildNotifier.next('online');
    this.cdref.detectChanges();
    this.globalComp1.setshowroomOnlineForm();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnDestroy(): void {
    this.pgFormChanges?.unsubscribe()
  }

}