import { OfferTypes } from '../../../components/shared/enums/offer-types.enum';

export function getRateFieldName(offerType: string): string {
    let rateFieldName;
    switch (offerType) {
        case OfferTypes.LEASE:
            rateFieldName = 'rcf';
            break;
        case OfferTypes.FINANCE:
        default:
            rateFieldName = 'rate';
            break;
    }
    return rateFieldName;
}
