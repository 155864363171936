import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'lodash';
import { IVehicleBrand, IVehicleSeries } from "../../../shared/models/vehicle-config-master.model";
import { ProtectionProductsComponentService } from '../protection-products.component.service';
import { VehicleConfigState } from '../../../store/vehicle-config/vehicle-config.reducer';
import { Store } from "@ngrx/store";
@Pipe({
    name: "getbrandseries"
})
export class GetBrandSeriesPipe implements PipeTransform {
    constructor(private readonly pPComponentService: ProtectionProductsComponentService, private readonly vehicleConfigState: Store<VehicleConfigState>) { }
    transform(brand: string, vehicleConfig: IVehicleBrand[]): IVehicleSeries[] {
        let result;
        if (brand && brand !== '' && brand !== null) {
            this.pPComponentService.checkAndfetchVehicleConfig(brand, this.vehicleConfigState);
            result = _.find(vehicleConfig, { name: brand })?.series || [];
            result = _.sortBy(result, [(item) => item.name.toLowerCase()]);
        }
        return result;
    }
}