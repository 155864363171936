import { Brands } from "../components/shared/enums/brands.enum";
import { IKeyNamePair } from "../components/shared/models/utility/key-label-pair.model";

export const brandList: IKeyNamePair[] = [
  {
    id: "toyota",
    name: "Toyota"
  },
  {
    id: "lexus",
    name: "Lexus"
  },
  {
    id: Brands.ALL_NTL,
    name: "All Non-T/L"
  },
  {
    id: "acura",
    name: "Acura"
  },
  {
    id: "alfa romeo",
    name: "Alfa Romeo"
  },
  {
    id: "aston martin",
    name: "Aston Martin"
  },
  {
    id: "audi",
    name: "Audi"
  },
  {
    id: "bmw",
    name: "BMW"
  },
  {
    id: "buick",
    name: "Buick"
  },
  {
    id: "cadillac",
    name: "Cadillac"
  },
  {
    id: "chevrolet",
    name: "Chevrolet"
  },
  {
    id: "chrysler",
    name: "Chrysler"
  },
  {
    id: "dodge",
    name: "Dodge"
  },
  {
    id: "ferrari",
    name: "Ferrari"
  },
  {
    id: "fiat",
    name: "FIAT"
  },
  {
    id: "ford",
    name: "Ford"
  },
  {
    id: "genesis",
    name: "Genesis"
  },
  {
    id: "gmc",
    name: "GMC"
  },
  {
    id: "honda",
    name: "Honda"
  },
  {
    id: "hummer",
    name: "HUMMER"
  },
  {
    id: "hyundai",
    name: "Hyundai"
  },
  {
    id: "infiniti",
    name: "Infiniti"
  },
  {
    id: "isuzu",
    name: "Isuzu"
  },
  {
    id: "jaguar",
    name: "Jaguar"
  },
  {
    id: "jeep",
    name: "Jeep"
  },
  {
    id: "kia",
    name: "Kia"
  },
  {
    id: "lamborghini",
    name: "Lamborghini"
  },
  {
    id: "land rover",
    name: "Land Rover"
  },
  {
    id: "lincoln",
    name: "Lincoln"
  },
  {
    id: "lotus",
    name: "Lotus"
  },
  {
    id: "lucid",
    name: "Lucid"
  },
  {
    id: "maserati",
    name: "Maserati"
  },
  {
    id: "mazda",
    name: "Mazda"
  },
  {
    id: "mercedes-benz",
    name: "Mercedes Benz"
  },
  {
    id: "mercury",
    name: "Mercury"
  },
  {
    id: "mini",
    name: "MINI"
  },
  {
    id: "mitsubishi",
    name: "Mitsubishi"
  },
  {
    id: "nissan",
    name: "Nissan"
  },
  {
    id: "polestar",
    name: "Polestar"
  },
  {
    id: "pontiac",
    name: "Pontiac"
  },
  {
    id: "porsche",
    name: "Porsche"
  },
  {
    id: "pontiac",
    name: "Pontiac"
  },
  {
    id: "ram",
    name: "Ram"
  },
  {
    id: "rivian",
    name: "Rivian"
  },
  {
    id: "saab",
    name: "Saab"
  },
  {
    id: "saturn",
    name: "Saturn"
  },
  {
    id: "scion",
    name: "Scion"
  },
  {
    id: "smart",
    name: "Smart"
  },
  {
    id: "subaru",
    name: "Subaru"
  },
  {
    id: "suzuki",
    name: "Suzuki"
  },
  {
    id: "tesla",
    name: "Tesla"
  },
  {
    id: "volkswagen",
    name: "Volkswagen"
  },
  {
    id: "volvo",
    name: "Volvo"
  }
];


export const brandTmisList: IKeyNamePair[] = [
  {
    id: "Toyota",
    name: "Toyota"
  },
  {
    id: "Lexus",
    name: "Lexus"
  },
  {
    id: Brands.ALL_NTL,
    name: "All Non-T/L"
  }
];