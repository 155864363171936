import { map } from 'rxjs/operators';
import { DealerInfoService } from './dealer-info.service';
import { UserProfileService } from '@toyota/dd365-platform-library';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';
import * as uuid from 'uuid';
import { IGetAuditLogsRequest, IGetAuditLogsResponse } from '../models/audit-log.model';

@Injectable({
    providedIn: 'root'
})
export class PEAuditService {

    private readonly DEALER_EVENTS_API = environment.dealer_events_api;
    private readonly SUBSCRIBER_CODE = environment.x_subscriber_code;
    private httpOptions = {
        headers: new HttpHeaders()
    };

    constructor(
        private http: HttpClient,
        private readonly userProfileService: UserProfileService,
        private readonly dealerInfoService: DealerInfoService
    ) {
    }

    private setHeaderOptions() {
        this.httpOptions.headers = new HttpHeaders({});
        this.httpOptions.headers = this.httpOptions.headers
            .set('Authorization', `Bearer ${this.userProfileService.getProfile().token}`)
            .set('x-subscriber-code', this.SUBSCRIBER_CODE)
            .set('x-transaction-id', uuid.v4());
    }

    getAuditLogs(dealerCode: string, req: IGetAuditLogsRequest) {
        this.setHeaderOptions();
        const queryString = `startDate=${req.startDate}&endDate=${req.endDate}&itemsOnPage=${req.itemsOnPage}&page=${req.page}${this.getModuleFilterQuery(req.module)}${this.getUserFilterQuery(req.userName)}`;
        return this.http.get(`${this.DEALER_EVENTS_API}auditTrail/${dealerCode}?${queryString}`, this.httpOptions)
            .pipe(
                map((data: IGetAuditLogsResponse) => {
                    return data;
                })
            );
    }

    private getModuleFilterQuery(modules: string[]) {
        return modules?.length > 0 ? `&module=${encodeURIComponent(modules.join(','))}` : '';
    }

    private getUserFilterQuery(user: string) {
        return user?.length > 0 ? `&user=${encodeURIComponent(user)}` : '';
    }

}