import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserProfileService } from '@toyota/dd365-platform-library';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as uuid from 'uuid';
import { brandList } from '../../../mocks/brands';
import { Brands } from '../../shared/enums/brands.enum';
import { CampaignStatus } from '../../shared/enums/campaign-status.enum';
import { GenericSelections } from '../../shared/enums/generic-selections.enum';
import { OfferTypes } from '../../shared/enums/offer-types.enum';
import { SalesClasses } from '../../shared/enums/sales-classes.enum';
import { IBrand } from '../../shared/models/brand.model';
import { ILenderInfo, IMaxStateApr } from '../../shared/models/dealer-configuration.model';
import { IAdvancePercentage, IDealerDefinedCampaign, IRate, IRateRule, IResidual, ITermRange, ITermRate, ITermResidual, IVariance } from '../../shared/models/dealer-defined-campaigns.model';
import { IValueRange } from '../../shared/models/utility/value-range.model';
import { IVehicleBrand } from '../../shared/models/vehicle-config-master.model';
import { DealerInfoService } from '../../shared/services/dealer-info.service';
import { SubscriptionList, unsubscribeSubscriptions } from '../../shared/services/util.service';
import { DealerDefinedCampaignsState } from '../../store/dealer-defined-campaigns/dealer-defined-campaigns.reducer';
import { selectBrands } from '../../store/dealer-defined-campaigns/dealer-defined-campaigns.selectors';
import * as vehicleConfigActions from '../../store/vehicle-config/vehicle-config.actions';
import { VehicleConfigState } from '../../store/vehicle-config/vehicle-config.reducer';
import { selectVehicleBrands } from '../../store/vehicle-config/vehicle-config.selectors';
import { CampaignDisclaimer } from '../../shared/enums/campaign-disclaimer.enum';
import { ICreditTier } from '../../shared/models/bank-configuration.model';
import { markupGPTypes } from './../../shared/enums/gp-types.enum';
@Injectable({
  providedIn: 'root',
})
export class DealerDefinedCampaignsComponentService {

  currentDate: DateTime;
  creditTiers: ICreditTier[] = [];
  defaultVariances: IVariance[] = [];
  rtiBrands: string[] = [Brands.TOYOTA, Brands.LEXUS];
  salesClasses: string[] = [];
  yearsToAllowNewSalesClass = [];
  lenderList: ILenderInfo[] = [];
  campaignList: IDealerDefinedCampaign[] = [];
  vehicleConfig: IVehicleBrand[] = [];
  allValue = GenericSelections.ALL;
  campaignLoading$: Observable<boolean>;
  vehicleConfigLoading$: Observable<boolean>;
  maxStateApr: IMaxStateApr;
  termRange: IValueRange = { min: 1, max: 96 };
  advPercentageRange: IValueRange = { min: 0, max: 1000 };
  residualRange: IValueRange = { min: 0, max: 100 };
  lmbVariance: IValueRange = { min: 0, max: 100 };
  hmrVariance: IValueRange = { min: -100, max: 0 };
  leaseBaserate: IValueRange;
  subs: SubscriptionList = {};
  vehicleBrands: IBrand[] = [];
  markupGPTypes = markupGPTypes;

  constructor(
    private readonly userProfileService: UserProfileService,
    private readonly dealerInfoService: DealerInfoService,
    private readonly dealerDefinedCampaignsState: Store<DealerDefinedCampaignsState>
  ) {
    const currentDateTime = DateTime.local();
    this.currentDate = DateTime.utc(
      currentDateTime.year,
      currentDateTime.month,
      currentDateTime.day
    );
    this.yearsToAllowNewSalesClass = [
      'All',
      currentDateTime.year.toString(),
      (currentDateTime.year + 1).toString(),
      (currentDateTime.year - 1).toString(),
    ];
    this.subs.brandListData = this.dealerDefinedCampaignsState.select(selectBrands).subscribe(data => {
      this.vehicleBrands = data;
    });
  }

  get isSETDealer() {
    return this.dealerInfoService.isSETDealer();
  }

  buildDraftCampaign(): IDealerDefinedCampaign {
    return {
      id: uuid.v4(),
      dealerCode: this.userProfileService.getProfile().dealerCd,
      title: '',
      make: brandList[0].id,
      year: [],
      model: [],
      startDate: null,
      endDate: null,
      incentiveAmount: null,
      lenderId: '',
      lenderName: '',
      maskedLenderName: '',
      dndText: CampaignDisclaimer.DISCLAIMER,
      otherRestrictions: null,
      status: CampaignStatus.DRAFT,
      rateRules: [],
      residuals: []
    }
  }

  buildDraftRateRule(offerType: string): IRateRule {
    return {
      offerType,
      salesClass: [],
      modelCode: [],
      termRate: []
    }
  }

  buildDraftTermRate(): ITermRate {
    return {
      term: {
        min: null,
        max: null
      },
      markup: {
        value: 0,
        measure: this.markupGPTypes.PERCENT
      },
      advancePercentage: [this.buildDraftAdvancePercentage()]
    }
  }

  buildDraftAdvancePercentage(): IAdvancePercentage {
    return {
      min: null,
      max: null,
      isDefault: true,
      rates: this.creditTiers.map(creditTier => ({ creditTier: creditTier.score, baseRate: null } as IRate))
    }
  }

  buildDraftResidual(modelCode: string, modelYear: string, salesClass: string, terms: any[]): IResidual {
    return {
      modelCode,
      modelYear,
      salesClass,
      terms: terms.map(t => this.buildDraftTermResidual(t)),
      variances: this.defaultVariances
    }
  }

  buildDraftTermResidual(term: any): ITermResidual {
    return { term, value: null };
  }

  checkAndfetchVehicleConfig(brand: string, vehicleConfigState: Store<VehicleConfigState>) {
    vehicleConfigState.select(selectVehicleBrands).pipe(take(1)).subscribe(brands => {
      if (_.indexOf(brands, brand) < 0) {
        if (this.rtiBrands.includes(brand)) {
          vehicleConfigState.dispatch(new vehicleConfigActions.LoadRTIVehicleConfig({ brand }));
        }
        else {
          vehicleConfigState.dispatch(new vehicleConfigActions.LoadNHTSAVehicleConfig({ brand }));
        }
      }
    });
  }

  currentAndFutureDateCheck = (d: DateTime): boolean => this.currentDate <= d;

  checkInvalidStartAndEndDate(startDate: DateTime, endDate: DateTime) {
    return startDate && endDate && endDate.diff(startDate, ['days']).days < 0
  }

  checkCampaignDateOverlap(c1: IDealerDefinedCampaign, c2: IDealerDefinedCampaign) {
    const c1Start = this.getLuxonUTCDate(c1.startDate);
    const c1End = this.getLuxonUTCDate(c1.endDate);
    const c2Start = this.getLuxonUTCDate(c2.startDate);
    const c2End = this.getLuxonUTCDate(c2.endDate);
    return c1Start && c1End && c2Start && c2End &&
      c2End.diff(c1Start, ['days']).days >= 0 &&
      c1End.diff(c2Start, ['days']).days >= 0;
  }

  getLuxonUTCDate(date: string) {
    return date ? DateTime.fromISO(date, { zone: 'UTC' }) : null;
  }

  isNewSalesClassAllowed(campaignMake: string, campaignYear: string[]) {
    const currentYear = DateTime.local().year;
    const yearsToAllowNew = [
      GenericSelections.ALL,
      currentYear.toString(),
      (currentYear + 1).toString(),
      (currentYear - 1).toString(),
    ];
    return this.rtiBrands.includes(campaignMake) &&
      _.intersection(campaignYear, yearsToAllowNew).length > 0;
  }

  getSalesClassForCampaign(campaignMake: string, campaignYear: string[]) {
    if (!this.rtiBrands.includes(campaignMake)) {
      return [SalesClasses.USED];
    }
    return this.isNewSalesClassAllowed(campaignMake, campaignYear) ?
      this.salesClasses : this.salesClasses.filter(salesClass => salesClass !== SalesClasses.NEW);
  }

  findConflictingCampaigns(campaign: IDealerDefinedCampaign) {
    return _.filter(this.campaignList, (eCampaign) =>
      campaign.id !== eCampaign.id &&
      campaign.lenderId === eCampaign.lenderId &&
      eCampaign.status === CampaignStatus.ACTIVE &&
      campaign.make === eCampaign.make &&
      (campaign.model.includes(this.allValue) ||
        eCampaign.model.includes(this.allValue) ||
        _.intersection(campaign.model, eCampaign.model).length > 0) &&
      (campaign.year.includes(this.allValue) ||
        eCampaign.year.includes(this.allValue) ||
        _.intersection(campaign.year, eCampaign.year).length > 0) &&
      this.checkCampaignDateOverlap(campaign, eCampaign)
    ).map(t => t.title);
  }

  findConflictingRateRules(rateRule: IRateRule, eRules: IRateRule[]) {
    return _.some(eRules, (eRule) =>
      rateRule.offerType === eRule.offerType &&
      (rateRule.modelCode.includes(this.allValue) ||
        eRule.modelCode.includes(this.allValue) ||
        _.intersection(rateRule.modelCode, eRule.modelCode).length > 0) &&
      _.intersection(rateRule.salesClass, eRule.salesClass).length > 0
    );
  }

  getMaxStateApr(salesClass: string[]) {
    return _.includes(salesClass, SalesClasses.NEW) ?
      this.maxStateApr.new : this.maxStateApr.used;
  }

  getLenderOffers(id: string) {
    return _.find(this.lenderList, { id })?.offerType || [];
  }

  getBrandById(id) {
    return _.find(this.vehicleBrands, ['id', id]) || id;
  }

  getModelByCode(data, code) {
    return _.find(data, ['code', code]) || code;
  }

  /* istanbul ignore next */
  destroySubs() {
    unsubscribeSubscriptions(this.subs);
  }
}
