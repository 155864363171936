import { DealerInfoService } from "./../services/dealer-info.service";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class DealerConfigFound implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly dealerInfoService: DealerInfoService
  ) {}

  canActivate(): boolean {
    if (this.dealerInfoService.isDealerConfigNotFound) {
      this.router.navigate([`/pe-admin/config-not-found`]);
      return false;
    }
    return true;
  }
}
