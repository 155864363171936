import { FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { IProductConfiguration } from '../../../shared/models/protection-products.model';
import { ProtectionProductsComponentService } from '../protection-products.component.service';

@Pipe({
  name: 'getGpAdjustments'
})
export class GetGpAdjustmentsPipe implements PipeTransform {

  constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

  transform(_product: IProductConfiguration, productForm: FormGroup, salesClass: string, offerType: string): AbstractControl[] {
    const adjustments = productForm.get(['global', 'gp', 'adjustments', salesClass, offerType]) as FormArray;
    if (adjustments) {
      return adjustments.controls;
    }
    else {
      return [];
    }
  }
}