import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureKeys } from '../app.reducer';
import { VehicleConfigState } from './vehicle-config.reducer';

export const selectVehicleConfigFeature = createFeatureSelector<VehicleConfigState>(FeatureKeys.VEHICLECONFIG);

export const selectVehicleConfig = createSelector(
    selectVehicleConfigFeature,
    (state: VehicleConfigState) => state.vehicleConfig
);

export const selectYearMasterList = createSelector(
    selectVehicleConfigFeature,
    (state: VehicleConfigState) => state.yearMasterList.map(t => t.toString())
);

export const selectVehicleBrands = createSelector(
    selectVehicleConfigFeature,
    (state: VehicleConfigState) => state.vehicleConfig.map(t => t.name)
);

export const selectIsVehicleConfigLoading = createSelector(
    selectVehicleConfigFeature,
    (state: VehicleConfigState) => state.loading
);

export const selectVehicleConfigLoadCounter = createSelector(
    selectVehicleConfigFeature,
    (state: VehicleConfigState) => state.configLoadCounter
);

export const selectBrandsLoading = createSelector(
    selectVehicleConfigFeature,
    (state: VehicleConfigState) => state.brandsLoading
);
