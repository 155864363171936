import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ILeaseFormParameter } from '../models/lease-settings.model';

@Pipe({
    name: 'disabledoption'
})
export class DisabledOptionPipe implements PipeTransform {

    transform(name: string, equityAllocation: ILeaseFormParameter[]): boolean {
        return !_.find(equityAllocation, { name })?.value;
    }

}
