import { IPennProvider } from './../models/product-offerings.model';
import { IGetPennProductDefaults, IGetProducts, ISaveProducts, ITaxCategory, IPacChangelog } from '../models/protection-products.model';
import { DealerInfoService } from './dealer-info.service';
import { UserProfileService } from '@toyota/dd365-platform-library';
import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as uuid from 'uuid';
import { IProtectionProducts } from '../models/protection-products.model';
import { of, Observable } from 'rxjs';
import { IGetPresignedUrlRequest, IGetPresignedUrlResponse } from '../models/s3-file-upload.model';
import { DealerConfigVersion } from '../enums/dc-version.enum';
import { AssetUrlPipe } from '../../../../app/pipes/assets';
@Injectable({
    providedIn: 'root'
})
export class ProtectionProductsService {
    private readonly folderPath = 'assets/sample';
    private readonly DEALER_CONFIGURATION_API = environment.dealer_configuration_api;
    private readonly PP_DEALER_CONFIGURATION_API: string = environment.get_dealer_configuration_api;
    private readonly SUBSCRIBER_CODE = environment.x_subscriber_code;
    private httpOptions = {
        headers: new HttpHeaders()
    };
    constructor(private http: HttpClient,
        private readonly assetUrlPipe: AssetUrlPipe,
        private readonly userProfileService: UserProfileService,
        private readonly dealerInfoService: DealerInfoService) {
    }

    private setHeaderOptions() {
        this.httpOptions.headers = new HttpHeaders({});
        this.httpOptions.headers = this.httpOptions.headers
            .set('Authorization', `Bearer ${this.userProfileService.getProfile().token}`)
            .set('x-subscriber-code', this.SUBSCRIBER_CODE)
            .set('x-transaction-id', uuid.v4());
    }

    getProtectionProducts(dealerCode: string) {
        this.setHeaderOptions();
        return this.http.get(`${this.PP_DEALER_CONFIGURATION_API}${dealerCode}/protectionProducts`, this.httpOptions)
            .pipe(
                map((data: IProtectionProducts) => {
                    return data;
                })
            );
    }

    getPennProviders(dealerCode: string): Observable<IPennProvider[]> {
        this.setHeaderOptions();
        return this.http.get(`${this.PP_DEALER_CONFIGURATION_API}${dealerCode}/pennProtectionProducts`, this.httpOptions)
            .pipe(
                map((data: IPennProvider[]) => {
                    return data;
                })
            );
    }

    getPennProductDefaults(dealerCode: string, prodviderName: string, productId: number) {
        this.setHeaderOptions();
        const queryParams = `provider=${encodeURIComponent(prodviderName)}&productId=${productId}`;
        return this.http.get(`${this.PP_DEALER_CONFIGURATION_API}${dealerCode}/pennProductDefaults?${queryParams}`, this.httpOptions)
            .pipe(
                map((data: IGetPennProductDefaults) => {
                    return data;
                })
            );
    }

    getProductOfferings(dealerCode: string, version: DealerConfigVersion): Observable<IGetProducts> {
        this.setHeaderOptions();
        const distributor = this.dealerInfoService.getDealerDistributor().toLowerCase();
        return this.http.get(`${this.PP_DEALER_CONFIGURATION_API}${dealerCode}/productOfferings?distributor=${distributor}&version=${version}`, this.httpOptions)
            .pipe(
                map((data: any) => {
                    if (typeof (data.configurationData.maxVisiblePP) === 'undefined') {
                        data.configurationData = {
                            maxVisiblePP: 1,
                            products: data.configurationData
                        }
                    }
                    return data;
                })
            );
    }

    getTaxCategories(dealerCode: string) {
        this.setHeaderOptions();
        return this.http.get(`${this.DEALER_CONFIGURATION_API}taxCategory?dealerCode=${dealerCode}`, this.httpOptions)
            .pipe(
                map((data: ITaxCategory[]) => {
                    return data;
                })
            );
    }

    getPacChangelogs(dealerCode: string, productName: string, providerId: string) {
        this.setHeaderOptions();
        return this.http.get(`${this.PP_DEALER_CONFIGURATION_API}${dealerCode}/${encodeURIComponent(productName)}/pacLog?providerId=${providerId}`, this.httpOptions)
            .pipe(
                map((data: IPacChangelog[]) => {
                    return data;
                })
            );
    }

    updateProtectionProducts(dealerCode: string, protectionProducts: IProtectionProducts) {
        this.setHeaderOptions();
        return this.http.post(`${this.PP_DEALER_CONFIGURATION_API}${dealerCode}/protectionProducts`, protectionProducts, this.httpOptions).pipe(
            map((data: IProtectionProducts) => {
                return data;
            })
        );
    }

    updateTfsProtectionProducts(dealerCode: string, productDetails: ISaveProducts) {
        this.setHeaderOptions();
        return this.http.post(`${this.PP_DEALER_CONFIGURATION_API}${dealerCode}/protectionProductsConfiguration`, productDetails, this.httpOptions).pipe(
            map((data: ISaveProducts) => {
                return data;
            })
        );
    }

    getPresignedUrl(dealerCode: string, fileDetails: IGetPresignedUrlRequest) {
        this.setHeaderOptions();
        return this.http.post(`${this.PP_DEALER_CONFIGURATION_API}${dealerCode}/fileUpload`, fileDetails, this.httpOptions).pipe(
            map((data: IGetPresignedUrlResponse) => {
                return data;
            })
        );
    }

}