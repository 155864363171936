import { IUserProfile, UserProfileService } from '@toyota/dd365-platform-library';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class AccessControlService {
    user: IUserProfile;
    readFlag: boolean = false;
    writeFlag: boolean = false;
    fromRatesAndResiduals : any;
    constructor(private _userProfileService: UserProfileService) { }
    

    getUserProfile(): void {
        this.user = this._userProfileService.getProfile();
        const pePermissions = this.user.permission.policies.action.filter(x => x.feature === 'ddPaymentEngineUIAdmin')[0]?.data[2];
        switch (pePermissions) {
            case '1':
                this.readFlag = true;
                this.writeFlag = false;
                break;
            case '3':
            case '7':
                this.readFlag = true;
                this.writeFlag = true;
                break;
            default:
                this.readFlag = false;
                this.writeFlag = false;
                break;
        }
    }

    getProtectionProductsAccessLevel() {
        return this._userProfileService.getProfile()
            .permission.policies.action.filter(t => t.silo === 'Vehicles' && t.feature === 'ddPaymentEngineUIAdmin')
            .map(t => t.accessLevel.length > 0 ? +t.accessLevel : 0)
            .reduce((a, b) => a > b ? a : b, 0);
    }

}