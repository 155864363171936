import { Pipe, PipeTransform } from "@angular/core";
import { DealerInfoService } from '../../../shared/services/dealer-info.service';
@Pipe({
    name: "disabledealercash"
})
export class DisableDealerCashPipe implements PipeTransform {
    constructor(
        private readonly dealerInfoService: DealerInfoService,
    ) { }
    transform(id: string): boolean {
        return (!this.dealerInfoService.isSETDealer() && id === 'dealerCash') ? true : false;
    }

}