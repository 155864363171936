import { Pipe, PipeTransform } from "@angular/core";
import { ILenderInfo } from "../../../shared/models/dealer-configuration.model";
import { IDealerDefinedCampaign } from "../../../shared/models/dealer-defined-campaigns.model";
import * as _ from 'lodash';

@Pipe({
    name: "lenderdisplayname"
})
export class LenderDisplayNamePipe implements PipeTransform {

    transform(
        lenderId: string,
        activeLenderList: ILenderInfo[],
        campaigns: IDealerDefinedCampaign[]
    ): string {

        let activeLender = _.find(activeLenderList, { id: lenderId });
        if (activeLender) {
            return `${activeLender.name} (${activeLender.maskedLenderName})`;
        }
        else {
            let inactiveLenderCampaign = _.find(campaigns, { lenderId });
            return inactiveLenderCampaign ? `${inactiveLenderCampaign.lenderName} (Inactive)` : 'UNKNOWN LENDER';
        }

    }
}