import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PEAuditComponent } from './pe-audit.component';

const routes: Routes = [
    {
        path: '',
        component: PEAuditComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PEAuditRoutingModule { }