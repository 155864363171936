<dd-spinner *ngIf="isLoading$ | async"></dd-spinner>
<div *ngIf="pPComponentService.protectionProductsForm" class="protection-product-container">
    <div class="row no-gutters tfs-pp-color" style="margin-top: -1px;">
        <div class="col-md-6 product-list-container">
            <app-protection-products-table [isOwnPpType]="true"></app-protection-products-table>
            <app-protection-products-table *ngIf="!pPComponentService.isSetDealer" [isOwnPpType]="false"></app-protection-products-table>
        </div>

        <div class="col-md-6 product-details-container">
            <app-protection-product-details></app-protection-product-details>
        </div>
    </div>
</div>