<span *ngIf="!this.taxorNotax">
<div class="bodyDiv container-fluid deal-validation-detail" *ngIf="calcResultResponse">
    <mat-label class="bodyDivHeader">{{'LOCALIZATION.DEAL_VALIDATION.DETAILS_TITLE' | translate:({"offerType": offerTypeTranslated})}}</mat-label>
    <div class="peng-detail-block-header">
       <span class="title text">
          <h1>{{'LOCALIZATION.DEAL_VALIDATION.VEHICLE_COST' | translate}}</h1>
       </span>
       <mat-divider role="separator" class="mat-divider mat-divider-horizontal line" aria-orientation="horizontal">
       </mat-divider>
    </div>
    <div class="cost-content">
 
       <div class="cost-classifications-container">
          <div class="row-container">
             <div> {{'LOCALIZATION.DEAL_VALIDATION.BASE_MODEL' | translate}}</div>
             <div>{{(calcResultResponse.quotes[1].Quote?.BasePrice | currency:'USD':true:'2.0') || "$0.00"}}</div>
          </div>
 
          <mat-accordion>
             <mat-expansion-panel [disabled] = "total === 0">
                <mat-expansion-panel-header>
 
                   <mat-panel-title>
                      <div>{{'LOCALIZATION.DEAL_VALIDATION.INSTALLED_PACKS' | translate}} </div>
                   </mat-panel-title>
                   <mat-panel-description>
                      <div>{{(total.toFixed(2) | currency:'USD':true:'2.0') || "$0.00"}} </div>
                   </mat-panel-description>
                </mat-expansion-panel-header>
          
                <div class="row-container" *ngFor="let data of vehicleDetailsResponse.options;  let i = index">
                  <div id="acc0"><span>{{data?.marketingName}}</span></div>
                   <div> {{(data?.dealerInvoicePrice | currency:'USD':true:'2.0') || "$0.00"}}  </div>
                </div>
                
             </mat-expansion-panel>
          </mat-accordion>
 
          <div class="row-container margin-up-three">
             <div> {{'LOCALIZATION.DEAL_VALIDATION.DELIVERYFEE' | translate}}</div>
             <div>{{(vehicleDetailsResponse.price?.dph | currency:'USD':true:'2.0') || "$0.00"}}</div>
          </div>
          <div class="row-container">
             <div>{{'LOCALIZATION.DEAL_VALIDATION.MSRP' | translate}}</div>
             <div> {{(vehicleDetailsResponse.price?.totalMsrp | currency:'USD':true:'2.0') || "$0.00"}} </div>
          </div>
          <div class="row-container">
             <div>
                <span><strong>{{'LOCALIZATION.DEAL_VALIDATION.SMARTPATHPRICE' | translate}}</strong></span>
             </div>
             <div> <strong>{{(vehicleDetailsResponse.price?.sellingPrice | currency:'USD':true:'2.0') || "$0.00"}} </strong></div>
          </div>
          <div class="row-container note-row">
             <div class="note"> {{'LOCALIZATION.DEAL_VALIDATION.SELLINGPRICE_NOTE' | translate}} </div>
          </div>
          <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal">
          </mat-divider>
          <mat-accordion >
             <mat-expansion-panel [disabled]="(taxtotal+feetotal) === 0">
                <mat-expansion-panel-header>
                 <mat-panel-title>
                      <div class="label" *ngIf="offerType === 'lease'"> {{'LOCALIZATION.DEAL_VALIDATION.CAPITALIZED_FEES' | translate}}</div>
                      <div class="label" *ngIf="offerType != 'lease'"> {{'LOCALIZATION.DEAL_VALIDATION.TAXES_FEES' | translate}}</div>
                   </mat-panel-title>
                   <mat-panel-description [ngClass]="{'disabledstyle': (taxtotal+feetotal) === 0}">
                      <div class="value">{{(((taxtotal+feetotal) | currency:'USD':true:'2.0') || "$0.00")}} </div>
                   </mat-panel-description>
                </mat-expansion-panel-header>
               
                <span *ngIf="calcResultResponse?.quotes[1].Quote.Taxes.length > 0">
                <div class="row-container" *ngFor="let taxdata of calcResultResponse?.quotes[1].Quote.Taxes">
                   <div>
                      {{taxdata.Name}}
                   </div>
                   <div *ngIf="offerType === 'lease'"> {{(taxdata.TotalCapitalized | currency:'USD':true:'2.0') || "$0.00"}} </div>
                   <div *ngIf="offerType != 'lease'"> {{(taxdata.Amount | currency:'USD':true:'2.0') || "$0.00"}} </div>
                </div>
                </span>
                <span *ngIf="calcResultResponse?.quotes[1].Quote.Fees.length > 0">
                <div class="row-container" *ngFor="let feedata of calcResultResponse?.quotes[1].Quote.Fees">
                 <div>
                      {{feedata.Name}}
                   </div>
                   <div> {{(feedata.Amount | currency:'USD':true:'2.0') || "$0.00"}} </div>
                </div>
               </span>
           
                </mat-expansion-panel>
          </mat-accordion>
          <div class="row-container">
                <span>
                  {{'LOCALIZATION.DEAL_VALIDATION.CALCULATEDZIP' | translate}}{{calcResultResponse.customerAddress?.zipCode}}
                </span>
             </div>
             <div class="row-container note-row">
                <div class="note">  {{'LOCALIZATION.DEAL_VALIDATION.CALCULATEDZIPNOTE' | translate}}</div>
             </div>
             <div class="row-container" *ngIf="offerType != 'cash'">
             <div *ngIf="offerType === 'finance'">{{'LOCALIZATION.DEAL_VALIDATION.DOWNPAY' | translate}}</div>
             <div *ngIf="offerType === 'lease'"> {{'LOCALIZATION.DEAL_VALIDATION.CASHDOWNPAY' | translate}}</div>
             <div>  {{(((getMultipliedValue(downpayment)) | currency:'USD') || "$0.00")}} </div>
          </div>
          <div class="row-container" *ngIf="offerType === 'lease'">
             <div>{{'LOCALIZATION.DEAL_VALIDATION.NETTRADE_VALUE' | translate}}<sup class="disclosure_num"></sup></div>
             <div> {{(nettradeindata | currency:'USD') || "$0.00"}}  </div>
             
          </div>
          
          <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal">
          </mat-divider>
          <div class="row-container" *ngIf="offerType === 'lease'">
             <div> <strong>{{'LOCALIZATION.DEAL_VALIDATION.ESTIMATED_NET_COST' | translate}}</strong></div>
             <div> <strong>{{(calcResultResponse.quotes[1].Quote?.AdjustedCapitalizedCost | currency:'USD':true:'2.0') || "$0.00"}}</strong> </div>
          </div>
          <div class="row-container note-row" *ngIf="offerType === 'lease'">
             <div class="note">{{'LOCALIZATION.DEAL_VALIDATION.ESTIMATED_NET_COST_NOTE' | translate}} </div>
          </div>
       </div>
      
       <div id="whatsRequired" class="peng-detail-block-header" *ngIf="offerType === 'lease'">
          <span class="title text">
             <h1>{{'LOCALIZATION.DEAL_VALIDATION.REQUIRED_SIGNING' | translate}}</h1>
          </span>
          <mat-divider role="separator" class="mat-divider mat-divider-horizontal line" aria-orientation="horizontal">
          </mat-divider>
       </div>
       <div class="cost-classifications-container">
          <div class="row-container" *ngIf="offerType === 'lease'">
             <div>{{'LOCALIZATION.DEAL_VALIDATION.FIRSTMONTH_PAY' | translate}}</div>
             <div> {{(calcResultResponse.quotes[1].Quote?.RegularPayment | currency:'USD':true:'2.0') || "$0.00"}} </div>
          </div>
          <div class="row-container" *ngIf="offerType === 'lease'">
             <div>{{'LOCALIZATION.DEAL_VALIDATION.SECURITY_DEPOSIT' | translate}}</div>
             <div>{{(calcResultResponse.quotes[1].Quote?.SecurityDeposit | currency:'USD':true:'2.0') || "$0.00"}}</div>
          </div>
          <mat-accordion *ngIf="offerType === 'lease'">
             <mat-expansion-panel [disabled]="calcResultResponse.quotes[1].Quote?.TotalUpfrontTaxesAndFees === 0">
                <mat-expansion-panel-header>
 
                   <mat-panel-title>
                      <div class="label">{{'LOCALIZATION.DEAL_VALIDATION.NON_CAPITALIZED_TAX_FEES' | translate}}</div>
                   </mat-panel-title>
                   <mat-panel-description [ngClass]="{'disabledstyle': calcResultResponse.quotes[1].Quote?.TotalUpfrontTaxesAndFees === 0}">
                      <div class="value"> {{(calcResultResponse.quotes[1].Quote?.TotalUpfrontTaxesAndFees | currency:'USD':true:'2.0') || "$0.00"}} </div> 
                   </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row-container" *ngIf="calcResultResponse.quotes[1].Quote?.TotalUpfrontTaxes > 0">
                   <div>
                     {{'LOCALIZATION.DEAL_VALIDATION.NON_CAPITALIZED_STATETAX' | translate}}
                  </div>
                   <div> {{(calcResultResponse.quotes[1].Quote?.TotalUpfrontTaxes | currency:'USD':true:'2.0') || "$0.00"}} </div>
                </div>
                <div class="row-container" *ngIf="calcResultResponse.quotes[1].Quote?.TotalUpfrontFees > 0">
                   <div>
                     {{'LOCALIZATION.DEAL_VALIDATION.NON_CAPITALIZED_FEES' | translate}}
                   </div>
                   <div> {{(calcResultResponse.quotes[1].Quote?.TotalUpfrontFees | currency:'USD':true:'2.0') || "$0.00"}} </div>
                </div>
             </mat-expansion-panel>
          </mat-accordion>
          <div class="row-container margin-up-two" *ngIf="offerType === 'lease'">
             <div> {{'LOCALIZATION.DEAL_VALIDATION.DOWNPAY' | translate}}</div>
             <div> {{(downpayment | currency:'USD') || "$0.00"}}</div>
          </div>
          <div class="row-container" *ngIf="offerType === 'lease'">
             <div> <strong>{{'LOCALIZATION.DEAL_VALIDATION.CASHOUT' | translate}}</strong></div>
             <div> <strong>{{(calcResultResponse.quotes[1].Quote?.AmountDueOnDelivery | currency:'USD':true:'2.0') || "$0.00"}}</strong> </div>
          </div>
          <div class="row-container note-row"  *ngIf="offerType === 'lease'">
             <div class="note">{{'LOCALIZATION.DEAL_VALIDATION.CASHOUT_NOTE' | translate}}</div>
          </div>
          <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal">
          </mat-divider>
          <div class="row-container">
             <div>{{'LOCALIZATION.DEAL_VALIDATION.NETTRADE_VALUE' | translate}}<sup class="disclosure_num"></sup></div>
             <div> {{(nettradeindata | currency:'USD' || "$0.00")}}  </div>
          </div>
          <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal">
          </mat-divider>
          <div class="row-container"  *ngIf="offerType === 'lease'">
             <div> <strong>{{'LOCALIZATION.DEAL_VALIDATION.AMOUNT_DUE' | translate}}</strong></div>
             <div><strong> {{(calcResultResponse.quotes[1].Quote?.TotalAmountDueAtSigning | currency:'USD':true:'2.0') || "$0.00"}}</strong></div> 
          </div>
          <div class="row-container"  *ngIf="offerType === 'finance'">
             <div> <strong>{{'LOCALIZATION.DEAL_VALIDATION.AMOUNT_FINANCED' | translate}}</strong></div>
             <div><strong> {{(calcResultResponse.quotes[1].Quote?.AmountFinanced | currency:'USD':true:'2.0') || "$0.00"}}  </strong></div>
          </div>
          <div class="row-container"  *ngIf="offerType === 'cash'">
             <div> <strong>{{'LOCALIZATION.DEAL_VALIDATION.NET_AMOUNT_DUE' | translate}}</strong></div>
             <div><strong>{{(calcResultResponse.quotes[1].Quote?.TotalCashSellingPrice | currency:'USD':true:'2.0') || "$0.00"}}</strong></div>
          </div>
          <div class="row-container note-row"  *ngIf="offerType === 'lease'">
             <div class="note">{{'LOCALIZATION.DEAL_VALIDATION.NET_AMOUNT_DUE_NOTE' | translate}} </div>
          </div>
       </div>
    </div>
 </div>
 </span>
 <span *ngIf="this.taxorNotax">
   <div class="bodyDiv container-fluid" *ngIf="calcResultResponse">
       <mat-label class="bodyDivHeader">{{'LOCALIZATION.DEAL_VALIDATION.DETAILS_TITLE' | translate:({"offerType": offerTypeTranslated})}}</mat-label>
       <div class="peng-detail-block-header">
          <span class="title text">
             <h1>{{'LOCALIZATION.DEAL_VALIDATION.VEHICLE_COST' | translate}}</h1>
          </span>
          <mat-divider role="separator" class="mat-divider mat-divider-horizontal line" aria-orientation="horizontal">
          </mat-divider>
       </div>
       <div class="cost-content">
       <div class="cost-classifications-container">
             <div class="row-container">
                <div> {{'LOCALIZATION.DEAL_VALIDATION.BASE_MODEL' | translate}} </div>
                <div>{{(calcResultResponse.quotes[0].Quote?.BasePrice | currency:'USD':true:'2.0') || "$0.00"}}</div>
             </div>
            <mat-accordion>
                <mat-expansion-panel [disabled] = "total === 0">
                   <mat-expansion-panel-header>
    
                      <mat-panel-title>
                         <div> {{'LOCALIZATION.DEAL_VALIDATION.INSTALLED_PACKS' | translate}} </div>
                      </mat-panel-title>
                      <mat-panel-description>
                         <div>{{(total.toFixed(2) | currency:'USD':true:'2.0') || "$0.00"}} </div>
                      </mat-panel-description>
                   </mat-expansion-panel-header>
                <div class="row-container" *ngFor="let data of vehicleDetailsResponse.options;  let i = index">
                     <div id="acc0"><span>{{data?.marketingName}}</span></div>
                      <div> {{(data?.dealerInvoicePrice | currency:'USD':true:'2.0') || "$0.00"}}  </div>
                   </div>
                </mat-expansion-panel>
             </mat-accordion>
             <div class="row-container margin-up-three">
                <div> {{'LOCALIZATION.DEAL_VALIDATION.DELIVERYFEE' | translate}}</div>
                <div>{{(vehicleDetailsResponse.price?.dph | currency:'USD':true:'2.0') || "$0.00"}}</div>
             </div>
             <div class="row-container">
                <div> {{'LOCALIZATION.DEAL_VALIDATION.MSRP' | translate}}</div>
                <div> {{(vehicleDetailsResponse.price?.totalMsrp | currency:'USD':true:'2.0') || "$0.00"}} </div>
             </div>
             <div class="row-container">
                <div>
                   <span><strong>{{'LOCALIZATION.DEAL_VALIDATION.SMARTPATHPRICE' | translate}}</strong></span>
                </div>
                <div> <strong>{{(vehicleDetailsResponse.price?.sellingPrice | currency:'USD':true:'2.0') || "$0.00"}} </strong></div>
             </div>
             <div class="row-container note-row">
                <div class="note"> {{'LOCALIZATION.DEAL_VALIDATION.SELLINGPRICE_NOTE' | translate}} </div>
             </div>
             <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal">
             </mat-divider>
             <mat-accordion>
                <mat-expansion-panel [disabled]="true">
                   <mat-expansion-panel-header>
                    <mat-panel-title>
                         <div class="label" *ngIf="offerType === 'lease'"> {{'LOCALIZATION.DEAL_VALIDATION.CAPITALIZED_FEES' | translate}}</div>
                         <div class="label" *ngIf="offerType != 'lease'">{{'LOCALIZATION.DEAL_VALIDATION.TAXES_FEES' | translate}}</div>
                      </mat-panel-title>
                      <mat-panel-description class="disabledstyle">
                         <div class="value">"$0.00" </div>
                      </mat-panel-description>
                   </mat-expansion-panel-header>
                  </mat-expansion-panel>
             </mat-accordion>
             <div class="row-container">
                   <span>
                     {{'LOCALIZATION.DEAL_VALIDATION.CALCULATEDZIP' | translate}}{{calcResultResponse.customerAddress?.zipCode}}
                   </span>
                </div>
                <div class="row-container note-row">
                   <div class="note"> {{'LOCALIZATION.DEAL_VALIDATION.CALCULATEDZIPNOTE' | translate}} </div>
                </div>
                <div class="row-container" *ngIf="offerType != 'cash'">
                <div *ngIf="offerType === 'finance'"> {{'LOCALIZATION.DEAL_VALIDATION.DOWNPAY' | translate}}</div>
                <div *ngIf="offerType === 'lease'"> {{'LOCALIZATION.DEAL_VALIDATION.CASHDOWNPAY' | translate}}</div>
                <div>  {{(((getMultipliedValue(downpayment)) | currency:'USD') || "$0.00")}} </div>
             </div>
             <div class="row-container" *ngIf="offerType === 'lease'">
                <div> {{'LOCALIZATION.DEAL_VALIDATION.NETTRADE_VALUE' | translate}}<sup class="disclosure_num"></sup></div>
                <div> {{(nettradeindata | currency:'USD') || "$0.00"}}  </div>
                </div>
             <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal">
             </mat-divider>
             <div class="row-container" *ngIf="offerType === 'lease'">
                <div> <strong>{{'LOCALIZATION.DEAL_VALIDATION.ESTIMATED_NET_COST' | translate}}</strong></div>
                <div> <strong>{{(calcResultResponse.quotes[0].Quote?.AdjustedCapitalizedCost | currency:'USD':true:'2.0') || "$0.00"}}</strong> </div>
             </div>
             <div class="row-container note-row" *ngIf="offerType === 'lease'">
                <div class="note"> {{'LOCALIZATION.DEAL_VALIDATION.ESTIMATED_NET_COST_NOTE' | translate}} </div>
             </div>
          </div>
         
          <div id="whatsRequired" class="peng-detail-block-header" *ngIf="offerType === 'lease'">
             <span class="title text">
                <h1>{{'LOCALIZATION.DEAL_VALIDATION.REQUIRED_SIGNING' | translate}}</h1>
             </span>
             <mat-divider role="separator" class="mat-divider mat-divider-horizontal line" aria-orientation="horizontal">
             </mat-divider>
          </div>
          <div class="cost-classifications-container">
             <div class="row-container" *ngIf="offerType === 'lease'">
                <div> {{'LOCALIZATION.DEAL_VALIDATION.FIRSTMONTH_PAY' | translate}}</div>
                <div> {{(calcResultResponse.quotes[0].Quote?.RegularPayment | currency:'USD':true:'2.0') || "$0.00"}} </div>
             </div>
             <div class="row-container" *ngIf="offerType === 'lease'">
                <div> {{'LOCALIZATION.DEAL_VALIDATION.SECURITY_DEPOSIT' | translate}}</div>
                <div> {{((calcResultResponse.quotes[0].Quote?.SecurityDeposit | currency:'USD':true:'2.0') || "$0.00")}} </div>
             </div>
             <mat-accordion *ngIf="offerType === 'lease'" >
                <mat-expansion-panel [disabled]="true">
                   <mat-expansion-panel-header>
    
                      <mat-panel-title>
                         <div class="label">{{'LOCALIZATION.DEAL_VALIDATION.NON_CAPITALIZED_TAX_FEES' | translate}} </div>
                      </mat-panel-title>
                      <mat-panel-description class="disabledstyle">
                         <div class="value"> "$0.00" </div> 
                      </mat-panel-description>
                   </mat-expansion-panel-header>
                   <div class="row-container" *ngIf="calcResultResponse.quotes[0].Quote?.TotalUpfrontTaxes > 0">
                      <div>
                        {{'LOCALIZATION.DEAL_VALIDATION.NON_CAPITALIZED_STATETAX' | translate}}
                      </div>
                      <div> {{(calcResultResponse.quotes[0].Quote?.TotalUpfrontTaxes | currency:'USD':true:'2.0') || "$0.00"}} </div>
                   </div>
                   <div class="row-container" *ngIf="calcResultResponse.quotes[0].Quote?.TotalUpfrontFees > 0">
                      <div>
                        {{'LOCALIZATION.DEAL_VALIDATION.NON_CAPITALIZED_FEES' | translate}}
                      </div>
                      <div> {{(calcResultResponse.quotes[0].Quote?.TotalUpfrontFees | currency:'USD':true:'2.0') || "$0.00"}} </div>
                   </div>
                </mat-expansion-panel>
             </mat-accordion>
             <div class="row-container margin-up-two" *ngIf="offerType === 'lease'">
                <div>{{'LOCALIZATION.DEAL_VALIDATION.DOWNPAY' | translate}}</div>
                <div> {{(downpayment | currency:'USD') || "$0.00"}}</div>
             </div>
             <div class="row-container" *ngIf="offerType === 'lease'">
                <div> <strong>{{'LOCALIZATION.DEAL_VALIDATION.CASHOUT' | translate}}</strong></div>
                <div> <strong>{{(calcResultResponse.quotes[0].Quote?.AmountDueOnDelivery | currency:'USD':true:'2.0') || "$0.00"}}</strong> </div>
             </div>
             <div class="row-container note-row"  *ngIf="offerType === 'lease'">
                <div class="note">{{'LOCALIZATION.DEAL_VALIDATION.CASHOUT_NOTE' | translate}}</div>
             </div>
             <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal">
             </mat-divider>
             <div class="row-container">
                <div>{{'LOCALIZATION.DEAL_VALIDATION.NETTRADE_VALUE' | translate}}<sup class="disclosure_num"></sup></div>
                <div> {{(nettradeindata | currency:'USD' || "$0.00")}}  </div>
             </div>
             <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal">
             </mat-divider>
             <div class="row-container"  *ngIf="offerType === 'lease'">
                <div> <strong>{{'LOCALIZATION.DEAL_VALIDATION.AMOUNT_DUE' | translate}}</strong></div>
                <div><strong> {{(calcResultResponse.quotes[0].Quote?.TotalAmountDueAtSigning | currency:'USD':true:'2.0') || "$0.00"}}</strong></div> 
             </div>
             <div class="row-container"  *ngIf="offerType === 'finance'">
                <div> <strong>{{'LOCALIZATION.DEAL_VALIDATION.AMOUNT_FINANCED' | translate}}</strong></div>
                <div><strong> {{(calcResultResponse.quotes[0].Quote?.AmountFinanced | currency:'USD':true:'2.0') || "$0.00"}}  </strong></div>
             </div>
             <div class="row-container"  *ngIf="offerType === 'cash'">
                <div> <strong>{{'LOCALIZATION.DEAL_VALIDATION.NET_AMOUNT_DUE' | translate}}</strong></div>
                <div><strong>{{(calcResultResponse.quotes[0].Quote?.TotalCashSellingPrice | currency:'USD':true:'2.0') || "$0.00"}}</strong></div>
             </div>
             <div class="row-container note-row"  *ngIf="offerType === 'lease'">
                <div class="note"> {{'LOCALIZATION.DEAL_VALIDATION.NET_AMOUNT_DUE_NOTE' | translate}} </div>
             </div>
          </div>
       </div>
    </div>
    </span>