<dd-spinner *ngIf="selectCampaignLoading$ | async"></dd-spinner>
<div class="d-flex campaign-bar">
    <div class="campaign-status">
        <span [ngClass]="{'active': isActive}" (click)="!isActive && toggleActive()">
            {{'LOCALIZATION.CAMPAIGNS.ACTIVE' | translate}}</span>
        <span class="divider"></span>
        <span [ngClass]="{'active': !isActive}" (click)="isActive && toggleActive()">
            {{'LOCALIZATION.CAMPAIGNS.EXPIRED' | translate}}</span>
    </div>
    <div *ngIf="!(disableActions$ | async)" class="d-flex">
        <input type="button" class="primary-button small-button ml-auto mt-4 mr-4" (click)="addCampaign()"
            value="{{'LOCALIZATION.CAMPAIGNS.ADD_CAMPAIGN' | translate}}" />
    </div>
</div>
<ng-container *ngFor="let lenderId of lenderList$ | async">
    <div *ngIf="((campaigns$ | async) | getcampaignsbylender : lenderId).length > 0"
        class="lender-container m-3 px-4 pb-4  pt-2">
        <strong class="d-block mb-2">{{lenderId | lenderdisplayname : (activeLenderList$ | async) : (campaigns$ |
            async)}}</strong>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let campaign of (campaigns$ | async) | getcampaignsbylender : lenderId">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'LOCALIZATION.CAMPAIGNS.CAMPAIGN_NAME' | translate}}: {{campaign.title}}
                    </mat-panel-title>
                    <mat-panel-title>
                        {{'LOCALIZATION.CAMPAIGNS.START_DATE' | translate}}: {{campaign.startDate}}
                    </mat-panel-title>
                    <mat-panel-title>
                        {{'LOCALIZATION.CAMPAIGNS.END_DATE' | translate}}: {{campaign.endDate}}
                    </mat-panel-title>
                    <mat-panel-title class="fw-bold" [ngClass]="'campaign-' + campaignStatus"
                        *ngIf="(campaign.endDate | getcampaignstatus : campaign.status) as campaignStatus">
                        {{'LOCALIZATION.CAMPAIGNS.STATUS' | translate}}: {{campaignStatus | titlecase}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-pe-view-campaign [campaign]="campaign"></app-pe-view-campaign>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-container>