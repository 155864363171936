import { ProtectionProductsComponentService } from '../protection-products.component.service';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { IPennProductDefaults } from '../../../shared/models/protection-products.model';

@Pipe({
    name: 'checkproductdefaultsloaded'
})
export class CheckProductDefaultsLoadedPipe implements PipeTransform {

    constructor() { }

    transform(productName: string, providerName: string, pennProductDefaultsList: IPennProductDefaults[]): boolean {
        if (pennProductDefaultsList.length > 0) {
            return !!_.find(pennProductDefaultsList, { providerName, productName });
        }
        return false;
    }

}
