import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { UserProfileService } from "@toyota/dd365-platform-library";
import { OfferTypes } from "../../shared/enums/offer-types.enum";
import {
  IDealerConfiguration,
  IMarkups,
  IMaxStateApr,
  IOfferTypesWithSubvention,
  Residue,
} from "../../shared/models/dealer-configuration.model";
import * as dealerConfigurationActions from "../../store/dealer-configuration/dealer-configuration.actions";
import { DealerConfigurationState } from "../../store/dealer-configuration/dealer-configuration.reducer";
import {
  selectDealerConfiguration,
  selectDisableActions,
  selectIsLoading,
  selectOfferMarkupMasterDataReady,
  selectIsLoadingBankConfig,
  selectBankConfigLoaded,
  selectOfferConfig
} from "../../store/dealer-configuration/dealer-configuration.selectors";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { AccessControlService } from "../../shared/services/access-control.service";
import { FinanceSources } from "../../../../app/models/enums";
import { SubscriptionList, unsubscribeSubscriptions } from "../../shared/services/util.service";
import { DealerInfoService } from '../../shared/services/dealer-info.service';
import { IBankOffer } from "../../shared/models/bank-configuration.model";
import { filter, take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-offer-markup",
  templateUrl: "./offer-markup.component.html",
  styleUrls: ["./offer-markup.component.scss"],
})
export class OfferMarkupComponent implements OnInit, OnDestroy {
  maxTermRange: any = 60;
  empsClass;
  residueFinalArrayValue: Residue;
  offerMarkupForm: FormGroup;
  isLoading$: Observable<boolean>;
  private subs: SubscriptionList = {};
  readFlag: boolean = true;
  dealerCode: string;
  gettingMocked: Residue = [];
  finalResidueData: Residue = [];
  selectOfferMarkupMasterDataReady$: any;
  selectResidueMarkupMasterDataReady$: any;
  selectDealerConfiguration$: any;
  selectResidueConfiguration$: any;
  distributor: string;
  markups: IMarkups[];
  user: any;
  minTermRange: any = 0;
  writeFlag: boolean;
  daysToFirstPayment: number;
  bankDefaultRate: boolean = false;
  defaultTerms = [24, 36, 39, 42, 48, 51, 54, 60];
  terms = [24, 36, 39, 42, 48, 51, 54, 60];
  totalList = [
    {
      modelYear: 2022,
      modelCode: 8642,
      twoFour: 62,
      threeSix: 60,
      threeNine: 58,
      fourTwo: 56,
      fourEight: 52,
      fiveOne: 50,
      fiveFour: 48,
      sixZero: 46,
    },
    {
      modelYear: 2022,
      modelCode: 8642,
      twoFour: 62,
      threeSix: 60,
      threeNine: 58,
      fourTwo: 56,
      fourEight: 52,
      fiveOne: 50,
      fiveFour: 48,
      sixZero: 46,
    },
    {
      modelYear: 2022,
      modelCode: 8642,
      twoFour: 62,
      threeSix: 60,
      threeNine: 58,
      fourTwo: 56,
      fourEight: 52,
      fiveOne: 50,
      fiveFour: 48,
      sixZero: 46,
    },
    {
      modelYear: 2022,
      modelCode: 8642,
      twoFour: 62,
      threeSix: 60,
      threeNine: 58,
      fourTwo: 56,
      fourEight: 52,
      fiveOne: 50,
      fiveFour: 48,
      sixZero: 46,
    },
  ];
  fundingSources = [
    {
      label: "TFS",
      value: "TFS",
    },
    {
      label: "SETF",
      value: "SETF",
    },
    {
      label: "Dealer Defined",
      value: "DEALERDEFINED",
    },
  ];
  offerTypesWithSubvention: IOfferTypesWithSubvention[] = [
    {
      offerType: OfferTypes.FINANCE,
      offerSubType: "Standard APR",
      row: "financeStandard",
      offerSubvented: false,
      tooltip: "Annual Percentage Rate",
    },
    {
      offerType: OfferTypes.FINANCE,
      offerSubType: "Special APR",
      row: "financeSubvention",
      offerSubvented: true,
      tooltip: "Annual Percentage Rate",
    },
    {
      offerType: OfferTypes.LEASE,
      offerSubType: "Standard RCF",
      row: "leaseStandard",
      offerSubvented: false,
      tooltip: "Rent Charge Factor",
    },
    {
      offerType: OfferTypes.LEASE,
      offerSubType: "Special RCF",
      row: "leaseSubvention",
      offerSubvented: true,
      tooltip: "Rent Charge Factor",
    },
  ];
  dealerConfig: IDealerConfiguration;
  toyotaDealer: boolean = true;
  fundingSource: string;
  k: number = 0;
  errMsg: any;
  days = [
    {
      label: "30 ",
      value: 30,
    },
    {
      label: "45 ",
      value: 45,
    },
    {
      label: "60 ",
      value: 60,
    },
    {
      label: "90 ",
      value: 90,
    },
    {
      label: "180 ",
      value: 180,
    },
  ];
  scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  selectedOption: string = "";
  skillsForm: FormGroup;
  residueFormData: FormGroup;
  Residueinterface: Residue;
  maxStateApr: IMaxStateApr;
  vehicleTypeArray: any;
  datatoarray: any;
  disableActions$: Observable<boolean>;
  disableFlag: boolean = false;
  selectIsLoadingBankConfig$: Observable<boolean>;
  selectBankConfigLoaded$: Observable<boolean>;
  offerMarkupConfig: IBankOffer;

  constructor(
    private accessControlService: AccessControlService,
    private readonly formBuilder: FormBuilder,
    private _userProfileService: UserProfileService,
    private snackBar: MatSnackBar,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly router: Router,
    private readonly dealerInfoService: DealerInfoService,
    private readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.getUserProfile();
    if (this.readFlag) {
      this.restoreAll();
    }
    this.initForm();
    this.isLoading$ = this.dealerConfigurationState.select(selectIsLoading);
    this.disableActions$ = this.dealerConfigurationState.select(selectDisableActions);
    this.selectOfferMarkupMasterDataReady$ =
      this.dealerConfigurationState.select(selectOfferMarkupMasterDataReady);
    this.selectDealerConfiguration$ = this.dealerConfigurationState.select(
      selectDealerConfiguration
    );
    this.selectIsLoadingBankConfig$ = this.dealerConfigurationState.select(selectIsLoadingBankConfig);
    this.selectBankConfigLoaded$ = this.dealerConfigurationState.select(selectBankConfigLoaded);
    this.dealerConfigurationState.select(selectBankConfigLoaded).pipe(take(1), filter((t) => !t))
      .subscribe(() => {
        this.dealerConfigurationState.dispatch(
          new dealerConfigurationActions.LoadBankConfiguration(
            {
              distributor: this.dealerInfoService.getDealerBankDistributor()
            }));
      });
    this.subs.selectSystemConfigSub = this.dealerConfigurationState
      .select(selectOfferConfig)
      .subscribe((offerConfig) => {
        if (offerConfig) {
          this.offerMarkupConfig = offerConfig;
        }
      });
    this.selectDealerConfiguration$
      .pipe()
      .subscribe((dealerConfiguration: IDealerConfiguration) => {
        if (dealerConfiguration) {
          this.dealerConfig = JSON.parse(JSON.stringify(dealerConfiguration));
          // If distributor is GST or undefined, display finance source as TFS 
          const financeSource = dealerConfiguration.daInfo[0]?.distributor || FinanceSources.TMNA;
          this.distributor = financeSource === FinanceSources.GST ? FinanceSources.TMNA : financeSource;
          this.markups =
            dealerConfiguration.policy.global.financeSource[0].markups;
          const allTypeMarkups = _.cloneDeep(
            this.markups.filter((x) => x.hint === "all")
          );
          this.markups = this.markups.filter((x) => x.hint !== "all");
          const groupedAllTypeMarkups = _.groupBy(allTypeMarkups, (t) =>
            `${t.offerType}-${t.offerSubvented}-${t.vehicleMake}-${t.term.min}-${t.term.max}`
          );
          _.forEach(groupedAllTypeMarkups, (markups, _key) => {
            this.markups.push({ ...markups[0], vehicleType: "all", saleClass: "all" });
          });
          this.bankDefaultRate = dealerConfiguration.policy.global
            .financeSource[0].markups.length
            ? true
            : false;
          this.daysToFirstPayment =
            dealerConfiguration.policy.global.financeSource[0].daysToFirstPayment;
          this.maxStateApr = dealerConfiguration.maxStateApr;
          this.buildForm();
        }
      });


    this.disableActions$.subscribe((disable: boolean) => {
      this.disableFlag = disable;
      if (disable) {
        this.offerMarkupForm.disable();
      } else {
        this.offerMarkupForm.enable();
      }
    })

  }

  onKeydown(val: boolean) {
    if (val) {
      return false;
    }
  }

  getMinimumValue(event: any) {
    this.minTermRange = event;
    if (this.minTermRange === "") {
      this.minTermRange = "0";
    }
    this.terms = this.defaultTerms;
    const terms2 = [];
    for (let i = 0; i < this.terms.length; i++) {
      if (
        this.minTermRange <= this.terms[i] &&
        this.terms[i] <= this.maxTermRange
      ) {
        terms2.push(this.terms[i]);
      }
    }
    this.terms = terms2;
  }

  getMaximumValue(event: any) {
    this.maxTermRange = event;
    if (this.maxTermRange === "") {
      this.maxTermRange = "60";
    }
    this.terms = this.defaultTerms;
    const terms2 = [];
    for (let i = 0; i < this.terms.length; i++) {
      if (
        this.maxTermRange >= this.terms[i] &&
        this.terms[i] >= this.minTermRange
      ) {
        terms2.push(this.terms[i]);
      }
    }
    this.terms = terms2;
  }

  initForm() {
    const markups = this.formBuilder.array([]);
    this.offerMarkupForm = this.formBuilder.group({
      name: "TFS",
      daysToFirstPayment: 0,
      daysToFirstPaymentEdit: 0,
      markups: markups,
    });

    this.getMarkups.valueChanges.subscribe(() => {
      this.getMarkups.controls.forEach((x) => {
        x.markAsDirty();
        x.updateValueAndValidity({ emitEvent: false });
      });
      this.getMarkups.updateValueAndValidity({ emitEvent: false });
    });
  }

  buildForm() {
    this.initForm();
    if (
      !(
        this.days.map(({ value }) => value).indexOf(this.daysToFirstPayment) >
        -1
      )
    ) {
      this.offerMarkupForm.controls.daysToFirstPaymentEdit.patchValue(
        this.daysToFirstPayment
      );
      this.offerMarkupForm.controls.daysToFirstPayment.patchValue("custom");
    } else {
      this.offerMarkupForm.controls.daysToFirstPayment.patchValue(
        this.daysToFirstPayment.toString()
      );
    }

    const markupsArray = this.offerMarkupForm.controls.markups as FormArray;
    if (this.markups && this.markups.length) {
      this.markups.forEach((element) => {
        const group = new FormGroup({
          offerSubvented: new FormControl(element.offerSubvented, [
            Validators.required,
          ]),
          offerType: new FormControl(element.offerType, [Validators.required]),
          term: new FormGroup({
            max: new FormControl(element.term.max, [Validators.required]),
            min: new FormControl(element.term.min, [Validators.required]),
          }),
          value: new FormControl(element.value, [Validators.required]),
          vehicleMake: new FormControl(element.vehicleMake, [
            Validators.required,
          ]),
          vehicleType: new FormControl(element.saleClass, [
            Validators.required,
          ])
        });
        group.setValidators(this.validateTerm());
        markupsArray.push(group);
      });
    }
    if (!this.writeFlag) {
      this.offerMarkupForm.disable();
    }

  }

  /**
   * To get the user profile
   *
   * @returns void
   */
  getUserProfile(): void {
    this.user = this._userProfileService.getProfile();
    this.dealerCode = this.user.dealerCd;
    this.toyotaDealer =
      this.user.dealer.brand.name.toLocaleLowerCase().toString() !== "toyota"
        ? false
        : true;
    if (this.toyotaDealer) {
      this.offerTypesWithSubvention = this.offerTypesWithSubvention.filter(
        (x) => x.row !== "financeSubvention"
      );
    }
    this.fundingSource = "TFS";
    const pePermissions = this.getPermissions();
    switch (pePermissions) {
      case "1":
        this.readFlag = true;
        this.writeFlag = false;
        break;
      case "3":
        this.readFlag = true;
        this.writeFlag = true;
        break;
      case "7":
        this.readFlag = true;
        this.writeFlag = true;
        break;
      default:
        this.readFlag = false;
        this.writeFlag = false;
        break;
    }
  }

  getPermissions(): any {
    return this.user["permission"]["policies"]["action"].filter(
      (x) => x.feature === "ddPaymentEngineUIAdmin"
    )[0]?.data[2];
  }

  /**
   * To restore the table values to last saved version
   *
   * @returns void
   */
  restoreAll(): void {

    this.dealerConfigurationState.dispatch(
      new dealerConfigurationActions.LoadDealerConfiguration({
        dealerCode: this.dealerCode,
      })
    );
  }

  getMarkupsByFilter(offerType: string, offerSubvented: boolean) {
    return (this.offerMarkupForm.get("markups") as FormArray).controls.filter(
      (x) =>
        x.value.offerSubvented === offerSubvented &&
        x.value.offerType.toLocaleLowerCase() === offerType.toLocaleLowerCase()
    );
  }

  /**
   * To add the table field value
   *
   * @returns void
   */

  /* istanbul ignore start */
  addFieldValue(offerType: string, offerSubvented: boolean): void {

    const existingMarkUpRows = (
      this.offerMarkupForm.get("markups") as FormArray
    ).controls.filter(
      (x) =>
        (x.value.vehicleType === "new" || x.value.vehicleType === "all") &&
        x.value.offerType === offerType.toLocaleLowerCase() &&
        x.value.offerSubvented === offerSubvented
    );
    const maxTerm = existingMarkUpRows.length
      ? Math.max.apply(
        Math,
        existingMarkUpRows.map(function (o) {
          return o.value.term.max;
        })
      )
      : 0;
    const defaultMarkUpRow = new FormGroup({
      offerSubvented: new FormControl(offerSubvented, [Validators.required]),
      offerType: new FormControl(offerType.toLocaleLowerCase(), [
        Validators.required,
      ]),
      term: new FormGroup({
        max: new FormControl(maxTerm ? maxTerm + 1 : 0, [Validators.required]),
        min: new FormControl(maxTerm ? maxTerm + 1 : 0, [Validators.required]),
      }),
      value: new FormControl(0, [Validators.required]),
      vehicleMake: new FormControl("toyota", [Validators.required]),
      vehicleType: new FormControl("new", [Validators.required])
    });
    defaultMarkUpRow.setValidators(this.validateTerm());
    const markupsArray = this.offerMarkupForm.controls.markups as FormArray;
    markupsArray.push(defaultMarkUpRow);
  }

  changeMake(e, markup) {
    const index = (
      this.offerMarkupForm.get("markups") as FormArray
    ).controls?.findIndex((x) => _.isEqual(x, markup));
    if (e.value === "ntl") {
      /* istanbul ignore next */
      (this.offerMarkupForm.get("markups") as FormArray)
        .at(index)
        .get("vehicleType")
        .setValue("used");
    }
    else {
      /* istanbul ignore next */
      (this.offerMarkupForm.get("markups") as FormArray)
        .at(index)
        .get("vehicleType")
        .setValue("new");
    }
  }

  changeType(e, markup) {
    const index = (
      this.offerMarkupForm.get("markups") as FormArray
    ).controls?.findIndex((x) => _.isEqual(x, markup));
    /* istanbul ignore next */
    (this.offerMarkupForm.get("markups") as FormArray)
      .at(index)
      .get("vehicleType")
      .setValue(e.value);
  }

  /**
   * To delete the table field value
   *
   * @returns void
   */

  deleteFieldValue(markup: any): void {
    const index = (
      this.offerMarkupForm.get("markups") as FormArray
    ).controls.findIndex((x) => _.isEqual(x, markup));
    (this.offerMarkupForm.get("markups") as FormArray).removeAt(index);

  }

  /**
   * Submit the form response.
   *
   * @returns void
   */
  onSubmit(e): void {
    e.preventDefault();
    if (!this.offerMarkupForm.invalid) {
      if (this.offerMarkupForm.value.daysToFirstPayment === "custom") {
        this.dealerConfig.policy.global.financeSource[0].daysToFirstPayment =
          parseInt(this.offerMarkupForm.value.daysToFirstPaymentEdit);
      } else {
        this.dealerConfig.policy.global.financeSource[0].daysToFirstPayment =
          parseInt(this.offerMarkupForm.value.daysToFirstPayment);
      }
      this.setMarkups();

    } else {
      this.snackBar.open(
        "Invalid input provided. Please fix the errors and try again.",
        "Dismiss"
      );
    }
  }

  setMarkups() {
    const finalResponse = [];
    const clonedMarkups = _.cloneDeep(this.offerMarkupForm.value.markups);
    const vehicleTypes = ["new", "used", "certified"];
    clonedMarkups.forEach((markup: IMarkups) => {
      let temp: any = [];
      if (markup.vehicleType === "all") {
        vehicleTypes.forEach((value) => {
          temp = Object.assign({}, markup);
          // temp.vehicleType = value;
          temp.saleClass = value;
          temp.hint = "all";
          finalResponse.push(temp);
        });
      }
      else {
        temp = Object.assign({}, markup);
        temp.saleClass = markup.vehicleType;
        finalResponse.push(temp);
      }
    });
    this.dealerConfig.policy.global.financeSource[0].markups = this
      .bankDefaultRate
      ? finalResponse
      : [];
    this.dealerConfig.policy.global.financeSource[0].name =
      this.offerMarkupForm.value.name;
    this.dealerConfig.region = `${this.user.dealer.region.code}0`;
    this.dealerConfigurationState.dispatch(
      new dealerConfigurationActions.UpdateDealerConfiguration({
        dealerCode: this.dealerCode,
        dealerConfiguration: this.dealerConfig,
      })
    );
  }

  isValidMaxTerm(group, markupType) {
    const max = group.get(["term", "max"]).value;
    const offerType = group.get("offerType").value;
    if (max > Math.max(...this.offerMarkupConfig[offerType].markup[markupType].map(o => o.maxTerm))) {
      group.get(["term", "max"]).setErrors({ markUpError: true, message: this.translateService.instant('LOCALIZATION.OFFER_MARKUP.MARKUP_ERROR_COMMON') });
    }
  }

  validateMarkup(group: FormGroup) {
    const offerSubvented = group.get("offerSubvented").value;
    const offerType = group.get("offerType").value;
    const vehicleMake = group.get("vehicleMake").value;
    const min = group.get(["term", "min"]).value;
    const max = group.get(["term", "max"]).value;
    const markupType = offerSubvented ? "subvented" : "standard";
    const percentage = offerType === "finance" ? "%" : "";
    const markupValue = group.get(["value"]).value;
    if (!offerSubvented && offerType === "finance") {
      this.isValidMaxTerm(group, markupType);
      this.offerMarkupConfig.finance.markup.standard.forEach((item: any) => {
        if (_.inRange(max, item.minTerm, item.maxTerm + 1)) {
          if (markupValue > item.max) {
            group.get('value').setErrors({ markUpError: true, message: this.translateService.instant('LOCALIZATION.OFFER_MARKUP.MARKUP_ERROR_1', { markupType: markupType, offerType: offerType, markupLimit: item.max, termLimit: max, percentage: percentage }) });
          }
        }
      });
    }
    if (!offerSubvented && offerType === "lease") {
      this.isValidMaxTerm(group, markupType);
      this.offerMarkupConfig.lease.markup.standard.forEach((item: any) => {
        if (markupValue > item.max) {
          group.get('value').setErrors({ markUpError: true, message: this.translateService.instant('LOCALIZATION.OFFER_MARKUP.MARKUP_ERROR_1', { markupType: markupType, offerType: offerType, markupLimit: item.max, termLimit: max, percentage: percentage }) });
        }
      });
    }
    if (offerSubvented && offerType === "finance") {
      this.isValidMaxTerm(group, markupType);
      this.offerMarkupConfig.finance.markup.subvented.forEach((item: any) => {
        if (markupValue > item.max) {
          group.get('value').setErrors({ markUpError: true, message: this.translateService.instant('LOCALIZATION.OFFER_MARKUP.MARKUP_ERROR_2', { markupType: markupType, offerType: offerType, markupLimit: item.max, termLimit: max, vehicleMake: vehicleMake, percentage: percentage }) });
        }
      });
    }
    if (offerSubvented && offerType === "lease") {
      this.isValidMaxTerm(group, markupType);
      this.offerMarkupConfig.lease.markup.subvented.forEach((item: any) => {
        if (markupValue > item.max) {
          group.get('value').setErrors({ markUpError: true, message: this.translateService.instant('LOCALIZATION.OFFER_MARKUP.MARKUP_ERROR_2', { markupType: markupType, offerType: offerType, markupLimit: item.max, termLimit: max, vehicleMake: vehicleMake, percentage: percentage }) });
        }
      });
    }


  }

  validateTerm(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const offerSubvented = group.get("offerSubvented").value;
      const offerType = group.get("offerType").value;
      const vehicleType = group.get("vehicleType").value;
      const vehicleMake = group.get("vehicleMake").value;
      const min = group.get(["term", "min"]).value;
      const max = group.get(["term", "max"]).value;
      const markups = (this.offerMarkupForm.get(
        "markups"
      ) as FormArray).controls.filter((x) => !_.isEqual(x, group));

      let filteredMarkups = markups.filter(
        (x) =>
          x.value.offerSubvented === offerSubvented &&
          x.value.offerType === offerType &&
          (vehicleMake === "all" || x.value.vehicleMake === "all" ||
            x.value.vehicleMake === vehicleMake) &&
          (vehicleType === "all" || x.value.vehicleType === "all" ||
            x.value.vehicleType === vehicleType) &&
          ((x.value.term.min >= min && x.value.term.min <= max) ||
            (x.value.term.max >= min && x.value.term.max <= max) ||
            (x.value.term.min <= min && x.value.term.max >= max))
      );
      if (this.offerMarkupConfig) {
        this.validateMarkup(group);
      }

      if (filteredMarkups?.length) {
        return { notEquivalent: true };
      } else if (min > max) {
        return { notEquivalent: true };
      } else {
        return;
      }
    };
  }

  getTooltip(control: AbstractControl, controlName?: string, offerType?: string): string {
    if (control.hasError('markUpError')) {
      return control.getError('message');
    }
    return '';
  }

  get getMarkups() {
    return this.offerMarkupForm.get("markups") as FormArray;
  }

  getMarkupIndex(group) {
    const index1 = (
      this.offerMarkupForm.get("markups") as FormArray
    ).controls.findIndex((x) => _.isEqual(x, group));
    return index1;
  }

  validateFormGroup(group: FormGroup, disabled?: boolean) {
    if (disabled) { return "offer-disabled" }
    return !group.valid && group.dirty ? "inputBoxRed" : "inputBoxBlack";
  }

  /**
   * To navigate to the next page
   *
   * @returns void
   */
  navigateToNextPage(): void {
    this.router.navigate(["pe-admin/lease-setting"]);
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }
}
