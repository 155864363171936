import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { OfferTypes } from '../../../shared/enums/offer-types.enum';
import { SalesClasses } from '../../../shared/enums/sales-classes.enum';

@Pipe({
    name: 'offersforsalesclass'
})
export class OffersForSalesClassPipe implements PipeTransform {

    transform(value: string): string[] {
        const name: string[] = [];
        switch (value) {
            case SalesClasses.NEW:
                name.push(...[OfferTypes.FINANCE, OfferTypes.LEASE]);
                break;
            case SalesClasses.CERTIFIED:
                name.push(...[OfferTypes.FINANCE, OfferTypes.LEASE]);
                break;
            case SalesClasses.USED:
                name.push(OfferTypes.FINANCE);
                break;
        }
        return name;
    }

}
