import produce from "immer";
import * as fromActions from "./deploy-job-management.actions";
import * as _ from "lodash";
import {
  IDeploymentList,
} from "../../shared/models/deploy-job-management.model";
import { IGetAuditLogsResponse, IGetDJAuditLogsResponse } from "../../shared/models/audit-log.model";

export interface DeployJobManageState {
  deployJob: IDeploymentList;
  deployJobConfig: IGetAuditLogsResponse;
  auditLog: IGetDJAuditLogsResponse;
  loading: boolean;
  configLoading: boolean;
  auditLoading: boolean,
  error: string;
}

export const deployJobManageInitialState: DeployJobManageState = {
  deployJob: null,
  deployJobConfig: null,
  auditLog: null,
  loading: true,
  configLoading: true,
  auditLoading: true,
  error: "",
};

export function deployJobManageReducer(
  state: DeployJobManageState = deployJobManageInitialState,
  action: fromActions.DeployJobManageActions
): DeployJobManageState {
  switch (action.type) {
    case fromActions.DeployJobManageActionTypes.LOAD_DJ_MANAGEMENT: {
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.error = "";
      });
    }
    case fromActions.DeployJobManageActionTypes.LOAD_DJ_MANAGEMENT_SUCCESS: {
      return produce(state, (draftState) => {
        draftState.deployJob =
          action instanceof fromActions.LoadDeployJobManageSuccess
            ? action.payload.deployJob
            : ({} as IDeploymentList);
        draftState.loading = false;
        draftState.error = "";
      });
    }
    case fromActions.DeployJobManageActionTypes.LOAD_DJ_MANAGEMENT_FAILURE: {
      return produce(state, (draftState) => {
        draftState.deployJob =
          action instanceof fromActions.LoadDeployJobManageFailure
            ? action.payload.deployJob
            : draftState.deployJob;
        draftState.loading = false;
        draftState.error =
          action instanceof fromActions.LoadDeployJobManageFailure
            ? action.payload.error
            : "";
      });
    }
    case fromActions.DeployJobManageActionTypes.LOAD_DJ_CONFIGURATION: {
      return produce(state, (draftState) => {
        if (!action.payload.isReloadDJConfig) {
          draftState.configLoading = true;
        }
        draftState.error = "";
      });
    }
    case fromActions.DeployJobManageActionTypes.LOAD_DJ_CONFIGURATION_SUCCESS: {
      return produce(state, (draftState) => {
        draftState.deployJobConfig =
          action instanceof fromActions.LoadDeployJobConfigSuccess
            ? action.payload.deployJobConfig
            : ({} as IGetAuditLogsResponse);
        draftState.configLoading = false;
        draftState.error = "";
      });
    }
    case fromActions.DeployJobManageActionTypes.LOAD_DJ_CONFIGURATION_FAILURE: {
      return produce(state, (draftState) => {
        draftState.deployJobConfig =
          action instanceof fromActions.LoadDeployJobConfigFailure
            ? action.payload.deployJobConfig
            : draftState.deployJobConfig;
        draftState.configLoading = false;
        draftState.error =
          action instanceof fromActions.LoadDeployJobConfigFailure
            ? action.payload.error
            : "";
      });
    }
    case fromActions.DeployJobManageActionTypes.LOAD_DJ_AUDITLOG: {
      return produce(state, (draftState) => {
        draftState.auditLoading = true;
        draftState.error = "";
      });
    }
    case fromActions.DeployJobManageActionTypes.LOAD_DJ_AUDITLOG_SUCCESS: {
      return produce(state, (draftState) => {
        draftState.auditLog =
          action instanceof fromActions.LoadAuditLogSuccess
            ? action.payload.auditLogs
            : ({} as IGetDJAuditLogsResponse);
        draftState.auditLoading = false;
        draftState.error = "";
      });
    }
    case fromActions.DeployJobManageActionTypes.LOAD_DJ_AUDITLOG_FAILURE: {
      return produce(state, (draftState) => {
        draftState.auditLog =
          action instanceof fromActions.LoadAuditLogFailure
            ? action.payload.auditLogs
            : draftState.auditLog;
        draftState.auditLoading = false;
        draftState.error =
          action instanceof fromActions.LoadAuditLogFailure
            ? action.payload.error
            : "";
      });
    }
    default:
      return state;
  }
}
