<div *ngIf="(dDCCService.vehicleConfigLoading$ | async) || (dDCCService.campaignLoading$ | async)">
    <dd-spinner>
    </dd-spinner>
</div>
<div class="close-dialog-icon-container d-flex">
    <mat-icon class="close-dialog-icon" mat-dialog-close>close</mat-icon>
</div>
<div class="common-campaign-container">
    <div class="modify-campaign-container">
        <div class="header">
            <div mat-dialog-title>
                {{('LOCALIZATION.CAMPAIGNS.'+ data.action + '_CAMPAIGN') | translate}}
            </div>
        </div>
    </div>
    <div [formGroup]="campaignForm" class="modify-campaign-container container-fluid p-3">
        <div class="d-flex">
            <div class="col-md-3">
                <mat-label class="d-flex mb-1 fw-bold required-val">
                    {{'LOCALIZATION.CAMPAIGNS.CAMPAIGN_NAME' | translate}}
                </mat-label>
                <input [ngClass]="{'input-error': campaignForm.controls.title.invalid}" formControlName="title"
                    class="campaign-input" type="text" maxlength="50" />
            </div>
            <div class="col-md-3">
                <mat-label class="d-flex mb-1 fw-bold required-val">
                    {{'LOCALIZATION.CAMPAIGNS.MAKE' | translate}}
                </mat-label>
                <mat-select [ngClass]="{'input-error': campaignForm.controls.make.invalid}" formControlName="make"
                    class="campaign-input" (selectionChange)="onMakeChange($event.value,makeChange)">
                    <mat-option *ngFor="let make of (brandList$ | async) | filterbrandlist" [value]="make.id">
                        {{make.name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="col-md-3">
                <mat-label class="d-flex mb-1 fw-bold required-val">
                    {{'LOCALIZATION.CAMPAIGNS.MODEL' | translate}}
                </mat-label>
                <mat-select [ngClass]="{'input-error': campaignForm.controls.model.invalid}" formControlName="model"
                    class="campaign-input" multiple selectAll>
                    <mat-option [value]="allOptionValue">{{'LOCALIZATION.COMMON.ALL' | translate}}</mat-option>
                    <mat-option *ngFor="let series of campaignForm.controls.make.value
                        | getbrandseries : (vehicleConfig$ | async)" [value]="series.code">
                        {{series.name}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="col-md-3">
                <mat-label class="d-flex mb-1 fw-bold required-val">
                    {{'LOCALIZATION.CAMPAIGNS.YEAR' | translate}}
                </mat-label>
                <mat-select [ngClass]="{'input-error': campaignForm.controls.year.invalid}" formControlName="year"
                    class="campaign-input" multiple selectAll>
                    <mat-option [value]="allOptionValue">{{'LOCALIZATION.COMMON.ALL' | translate}}</mat-option>
                    <mat-option *ngFor="let year of (yearMasterList$ | async)" [value]="year">
                        {{year}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="d-flex mt-2">
            <div class="col-md-3">
                <mat-label class="d-flex mb-1 fw-bold required-val">
                    {{'LOCALIZATION.CAMPAIGNS.START_DATE' | translate}}
                </mat-label>
                <input [ngClass]="{'input-error': campaignForm.controls.startDate.invalid ||
            campaignForm.errors?.InvalidDates}" formControlName="startDate" matInput autocomplete="off"
                    class="campaign-input" placeholder="mm/dd/yyyy" [matDatepicker]="startDate"
                    [matDatepickerFilter]="dDCCService.currentAndFutureDateCheck">
                <mat-datepicker-toggle disableRipple matSuffix [for]="startDate">
                    <mat-icon matDatepickerToggleIcon>
                        <em class="tmi tmi-date"></em>
                    </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </div>
            <div class="col-md-3">
                <mat-label class="d-flex mb-1 fw-bold required-val">
                    {{'LOCALIZATION.CAMPAIGNS.EXPIRATION_DATE' | translate}}
                </mat-label>
                <input [ngClass]="{'input-error': campaignForm.controls.endDate.invalid ||
            campaignForm.errors?.InvalidDates}" formControlName="endDate" matInput autocomplete="off"
                    class="campaign-input" placeholder="mm/dd/yyyy" [matDatepicker]="endDate"
                    [matDatepickerFilter]="dDCCService.currentAndFutureDateCheck">
                <mat-datepicker-toggle disableRipple matSuffix [for]="endDate">
                    <mat-icon matDatepickerToggleIcon>
                        <em class="tmi tmi-date"></em>
                    </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </div>
            <div class="col-md-3">
                <mat-label class="d-flex mb-1 fw-bold required-val">
                    {{'LOCALIZATION.CAMPAIGNS.BANK_SOURCE' | translate}}
                </mat-label>
                <input [ngClass]="{'input-error': campaignForm.controls.lenderId.invalid }" formControlName="lenderName"
                    class="campaign-input" type="text" floatLabel="never" [matAutocomplete]="bankSource" />
                <mat-autocomplete #bankSource="matAutocomplete" autoActiveFirstOption="false" panelWidth="auto">
                    <mat-option *ngFor="let lender of filteredLenderList$ | async" [value]="lender.name"
                        title="{{lender.name}} ({{lender.maskedLenderName}})">
                        {{lender.name}} ({{lender.maskedLenderName}})
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="col-md-3">
                <mat-label class="d-flex mb-1 fw-bold">
                    {{'LOCALIZATION.CAMPAIGNS.LENDER_INCENTIVE' | translate}}
                </mat-label>
                <input [ngClass]="{'input-error': campaignForm.controls.incentiveAmount.invalid}"
                    formControlName="incentiveAmount" class="campaign-input" type="number" step="any" min="0"
                    maxlength="5" />
            </div>
        </div>

        <div class="d-flex mt-1">
            <div class="col-md-12">
                <mat-label class=" d-flex fw-bold required-val">
                    {{'LOCALIZATION.CAMPAIGNS.DISCLAIMER' | translate}}
                </mat-label>
                <p class="disclaimer-for-disclosure mb-1">The sample disclaimers below for third-party rates offered by
                    you are not intended to address federal, state, or local law requirements, and Toyota makes not
                    representations or warranties about their accuracy, completeness, or compliance with applicable law.
                    Dealers are responsible for ensuring that the disclaimers included in these fields comply with all
                    applicable laws and include all the information necessary to ensure that the third-party rate and
                    related disclaimers being provided to the consumer are not misleading, deceptive, or incomplete.</p>
                <textarea [ngClass]="{'input-error': campaignForm.controls.dndText.invalid}" formControlName="dndText"
                    class="campaign-description form-outline" rows="5" placeholder="Enter Disclaimer" maxlength="2000">
                </textarea>
            </div>
        </div>
        <div class="d-flex mt-2">
            <div class="col-md-12">
                <mat-label class=" d-flex mb-1 fw-bold">
                    {{'LOCALIZATION.CAMPAIGNS.OTHER_RESTRICTIONS' | translate}}
                </mat-label>
                <textarea [ngClass]="{'input-error': campaignForm.controls.otherRestrictions.invalid}"
                    formControlName="otherRestrictions" class="campaign-description form-outline" rows="5"
                    placeholder="Enter Other Restrictions" maxlength="2000">
                </textarea>
            </div>
        </div>
    </div>
</div>

<div class=" d-flex mt-2 mr-3">
    <span *ngIf="campaignForm.errors?.ConflictingRules" class="campaign-error-span ml-4 my-auto">
        {{'LOCALIZATION.CAMPAIGNS.INVALID_UNDERLYING_RULES' | translate}}
    </span>
    <button class="secondary-button ml-auto mr-3" #matDialogCloseBtn mat-dialog-close>
        {{'LOCALIZATION.COMMON.CANCEL' | translate}}
    </button>
    <button type="button" class="primary-button" [disabled]="campaignForm.invalid" (click)="saveCampaign()">
        {{'LOCALIZATION.COMMON.' + data.action | translate}}
    </button>
</div>