import { IDealDetails, IQuoteCustomerInfo } from './../../../shared/models/pe-calculator.model';
import { SubscriptionList, unsubscribeSubscriptions } from './../../../shared/services/util.service';
import {
  selectDealCreditTiers, selectDealTerms, selectIsDealOffersLoaded,
  selectAvailableDealTypes, selectDealRates, selectDealerDetails, selectVehicleDetails,
} from './../../../store/deal-validation/deal-validation.selectors';
import { UserProfileService } from '@toyota/dd365-platform-library';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IBuilderRequest, IDealOfferForm, IDealValidationForm, IQuoteVehicleInfo, IRateValue } from './../../../shared/models/pe-builder.model';
import { DealValidationState } from './../../../store/deal-validation/deal-validation.reducer';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as dealValidationActions from '../../../store/deal-validation/deal-validation.actions';
import { combineLatest, Observable } from 'rxjs';
import { OfferTypes } from '../../../shared/enums/offer-types.enum';
import { DealerInfoService } from '../../../shared/services/dealer-info.service';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';
import { ValidationServiceService } from '../../../shared/services/validation-service.service'
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: 'app-pe-deal-validation-form',
  templateUrl: './deal-validation-form.component.html',
  styleUrls: ['./deal-validation-form.component.scss']
})
export class DealValidationFormComponent implements OnInit, OnDestroy {

  dealValidationForm: FormGroup;
  dealerCode: string;
  isDealOffersLoaded$: Observable<boolean>;
  dealTypes$: Observable<string[]>;
  dealTerms$: Observable<number[]>;
  dealTiers$: Observable<string[]>;
  dealRates$: Observable<IRateValue[]>;
  dealFormInitialState: IDealValidationForm;
  dealRequest: IBuilderRequest;
  customer: IQuoteCustomerInfo;
  vehicle: IQuoteVehicleInfo;
  dealdata: IDealDetails;
  showForm: boolean;
  @Input() selectedOffertypeData: string;
  offersSection

  private subs: SubscriptionList = {};

  constructor(
    private readonly dealValidationState: Store<DealValidationState>,
    private readonly userProfileService: UserProfileService,
    readonly dealerInfoService: DealerInfoService,
    private readonly formBuilder: FormBuilder,
    private readonly validationService: ValidationServiceService,
    readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    this.setupDealForm();
    this.setupControls();
    this.isDealOffersLoaded$ = this.dealValidationState.select(selectIsDealOffersLoaded);
    this.subs.selectIsDealOffersLoadedSub = this.isDealOffersLoaded$.subscribe(isLoaded => {
      if (isLoaded) {
        this.offerForm.enable();
      }
      else {
        this.offerForm.disable();
      }
    });
  }

  setupDealForm() {
    this.dealValidationForm = this.formBuilder.group({
      quote: this.formBuilder.group({
        vin: ['', [Validators.required, Validators.minLength(17), Validators.maxLength(17), Validators.pattern('^[a-zA-Z0-9]{17,}$')]],
        sellingPrice: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
      }),
      offer: this.formBuilder.group({
        zipCode: ['', [Validators.required, Validators.pattern('^[0-9]{5}(?:[- ]{1}[0-9]{4}){0,1}$')]],
        dealType: ['', [Validators.required]],
        level: [{ value: '', disabled: true }, [Validators.required]],
        term: [{ value: '', disabled: true }, [Validators.required]],
        fundingSource: ['', [Validators.required]],
        mileage: [{ value: '', disabled: true }, [Validators.required]],
        rate: [{ value: '', disabled: true }, [Validators.required]],
        disableQuoteWithoutTaxes: [false, [Validators.required]],
        downPayment: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        tradeIn: ['', [Validators.pattern('^[0-9]*$')]],
        incentive: ['',]
      })
    });
    this.dealFormInitialState = this.dealValidationForm.getRawValue();
  }

  setupControls() {
    this.dealTypes$ = this.dealValidationState.select(selectAvailableDealTypes);
    const offerTypeControl = this.offerForm.get(['dealType']);
    const creditTierControl = this.offerForm.get(['level']);
    const termControl = this.offerForm.get(['term']);
    const mileageControl = this.offerForm.get(['mileage']);
    const rateControl = this.offerForm.get(['rate']);
    this.subs.dealTypeSub = offerTypeControl.valueChanges.subscribe((offerType: string) => {
      switch (offerType) {
        case OfferTypes.LEASE:
          creditTierControl.enable();
          termControl.enable();
          rateControl.enable();
          mileageControl.enable();
          this.offerForm.controls['downPayment'].setValue(0);
          break;
        case OfferTypes.FINANCE:
          creditTierControl.enable();
          termControl.enable();
          rateControl.enable();
          mileageControl.disable();
          this.offerForm.controls['downPayment'].setValue('');
          break;
        default:
          creditTierControl.disable();
          termControl.disable();
          rateControl.disable();
          mileageControl.disable();
          this.offerForm.controls['downPayment'].setValue('');
          break;
      }

      this.dealTiers$ = this.dealValidationState.select(selectDealCreditTiers, offerType);
      this.dealTiers$.pipe(take(1)).subscribe(tierList => {
        if (_.indexOf(tierList, creditTierControl.value) < 0) {
          creditTierControl.setValue('');
        }
      });
      this.dealTerms$ = this.dealValidationState.select(selectDealTerms, offerType);
      this.dealTerms$.pipe(take(1)).subscribe(termList => {
        if (_.indexOf(termList, termControl.value) < 0) {
          termControl.setValue('');
        }
      });
      this.offerForm.updateValueAndValidity();
    });

    this.subs.dealRatesSub = combineLatest([
      offerTypeControl.valueChanges as Observable<string>,
      creditTierControl.valueChanges as Observable<string>,
      termControl.valueChanges as Observable<string>
    ])
      .subscribe(([offerType, level, term]) => {
        this.dealRates$ = this.dealValidationState.select(selectDealRates, { offerType, level, term });
        this.dealRates$.pipe(take(1)).subscribe(rateData => {
          const rateValue: IRateValue = rateControl.value;
          const rateList: number[] = rateData.map(t => t.rate);
          if (_.indexOf(rateList, rateValue.rate) < 0) {
            rateControl.setValue('');
          }
        });
      });
  }

  get quoteForm() {
    return this.dealValidationForm.get(['quote']) as FormGroup;
  }

  get offerForm() {
    return this.dealValidationForm.get(['offer']) as FormGroup;
  }

  loadDealerOffers() {
    const vin = this.quoteForm.get(['vin']).value;
    const dealerInvoice = this.quoteForm.get(['sellingPrice']).value;
    this.dealRequest = {
      vin, dealer: { code: this.dealerCode }, vehicle: { price: { dealerInvoice } }
    };
    this.offerForm.patchValue(this.dealFormInitialState.offer);
    this.offerForm.updateValueAndValidity();
    this.dealValidationState.dispatch(new dealValidationActions.LoadOffersFromBuilder({ quoteRequest: this.dealRequest }));
  }

  calculatePayment() {
    const offerFormData: IDealOfferForm = this.offerForm.value;
    combineLatest([
      this.dealValidationState.select(selectDealerDetails),
      this.dealValidationState.select(selectVehicleDetails)
    ]).pipe(take(1)).subscribe(([dealer, vehicleDetails]) => {
      const customer: IQuoteCustomerInfo = { address: { zipCode: offerFormData.zipCode } };
      const endDate: string = DateTime.now().toFormat('yyyy-MM-dd');
      const vehicle: IQuoteVehicleInfo = { ...vehicleDetails, price: { ...vehicleDetails.price, sellingPrice: vehicleDetails.price.dealerInvoice } };
      const deal: IDealDetails = {
        offers: [
          {
            level: offerFormData.level || '0',
            term: offerFormData.term || 0,
            rate: offerFormData.rate?.rate || 0,
            endDate,
            isSubvented: offerFormData.rate?.isSubvented || true,
            downPayment: offerFormData.downPayment
          }
        ]
      }
      this.dealValidationState.dispatch(new dealValidationActions.LoadDeal({
        offerType: offerFormData.dealType,
        dealRequest: { deal, dealer, customer, vehicle, applicationType: 'mst', contractDate: endDate }
      }));
      this.dealValidationState.dispatch(new dealValidationActions.LoadOffersFromBuilder({ quoteRequest: this.dealRequest }));
      this.offerForm.patchValue(this.dealFormInitialState.offer);
      this.offerForm.updateValueAndValidity();
      const vin = this.quoteForm.get(['vin']).value;
      this.dealValidationState.dispatch(new dealValidationActions.LoadVehicleDetailsFromDD365({
        vehicleRequest: vin
      }));
    });
    //alert(offerFormData.disableQuoteWithoutTaxes);
    this.validationService.setOfferTypeInfo({
      offerType: offerFormData.dealType,
      offerTypeTranslated: this.translateService.instant(('LOCALIZATION.DEAL_VALIDATION.' + offerFormData.dealType)),
    });
    this.validationService.setdownpaymentinfo({
      downPayment: offerFormData.downPayment,
    });
    if (offerFormData.tradeIn > 0) {
      this.validationService.setnettradeininfo({

        netTradeIn: offerFormData.tradeIn,
      });
    }
    else {
      this.validationService.setnettradeininfo({

        netTradeIn: 0,
      });
    }

    this.validationService.setSelectedTypeTaxInfo({
      selectedTaxType: offerFormData.disableQuoteWithoutTaxes,
    });
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }
}
