import { ProtectionProductsComponentService } from './protection-products.component.service';
import { Observable } from 'rxjs';
import {
  selectMasterDataReady,
  selectMasterDataLoadFailed,
  selectMasterDataLoading
} from '../../store/protection-products/protection-products.selectors';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProtectionProductsState } from '../../store/protection-products/protection-products.reducer';
import * as _ from 'lodash';
import { DealerConfigurationState } from '../../store/dealer-configuration/dealer-configuration.reducer';
import { selectBankConfigLoaded, selectDraftStatus, selectIsLoadingBankConfig } from '../../store/dealer-configuration/dealer-configuration.selectors';
import { SubscriptionList, unsubscribeSubscriptions } from '../../shared/services/util.service';

@Component({
  selector: 'app-protection-product',
  templateUrl: './protection-product.component.html',
  styleUrls: ['./protection-product.component.scss']
})
export class ProtectionProductComponent implements OnInit, OnDestroy {
  private subs: SubscriptionList = {};
  selectMasterDataLoading$: Observable<boolean>;
  selectMasterDataReady$: Observable<boolean>;
  selectMasterDataLoadFailed$: Observable<boolean>;
  selectDynamicFieldsMasterDataReady$: Observable<boolean>;
  selectIsLoadingBankConfig$: Observable<boolean>;
  selectBankConfigLoaded$: Observable<boolean>;
  draftStatus: boolean;

  constructor(
    private readonly protectionProductsState: Store<ProtectionProductsState>,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    readonly pPComponentService: ProtectionProductsComponentService
  ) { }

  ngOnInit(): void {
    this.subs.selectDraftStatusSub =
      this.dealerConfigurationState.select(selectDraftStatus).subscribe((draftStatus) => {
        this.draftStatus = draftStatus;
        this.pPComponentService.initPpService(draftStatus);
      });
    this.selectMasterDataLoading$ = this.protectionProductsState.select(selectMasterDataLoading);
    this.selectMasterDataReady$ = this.protectionProductsState.select(selectMasterDataReady);
    this.selectMasterDataLoadFailed$ = this.protectionProductsState.select(selectMasterDataLoadFailed);
    this.selectIsLoadingBankConfig$ = this.dealerConfigurationState.select(selectIsLoadingBankConfig);
    this.selectBankConfigLoaded$ = this.dealerConfigurationState.select(selectBankConfigLoaded);
  }

  reload() {
    this.pPComponentService.initPpService(this.draftStatus);
    this.selectMasterDataReady$ = this.protectionProductsState.select(selectMasterDataReady);
    this.selectMasterDataLoadFailed$ = this.protectionProductsState.select(selectMasterDataLoadFailed);
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

}
