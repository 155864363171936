export default {
  localhost: {
    production: false,
    get_all_dealers_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getAllDealers?resultsFormat=json&brandId=1&resultsMode=starOnly",
    get_dealer_by_id_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getDealers?searchMode=filterOnly&resultsFormat=json&dealerCode=",
    dealer_configuration_api: "https://pepp.dev.dealer.toyota.com/configuration/v1/",
    system_configuration_api: "https://pepp.dev.dealer.toyota.com/system/v1/",
    get_dealer_configuration_api: "https://pepp.dev.dealer.toyota.com/configuration/v1/dealer/",
    dealer_events_api: "https://pepp.dev.dealer.toyota.com/events/v1/",
    get_builder_api: "https://pepp.dev.dealer.toyota.com/quote/v1/builder/",
    get_calc_api: "https://pepp.dev.dealer.toyota.com/quote/v1/calculator/",
    dxiu_vehicle_search_api: "https://dxiu-api.qa.dealer.toyota.com/used-vehicle-inventory/vehicles",
    rti_new_vehicle_search_api: "https://api.rti.qa.toyota.com/dealer-inventory/vehicles",
    rti_used_vehicle_search_api: "https://api.rti.qa.toyota.com/dealer-inventory/usedvehicles",
    x_subscriber_code: "54c1da12-ada9-47c9-babb-075fad25d386",
    smartpath_get_product_offerings:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetProductOfferings",
    smartpath_get_rates:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetRates",
    vpic_nhtsa_vehicle_api: "https://vpic.nhtsa.dot.gov/api/vehicles/"
  },
  "dealer.dev.toyota.com": {
    production: true,
    get_all_dealers_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getAllDealers?resultsFormat=json&brandId=1&resultsMode=starOnly",
    get_dealer_by_id_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getDealers?searchMode=filterOnly&resultsFormat=json&dealerCode=",
    dealer_configuration_api: "https://pepp.dev.dealer.toyota.com/configuration/v1/",
    system_configuration_api: "https://pepp.dev.dealer.toyota.com/system/v1/",
    get_dealer_configuration_api: "https://pepp.dev.dealer.toyota.com/configuration/v1/dealer/",
    dealer_events_api: "https://pepp.dev.dealer.toyota.com/events/v1/",
    get_builder_api: "https://pepp.dev.dealer.toyota.com/quote/v1/builder/",
    get_calc_api: "https://pepp.dev.dealer.toyota.com/quote/v1/calculator/",
    dxiu_vehicle_search_api: "https://dxiu-api.qa.dealer.toyota.com/used-vehicle-inventory/vehicles",
    rti_new_vehicle_search_api: "https://api.rti.dev.toyota.com/dealer-inventory/vehicles",
    rti_used_vehicle_search_api: "https://api.rti.dev.toyota.com/dealer-inventory/usedvehicles",
    x_subscriber_code: "54c1da12-ada9-47c9-babb-075fad25d386",
    smartpath_get_product_offerings:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetProductOfferings",
    smartpath_get_rates:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetRates",
    vpic_nhtsa_vehicle_api: "https://vpic.nhtsa.dot.gov/api/vehicles/"
  },
  "dev.dealer.toyota.com": {
    production: true,
    get_all_dealers_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getAllDealers?resultsFormat=json&brandId=1&resultsMode=starOnly",
    get_dealer_by_id_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getDealers?searchMode=filterOnly&resultsFormat=json&dealerCode=",
    dealer_configuration_api: "https://pepp.dev.dealer.toyota.com/configuration/v1/",
    system_configuration_api: "https://pepp.dev.dealer.toyota.com/system/v1/",
    get_dealer_configuration_api: "https://pepp.dev.dealer.toyota.com/configuration/v1/dealer/",
    dealer_events_api: "https://pepp.dev.dealer.toyota.com/events/v1/",
    get_builder_api: "https://pepp.dev.dealer.toyota.com/quote/v1/builder/",
    get_calc_api: "https://pepp.dev.dealer.toyota.com/quote/v1/calculator/",
    dxiu_vehicle_search_api: "https://dxiu-api.qa.dealer.toyota.com/used-vehicle-inventory/vehicles",
    rti_new_vehicle_search_api: "https://api.rti.dev.toyota.com/dealer-inventory/vehicles",
    rti_used_vehicle_search_api: "https://api.rti.dev.toyota.com/dealer-inventory/usedvehicles",
    x_subscriber_code: "54c1da12-ada9-47c9-babb-075fad25d386",
    smartpath_get_product_offerings:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetProductOfferings",
    smartpath_get_rates:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetRates",
    vpic_nhtsa_vehicle_api: "https://vpic.nhtsa.dot.gov/api/vehicles/"

  },
  "dealer.stage.toyota.com": {
    production: true,
    get_all_dealers_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getAllDealers?resultsFormat=json&brandId=1&resultsMode=starOnly",
    get_dealer_by_id_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getDealers?searchMode=filterOnly&resultsFormat=json&dealerCode=",
    dealer_configuration_api: "https://pepp.stage.dealer.toyota.com/configuration/v1/",
    system_configuration_api: "https://pepp.stage.dealer.toyota.com/system/v1/",
    get_dealer_configuration_api: "https://pepp.stage.dealer.toyota.com/configuration/v1/dealer/",
    dealer_events_api: "https://pepp.stage.dealer.toyota.com/events/v1/",
    get_builder_api: "https://pepp.stage.dealer.toyota.com/quote/v1/builder/",
    get_calc_api: "https://pepp.stage.dealer.toyota.com/quote/v1/calculator/",
    dxiu_vehicle_search_api: "https://dxiu-api.qa.dealer.toyota.com/used-vehicle-inventory/vehicles",
    rti_new_vehicle_search_api: "https://api.rti.stage.toyota.com/dealer-inventory/vehicles",
    rti_used_vehicle_search_api: "https://api.rti.stage.toyota.com/dealer-inventory/usedvehicles",
    x_subscriber_code: "acc3b4bd-1989-485f-908e-9af4b9cccf98",
    smartpath_get_product_offerings:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetProductOfferings",
    smartpath_get_rates:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetRates",
    vpic_nhtsa_vehicle_api: "https://vpic.nhtsa.dot.gov/api/vehicles/"

  },
  "stage.dealer.toyota.com": {
    production: true,
    get_all_dealers_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getAllDealers?resultsFormat=json&brandId=1&resultsMode=starOnly",
    get_dealer_by_id_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getDealers?searchMode=filterOnly&resultsFormat=json&dealerCode=",
    dealer_configuration_api: "https://pepp.stage.dealer.toyota.com/configuration/v1/",
    system_configuration_api: "https://pepp.stage.dealer.toyota.com/system/v1/",
    get_dealer_configuration_api: "https://pepp.stage.dealer.toyota.com/configuration/v1/dealer/",
    dealer_events_api: "https://pepp.stage.dealer.toyota.com/events/v1/",
    get_builder_api: "https://pepp.stage.dealer.toyota.com/quote/v1/builder/",
    get_calc_api: "https://pepp.stage.dealer.toyota.com/quote/v1/calculator/",
    dxiu_vehicle_search_api: "https://dxiu-api.qa.dealer.toyota.com/used-vehicle-inventory/vehicles",
    rti_new_vehicle_search_api: "https://api.rti.stage.toyota.com/dealer-inventory/vehicles",
    rti_used_vehicle_search_api: "https://api.rti.stage.toyota.com/dealer-inventory/usedvehicles",
    x_subscriber_code: "acc3b4bd-1989-485f-908e-9af4b9cccf98",
    smartpath_get_product_offerings:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetProductOfferings",
    smartpath_get_rates:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetRates",
    vpic_nhtsa_vehicle_api: "https://vpic.nhtsa.dot.gov/api/vehicles/"

  },
  "dealer.qa.toyota.com": {
    production: true,
    get_all_dealers_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getAllDealers?resultsFormat=json&brandId=1&resultsMode=starOnly",
    get_dealer_by_id_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getDealers?searchMode=filterOnly&resultsFormat=json&dealerCode=",
    dealer_configuration_api: "https://pepp.qa.dealer.toyota.com/configuration/v1/",
    system_configuration_api: "https://pepp.qa.dealer.toyota.com/system/v1/",
    get_dealer_configuration_api: "https://pepp.qa.dealer.toyota.com/configuration/v1/dealer/",
    dealer_events_api: "https://pepp.qa.dealer.toyota.com/events/v1/",
    get_builder_api: "https://pepp.qa.dealer.toyota.com/quote/v1/builder/",
    get_calc_api: "https://pepp.qa.dealer.toyota.com/quote/v1/calculator/",
    dxiu_vehicle_search_api: "https://dxiu-api.qa.dealer.toyota.com/used-vehicle-inventory/vehicles",
    rti_new_vehicle_search_api: "https://api.rti.qa.toyota.com/dealer-inventory/vehicles",
    rti_used_vehicle_search_api: "https://api.rti.qa.toyota.com/dealer-inventory/usedvehicles",
    x_subscriber_code: "acc3b4bd-1989-485f-908e-9af4b9cccf98",
    smartpath_get_product_offerings:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetProductOfferings",
    smartpath_get_rates:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetRates",
    vpic_nhtsa_vehicle_api: "https://vpic.nhtsa.dot.gov/api/vehicles/"

  },
  "qa.dealer.toyota.com": {
    production: true,
    get_all_dealers_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getAllDealers?resultsFormat=json&brandId=1&resultsMode=starOnly",
    get_dealer_by_id_api:
      "https://staging.ws.disrest.tms.aws.toyota.com/disrest/getDealers?searchMode=filterOnly&resultsFormat=json&dealerCode=",
    dealer_configuration_api: "https://pepp.qa.dealer.toyota.com/configuration/v1/",
    system_configuration_api: "https://pepp.qa.dealer.toyota.com/system/v1/",
    get_dealer_configuration_api: "https://pepp.qa.dealer.toyota.com/configuration/v1/dealer/",
    dealer_events_api: "https://pepp.qa.dealer.toyota.com/events/v1/",
    get_builder_api: "https://pepp.qa.dealer.toyota.com/quote/v1/builder/",
    get_calc_api: "https://pepp.qa.dealer.toyota.com/quote/v1/calculator/",
    dxiu_vehicle_search_api: "https://dxiu-api.qa.dealer.toyota.com/used-vehicle-inventory/vehicles",
    rti_new_vehicle_search_api: "https://api.rti.qa.toyota.com/dealer-inventory/vehicles",
    rti_used_vehicle_search_api: "https://api.rti.qa.toyota.com/dealer-inventory/usedvehicles",
    x_subscriber_code: "acc3b4bd-1989-485f-908e-9af4b9cccf98",
    smartpath_get_product_offerings:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetProductOfferings",
    smartpath_get_rates:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetRates",
    vpic_nhtsa_vehicle_api: "https://vpic.nhtsa.dot.gov/api/vehicles/"

  },
  "dealer.toyota.com": {
    production: true,
    get_all_dealers_api:
      "https://www.ws.disrest.tms.aws.toyota.com/disrest/getAllDealers?resultsFormat=json&brandId=1&resultsMode=starOnly",
    get_dealer_by_id_api:
      "https://www.ws.disrest.tms.aws.toyota.com/disrest/getDealers?searchMode=filterOnly&resultsFormat=json&dealerCode=",
    dealer_configuration_api: "https://pepp.dealer.toyota.com/configuration/v1/",
    system_configuration_api: "https://pepp.dealer.toyota.com/system/v1/",
    get_dealer_configuration_api: "https://pepp.dealer.toyota.com/configuration/v1/dealer/",
    dealer_events_api: "https://pepp.dealer.toyota.com/events/v1/",
    get_builder_api: "https://pepp.dealer.toyota.com/quote/v1/builder/",
    get_calc_api: "https://pepp.dealer.toyota.com/quote/v1/calculator/",
    dxiu_vehicle_search_api: "https://dxiu-api.dealer.toyota.com/used-vehicle-inventory/vehicles",
    rti_new_vehicle_search_api: "https://api.rti.toyota.com/dealer-inventory/vehicles",
    rti_used_vehicle_search_api: "https://api.rti.toyota.com/dealer-inventory/usedvehicles",
    x_subscriber_code: "4541c7ac-b565-4e8e-917f-25614419a331",
    smartpath_get_product_offerings:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetProductOfferings",
    smartpath_get_rates:
      "https://apim-jma-u-slsen-ratingsvc.azure-api.net/smartpath/v1/tmna/SmartpathGetRates/GetRates",
    vpic_nhtsa_vehicle_api: "https://vpic.nhtsa.dot.gov/api/vehicles/"

  },
};
