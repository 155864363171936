import {
  protectionProductsReducer,
  ProtectionProductsState,
} from "./protection-products/protection-products.reducer";
import { ActionReducerMap, Action } from "@ngrx/store";
import {
  DealerConfigurationState,
  dealerConfigurationReducer,
} from "./dealer-configuration/dealer-configuration.reducer";
import {
  vehicleConfigReducer,
  VehicleConfigState,
} from "./vehicle-config/vehicle-config.reducer";
import {
  dealValidationReducer,
  DealValidationState,
} from "./deal-validation/deal-validation.reducer";
import {
  lenderListConfigReducer,
  LenderListConfigState,
} from "./lender-list-config/lender-list-config.reducer";
import { dealerDefinedCampaignsReducer, DealerDefinedCampaignsState }
  from "./dealer-defined-campaigns/dealer-defined-campaigns.reducer";
import { DeployJobManageState, deployJobManageReducer } from "./deploy-job-management/deploy-job-management.reducer";
import { incentivesReducer, IncentivesState } from "./incentives/incentives.reducer";

/* Feature keys */

export enum FeatureKeys {
  PROTECTIONPRODUCTS = "protectionProductsState",
  DEALERCONFIGURATION = "dealerConfigurationState",
  VEHICLECONFIG = "vehicleConfigState",
  GetModelsForMakeYear = "getModelsForMakeYearState",
  GetAllMakes = "getAllMakesState",
  DEALVALIDATION = "dealValidationState",
  LENDERLISTCONFIG = "lenderListConfigState",
  GetModelsForMake = "GetModelsForMakeState",
  DEALERDEFINEDCAMPAIGNS = "dealerDefinedCampaignsState",
  INCENTIVES = "incentivesState",
  DEPLOYMENTJOB = "deploymentJobState"
}

/* App Reducer */
export interface AppState {
  [FeatureKeys.PROTECTIONPRODUCTS]: ProtectionProductsState;
  [FeatureKeys.DEALERCONFIGURATION]: DealerConfigurationState;
  [FeatureKeys.VEHICLECONFIG]: VehicleConfigState;
  [FeatureKeys.DEALVALIDATION]: DealValidationState;
  [FeatureKeys.LENDERLISTCONFIG]: LenderListConfigState;
  [FeatureKeys.DEALERDEFINEDCAMPAIGNS]: DealerDefinedCampaignsState;
  [FeatureKeys.DEPLOYMENTJOB]: DeployJobManageState;
  [FeatureKeys.INCENTIVES]: IncentivesState;
}

export const appReducer: ActionReducerMap<AppState> = {
  protectionProductsState: protectionProductsReducer,
  dealerConfigurationState: dealerConfigurationReducer,
  vehicleConfigState: vehicleConfigReducer,
  dealValidationState: dealValidationReducer,
  lenderListConfigState: lenderListConfigReducer,
  dealerDefinedCampaignsState: dealerDefinedCampaignsReducer,
  deploymentJobState: deployJobManageReducer,
  incentivesState: incentivesReducer
};

/* Actions */
export enum AppStoreTypes {
  START_APP_INITIALIZER = "[AppStore] Start App Initializer",
  FINISH_APP_INITIALIZER = "[AppStore] Finish App Initializer",
}

export class StartAppInitializer implements Action {
  public readonly type = AppStoreTypes.START_APP_INITIALIZER;
}

export class FinishAppInitializer implements Action {
  public readonly type = AppStoreTypes.FINISH_APP_INITIALIZER;

  constructor(public _payload: { success: boolean } = { success: true }) { }
}

export type AppActions = StartAppInitializer | FinishAppInitializer;
