<dd-spinner *ngIf="selectIncentiveLoading$ | async"></dd-spinner>
<div class="d-flex incentive-bar">
    <div class="incentive-status">
        <span [ngClass]="{'active': isActive}" (click)="!isActive && toggleActive()">
            {{'LOCALIZATION.INCENTIVES.ACTIVE' | translate}}</span>
        <span class="divider"></span>
        <span [ngClass]="{'active': !isActive}" (click)="isActive && toggleActive()">
            {{'LOCALIZATION.INCENTIVES.EXPIRED' | translate}}</span>
    </div>
    <div *ngIf="!(disableActions$ | async)">
        <input type="button" class="primary-button small-button ml-auto mt-4 mr-4" (click)="addIncentive()"
            value="{{'LOCALIZATION.INCENTIVES.ADD_INCENTIVE' | translate}}" />
    </div>
</div>

<ng-container *ngFor="let incentiveSource of incentiveSourceList$ | async">
    <div *ngIf="((incentives$ | async) | getincentivesbysource : incentiveSource).length > 0"
        class="incentive-source-container m-3 px-4 pb-4  pt-2">
        <strong class="d-block mb-2">{{incentiveSource}}</strong>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let incentive of (incentives$ | async) | getincentivesbysource : incentiveSource">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'LOCALIZATION.INCENTIVES.INCENTIVE_NAME' | translate}}: {{incentive.title}}
                    </mat-panel-title>
                    <mat-panel-title>
                        {{'LOCALIZATION.INCENTIVES.START_DATE' | translate}}: {{incentive.startDate}}
                    </mat-panel-title>
                    <mat-panel-title>
                        {{'LOCALIZATION.INCENTIVES.END_DATE' | translate}}: {{incentive.endDate}}
                    </mat-panel-title>
                    <mat-panel-title class="fw-bold" [ngClass]="'incentive-' + incentiveStatus"
                        *ngIf="(incentive.endDate | getincentivestatus : incentive.status) as incentiveStatus">
                        {{'LOCALIZATION.INCENTIVES.STATUS' | translate}}: {{incentiveStatus | titlecase}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-pe-view-incentive [incentive]="incentive"></app-pe-view-incentive>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-container>