import { IPennProvider } from './../../shared/models/product-offerings.model';
import { IGetProducts, IProtectionProducts, ISaveProducts, IGetPennProductDefaults, ITaxCategory, IPacChangelog, IDynamicFieldsMasterData } from '../../shared/models/protection-products.model';
import { Action } from '@ngrx/store';

export enum ProtectionProductsActionTypes {
    RESET_MASTER_DATA_LOAD_FAILED_FLAG = '[ProtectionProducts] Reset master data load failed flag',
    RESET_AND_REBUILD_PP = '[ProtectionProducts] Trigger a reset on PP Data',
    LOAD_PRODUCT_OFFERINGS = '[ProtectionProducts] Load Product Offerings',
    LOAD_PRODUCT_OFFERINGS_SUCCESS = '[ProtectionProducts] Load Product Offerings success',
    LOAD_PRODUCT_OFFERINGS_FAILURE = '[ProtectionProducts] Load Product Offerings failure',
    PRODUCT_OFFERINGS_NOT_FOUND = '[ProtectionProducts] Product Offerings Not Found',
    LOAD_PENN_PROVIDERS = '[ProtectionProducts] Load Penn Providers and Products',
    LOAD_PENN_PROVIDERS_SUCCESS = '[ProtectionProducts] Load Penn Providers and Products success',
    LOAD_PENN_PROVIDERS_FAILURE = '[ProtectionProducts] Load Penn Providers and Products failure',
    LOAD_PENN_PRODUCT_DEFAULTS = '[ProtectionProducts] Load Penn Product Defaults',
    LOAD_PENN_PRODUCT_DEFAULTS_SUCCESS = '[ProtectionProducts] Load Penn Product Defaults success',
    LOAD_PENN_PRODUCT_DEFAULTS_FAILURE = '[ProtectionProducts] Load Penn Product Defaults failure',
    RESET_LAST_PENN_PRODUCT_LOADED = '[ProtectionProducts] Reset last penn product loaded',
    LOAD_TAX_CATEGORIES = '[ProtectionProducts] Load Tax Categories',
    LOAD_TAX_CATEGORIES_SUCCESS = '[ProtectionProducts] Load Tax Categories success',
    LOAD_TAX_CATEGORIES_FAILURE = '[ProtectionProducts] Load Tax Categories failure',
    UPDATE_TFS_PRODUCTS_ORDER = '[ProtectionProducts] Update TFS Products Order',
    UPDATE_TFS_PRODUCTS_ORDER_SUCCESS = '[ProtectionProducts] Update TFS Products Order success',
    UPDATE_TFS_PRODUCTS_ORDER_FAILURE = '[ProtectionProducts] Update TFS Products Order failure',
    UPDATE_MAX_VISIBLE_PP = '[ProtectionProducts] Update max visible protection products',
    UPDATE_MAX_VISIBLE_PP_SUCCESS = '[ProtectionProducts] Update max visible protection products success',
    UPDATE_MAX_VISIBLE_PP_FAILURE = '[ProtectionProducts] Update max visible protection products failure',
    UPDATE_PROTECTION_PRODUCTS = '[ProtectionProducts] Update Protection Products',
    UPDATE_PROTECTION_PRODUCTS_SUCCESS = '[ProtectionProducts] Update Protection Products success',
    UPDATE_PROTECTION_PRODUCTS_FAILURE = '[ProtectionProducts] Update Protection Products failure',
    UPDATE_TFS_PROTECTION_PRODUCTS = '[ProtectionProducts] Update TFS Protection Products',
    UPDATE_TFS_PROTECTION_PRODUCTS_SUCCESS = '[ProtectionProducts] Update TFS Protection Products success',
    UPDATE_TFS_PROTECTION_PRODUCTS_FAILURE = '[ProtectionProducts] Update TFS Protection Products failure',
    LOAD_PAC_CHANGELOGS = '[ProtectionProducts] Load Product PAC Changelogs',
    LOAD_PAC_CHANGELOGS_SUCCESS = '[ProtectionProducts] Load Product PAC Changelogs success',
    LOAD_PAC_CHANGELOGS_FAILURE = '[ProtectionProducts] Load Product PAC Changelogs failure',
    UPDATE_ISSETDEALER = '[ProtectionProducts] Update isSetDealer'
}

export class ResetMasterDataLoadFailedFlag implements Action {
    readonly type = ProtectionProductsActionTypes.RESET_MASTER_DATA_LOAD_FAILED_FLAG;
    constructor(public payload: { loadFailed: boolean }) {
    }
}

export class ResetAndRebuildPp implements Action {
    readonly type = ProtectionProductsActionTypes.RESET_AND_REBUILD_PP;
    constructor() { }
}

export class LoadProductOfferings implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PRODUCT_OFFERINGS;
    constructor(public payload: { dealerCode: string, draftStatus: boolean }) {
    }
}

export class LoadProductOfferingsSuccess implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PRODUCT_OFFERINGS_SUCCESS;
    constructor(public payload: { productDetails: IGetProducts }) {
    }
}

export class LoadProductOfferingsFailure implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PRODUCT_OFFERINGS_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class ProductOfferingsNotFound implements Action {
    readonly type = ProtectionProductsActionTypes.PRODUCT_OFFERINGS_NOT_FOUND;
    constructor(public payload: { error: string, dynamicFields?: IDynamicFieldsMasterData }) {
    }
}

export class LoadPennProviders implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PENN_PROVIDERS;
    constructor(public payload: { dealerCode: string }) {
    }
}

export class LoadPennProvidersSuccess implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PENN_PROVIDERS_SUCCESS;
    constructor(public payload: { pennProviders: IPennProvider[] }) {
    }
}

export class LoadPennProvidersFailure implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PENN_PROVIDERS_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class LoadPennProductDefaults implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PENN_PRODUCT_DEFAULTS;
    constructor(public payload: { dealerCode: string, providerName: string, productId: number, productName: string }) {
    }
}

export class LoadPennProductDefaultsSuccess implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PENN_PRODUCT_DEFAULTS_SUCCESS;
    constructor(public payload: { pennProducts: IGetPennProductDefaults }) {
    }
}

export class ResetLastPennProductLoaded implements Action {
    readonly type = ProtectionProductsActionTypes.RESET_LAST_PENN_PRODUCT_LOADED;
    constructor(public payload: { pennProducts: IGetPennProductDefaults }) {
    }
}

export class LoadPennProductDefaultsFailure implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PENN_PRODUCT_DEFAULTS_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class LoadTaxCategories implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_TAX_CATEGORIES;
    constructor(public payload: { dealerCode: string }) {
    }
}

export class LoadTaxCategoriesSuccess implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_TAX_CATEGORIES_SUCCESS;
    constructor(public payload: { taxCategories: ITaxCategory[] }) {
    }
}

export class LoadTaxCategoriesFailure implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_TAX_CATEGORIES_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class UpdateProtectionProducts implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_PROTECTION_PRODUCTS;
    constructor(public payload: { dealerCode: string, protectionProducts: IProtectionProducts }) {
    }
}

export class UpdateProtectionProductsSuccess implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_PROTECTION_PRODUCTS_SUCCESS;
    constructor(public payload: { protectionProducts: IProtectionProducts }) {
    }
}

export class UpdateProtectionProductsFailure implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_PROTECTION_PRODUCTS_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class UpdateTfsProtectionProducts implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_TFS_PROTECTION_PRODUCTS;
    constructor(public payload: { dealerCode: string, protectionProducts: ISaveProducts }) {
    }
}

export class UpdateTfsProtectionProductsSuccess implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_TFS_PROTECTION_PRODUCTS_SUCCESS;
    constructor(public payload: { protectionProducts: ISaveProducts }) {
    }
}

export class UpdateTfsProtectionProductsFailure implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_TFS_PROTECTION_PRODUCTS_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class UpdateTfsProductsOrder implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_TFS_PRODUCTS_ORDER;
    constructor(public payload: { dealerCode: string, protectionProducts: ISaveProducts }) {
    }
}

export class UpdateTfsProductsOrderSuccess implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_TFS_PRODUCTS_ORDER_SUCCESS;
    constructor(public payload: { protectionProducts: ISaveProducts }) {
    }
}

export class UpdateTfsProductsOrderFailure implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_TFS_PRODUCTS_ORDER_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class UpdateMaxVisiblePP implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_MAX_VISIBLE_PP;
    constructor(public payload: { dealerCode: string, protectionProducts: ISaveProducts }) {
    }
}

export class UpdateMaxVisiblePPSuccess implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_MAX_VISIBLE_PP_SUCCESS;
    constructor(public payload: { protectionProducts: ISaveProducts }) {
    }
}

export class UpdateMaxVisiblePPFailure implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_MAX_VISIBLE_PP_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class LoadPacChangelogs implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PAC_CHANGELOGS;
    constructor(public payload: { dealerCode: string, productName: string, providerId: string }) {
    }
}

export class LoadPacChangelogsSuccess implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PAC_CHANGELOGS_SUCCESS;
    constructor(public payload: { pacChangelogs: IPacChangelog[] }) {
    }
}

export class LoadPacChangelogsFailure implements Action {
    readonly type = ProtectionProductsActionTypes.LOAD_PAC_CHANGELOGS_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class UpdateIsSETDealer implements Action {
    readonly type = ProtectionProductsActionTypes.UPDATE_ISSETDEALER;
    constructor(public payload: { isSetDealer: boolean }) {
    }
}

export type ProtectionProductsActions =
    ResetMasterDataLoadFailedFlag
    | ResetAndRebuildPp
    | LoadProductOfferings
    | LoadProductOfferingsSuccess
    | LoadProductOfferingsFailure
    | ProductOfferingsNotFound
    | LoadPennProviders
    | LoadPennProvidersSuccess
    | LoadPennProvidersFailure
    | LoadPennProductDefaults
    | LoadPennProductDefaultsSuccess
    | LoadPennProductDefaultsFailure
    | ResetLastPennProductLoaded
    | LoadTaxCategories
    | LoadTaxCategoriesSuccess
    | LoadTaxCategoriesFailure
    | UpdateProtectionProducts
    | UpdateProtectionProductsSuccess
    | UpdateProtectionProductsFailure
    | UpdateTfsProtectionProducts
    | UpdateTfsProtectionProductsSuccess
    | UpdateTfsProtectionProductsFailure
    | UpdateTfsProductsOrder
    | UpdateTfsProductsOrderSuccess
    | UpdateTfsProductsOrderFailure
    | UpdateMaxVisiblePP
    | UpdateMaxVisiblePPSuccess
    | UpdateMaxVisiblePPFailure
    | LoadPacChangelogs
    | LoadPacChangelogsSuccess
    | LoadPacChangelogsFailure
    | UpdateIsSETDealer;
