import { Action } from '@ngrx/store';
import { INHTSAModel } from '../../shared/models/nhtsa/nhtsa.model';
import { IRTIVehicleSeriesConfig } from '../../shared/models/rti/rti-vehicle-config.model';

export enum VehicleConfigActionTypes {
  LOAD_RTI_VEHICLE_CONFIG = '[VehicleConfig] Load RTI Vehicle Config',
  LOAD_RTI_VEHICLE_CONFIG_SUCCESS = '[VehicleConfig] Load RTI Vehicle Config success',
  LOAD_RTI_VEHICLE_CONFIG_FAILURE = '[VehicleConfig] Load RTI Vehicle Config failure',
  LOAD_NHTSA_VEHICLE_CONFIG = '[VehicleConfig] Load NHTSA Vehicle Config',
  LOAD_NHTSA_VEHICLE_CONFIG_SUCCESS = '[VehicleConfig] Load NHTSA Vehicle Config success',
  LOAD_NHTSA_VEHICLE_CONFIG_FAILURE = '[VehicleConfig] Load NHTSA Vehicle Config failure',
  UPDATE_BRANDS_LOADING = '[VehicleConfig] Update Brands Loading'
}

export class LoadRTIVehicleConfig implements Action {
  type: string = VehicleConfigActionTypes.LOAD_RTI_VEHICLE_CONFIG;
  constructor(public payload: { brand: string }) {
  }
}

export class LoadRTIVehicleConfigSuccess implements Action {
  type: string = VehicleConfigActionTypes.LOAD_RTI_VEHICLE_CONFIG_SUCCESS;
  constructor(public payload: { brand: string, rtiVehicleConfig: IRTIVehicleSeriesConfig[] }) {
  }
}

export class LoadRTIVehicleConfigFailure implements Action {
  type: string = VehicleConfigActionTypes.LOAD_RTI_VEHICLE_CONFIG_FAILURE;
  constructor(public payload: { brand: string, error: string }) {
  }
}

export class LoadNHTSAVehicleConfig implements Action {
  type: string = VehicleConfigActionTypes.LOAD_NHTSA_VEHICLE_CONFIG;
  constructor(public payload: { brand: string }) {
  }
}

export class LoadNHTSAVehicleConfigSuccess implements Action {
  type: string = VehicleConfigActionTypes.LOAD_NHTSA_VEHICLE_CONFIG_SUCCESS;
  constructor(public payload: { brand: string, nhtsaVehicleConfig: INHTSAModel[] }) {
  }
}

export class LoadNHTSAVehicleConfigFailure implements Action {
  type: string = VehicleConfigActionTypes.LOAD_NHTSA_VEHICLE_CONFIG_FAILURE;
  constructor(public payload: { brand: string, error: string }) {
  }
}

export class UpdateBrandsLoading implements Action {
  type: string = VehicleConfigActionTypes.UPDATE_BRANDS_LOADING;
  constructor(public payload: { brand: string }) {
  }
}

export type VehicleConfigActions =
  LoadRTIVehicleConfig
  | LoadRTIVehicleConfigSuccess
  | LoadRTIVehicleConfigFailure
  | LoadNHTSAVehicleConfig
  | LoadNHTSAVehicleConfigSuccess
  | LoadNHTSAVehicleConfigFailure
  | UpdateBrandsLoading;