import { Observable, Subscription } from "rxjs";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { NavigationStart, Router } from "@angular/router";
import { DealerInfoService } from "../../shared/services/dealer-info.service";
import { canActivateOnlyOnDev, canActivateOnlyOnQAandDev } from "../../shared/services/util.service";
import { DealerConfigurationState } from "../../store/dealer-configuration/dealer-configuration.reducer";
import { selectDraftStatus } from '../../store/dealer-configuration/dealer-configuration.selectors';
import * as dealerConfigurationActions from "../../store/dealer-configuration/dealer-configuration.actions";
import { Store } from "@ngrx/store";
import { UserProfileService } from "@toyota/dd365-platform-library";
import * as deployJobManagementActions from "../../store/deploy-job-management/deploy-job-management.actions";
import { SubscriptionList } from '../../shared/services/util.service';
import { DeployJobManageState } from "../../store/deploy-job-management/deploy-job-management.reducer";
import * as _ from 'lodash';
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "main-nav",
  templateUrl: "./main-nav.component.html",
  host: { '[class.dc-toggle-on]': '!hideConfigStatus' },
  styleUrls: ["./main-nav.component.scss"]
})

export class MainNavComponent implements OnInit, OnDestroy {
  public pathSelected: string = "library";
  public disableNavigation: boolean = false;
  routerSub: Subscription;
  dealerCode: string;
  draftState$: Observable<boolean>;
  hideConfigStatus: boolean;
  totalItems: number = 0;
  private subs: SubscriptionList = {};
  constructor(
    protected loc: Location,
    protected router: Router,
    private readonly userProfileService: UserProfileService,
    readonly dealerInfoService: DealerInfoService,
    readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly deployJobManageState: Store<DeployJobManageState>,
    readonly translateService: TranslateService,
  ) {

    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    const dealerCode = this.userProfileService.getProfile().dealerCd;
    this.deployJobManageState.dispatch(
      new deployJobManagementActions.LoadDeployJobConfig({ dealerCode })
    );
    this.draftState$ = this.dealerConfigurationState.select(selectDraftStatus);
    router.events.subscribe(() => {
      this.hideConfigStatus = false;
      if (loc.path().indexOf("basic-information") !== -1) {
        this.pathSelected = "basic-information";
      } else if (loc.path().indexOf("payment-grid") !== -1) {
        this.pathSelected = "payment-grid";
      } else if (loc.path().indexOf("vehicle-pac") !== -1) {
        this.pathSelected = "vehicle-pac";
      } else if (loc.path().indexOf("dealer-defined-campaigns") !== -1) {
        this.pathSelected = "dealer-defined-campaigns";
        this.hideConfigStatus = true;
      } else if (loc.path().indexOf("incentives") !== -1) {
        this.pathSelected = "incentives";
        this.hideConfigStatus = true;
      } else if (loc.path().indexOf("fees-and-tax") !== -1) {
        this.pathSelected = "fees-and-tax";
      } else if (loc.path().indexOf("lease-setting") !== -1) {
        this.pathSelected = "lease-setting";
      } else if (loc.path().indexOf("protection-products") !== -1) {
        this.pathSelected = "protection-products";
      } else if (loc.path().indexOf("compare-and-validate") !== -1) {
        this.pathSelected = "compare-and-validate";
      } else if (loc.path().indexOf("audit") !== -1) {
        this.pathSelected = "audit";
        this.hideConfigStatus = true;
      } else if (loc.path().indexOf("deal-validation") !== -1) {
        this.pathSelected = "deal-validation";
        this.hideConfigStatus = true;
      } else if (loc.path().indexOf("deploy-job-management") !== -1) {
        this.pathSelected = "deploy-job-management";
        this.hideConfigStatus = true;
      } else if (loc.path().indexOf("config-not-found") !== -1) {
        this.pathSelected = "config-not-found";
      }
      else if (loc.path().indexOf("gross-profit") !== -1) {
        this.pathSelected = "gross-profit";
      } else {
        this.pathSelected = "offer-markup";
      }
    });
  }

  onClick(path: string): void {
    this.pathSelected = path;
    this.router.navigate([`/pe-admin/${path}`]);
  }

  ngOnInit(): void {
    // Added to supress URL Redirection Infinite Loop
    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.disableNavigation = true;
        setTimeout(() => {
          this.disableNavigation = false;
        }, 200);
      }
    });
  }

  canActivateOnlyOnDev() {
    return canActivateOnlyOnDev();
  }

  canActivateOnlyOnQAandDev() {
    return canActivateOnlyOnQAandDev();
  }

  setDraftStatus(draftState: boolean) {
    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.SetDraftStatus({ draftState }));
    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.LoadDealerConfiguration({ dealerCode: this.dealerCode }));
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }
}
