import { Action } from '@ngrx/store';
import { IDealerDefinedCampaign } from '../../shared/models/dealer-defined-campaigns.model';

export enum DealerDefinedCampaignsActionTypes {
    LOAD_DD_CAMPAIGNS = '[DealerDefinedCampaigns] Load Dealer Defined Campaigns',
    LOAD_DD_CAMPAIGNS_SUCCESS = '[DealerDefinedCampaigns] Load Dealer Defined Campaigns success',
    LOAD_DD_CAMPAIGNS_FAILURE = '[DealerDefinedCampaigns] Load Dealer Defined Campaigns failure',
    UPDATE_DD_CAMPAIGN = '[DealerDefinedCampaigns] Update Dealer Defined Campaign',
    UPDATE_DD_CAMPAIGN_SUCCESS = '[DealerDefinedCampaigns] Update Dealer Defined Campaign success',
    UPDATE_DD_CAMPAIGN_FAILURE = '[DealerDefinedCampaigns] Update Dealer Defined Campaign failure',
    DELETE_DD_CAMPAIGN = '[DealerDefinedCampaigns] Delete Dealer Defined Campaign',
    DELETE_DD_CAMPAIGN_SUCCESS = '[DealerDefinedCampaigns] Delete Dealer Defined Campaign success',
    DELETE_DD_CAMPAIGN_FAILURE = '[DealerDefinedCampaigns] Delete Dealer Defined Campaign failure'
}

export class LoadDealerDefinedCampaigns implements Action {
    readonly type = DealerDefinedCampaignsActionTypes.LOAD_DD_CAMPAIGNS;
    constructor(public payload: { dealerCode: string }) {
    }
}

export class LoadDealerDefinedCampaignsSuccess implements Action {
    readonly type = DealerDefinedCampaignsActionTypes.LOAD_DD_CAMPAIGNS_SUCCESS;
    constructor(public payload: { campaigns: IDealerDefinedCampaign[] }) {
    }
}

export class LoadDealerDefinedCampaignsFailure implements Action {
    readonly type = DealerDefinedCampaignsActionTypes.LOAD_DD_CAMPAIGNS_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class UpdateDealerDefinedCampaign implements Action {
    readonly type = DealerDefinedCampaignsActionTypes.UPDATE_DD_CAMPAIGN;
    constructor(public payload: { campaign: IDealerDefinedCampaign }) {
    }
}

export class UpdateDealerDefinedCampaignSuccess implements Action {
    readonly type = DealerDefinedCampaignsActionTypes.UPDATE_DD_CAMPAIGN_SUCCESS;
    constructor(public payload: { dealerDefinedCampaign: IDealerDefinedCampaign }) {
    }
}

export class UpdateDealerDefinedCampaignFailure implements Action {
    readonly type = DealerDefinedCampaignsActionTypes.UPDATE_DD_CAMPAIGN_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export class DeleteDealerDefinedCampaign implements Action {
    readonly type = DealerDefinedCampaignsActionTypes.DELETE_DD_CAMPAIGN;
    constructor(public payload: { campaignId: string, dealerCode: string }) {
    }
}

export class DeleteDealerDefinedCampaignSuccess implements Action {
    readonly type = DealerDefinedCampaignsActionTypes.DELETE_DD_CAMPAIGN_SUCCESS;
    constructor(public payload: { campaignId: string }) {
    }
}

export class DeleteDealerDefinedCampaignFailure implements Action {
    readonly type = DealerDefinedCampaignsActionTypes.DELETE_DD_CAMPAIGN_FAILURE;
    constructor(public payload: { error: string }) {
    }
}

export type DealerDefinedCampaignsActions =
    LoadDealerDefinedCampaigns
    | LoadDealerDefinedCampaignsSuccess
    | LoadDealerDefinedCampaignsFailure
    | UpdateDealerDefinedCampaign
    | UpdateDealerDefinedCampaignSuccess
    | UpdateDealerDefinedCampaignFailure
    | DeleteDealerDefinedCampaign
    | DeleteDealerDefinedCampaignSuccess
    | DeleteDealerDefinedCampaignFailure;
