<div>
    <dd-spinner *ngIf="(loading$ | async) || (selectDealerConfigLoading$ | async) || 
        (selectBankConfigLoading$ | async)">
    </dd-spinner>
    <div *ngIf="!(selectDealerConfigLoading$ | async) &&
    !(selectBankConfigLoading$ | async)">
        <mat-tab-group class="payment-grid-div" [formGroup]="paymentGridForm" animationDuration="0ms" mat-stretch-tabs
            [(selectedIndex)]="selectedPGTabIndex" (selectedTabChange)="onTabChange($event);">
            <mat-tab label="{{'LOCALIZATION.PAYMENT_GRID.GLOBAL' | translate}}">
                <app-pe-global-grid-config [selectedTab]="'global'" (saveEvent)="saveEventHandler($event,'global')"
                    (resetEvent)="resetEventHander($event)" [paymentGridConfig]="paymentGridConfig" [pgformValidEvent]="paymentGridForm.status"
                    [getformGroupName]="globalchildNotifier"></app-pe-global-grid-config>
            </mat-tab>
            <mat-tab label="{{'LOCALIZATION.PAYMENT_GRID.SHOWROOM' | translate}}">
                <app-pe-showroom-online-grid-config [selectedTab]="'showroom'"
                    (saveEvent)="saveEventHandler($event,'showroom')" (resetEvent)="resetEventHander($event)"
                    [pgformValidEvent]="paymentGridForm.status" [getformGroupName]="showroomchildNotifier">
                </app-pe-showroom-online-grid-config>
            </mat-tab>
            <mat-tab label="{{'LOCALIZATION.PAYMENT_GRID.ONLINE' | translate}}">
                <app-pe-showroom-online-grid-config [selectedTab]="'online'"
                    (saveEvent)="saveEventHandler($event,'online')" (resetEvent)="resetEventHander($event)"
                    [pgformValidEvent]="paymentGridForm.status" [getformGroupName]="onlinechildNotifier">
                </app-pe-showroom-online-grid-config>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>