import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DeployJobManagementComponent } from "./deploy-job-management.component";
import { TranslateModule } from "@ngx-translate/core";
import { GlobalModule } from "../../global/global.module";
import { SharedModule } from "../../shared/shared.module";
import { DeployJobManagementRoutingModule } from "./deploy-job-management.routing.module";
import { ViewDeployJobComponent } from "./view-deploy-job/view-deploy-job.component";
import { DeployJobSummaryComponent } from "./deploy-job-summary/deploy-job-summary.component";
import { DeployJobPlanComponent } from "./deploy-job-plan/deploy-job-plan.component";
import { DeployJobAuditlogsComponent } from "./deploy-job-auditlogs/deploy-job-auditlogs.component";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DiscardConfirmationComponent } from "./discard-confirmation/discard-confirmation.component";

@NgModule({
  declarations: [
    DeployJobManagementComponent,
    ViewDeployJobComponent,
    DeployJobSummaryComponent,
    DeployJobPlanComponent,
    DeployJobAuditlogsComponent,
    DiscardConfirmationComponent
  ],
  imports: [
    DeployJobManagementRoutingModule,
    TranslateModule.forChild({ extend: true }),
    CommonModule,
    GlobalModule,
    SharedModule,
    MatProgressSpinnerModule
  ],
})
export class DeployJobManagementModule { }
