<dd-spinner *ngIf="loading$ | async"></dd-spinner>
<div class="mainDiv LeaseDiv">
    <div class="bodyDiv container-fluid" *ngIf="leaseSettingsForm" [formGroup]="leaseSettingsForm">
        <mat-label class="bodyDivHeader">2.1 {{'LOCALIZATION.LEASE_SETTING.HEADER' | translate}}<span
                class="dividerLine"></span><span class="restoreAll" (click)="resetLeaseSettingsForm()"
                matTooltip="{{'LOCALIZATION.LEASE_SETTING.RESTORE_ALL_TOOLTIP' | translate}}">{{'LOCALIZATION.LEASE_SETTING.RESTORE_ALL_TEXT'
                | translate}}</span></mat-label>
        <div class="row">
            <mat-label class="tableHeader">{{'LOCALIZATION.LEASE_SETTING.LEASE_TYPE_CONTROLS' | translate}}</mat-label>
            <div class="tableDiv pb-0">
                <table aria-describedby="tableHd" class="table example-full-width">
                    <thead>
                        <th scope="col">{{'LOCALIZATION.LEASE_SETTING.LEASE_TYPE' | translate}}</th>
                        <th scope="col">{{'LOCALIZATION.LEASE_SETTING.ENABLE' | translate}}</th>
                        <th *ngIf="hideShowroomDefaultColumn" scope="col">
                            {{'LOCALIZATION.LEASE_SETTING.MST_SHOWROOM_DEFAULT' | translate}}</th>
                    </thead>
                    <tbody formArrayName="leaseTypeControls">
                        <tr [ngClass]="{'border-bottom': !lastRow}"
                            *ngFor="let leaseControl of leaseSettingsForm.controls.leaseTypeControls.controls; let i=index, last as lastRow"
                            [formGroupName]="i">
                            <td>{{'LOCALIZATION.LEASE_SETTING.CCR_LEASE_TYPE_CONTROLS.' +
                                leaseControl.controls.label.value |
                                translate}}</td>
                            <td>
                                <pre
                                    *ngIf="leaseControl.value.name === 'regular'"> {{'LOCALIZATION.COMMON.' + (leaseControl.value.value ? 'YES': 'NO') | translate}}</pre>
                                <mat-slide-toggle formControlName="value" *ngIf="leaseControl.value.name !== 'regular'"
                                    [disabled]="(disableActions$ | async)">
                                </mat-slide-toggle>
                            </td>
                            <td *ngIf="hideShowroomDefaultColumn">
                                <pre *ngIf="leaseControl.value.name === 'onePayLease'"><b>-</b></pre>
                                <mat-slide-toggle formControlName="default"
                                    *ngIf="leaseControl.value.name !== 'onePayLease'"
                                    [disabled]="(disableActions$ | async)">
                                </mat-slide-toggle>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <mat-label class="tableHeader"> {{'LOCALIZATION.LEASE_SETTING.TABLE_HEADER' | translate}}</mat-label>
            <div class="tableDiv pb-0">
                <table aria-describedby="tableHd" class="table example-full-width">
                    <thead>
                        <th scope="col">{{'LOCALIZATION.LEASE_SETTING.RULE' | translate}}</th>
                        <th scope="col">{{'LOCALIZATION.LEASE_SETTING.SELECTION' | translate}}</th>
                    </thead>
                    <tbody formArrayName="equityAllocation">
                        <tr [ngClass]="{'border-bottom': !lastRow}"
                            *ngFor="let equityControl of leaseSettingsForm.controls.equityAllocation.controls; let i=index, last as lastRow"
                            [formGroupName]="i">
                            <td>{{'LOCALIZATION.LEASE_SETTING.CCR_CONTROLS.' + equityControl.controls.label.value |
                                translate}}</td>
                            <td>
                                <mat-slide-toggle formControlName="value" [disabled]="(disableActions$ | async)">
                                </mat-slide-toggle>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" (cdkDropListDropped)="processingOrderOptionsDrop($event)" cdkDropList>
            <mat-label class="tableHeader">{{'LOCALIZATION.LEASE_SETTING.CCR_TABLE_HEADER' | translate}}</mat-label>
            <div class="row clearfix" style="width: 100%;"
                *ngFor="let orderOption of processingOrderOptions; let i=index">
                <mat-label style="width: 100%; padding-left: 10px;">
                    <span class="float-l dragSpan">{{i+1}}. </span>
                    <div [ngClass]="{'disabled-dragBox': (orderOption.mappingKey | disabledoption : leaseSettingsForm.controls.equityAllocation.value) || (disableActions$ | async)}"
                        class="dragBox float-l" [cdkDragDisabled]="(disableActions$ | async)" cdkDrag>
                        <div class="tmi icon tmi-move">
                        </div> {{orderOption.id}}
                    </div>
                </mat-label>
            </div>
        </div>
    </div>
    <div class="footerDiv">
        <button *ngIf="!(disableActions$ | async)" class="primary-button large-button float-r"
            (click)="saveLeaseSettings()">{{'LOCALIZATION.COMMON.SAVE' | translate}}</button>
        <!-- <button class="secondary-button large-button float-l"
            (click)="navigateToPreviousPage()">{{'LOCALIZATION.COMMON.BACK' | translate}}</button> -->
    </div>
</div>