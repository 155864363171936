import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { DeployJobManagementComponent } from "./deploy-job-management.component";

const routes: Routes = [
  {
    path: "",
    component: DeployJobManagementComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeployJobManagementRoutingModule {}
