import { ICalculatorResponse, ICalculatorRequest } from './../../shared/models/pe-calculator.model';
import { IBuilderResponse,IBuilderRequest } from './../../shared/models/pe-builder.model';
import { IVehicleDetailsDD365Response } from './../../shared/models/pe-dd365-vehicledetails.model';
import produce from 'immer';
import * as fromActions from './deal-validation.actions';

export interface DealValidationState {
  dealOffersDetail: IBuilderResponse,
  VehicleDetailsDD365 : IVehicleDetailsDD365Response,
  dealDetail: ICalculatorResponse,
  BuilderRequestInfo: IBuilderRequest,
  CalculatorRequestInfo: ICalculatorRequest,
  loadingOffers: boolean,
  loadingDeal: boolean,
  error: string
}

const initialState: DealValidationState = {
  dealOffersDetail: undefined,
  VehicleDetailsDD365: undefined,
  BuilderRequestInfo: undefined,
  CalculatorRequestInfo : undefined,
  dealDetail: undefined,
  loadingOffers: false,
  loadingDeal: false,
  error: ''
};

export function dealValidationReducer(
  state: DealValidationState = initialState,
  action: fromActions.DealValidationActions
): DealValidationState {

  switch (action.type) {

    case fromActions.DealValidationActionTypes.LOAD_OFFERS_FROM_BUILDER: {
      return produce(state, draftState => {
        draftState.dealOffersDetail = undefined;
        draftState.loadingOffers = true;
        draftState.error = '';
      });
    }
    case fromActions.DealValidationActionTypes.LOAD_OFFERS_FROM_BUILDER_SUCCESS: {
      return produce(state, draftState => {
        draftState.dealOffersDetail = action instanceof fromActions.LoadOffersFromBuilderSuccess ? action.payload.quoteDetails : undefined;
        draftState.loadingOffers = false;
        draftState.error = '';
      });
    }
    case fromActions.DealValidationActionTypes.LOAD_OFFERS_FROM_BUILDER_FAILURE: {
      return produce(state, draftState => {
        draftState.loadingOffers = false;
        draftState.error = action instanceof fromActions.LoadOffersFromBuilderFailure ? action.payload.error : '';
      });
    }
    case fromActions.DealValidationActionTypes.LOAD_VEHDETAILS_FROM_DD365: {
      return produce(state, draftState => {
        draftState.VehicleDetailsDD365 = undefined;
        draftState.loadingOffers = true;
        draftState.error = '';
      });
    }
    case fromActions.DealValidationActionTypes.LOAD_VEHDETAILS_FROM_DD365_SUCCESS: {
      return produce(state, draftState => {
        draftState.VehicleDetailsDD365 = action instanceof fromActions.LoadVehicleDetailsFromDD365Success ? action.payload.vehicleDetails : undefined;
        draftState.loadingOffers = false;
        draftState.error = '';
      });
    }
    case fromActions.DealValidationActionTypes.LOAD_OFFERS_FROM_BUILDER_FAILURE: {
      return produce(state, draftState => {
        draftState.loadingOffers = false;
        draftState.error = action instanceof fromActions.LoadOffersFromBuilderFailure ? action.payload.error : '';
      });
    }


    case fromActions.DealValidationActionTypes.LOAD_DEAL: {
      return produce(state, draftState => {
        draftState.dealDetail = undefined;
        draftState.loadingDeal = true;
        draftState.error = '';
      });
    }
    case fromActions.DealValidationActionTypes.LOAD_DEAL_SUCCESS: {
      return produce(state, draftState => {
        draftState.dealDetail = action instanceof fromActions.LoadDealSuccess ? action.payload.dealDetails : undefined;
        draftState.loadingDeal = false;
        draftState.error = '';
      });
    }
    case fromActions.DealValidationActionTypes.LOAD_DEAL_FAILURE: {
      return produce(state, draftState => {
        draftState.loadingDeal = false;
        draftState.error = action instanceof fromActions.LoadDealFailure ? action.payload.error : '';
      });
    }

    default:
      return state;
  }
}
