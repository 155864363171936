import { Subscription } from 'rxjs';
import _ from 'lodash';

export type SubscriptionList = { [key: string]: Subscription };

export const unsubscribeSubscriptions = (subscriptions: SubscriptionList) => {
  for (const subs of Object.keys(subscriptions)) {
    subscriptions[subs].unsubscribe();
  }
};

export const generateRandomQueryParam = (url: string = '') => {
  if (url && !_.includes(url, 'X-Amz-Credential')) {
    const ramdonQuery = `${url.indexOf('?') >= 0 ? '&' : '?'}cacheBusting=${new Date().getTime()}`;
    return url + ramdonQuery;
  }
  return url;
};

export const canActivateOnlyOnDev = () => {
  const hostName = window.location.hostname.toLowerCase();
  return hostName === 'localhost' || hostName === 'dev.dealer.toyota.com' || hostName === 'dealer.dev.toyota.com';
}

export const canActivateOnlyOnQAandDev = () => {
  const hostName = window.location.hostname.toLowerCase();
  return hostName === 'localhost' || hostName === 'dev.dealer.toyota.com' || hostName === 'dealer.dev.toyota.com'
    || hostName === 'qa.dealer.toyota.com' || hostName === 'dealer.qa.toyota.com';
}