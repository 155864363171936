import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  popupTitle: string;
  popupBodyText: string;
  cancelButtonText: string;
  confirmButtonText: string;
  errorText: string = '';

  constructor(public readonly dialogRef: MatDialogRef<ConfirmDialogComponent>,
    readonly translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public readonly data: any) {
  }

  ngOnInit() {
    this.popupTitle = this.translateService.instant('LOCALIZATION.COMMON.CONFIRM_TITLE');
    this.popupBodyText = this.translateService.instant('LOCALIZATION.COMMON.CONFIRM_BODY_TEXT');
    this.cancelButtonText = this.translateService.instant('LOCALIZATION.COMMON.CANCEL');
    this.confirmButtonText = this.translateService.instant('LOCALIZATION.COMMON.OK');
    this.updateProperty();
  }

  updateProperty() {
    if (this.data) {
      this.popupTitle = this.data.popupTitle || this.popupTitle;
      this.popupBodyText = this.data.popupBodyText || this.popupBodyText;
      this.cancelButtonText = this.data.cancelButtonText || this.cancelButtonText;
      this.confirmButtonText = this.data.confirmButtonText || this.confirmButtonText;
    }
  }

  close() {
    this.dialogRef.close({ action: 'close' });
  }

  confirm() {
    this.dialogRef.close({ action: this.data?.action || 'confirm' });
  }

}
