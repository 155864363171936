<dd-spinner *ngIf="(isLoading$ | async) || (selectIsLoadingBankConfig$ | async)"></dd-spinner>
<div *ngIf="(selectBankConfigLoaded$ | async)">
    <div *ngIf="readFlag" class="offer-markup" [formGroup]="offerMarkupForm">
        <div class="bodyDiv container-fluid widthStyle" style="margin-top: 20px;">
            <mat-label class="mainDescription">{{'LOCALIZATION.OFFER_MARKUP.MAIN_DESCRIPTION_LINE_ONE' |
                translate}}<br />
                {{'LOCALIZATION.OFFER_MARKUP.MAIN_DESCRIPTION_LINE_TWO' | translate}}<br />
                {{'LOCALIZATION.OFFER_MARKUP.MAIN_DESCRIPTION_LINE_THREE' | translate}}<br />
                {{'LOCALIZATION.OFFER_MARKUP.MAIN_DESCRIPTION_LINE_FOUR' | translate}}<br />
            </mat-label>
        </div>
        <div class="bodyDiv container-fluid widthStyle">
            <mat-label class="bodyDivHeader">{{'LOCALIZATION.OFFER_MARKUP.HEADER' | translate}}<span
                    class="dividerLine"></span><span class="restoreAll" (click)="restoreAll()"
                    matTooltip="{{'LOCALIZATION.OFFER_MARKUP.RESTORE_ALL_TOOLTIP' | translate}}">{{'LOCALIZATION.OFFER_MARKUP.RESTORE_ALL_TEXT'
                    | translate}}</span></mat-label>
            <div class="">
                <div class="form-group">
                    <mat-label>{{'LOCALIZATION.OFFER_MARKUP.DAYS_TO_FIRST_PAYMENT_TEXT' | translate}}</mat-label>
                    <br><br>
                    <div>
                        <select class="select daySelect" formControlName="daysToFirstPayment"
                            [disabled]="!writeFlag || (disableActions$ | async)" [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let day of days" [value]="day.value">
                                {{day.label}}{{'LOCALIZATION.OFFER_MARKUP.DAYS_TEXT' | translate}}</option>
                            <option value="custom">{{'LOCALIZATION.OFFER_MARKUP.CUSTOM_OPTION_TEXT' | translate}}
                            </option>
                        </select>
                        <input *ngIf="offerMarkupForm.controls.daysToFirstPayment.value === 'custom'" matInput
                            appOnlynumber class="input dayInput" formControlName="daysToFirstPaymentEdit"
                            [ngModelOptions]="{standalone: true}" autocomplete="off" type="number"
                            [disabled]="!writeFlag || (disableActions$ | async)" />
                        <mat-label *ngIf="daysToFirstPayment === 'custom'"> {{'LOCALIZATION.OFFER_MARKUP.DAYS_TEXT' |
                            translate}}</mat-label>
                    </div>
                </div>
            </div><br>
            <div>
                <div class="form-group toggleClass">
                    <mat-label>{{'LOCALIZATION.OFFER_MARKUP.APPLY_RATE_MARKUP_TEXT' | translate}}</mat-label><br><br>
                    <mat-slide-toggle [(ngModel)]="bankDefaultRate" [ngModelOptions]="{standalone: true}"
                        [disabled]="!writeFlag || (disableActions$ | async)">
                    </mat-slide-toggle>
                </div>
            </div><br>
            <div *ngIf="bankDefaultRate">
                <div class="d-flex">
                    <mat-label class="tableHeader">{{'LOCALIZATION.OFFER_MARKUP.RULES_TABLE_HEADER' |
                        translate:({"distributor": distributor})}}
                    </mat-label>
                </div><br>
                <div class="tableDiv">
                    <table aria-describedby="tableHd" class="table">
                        <thead>
                            <tr>
                                <th scope="col" style="width:155px !important;"><span
                                        style="margin-left:40px !important;">{{'LOCALIZATION.OFFER_MARKUP.OFFER_TYPE' |
                                        translate}}</span></th>
                                <th scope="col" style="width:114px !important;"><span
                                        style="margin-left:40px !important;">{{'LOCALIZATION.OFFER_MARKUP.MAKE' |
                                        translate}}</span></th>
                                <th scope="col" style="width:114px !important;"><span
                                        style="margin-left:15px !important;">{{'LOCALIZATION.OFFER_MARKUP.SALES_CLASS' |
                                        translate}}</span></th>
                                <th scope="col" style="width:124px !important;"><span
                                        style="margin-left:-10px !important;">{{'LOCALIZATION.OFFER_MARKUP.TERM_RANGE_MIN'
                                        |
                                        translate}}</span></th>
                                <th scope="col" style="width:124px !important;"><span
                                        style="margin-left:-20px !important;">{{'LOCALIZATION.OFFER_MARKUP.TERM_RANGE_MAX'
                                        |
                                        translate}}</span></th>
                                <th scope="col" style="width:124px !important;"><span
                                        style="margin-left:-25px !important;">{{'LOCALIZATION.OFFER_MARKUP.RATE_MARKUP'
                                        |
                                        translate}}</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-bottom" *ngFor="let offerType of offerTypesWithSubvention;">
                                <td>
                                    <span style="width:155px !important;">
                                        <span class="offerType">{{offerType.offerType}}</span> <br>
                                        <span class="offerSubType" class="pointer"
                                            [matTooltip]="offerType.tooltip">{{offerType.offerSubType}}</span>
                                        <br>
                                        <br>
                                        <span class="addLabel addRow" *ngIf="writeFlag && !(disableActions$ | async)">
                                            <span class="material-icons"
                                                (click)="addFieldValue(offerType.offerType, offerType.offerSubvented)">add</span>
                                            <span
                                                (click)="addFieldValue(offerType.offerType, offerType.offerSubvented)">{{'LOCALIZATION.OFFER_MARKUP.ADD_ADDITIONAL_RULE'
                                                | translate}}</span>
                                        </span>
                                    </span>
                                </td>
                                <td colspan="5">
                                    <table aria-describedby="tableHd" class="table subTable">
                                        <thead style="display: none;">
                                            <th scope="col"></th>
                                        </thead>
                                        <tbody formArrayName="markups" class="markups">
                                            <ng-container
                                                *ngFor="let markup of getMarkupsByFilter(offerType.offerType, offerType.offerSubvented); let j = index;"
                                                [formGroupName]="getMarkupIndex(markup)">
                                                <tr>
                                                    <td>
                                                        <mat-select class="select d-flex my-auto"
                                                            style="width:114px !important;"
                                                            formControlName="vehicleMake"
                                                            [disabled]="!writeFlag || (disableActions$ | async)"
                                                            (selectionChange)="changeMake($event,markup)"
                                                            [ngClass]="validateFormGroup(markup)">
                                                            <mat-option value="all">{{'LOCALIZATION.COMMON.ALL' |
                                                                translate}}
                                                            </mat-option>
                                                            <mat-option value="toyota">{{'LOCALIZATION.BRANDS.TOYOTA' |
                                                                translate}}</mat-option>
                                                            <mat-option value="lexus">{{'LOCALIZATION.BRANDS.LEXUS' |
                                                                translate}}</mat-option>
                                                            <mat-option value="ntl">{{'LOCALIZATION.BRANDS.NTL' |
                                                                translate}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </td>
                                                    <td>
                                                        <mat-select class="select d-flex my-auto"
                                                            style="width:114px !important;"
                                                            formControlName="vehicleType"
                                                            [disabled]="!writeFlag || (disableActions$ | async)"
                                                            (selectionChange)="changeType($event,markup)"
                                                            [ngClass]="validateFormGroup(markup)">
                                                            <ng-container>
                                                                <mat-option value="all"
                                                                    *ngIf="(offerMarkupForm.get('markups').at(getMarkupIndex(markup)).controls['vehicleMake'].value) != 'ntl'">
                                                                    {{'LOCALIZATION.SALES_CLASS_TYPES.ALL_TYPES' |
                                                                    translate}}</mat-option>
                                                                <mat-option value="new"
                                                                    *ngIf="(offerMarkupForm.get('markups').at(getMarkupIndex(markup)).controls['vehicleMake'].value) != 'ntl'">
                                                                    {{'LOCALIZATION.SALES_CLASS_TYPES.NEW' |
                                                                    translate}}</mat-option>
                                                                <mat-option value="used">
                                                                    {{'LOCALIZATION.SALES_CLASS_TYPES.USED' |
                                                                    translate}}</mat-option>
                                                                <mat-option value="certified"
                                                                    *ngIf="(offerMarkupForm.get('markups').at(getMarkupIndex(markup)).controls['vehicleMake'].value) != 'ntl'">
                                                                    {{'LOCALIZATION.SALES_CLASS_TYPES.CERTIFIED' |
                                                                    translate}}</mat-option>
                                                            </ng-container>
                                                        </mat-select>
                                                    </td>

                                                    <td formGroupName="term">
                                                        <input style="width:124px !important;" type="number"
                                                            [pattern]="decimalRegex" matInput appOnlynumber nxNoScroll
                                                            [ngClass]="validateFormGroup(markup,disableFlag)"
                                                            autocomplete="off"
                                                            class="input d-flex my-auto no-number-arrow"
                                                            formControlName="min"
                                                            [attr.disabled]="(disableActions$ | async)"
                                                            (keydown)="onKeydown(disableFlag)" />
                                                    </td>
                                                    <td formGroupName="term"><input style="width:124px !important;"
                                                            type="number" [pattern]="decimalRegex" matInput
                                                            appOnlynumber nxNoScroll
                                                            [ngClass]="validateFormGroup(markup,disableFlag)"
                                                            autocomplete="off"
                                                            class="input d-flex my-auto no-number-arrow"
                                                            formControlName="max"
                                                            [matTooltip]="getTooltip(markup.controls.term.controls.max)"
                                                            [attr.disabled]="(disableActions$ | async)"
                                                            (keydown)="onKeydown(disableFlag)" /></td>
                                                    <td class="d-flex" *ngIf="offerType.offerType!=='lease'"><input
                                                            style="width:124px !important;" type="number"
                                                            [pattern]="decimalRegex" matInput nxNoScroll
                                                            [attr.disabled]="!writeFlag || (disableActions$ | async)"
                                                            appTwoDigitDecimal
                                                            [ngClass]="validateFormGroup(markup,disableFlag)"
                                                            autocomplete="off"
                                                            class="input markupInput d-flex my-auto no-number-arrow"
                                                            formControlName="value"
                                                            step="any"
                                                            [matTooltip]="getTooltip(markup.controls.value)"
                                                            (keydown)="onKeydown(disableFlag)" />
                                                        <mat-label class="d-flex my-auto">%</mat-label>
                                                    </td>
                                                    <td class="d-flex" *ngIf="offerType.offerType==='lease'"><input
                                                            style="width:124px !important;" type="number"
                                                            [pattern]="decimalRegex" matInput nxNoScroll
                                                            [attr.disabled]="!writeFlag || (disableActions$ | async)"
                                                            appFiveDigitDecimal autocomplete="off"
                                                            [ngClass]="validateFormGroup(markup,disableFlag)"
                                                            class="input markupInput d-flex my-auto no-number-arrow"
                                                            formControlName="value"
                                                            step="any"
                                                            [matTooltip]="getTooltip(markup.controls.value)"
                                                            (keydown)="onKeydown(disableFlag)" />
                                                        <mat-label class="visibility-hidden">%</mat-label>
                                                    </td>
                                                    <td>
                                                        <mat-icon style="position: relative;top: 7px;" class="pointer"
                                                            class="highlight_off"
                                                            *ngIf="writeFlag && !(disableActions$ | async)"
                                                            (click)="deleteFieldValue(markup)">
                                                            close</mat-icon>
                                                    </td>
                                                </tr>
                                                <tr class="seperator"></tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="footerDiv mb-2 mr-2">
            <button class="secondary-button large-button float-r" (click)="navigateToNextPage()"
                [disabled]="!writeFlag || (disableActions$ | async)">{{'LOCALIZATION.COMMON.NEXT' | translate}}</button>
            <button *ngIf="!(disableActions$ | async)" class="primary-button large-button float-r"
                (click)="onSubmit($event)"
                [disabled]="!writeFlag || offerMarkupForm.invalid">{{'LOCALIZATION.COMMON.SAVE' |
                translate}}</button>
        </div>
    </div>
    <div class="mainDiv" *ngIf="!readFlag">
        <mat-label class="mainHeading">{{'LOCALIZATION.NO_PERMISSION.HEADER' | translate}}</mat-label>
        <div>
            <mat-label class="mainDescription"> {{'LOCALIZATION.NO_PERMISSION.DESCRIPTION' | translate}}</mat-label>
        </div>
    </div>
</div>