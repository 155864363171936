import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidationServiceService {

  constructor() { }

  private selectedOffertype = new BehaviorSubject<any>({
    offerType: '',
    offerTypeTranslated: ''
  });

  private enteredDownpayment = new BehaviorSubject<any>({
    downPayment: 0,
  });

  private enteredNetTradeInValue = new BehaviorSubject<any>({
    netTradeIn: 0,
  });
  private selectedTaxType = new BehaviorSubject<any>({
    selectedTaxType: '',
  });
  setOfferTypeInfo(offer: any) {
    this.selectedOffertype.next(offer);
  }
  getOfferTypeInfo() {
    return this.selectedOffertype.asObservable();
  }

  getdownpaymentinfo() {
    return this.enteredDownpayment.asObservable();
  }
  setdownpaymentinfo(down: any) {
    this.enteredDownpayment.next(down);
  }

  getnettradeininfo() {
    return this.enteredNetTradeInValue.asObservable();
  }
  setnettradeininfo(netrade: any) {
    this.enteredNetTradeInValue.next(netrade);
  }
  setSelectedTypeTaxInfo(tax: any) {
    this.selectedTaxType.next(tax);
  }
  getSelectedTypeTaxInfo() {
    return this.selectedTaxType.asObservable();
  }
}
