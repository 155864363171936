import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as _ from 'lodash';

@Component({
    selector: 'app-pe-discard-changes-confirmation',
    templateUrl: './discard-confirmation.component.html',
    styleUrls: ['./discard-confirmation.component.scss'],
})
export class DiscardConfirmationComponent {

    reason: string;

    constructor(
        private readonly dialogRef: MatDialogRef<DiscardConfirmationComponent>,
        private readonly translateService: TranslateService
    ) { }

    close() {
        this.dialogRef.close({ action: 'close' });
    }

    confirm() {
        this.dialogRef.close({ action: 'discard', comments: _.isEmpty(this.reason) ? undefined : this.reason });
    }
}