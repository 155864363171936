<div class="close-dialog-icon-container d-flex">
    <mat-icon class="close-dialog-icon" mat-dialog-close>close</mat-icon>
</div>
<div class="audit-log-details">
    <div class="depjob-auditlog-container">
        <div class="header">
            <div mat-dialog-title>
                {{'LOCALIZATION.DEPLOYMENT_JOB.AUDIT_LOG_TITLE' | translate :({'deploymentid': data.dep_id})}}
            </div>
        </div>
    </div>
    <div *ngIf="(auditLoading$ | async)">
        <mat-spinner style="margin:0 auto;" diameter="40" value="100" color="warn">
        </mat-spinner>
    </div>
    <div class="container-fluid p-3 pb-0" *ngIf="data.comments">
        <b>{{'LOCALIZATION.DEPLOYMENT_JOB.DISCARD_COMMENTS' | translate}}:</b> {{data.comments}}
    </div>
    <div class="container-fluid p-3" *ngIf="
    !(auditLoading$ | async)">

        <table aria-describedby="tableHd" class="table example-full-width mt-3">
            <thead>
                <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.MODULE' | translate}}</th>
                <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.TIMESTAMP' | translate}}</th>
                <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.USER' | translate}}</th>
                <th scope="col" class="pl-2">{{'LOCALIZATION.AUDIT.ACTION' | translate}}</th>
            </thead>
            <tbody>
                <tr [ngClass]="{'border-bottom': !lastRow}"
                    *ngFor="let log of auditLogs, let i = index, last as lastRow, first as firstRow">
                    <td>
                        {{log.module}}
                    </td>
                    <td>
                        {{log.date}}
                    </td>
                    <td>
                        {{log.userName + ' (' + log.userId + ')'}}
                    </td>
                    <td>
                        <span *ngIf="log.description.length <= 1 else descriptionList">
                            {{log.description?.length ? log.description[0] : ''}}
                        </span>
                        <ng-template #descriptionList>
                            <ul class="ml-4">
                                <li *ngFor="let logDescItem of log.description" class="text-wrapper-col"
                                    style="word-break: break-all;">
                                    {{logDescItem}}
                                </li>
                            </ul>
                        </ng-template>
                    </td>
                </tr>
                <tr *ngIf="!auditLogs?.length">
                    <td colspan="4">
                        <mat-label class="d-flex pt-2 justify-content-center">{{'LOCALIZATION.AUDIT.NO_RECORDS' |
                            translate}}</mat-label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>