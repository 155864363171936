import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateLoaderFactoryService } from './translate-loader-factory.service';
import { registerLocaleData } from '@angular/common';
import localeEsMX from '@angular/common/locales/es-MX';
import localeEsPR from '@angular/common/locales/es-PR';
import localeEsUS from '@angular/common/locales/es-US';
import localeEs from '@angular/common/locales/es';
import { LanguageCode, LocaleCodes, UserProfileService } from '@toyota/dd365-platform-library';
import { AssetUrlPipe } from '../../../../app/pipes/assets';


@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, UserProfileService, AssetUrlPipe]
      }
    })
  ],
  providers: [
    AssetUrlPipe
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(translate: TranslateService) {
    translate.addLangs([LocaleCodes.EN_US, LocaleCodes.EN_MX, LocaleCodes.ES_US, LocaleCodes.ES_MX]);
    registerLocaleData(localeEs, LanguageCode.SPANISH);
    registerLocaleData(localeEsUS, LocaleCodes.ES_US);
    registerLocaleData(localeEsMX, LocaleCodes.ES_MX);
    registerLocaleData(localeEsPR, LocaleCodes.ES_PR);
    translate.setDefaultLang(LocaleCodes.EN_US);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es/) ? browserLang + '-US' : LocaleCodes.EN_US);
  }
}

export function translateLoaderFactory(httpClient: HttpClient, userProfieService: UserProfileService, assetUrlPipe: AssetUrlPipe) {
  return new TranslateLoaderFactoryService(httpClient, userProfieService, assetUrlPipe);
}
