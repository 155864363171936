import { DealValidationState } from './../../../store/deal-validation/deal-validation.reducer';
import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICalculatorResponse, ICalculatorRequest } from './../../../shared/models/pe-calculator.model';
import { IBuilderRequest } from './../../../shared/models/pe-builder.model';
import { IVehicleDetailsDD365Response } from './../../../shared/models/pe-dd365-vehicledetails.model';
import { Observable } from 'rxjs';
import { SubscriptionList, unsubscribeSubscriptions } from './../../../shared/services/util.service';
import { selectVehicleDetailsDD365, selectCalcResponse } from './../../../store/deal-validation/deal-validation.selectors';
import { UserProfileService } from '@toyota/dd365-platform-library';
import { DealValidationFormComponent } from '../deal-validation-form/deal-validation-form.component';
import { ValidationServiceService } from '../../../shared/services/validation-service.service'
@Component({
  selector: 'app-pe-deal-validation-details',
  templateUrl: './deal-validation-details.component.html',
  styleUrls: ['./deal-validation-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DealValidationDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(DealValidationFormComponent) childReference;
  quotesResult$: Observable<ICalculatorResponse>;
  vehDetailsResult$: Observable<IVehicleDetailsDD365Response>;
  builderInput$: Observable<IBuilderRequest>;
  calcInput$: Observable<ICalculatorRequest>;
  calcResultResponse: ICalculatorResponse;
  vehicleDetailsResponse: IVehicleDetailsDD365Response;
  builderInputData: IBuilderRequest;
  calcInputData: ICalculatorRequest;
  private subs: SubscriptionList = {};
  offerType: string;
  offerTypeTranslated: string;
  total: number;
  taxtotal: number;
  feetotal: number;
  downpayment: number;
  nettradeindata: number;
  taxorNotax: boolean;

  constructor(
    public store: Store,
    private readonly dealValidationState: Store<DealValidationState>,
    private readonly userProfileService: UserProfileService,
    private readonly validationService: ValidationServiceService
  ) { }

  ngOnInit(): void {
    this.taxtotal = 0;
    this.feetotal = 0;
    this.validationService.getOfferTypeInfo().subscribe(offer => {
      this.offerType = offer.offerType;
      this.offerTypeTranslated = offer.offerTypeTranslated;
    })
    this.validationService.getdownpaymentinfo().subscribe(down => {
      this.downpayment = down.downPayment;
    })
    this.validationService.getnettradeininfo().subscribe(trade => {
      this.nettradeindata = trade.netTradeIn;
    })
    this.validationService.getSelectedTypeTaxInfo().subscribe(tax => {
      this.taxorNotax = tax.selectedTaxType;
    })

    this.quotesResult$ = this.dealValidationState.select(selectCalcResponse);
    this.vehDetailsResult$ = this.dealValidationState.select(selectVehicleDetailsDD365);
    this.subs.selectCalcResponseSub = this.quotesResult$.subscribe(data => {
      this.calcResultResponse = data;
      if (data) {
        if (!this.taxorNotax) {
          for (let k = 1; k < this.calcResultResponse?.quotes[1].Quote.Fees.length; k++) {
            this.feetotal = this.feetotal + this.calcResultResponse?.quotes[1].Quote.Fees[k].Amount;
          }
          for (let l = 0; l < this.calcResultResponse?.quotes[1].Quote.Taxes.length; l++) {
            if (this.offerType === 'lease') {
              this.taxtotal = this.taxtotal + parseFloat(this.calcResultResponse?.quotes[1].Quote.Taxes[l].TotalCapitalized);
            }
            else {
              this.taxtotal = this.taxtotal + this.calcResultResponse?.quotes[1].Quote.Taxes[l].Amount;
            }
          }
        }
        else {
          for (let k = 1; k < this.calcResultResponse?.quotes[0].Quote.Fees.length; k++) {
            this.feetotal = this.feetotal + this.calcResultResponse?.quotes[0].Quote.Fees[k].Amount;
          }
          for (let l = 0; l < this.calcResultResponse?.quotes[0].Quote.Taxes.length; l++) {
              this.taxtotal = this.taxtotal + 0;
          }
        }
      }
    });

    this.subs.selectVehicleDetailsResponseSub = this.vehDetailsResult$.subscribe(data => {
      this.vehicleDetailsResponse = data;
      if (data) {
        this.total = 0;
        for (let j = 0; j < this.vehicleDetailsResponse?.options.length; j++) {
          this.total = this.total + this.vehicleDetailsResponse.options[j].dealerInvoicePrice;
        }
      }
    });
  }
  getMultipliedValue(data: number) {
    return ((data) * (-1));
  }
  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }
}
