import { DealValidationModule } from "./components/pe-admin/deal-validation/deal-validation.module";
import { PEAuditModule } from "./components/pe-admin/pe-audit/pe-audit.module";
import { CanActivatePP } from "./components/shared/guards/can-activate-pp.guard";
import { ProtectionProductModule } from "./components/pe-admin/protection-product/protection-product.module";
import { NgModule } from "@angular/core";
import { RouterModule, ROUTES } from "@angular/router";
import { APP_BASE_HREF } from "@angular/common";
import { DealerInfoService } from "./components/shared/services/dealer-info.service";
import { OfferMarkupModule } from "./components/pe-admin/offer-markup/offer-markup.module";
import { LeaseSettingModule } from "./components/pe-admin/lease-setting/lease-setting.module";
import { DealerDefinedCampaignsModule } from "./components/pe-admin/dealer-defined-campaigns/dealer-defined-campaigns.module";
import { ConfigNotFoundComponent } from "./components/pe-admin/config-not-found/config-not-found.component";
import { DealerConfigFound } from "./components/shared/guards/can-activate-config-found.guard";
import { DealerConfigNotFound } from "./components/shared/guards/can-activate-config-not-found.guard";
import { CanActivateOnlyOnDev } from "./components/shared/guards/can-activate-only-on-dev.guard";
import { DeployJobManagementModule } from "./components/pe-admin/deploy-job-management/deploy-job-management.module";
import { PaymentGridModule } from "./components/pe-admin/payment-grid/payment-grid.module";
import { GrossProfitModule } from "./components/pe-admin/gross-profit/gross-profit.module";
import { IncentivesModule } from "./components/pe-admin/incentives/incentives.module";
import { VehiclePacModule } from "./components/pe-admin/vehicle-pac/vehicle-pac.module";

@NgModule({
  imports: [RouterModule.forRoot([])],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: "/",
    },
    {
      provide: ROUTES,
      useFactory: setRoutesBasedOnRoles,
      deps: [DealerInfoService],
      multi: true,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

function setRoutesBasedOnRoles(_dealerInfoService: DealerInfoService) {
  return [
    {
      path: "pe-admin",
      children: [
        {
          path: "",
          canActivate: [DealerConfigFound],
          loadChildren: () => OfferMarkupModule,
        },
        {
          path: "offer-markup",
          canActivate: [DealerConfigFound],
          loadChildren: () => OfferMarkupModule,
        },
        {
          path: "protection-products",
          canActivate: [CanActivatePP, DealerConfigFound],
          loadChildren: () => ProtectionProductModule,
        },
        {
          path: "dealer-defined-campaigns",
          canActivate: [DealerConfigFound],
          loadChildren: () => DealerDefinedCampaignsModule,
        },
        {
          path: "incentives",
          canActivate: [DealerConfigFound],
          loadChildren: () => IncentivesModule,
        },
        {
          path: "lease-setting",
          canActivate: [DealerConfigFound],
          loadChildren: () => LeaseSettingModule,
        },
        {
          path: "audit",
          canActivate: [DealerConfigFound],
          loadChildren: () => PEAuditModule,
        },
        {
          path: "deal-validation",
          canActivate: [CanActivateOnlyOnDev, DealerConfigFound],
          loadChildren: () => DealValidationModule,
        },
        {
          path: "config-not-found",
          canActivate: [DealerConfigNotFound],
          component: ConfigNotFoundComponent,
        },
        {
          path: "deploy-job-management",
          canActivate: [DealerConfigFound],
          loadChildren: () => DeployJobManagementModule,
        },
        {
          path: "payment-grid",
          canActivate: [DealerConfigFound],
          loadChildren: () => PaymentGridModule,
        },
        {
          path: "gross-profit",
          canActivate: [DealerConfigFound],
          loadChildren: () => GrossProfitModule,
        },
        {
          path: "vehicle-pac",
          canActivate: [DealerConfigFound],
          loadChildren: () => VehiclePacModule,
        },
      ],
    },
  ];
}
