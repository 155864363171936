import { Observable } from 'rxjs';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserProfileService } from '@toyota/dd365-platform-library';
import { SubscriptionList, unsubscribeSubscriptions } from '../../shared/services/util.service';
import _ from 'lodash';
import { DealValidationState } from '../../store/deal-validation/deal-validation.reducer';
import { Store } from '@ngrx/store';
import { selectLoadingDeal, selectLoadingOffers } from '../../store/deal-validation/deal-validation.selectors';

@Component({
  selector: 'app-pe-deal-validation',
  templateUrl: './deal-validation.component.html',
  styleUrls: ['./deal-validation.component.scss']
})
export class DealValidationComponent implements OnInit, OnDestroy {

  dealerCode: string;
  loadingOffers$: Observable<boolean>;
  loadingDeal$: Observable<boolean>;

  private subs: SubscriptionList = {};

  constructor(
    private readonly userProfileService: UserProfileService,
    private readonly dealValidationState: Store<DealValidationState>
  ) { }

  ngOnInit(): void {
    this.dealerCode = this.userProfileService.getProfile().dealerCd;
    this.loadingOffers$ = this.dealValidationState.select(selectLoadingOffers);
    this.loadingDeal$ = this.dealValidationState.select(selectLoadingDeal);
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

}
