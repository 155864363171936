import { ProtectionProductsComponentService } from './../protection-products.component.service';
import { IPennProduct, IPennProvider } from './../../../shared/models/product-offerings.model';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'pennproductsfilter'
})
export class PennProductsFilterPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(provider: string, providerList: IPennProvider[]): IPennProduct[] {
        if (provider) {
            const disabledProducts = this.pPComponentService.getExistingAndDisabledProductsForProvider(provider);
            const pennProvider: IPennProvider = _.find(providerList, { name: provider });
            const pennProducts: IPennProduct[] = (pennProvider?.products || []);
            return pennProducts.map(t => ({ ...t, disabled: _.includes(disabledProducts, t.name) }));
        }
        else {
            return [];
        }
    }
}
