import { createSelector, createFeatureSelector } from "@ngrx/store";
import { FeatureKeys } from "../app.reducer";
import { DeployJobManageState } from "./deploy-job-management.reducer";
import * as _ from "lodash";


export const selectDeployJobManageFeature = createFeatureSelector<
  DeployJobManageState
>(FeatureKeys.DEPLOYMENTJOB);

export const selectDeployJobManagement = createSelector(
  selectDeployJobManageFeature,
  (state: DeployJobManageState) => state.deployJob
);

export const selectDeployJobIsLoading = createSelector(
  selectDeployJobManageFeature,
  (state: DeployJobManageState) => state.loading
);

export const selectDeployConfiguration = createSelector(
  selectDeployJobManageFeature,
  (state: DeployJobManageState) => state.deployJobConfig
);

export const selectAuditLogIsLoading = createSelector(
  selectDeployJobManageFeature,
  (state: DeployJobManageState) => state.auditLoading
);

export const selectAuditLogs = createSelector(
  selectDeployJobManageFeature,
  (state: DeployJobManageState) => state.auditLog
);

export const selectDeployConfigIsLoading = createSelector(
  selectDeployJobManageFeature,
  (state: DeployJobManageState) => state.configLoading
);