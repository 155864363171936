<div class="container">
    <div class="row header">
        <div mat-dialog-title class="col-md-10">
            {{popupTitle}}
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="body-text">
                {{popupBodyText}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="action-con">
                <div (click)="close()">
                    <button class="secondary-button">
                        <span>{{cancelButtonText}}</span>
                    </button>
                </div>
                <div (click)="confirm()">
                    <button class="primary-button">
                        <span>{{confirmButtonText}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>