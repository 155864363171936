import { DealerConfigurationState } from './components/store/dealer-configuration/dealer-configuration.reducer';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { GlobalModule } from './components/global/global.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpInterceptorService } from './service/authentication/http-interceptor.service';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { PeAdminModule } from './components/pe-admin/pe-admin.module';
import { AppPipesModule } from './pipes/app-pipes.module';
import { AssetUrlPipe } from './pipes/assets';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from './models/directives/directives.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { ChangeDealershipInterceptorService, CHANGE_DEALERSHIP_INTERCEPTOR_CONFIGS, UserProfileService, IUserProfile } from '@toyota/dd365-platform-library';
import { appReducer } from './components/store/app.reducer';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { appEffects } from './components/store/app.effects';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SingleSpaProps, singleSpaPropsSubject } from '../single-spa/single-spa-props';
import { filter, take } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import * as fromDealerConfigurationSelectors from '../app/components/store/dealer-configuration/dealer-configuration.selectors';
import * as fromDealerConfigurationActions from '../app/components/store/dealer-configuration/dealer-configuration.actions';
import { MatChipsModule } from '@angular/material/chips';
import { combineLatest } from 'rxjs';
import { DealerInfoService } from './components/shared/services/dealer-info.service';
import { SharedModule } from './components/shared/shared.module';
import { I18nModule } from './components/shared/locale/i18n.module';

@NgModule({
  declarations: [AppComponent, EmptyRouteComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot(appEffects),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    GlobalModule,
    PeAdminModule,
    AppPipesModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    MatTooltipModule,
    MatTabsModule,
    MatDialogModule,
    MatSidenavModule,
    MatIconModule,
    MatChipsModule,
    I18nModule,
    SharedModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [UserProfileService, DealerInfoService, Store]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,

    },
    {
      provide: CHANGE_DEALERSHIP_INTERCEPTOR_CONFIGS,
      useFactory: setChangeDealershipDomainConfigs
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ChangeDealershipInterceptorService,
      deps: [CHANGE_DEALERSHIP_INTERCEPTOR_CONFIGS, UserProfileService],
      multi: true,
    },
    AssetUrlPipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule { }

export function setChangeDealershipDomainConfigs() {
  return [
    new RegExp('api.rti(.dev|.test|.e2e|.qa|.stage)?.toyota.com'),
    new RegExp('dxid-api.rti(.dev|.test|.e2e|.qa|.stage)?.toyota.com'),
    new RegExp('pepp(.dev|.qa|.stage)?.dealer.toyota.com')
  ];
}

function initApp(userProfileService: UserProfileService, dealerInfoService: DealerInfoService, dealerConfigState: Store<DealerConfigurationState>) {
  return () => new Promise((resolve, reject) => {
    this.singleSpaPropsSubscription = singleSpaPropsSubject.pipe(take(1)).subscribe(
      (props: SingleSpaProps) => {
        // User profile subscription will get triggered any time the UI Gateway broadcasts a user profile update
        props.userProfile.subscribe((profile: IUserProfile) => {
          userProfileService.updateProfile(profile);
        });
        // One time User profile subscription to perform actions on app mount
        props.userProfile.pipe(take(1)).subscribe((profile: IUserProfile) => {
          if (profile) {
            dealerConfigState.dispatch(new fromDealerConfigurationActions.LoadDealerFeatureConfiguration({ dealerCode: profile.dealerCd }));
            dealerConfigState.dispatch(new fromDealerConfigurationActions.LoadDealerConfiguration({ dealerCode: profile.dealerCd }));
            combineLatest([
              dealerConfigState.select(fromDealerConfigurationSelectors.selectDealerConfiguration),
              dealerConfigState.select(fromDealerConfigurationSelectors.selectDealerFeatureConfig),
              dealerConfigState.select(fromDealerConfigurationSelectors.selectDealerFeatureConfigError)
            ]).pipe(
              filter(([dealerConfig, dealerFeatureConfig, dealerFeatureConfigError]) =>
                !!dealerFeatureConfigError || (!!dealerConfig && !!dealerFeatureConfig)), take(1)
            )
              .subscribe(([, featureConfig, featureConfigError]) => {
                if (featureConfigError) {
                  reject(featureConfigError);
                }
                else {
                  const canInitApp = dealerInfoService.setupFeatures(featureConfig);
                  if (canInitApp) {
                    resolve(true);
                  }
                  else {
                    reject('F&I Admin feature is disabled.');
                  }
                }
              }, (err) => {
                reject(err);
              }
              );
          }
        }, (err) => {
          reject(err);
        });
      });
  });
}