import { Action } from "@ngrx/store";
import { ILenderListConfig } from "../../shared/models/lender/lender-list-config.model";

export enum LenderListConfigActionTypes {
  LOAD_LENDER_LIST_CONFIG = "[LenderListConfig] Load Lender List Config",
  LOAD_LENDER_LIST_CONFIG_SUCCESS = "[LenderListConfig] Load Lender List Config success",
  LOAD_LENDER_LIST_CONFIG_FAILURE = "[LenderListConfig] Load Lender List Config failure",
}

export class LoadLenderListConfig implements Action {
  type: string = LenderListConfigActionTypes.LOAD_LENDER_LIST_CONFIG;
  constructor(public payload: { dealerCode: string }) {}
}

export class LoadLenderListConfigSuccess implements Action {
  type: string = LenderListConfigActionTypes.LOAD_LENDER_LIST_CONFIG_SUCCESS;
  constructor(public payload: { lenderListConfig: ILenderListConfig[] }) {}
}

export class LoadLenderListConfigFailure implements Action {
  type: string = LenderListConfigActionTypes.LOAD_LENDER_LIST_CONFIG_FAILURE;
  constructor(public payload: { error: string }) {}
}

export type LenderListConfigActions =
  | LoadLenderListConfig
  | LoadLenderListConfigSuccess
  | LoadLenderListConfigFailure;
