import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { IVehicleBrand, IVehicleSeries } from '../../../shared/models/vehicle-config-master.model';
import { VehiclePacComponentService } from '../vehicle-pac.component.service';
import { VehicleConfigState } from '../../../store/vehicle-config/vehicle-config.reducer';
import { Store } from '@ngrx/store';

@Pipe({
    name: 'getbrandseries'
})
export class GetBrandSeriesPipe implements PipeTransform {
    constructor(private readonly vehiclePacComponentService: VehiclePacComponentService, private readonly vehicleConfigState: Store<VehicleConfigState>) { }
    transform(brand: string, vehicleConfig: IVehicleBrand[]): IVehicleSeries[] {
        let result;
        if (brand && brand !== '' && brand !== null) {
            if (brand === 'all' || brand === 'ntl') {
                result = [];
            } else {
                this.vehiclePacComponentService.checkAndfetchVehicleConfig(brand, this.vehicleConfigState);
                result = _.find(vehicleConfig, { name: brand })?.series || [];
                result = _.sortBy(result, [(item) => item.name.toLowerCase()]);
            }
        }
        return result;
    }
}