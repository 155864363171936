import { Brands } from "../../shared/enums/brands.enum";
import { INHTSAModel } from "../../shared/models/nhtsa/nhtsa.model";
import { IRTIVehicleModelYear, IRTIVehicleSeriesConfig } from "../../shared/models/rti/rti-vehicle-config.model";
import { IVehicleBrand, IVehicleModel } from "../../shared/models/vehicle-config-master.model";
import * as _ from 'lodash';

export function mapRTIVehicleConfig(brand: string, rtiVehicleConfig: IRTIVehicleSeriesConfig[]): IVehicleBrand {
  const vehicleBrand: IVehicleBrand = { name: brand, series: [] };
  vehicleBrand.series =
    rtiVehicleConfig.map(series => ({
      code: series.seriesCode,
      name: series.marketingSeries || series.seriesCode,
      models: mapRTIVehicleModels(series.modelYears)
    }));
  return vehicleBrand;
}

function mapRTIVehicleModels(seriesModelYears: IRTIVehicleModelYear[]): IVehicleModel[] {
  const vehicleModels: IVehicleModel[] = [];
  seriesModelYears.forEach(modelYear => {
    vehicleModels.push(
      ...modelYear.models.map(model => ({
        code: model.modelCd,
        name: model.marketingName || model.name,
        year: modelYear.year.toString()
      } as IVehicleModel))
    );
  });
  return vehicleModels;
}

export function getYearList(): number[] {
  const yearList = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear + 1; year >= 1981; year--) {
    yearList.push(year);
  }
  return yearList;
}

export function getInitialVehicleConfig(): IVehicleBrand[] {
  return [
    {
      name: Brands.ALL_NTL,
      series: []
    }
  ];
}

export function mapNHTSAVehicleConfig(brand: string, nhtsaVehicleConfig: INHTSAModel[]): IVehicleBrand {
  const vehicleBrand: IVehicleBrand = { name: brand, series: [] };
  vehicleBrand.series =
    nhtsaVehicleConfig.map(series => ({
      code: series.Model_Name.toLowerCase(),
      name: series.Model_Name,
      models: []
    }));
  return vehicleBrand;
}

export function removeBrandFromLoadingList(brand: string, brandsLoading: string[]): string[] {
  const index = brandsLoading.indexOf(brand);
  if (index !== -1) {
    brandsLoading.splice(index, 1);
  }
  return brandsLoading;
}
