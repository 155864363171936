import { Pipe, PipeTransform } from "@angular/core";
import { IResidual } from "../../../shared/models/dealer-defined-campaigns.model";
import * as _ from 'lodash';

@Pipe({
    name: "getvariancevalue"
})
export class GetVariaceValuePipe implements PipeTransform {

    transform(mileage: number, residual: IResidual): number | string {
        return _.find(residual.variances, { mileage })?.value || '-';
    }
    
}