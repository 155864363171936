import { Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { Effect, ofType, Actions } from "@ngrx/effects";
import { DealerService } from "../../../service/dealers/dealer.service";
import { DealerInfoService } from "../../shared/services/dealer-info.service";
import * as fromActions from "./dealer-configuration.actions";
import { IDealerConfiguration } from "../../shared/models/dealer-configuration.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { loadInitiateState } from "./dealer-configuration.adapter";
import { UserProfileService } from "@toyota/dd365-platform-library";
import { DealerConfigurationState } from "../../store/dealer-configuration/dealer-configuration.reducer";
import { DealerFeatureConfigService } from "../../shared/services/dealer-feature-config.service";
import { IDealerFeatureConfig } from "../../shared/models/rti/rti-feature-config.model";
import { DealerSystemConfigService } from "../../shared/services/dealer-system-config.service";
import { IGetBankConfiguration } from "../../shared/models/bank-configuration.model";
import { IGetSystemConfiguration } from "../../shared/models/system-configuration.model";
import { Router } from "@angular/router";
import { DealerConfigVersion } from "../../shared/enums/dc-version.enum";
import { selectDraftStatus } from "./dealer-configuration.selectors";
import { DeployJobManageState } from "../../store/deploy-job-management/deploy-job-management.reducer";
import * as deployJobManagementActions from '../../store/deploy-job-management/deploy-job-management.actions';
@Injectable()
export class DealerConfigurationEffects {
  errMsgSWW: string = "Something Went Wrong, Please try again later";
  dismissMsg: string = "Dismiss";
  successSnackbar: string = "success-snackbar";
  dealerDefinedCampaignSuccessMsg: string =
    "Dealer Defined Campaigns updated successfully.";

  constructor(
    private readonly actions$: Actions,
    private dealerService: DealerService,
    private readonly dealerFeatureConfigService: DealerFeatureConfigService,
    private readonly dealerSystemConfigService: DealerSystemConfigService,
    private snackBar: MatSnackBar,
    private readonly userProfileService: UserProfileService,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private router: Router,
    private readonly dealerInfoService: DealerInfoService,
    private readonly deployJobManageState: Store<DeployJobManageState>,
  ) { }

  @Effect()
  loadDealerConfiguration: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadDealerConfiguration>(
      fromActions.DealerConfigurationActionTypes.LOAD_DEALER_CONFIGURATION
    ),
    withLatestFrom(this.dealerConfigurationState.select(selectDraftStatus)),
    mergeMap(([action, draftStatus]) =>
      this.dealerService
        .getDealerConfigurationPipe(action.payload.dealerCode,
          draftStatus ? DealerConfigVersion.DRAFT : DealerConfigVersion.ACTIVE)
        .pipe(
          map((dealerConfiguration: IDealerConfiguration) => {
            return new fromActions.LoadDealerConfigurationSuccess({
              dealerConfiguration,
            });
          }),
          catchError((err) => {
            if (err.status === 404) {
              this.dealerInfoService._isDealerConfigNotFound = true;
              const dealerConfiguration = loadInitiateState(
                this.userProfileService,
                this.dealerService
              );
              this.dealerConfigurationState.dispatch(
                new fromActions.LoadDealerConfigurationFailure({
                  error: err.error.message,
                  dealerConfiguration,
                })
              );
            } else {
              this.snackBar.open(
                err?.error?.message || this.errMsgSWW,
                this.dismissMsg,
                { duration: 5000 }
              );
            }
            const dealerConfiguration = loadInitiateState(
              this.userProfileService,
              this.dealerService
            );
            return of(
              new fromActions.LoadDealerConfigurationFailure({
                error: err,
                dealerConfiguration: dealerConfiguration,
              })
            );
          })
        )
    )
  );

  @Effect()
  loadDealerFeatureConfig: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadDealerFeatureConfiguration>(
      fromActions.DealerConfigurationActionTypes
        .LOAD_DEALER_FEATURE_CONFIGURATION
    ),
    mergeMap((action: fromActions.LoadDealerFeatureConfiguration) =>
      this.dealerFeatureConfigService
        .getFeatureConfig(action.payload.dealerCode)
        .pipe(
          map((dealerFeatureConfig: IDealerFeatureConfig[]) => {
            return new fromActions.LoadDealerFeatureConfigurationSuccess({
              dealerFeatureConfig,
            });
          }),
          catchError((err) => {
            return of(
              new fromActions.LoadDealerFeatureConfigurationFailure({
                error: err.message,
              })
            );
          })
        )
    )
  );

  @Effect()
  loadBankConfig: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadBankConfiguration>(
      fromActions.DealerConfigurationActionTypes.LOAD_BANK_CONFIGURATION
    ),
    mergeMap((action: fromActions.LoadBankConfiguration) =>
      this.dealerSystemConfigService
        .getBankConfiguration(action.payload.distributor)
        .pipe(
          map((bankConfiguration: IGetBankConfiguration) => {
            return new fromActions.LoadBankConfigurationSuccess({
              bankConfiguration,
            });
          }),
          catchError((err) => {
            return of(
              new fromActions.LoadBankConfigurationFailure({
                error: err.message,
              })
            );
          })
        )
    )
  );

  @Effect()
  loadSystemConfig: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadSystemConfiguration>(
      fromActions.DealerConfigurationActionTypes.LOAD_SYSTEM_CONFIGURATION
    ),
    mergeMap((action: fromActions.LoadSystemConfiguration) =>
      this.dealerSystemConfigService
        .getSystemConfiguration(action.payload.distributor)
        .pipe(
          map((systemConfiguration: IGetSystemConfiguration) => {
            return new fromActions.LoadSystemConfigurationSuccess({
              systemConfiguration,
            });
          }),
          catchError((err) => {
            return of(
              new fromActions.LoadSystemConfigurationFailure({
                error: err.message,
              })
            );
          })
        )
    )
  );

  @Effect()
  updateDealerConfiguration: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.UpdateDealerConfiguration>(
      fromActions.DealerConfigurationActionTypes.UPDATE_DEALER_CONFIGURATION
    ),
    mergeMap((action: fromActions.UpdateDealerConfiguration) =>
      this.dealerService
        .createOrUpdateDealerConfiguration(
          action.payload.dealerCode,
          action.payload.dealerConfiguration
        )
        .pipe(
          map((dealerConfiguration: IDealerConfiguration) => {
            if (action.showSuccessMessage) {
              const dealerCode = this.userProfileService.getProfile().dealerCd;
              this.deployJobManageState.dispatch(
                new deployJobManagementActions.LoadDeployJobConfig({ dealerCode })
              );
              this.snackBar.open(
                "Dealer configuration updated successfully.",
                this.dismissMsg,
                { duration: 5000 }
              );

            }
            return new fromActions.UpdateDealerConfigurationSuccess({
              dealerConfiguration,
            });
          }),
          catchError((err) => {
            const errMsg = err?.error?.errors?.map(function (value) {
              return value.message;
            });

            this.snackBar.open(
              errMsg?.join(`\n`) || this.errMsgSWW,
              this.dismissMsg,
              { duration: 5000, panelClass: [this.successSnackbar] }
            );
            return of(
              new fromActions.UpdateDealerConfigurationFailure({
                error: err.error.message,
                dealerConfiguration: action.payload.dealerConfiguration,
              })
            );
          })
        )
    )
  );
}
