<div class="close-dialog-icon-container d-flex">
    <mat-icon class="close-dialog-icon" mat-dialog-close>close</mat-icon>
</div>
<div class="summary-detail">
    <div class="modify-campaign-container">
        <div class="header">
            <div mat-dialog-title>
                {{'LOCALIZATION.DEPLOYMENT_JOB.PLAN_TITLE' | translate}}
            </div>
        </div>
    </div>
    <div class="summary-detail-container container-fluid p-3">
        <table class="table w-100" aria-describedby="rate-table">
            <thead>
                <tr>
                    <th scope="col" class="minw-100px">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.PLAN.NAME' | translate}}
                    </th>
                    <th scope="col">
                        {{'LOCALIZATION.DEPLOYMENT_JOB.PLAN.STATUS' | translate}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of plans;  let i = index">
                    <td>{{item.name}}</td>
                    <td>{{item.status}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>