<div class="campaigns-container common-campaign-container">
    <dd-spinner *ngIf="(selectCampaignsComponentLoading$ | async) || 
        (selectDealerConfigLoading$ | async) || 
        (selectBankConfigLoading$ | async) || 
        (selectSystemConfigLoading$ | async)">
    </dd-spinner>
    <div *ngIf="!(selectCampaignsComponentLoading$ | async) && 
    !(selectDealerConfigLoading$ | async) &&
    !(selectBankConfigLoading$ | async) &&
    !(selectSystemConfigLoading$ | async)">
        <app-pe-list-campaign></app-pe-list-campaign>
    </div>
</div>