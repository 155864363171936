import { Pipe, PipeTransform } from '@angular/core';
import { ProtectionProductsComponentService } from '../protection-products.component.service';

@Pipe({
    name: 'productdescription'
})
export class ProductDescriptionPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(productId: string): string {
        const product = this.pPComponentService.productOfferings.filter(t => t.id === productId);
        return product[0].code + (product[0]?.description ? '-' + product[0]?.description : '');
    }
}
