import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { IDJAuditLog } from "../../../shared/models/audit-log.model";
import { UserProfileService } from "@toyota/dd365-platform-library";
import { DeployJobManageState } from "../../../store/deploy-job-management/deploy-job-management.reducer";
import { selectAuditLogs, selectAuditLogIsLoading, selectDeployJobIsLoading } from '../../../store/deploy-job-management/deploy-job-management.selectors';
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import * as deployJobManagementActions from "../../../store/deploy-job-management/deploy-job-management.actions";
import { SubscriptionList, unsubscribeSubscriptions } from '../../../shared/services/util.service';
@Component({
  selector: 'app-pe-deploy-job-auditlogs',
  templateUrl: './deploy-job-auditlogs.component.html',
  styleUrls: ['./deploy-job-auditlogs.component.scss']
})
export class DeployJobAuditlogsComponent implements OnInit, OnDestroy {
  auditLogs: IDJAuditLog[] = [];
  totalItems: number = 0;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  deployJobLoading$: Observable<boolean>;
  auditLoading$: Observable<boolean>;
  private subs: SubscriptionList = {};
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private readonly deployJobManageState: Store<DeployJobManageState>,
    private readonly userProfileService: UserProfileService) { }

  ngOnInit(): void {
    const dealerCode = this.userProfileService.getProfile().dealerCd;
    this.deployJobLoading$ = this.deployJobManageState.select(
      selectDeployJobIsLoading
    );

    this.auditLoading$ = this.deployJobManageState.select(
      selectAuditLogIsLoading
    );
    this.deployJobManageState.dispatch(
      new deployJobManagementActions.LoadAuditLog({ dealerCode: dealerCode, deploymentId: this.data.dep_id })
    );
    this.subs.selectDeployJobConfigSub =
      this.deployJobManageState.select(selectAuditLogs).subscribe(auditLog => {
        if (auditLog && auditLog.trails) {
          this.auditLogs = auditLog.trails.items;
          this.totalItems = auditLog.trails.totalItems;
        }
      })
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }
}
