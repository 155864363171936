import { UserProfileService } from '@toyota/dd365-platform-library';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import * as uuid from 'uuid';
import { IGetBankConfiguration } from '../models/bank-configuration.model';
import { IGetSystemConfiguration } from '../models/system-configuration.model';

@Injectable({
    providedIn: 'root'
})
export class DealerSystemConfigService {
    private readonly SYSTEM_CONFIGURATION_API: string = environment.system_configuration_api;
    private readonly SUBSCRIBER_CODE = environment.x_subscriber_code;
    private httpOptions = {
        headers: new HttpHeaders()
    };
    constructor(
        private http: HttpClient,
        private readonly userProfileService: UserProfileService
    ) {
    }

    private setHeaderOptions() {
        this.httpOptions.headers = new HttpHeaders({});
        this.httpOptions.headers = this.httpOptions.headers
            .set('Authorization', `Bearer ${this.userProfileService.getProfile().token}`)
            .set('x-subscriber-code', this.SUBSCRIBER_CODE)
            .set('x-transaction-id', uuid.v4());
    }

    getBankConfiguration(distributor: string) {
        this.setHeaderOptions();
        let distributerCd = this.userProfileService.getProfile().dealer.distributor;
        return this.http.get(`${this.SYSTEM_CONFIGURATION_API}distributor/${distributerCd}/config?configItem=bank&configValue=${distributor}`, this.httpOptions)
            .pipe(
                map((data: IGetBankConfiguration) => {
                    return data;
                })
            );
    }
    getSystemConfiguration(distributor: string) {
        this.setHeaderOptions();
        let distributerCd = this.userProfileService.getProfile().dealer.distributor;
        return this.http.get(`${this.SYSTEM_CONFIGURATION_API}distributor/${distributerCd}/config?configItem=app&configValue=ALL`, this.httpOptions)
            .pipe(
                map((data: IGetSystemConfiguration) => {
                    return data;
                })
            );
    }
}