import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { IUserProfile, UserProfileService } from '@toyota/dd365-platform-library';
import { Subscription } from 'rxjs';
import { SingleSpaProps, singleSpaPropsSubject } from '../single-spa/single-spa-props';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  userProfile: IUserProfile = null;
  currentLocale: string = null;
  localeSub: Subscription = null;

  constructor(public userProfileService: UserProfileService,
    private readonly translateService: TranslateService,
    private readonly changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.localeSub = this.translateService.onLangChange.subscribe(lan => {
      this.currentLocale = lan.lang;
    });
    singleSpaPropsSubject.pipe(take(1)).subscribe(
      (props: SingleSpaProps) => {
        props.userProfile.subscribe((profile: IUserProfile) => {
          this.userProfile = profile;
          // User profile subscription will get triggered any time the UI Gateway broadcasts a user profile update
          if (profile?.localization?.locale !== this.currentLocale) {
            this.setLocale();
          } else {
            this.userProfileService.updateProfile(this.userProfile);
            // Manually run angular's change detection
            // Changes originate from outside the application's angular zone so change detection will not happen automatically
            this.changeDetector.detectChanges();
          }
        });

      });
  }
  setLocale() {
    if (this.userProfile) {
      this.translateService.use(this.userProfile.localization.locale).pipe(take(1)).subscribe(() => {
        this.currentLocale = this.userProfile.localization.locale;
        this.userProfileService.updateProfile(this.userProfile);
        // Manually run angular's change detection
        // Changes originate from outside the application's angular zone so change detection will not happen automatically
        this.changeDetector.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this.localeSub.unsubscribe();
  }

}
