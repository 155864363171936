import produce from 'immer';
import { brandList } from '../../../mocks/brands';
import { OfferTypes } from '../../shared/enums/offer-types.enum';
import { SalesClasses } from '../../shared/enums/sales-classes.enum';
import { IBrand } from '../../shared/models/brand.model';
import { IDealerDefinedCampaign, IVariance } from '../../shared/models/dealer-defined-campaigns.model';
import * as fromActions from './dealer-defined-campaigns.actions';
import * as _ from 'lodash';

export interface DealerDefinedCampaignsState {
    campaigns: IDealerDefinedCampaign[];
    defaultVariances: IVariance[];
    brandList: IBrand[];
    defaultMileage: number;
    salesClasses: string[];
    offerTypes: string[];
    loading: boolean;
    loadingCampaign: boolean;
    error: string;
}

export const dealerDefinedCampaignsInitialState: DealerDefinedCampaignsState = {
    campaigns: [],
    brandList: brandList,
    defaultVariances: [
        {
            mileage: 5000,
            value: 5,
        },
        {
            mileage: 7500,
            value: 4,
        },
        {
            mileage: 10000,
            value: 3,
        },
        {
            mileage: 12000,
            value: 0,
        },
        {
            mileage: 15000,
            value: -2,
        },
        {
            mileage: 18000,
            value: -4,
        },
    ],
    salesClasses: [SalesClasses.NEW, SalesClasses.CERTIFIED, SalesClasses.USED],
    offerTypes: [OfferTypes.LEASE, OfferTypes.FINANCE],
    defaultMileage: 12000,
    loading: false,
    loadingCampaign: false,
    error: ''
};

export function dealerDefinedCampaignsReducer(
    state: DealerDefinedCampaignsState = dealerDefinedCampaignsInitialState,
    action: fromActions.DealerDefinedCampaignsActions
): DealerDefinedCampaignsState {

    switch (action.type) {

        case fromActions.DealerDefinedCampaignsActionTypes.LOAD_DD_CAMPAIGNS: {
            return produce(state, draftState => {
                draftState.campaigns = [];
                draftState.loading = true;
                draftState.error = '';
            });
        }
        case fromActions.DealerDefinedCampaignsActionTypes.LOAD_DD_CAMPAIGNS_SUCCESS: {
            return produce(state, draftState => {
                draftState.campaigns = action instanceof fromActions.LoadDealerDefinedCampaignsSuccess ? action.payload.campaigns : [];
                draftState.loading = false;
                draftState.error = '';
            });
        }
        case fromActions.DealerDefinedCampaignsActionTypes.LOAD_DD_CAMPAIGNS_FAILURE: {
            return produce(state, draftState => {
                draftState.campaigns = [];
                draftState.loading = false;
                draftState.error = action instanceof fromActions.LoadDealerDefinedCampaignsFailure ? action.payload.error : '';
            });
        }
        case fromActions.DealerDefinedCampaignsActionTypes.UPDATE_DD_CAMPAIGN:
        case fromActions.DealerDefinedCampaignsActionTypes.DELETE_DD_CAMPAIGN: {
            return produce(state, draftState => {
                draftState.loadingCampaign = true;
                draftState.error = '';
            });
        }
        case fromActions.DealerDefinedCampaignsActionTypes.UPDATE_DD_CAMPAIGN_SUCCESS: {
            return produce(state, draftState => {
                const campaign = action instanceof fromActions.UpdateDealerDefinedCampaignSuccess ? action.payload.dealerDefinedCampaign : null;
                const campaignIndex = _.findIndex(draftState.campaigns, { id: campaign.id });
                if (campaignIndex >= 0) {
                    draftState.campaigns[campaignIndex] = campaign;
                } else {
                    draftState.campaigns.push(campaign)
                }
                draftState.loadingCampaign = false;
                draftState.error = '';
            });
        }
        case fromActions.DealerDefinedCampaignsActionTypes.UPDATE_DD_CAMPAIGN_FAILURE: {
            return produce(state, draftState => {
                draftState.loadingCampaign = false;
                draftState.error = action instanceof fromActions.UpdateDealerDefinedCampaignFailure ? action.payload.error : '';
            });
        }
        case fromActions.DealerDefinedCampaignsActionTypes.DELETE_DD_CAMPAIGN_SUCCESS: {
            return produce(state, draftState => {
                const campaignId = action instanceof fromActions.DeleteDealerDefinedCampaignSuccess ? action.payload.campaignId : null;
                const campaignIndex = _.findIndex(draftState.campaigns, { id: campaignId });
                draftState.campaigns.splice(campaignIndex, 1);
                draftState.loadingCampaign = false;
                draftState.error = '';
            });
        }
        case fromActions.DealerDefinedCampaignsActionTypes.DELETE_DD_CAMPAIGN_FAILURE: {
            return produce(state, draftState => {
                draftState.loadingCampaign = false;
                draftState.error = action instanceof fromActions.DeleteDealerDefinedCampaignFailure ? action.payload.error : '';
            });
        }
        default:
            return state;
    }

}
