import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-pe-config-not-found",
  templateUrl: "./config-not-found.component.html",
  styleUrls: ["./config-not-found.component.scss"],
})
export class ConfigNotFoundComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
