import { IRateBookCode } from './../../../shared/models/product-offerings.model';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'ratebookdescription'
})
export class GetRateBookDescriptionPipe implements PipeTransform {

    constructor() { }

    transform(bookCode: string, rateBookCodeList: IRateBookCode[]): string {
        if (bookCode && rateBookCodeList) {
            return _.find(rateBookCodeList, { bookCode }).description;
        }
        else {
            return '';
        }
    }
}
