import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './dealer-defined-campaigns.actions';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { DealerDefinedCampaignsService } from '../../shared/services/dealer-defined-campaigns.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IDealerDefinedCampaign } from '../../shared/models/dealer-defined-campaigns.model';
import { campaignUpdateComplete$ } from './dealer-defined-campaigns.adapter';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class DealerDefinedCampaignsEffects {

    private readonly dismissText = 'LOCALIZATION.COMMON.DISMISS';

    constructor(
        private readonly actions$: Actions,
        private readonly dealerDefinedCampaignService: DealerDefinedCampaignsService,
        private readonly translateService: TranslateService,
        private readonly snackBar: MatSnackBar
    ) {
    }

    loadDealerDefinedCampaigns: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.LoadDealerDefinedCampaigns>(
            fromActions.DealerDefinedCampaignsActionTypes.LOAD_DD_CAMPAIGNS
        ),
        mergeMap((action: fromActions.LoadDealerDefinedCampaigns) =>
            this.dealerDefinedCampaignService.loadDealerDefinedCampaigns(action.payload.dealerCode).pipe(
                map((campaigns: IDealerDefinedCampaign[]) => {
                    return new fromActions.LoadDealerDefinedCampaignsSuccess({ campaigns })
                }),
                catchError(err => {
                    this.snackBar.open(err.error.message, this.translateService.instant(this.dismissText), { duration: 4000 });
                    return of(new fromActions.LoadDealerDefinedCampaignsFailure({ error: err.error.message }));
                })
            )
        )
    ));

    updateDealerDefinedCampaign: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.UpdateDealerDefinedCampaign>(
            fromActions.DealerDefinedCampaignsActionTypes.UPDATE_DD_CAMPAIGN
        ),
        mergeMap((action: fromActions.UpdateDealerDefinedCampaign) =>
            this.dealerDefinedCampaignService.updateDealerDefinedCampaign(action.payload.campaign).pipe(
                map((dealerDefinedCampaign: IDealerDefinedCampaign) => {
                    campaignUpdateComplete$.next();
                    this.snackBar.open(this.translateService.instant('LOCALIZATION.CAMPAIGNS.UPDATE_CONFIRM'),
                        this.translateService.instant(this.dismissText), { duration: 4000 });
                    return new fromActions.UpdateDealerDefinedCampaignSuccess({ dealerDefinedCampaign });
                }),
                catchError(err => {
                    this.snackBar.open(err.error.message, this.translateService.instant(this.dismissText), { duration: 4000 });
                    return of(new fromActions.UpdateDealerDefinedCampaignFailure({ error: err.error.message }));
                })
            )
        )
    ));

    deleteDealerDefinedCampaign: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<fromActions.DeleteDealerDefinedCampaign>(
            fromActions.DealerDefinedCampaignsActionTypes.DELETE_DD_CAMPAIGN
        ),
        mergeMap((action: fromActions.DeleteDealerDefinedCampaign) =>
            this.dealerDefinedCampaignService.deleteDealerDefinedCampaign(action.payload.campaignId, action.payload.dealerCode).pipe(
                map(() => {
                    this.snackBar.open(this.translateService.instant('LOCALIZATION.CAMPAIGNS.DELETE_CONFIRM'),
                        this.translateService.instant(this.dismissText), { duration: 4000 });
                    return new fromActions.DeleteDealerDefinedCampaignSuccess({ campaignId: action.payload.campaignId });
                }),
                catchError(err => {
                    this.snackBar.open(err.error.message, this.translateService.instant(this.dismissText), { duration: 4000 });
                    return of(new fromActions.DeleteDealerDefinedCampaignFailure({ error: err.error.message }));
                })
            )
        )
    ));

}

