import { DealerInfoService } from './../services/dealer-info.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class CanActivatePP implements CanActivate {
    constructor(private readonly router: Router, private readonly dealerInfoService: DealerInfoService) { }

    canActivate(): boolean {
        if (!this.dealerInfoService.isPPFeatureEnabled) {
            this.router.navigate(['/pe-admin']);
        }
        return this.dealerInfoService.isPPFeatureEnabled;
    }
}
