import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserProfileService } from '@toyota/dd365-platform-library';
import { DealerDefinedCampaignsState } from '../../store/dealer-defined-campaigns/dealer-defined-campaigns.reducer';
import * as dealerDefinedCampaignsActions from "../../store/dealer-defined-campaigns/dealer-defined-campaigns.actions";
import * as dealerConfigurationActions from "../../store/dealer-configuration/dealer-configuration.actions";
import { Observable } from 'rxjs';
import { selectCampaignLoading, selectCampaignsComponentLoading, selectDdSalesClasses, selectDealerDefinedCampaigns, selectDefaultVariances } from '../../store/dealer-defined-campaigns/dealer-defined-campaigns.selectors';
import { DealerConfigurationState } from '../../store/dealer-configuration/dealer-configuration.reducer';
import { selectCreditTier, selectIsLoading, selectIsLoadingBankConfig, selectIsLoadingSystemConfig, selectLeaseBaserate, selectLenderList } from '../../store/dealer-configuration/dealer-configuration.selectors';
import { DealerInfoService } from '../../shared/services/dealer-info.service';
import { DealerDefinedCampaignsComponentService } from './dealer-defined-campaigns.component.service';
import { filter, take } from 'rxjs/operators';
import { selectIsVehicleConfigLoading, selectVehicleConfig } from '../../store/vehicle-config/vehicle-config.selectors';
import { VehicleConfigState } from '../../store/vehicle-config/vehicle-config.reducer';
import { SubscriptionList, unsubscribeSubscriptions } from '../../shared/services/util.service';

@Component({
  selector: 'app-pe-dealer-defined-campaigns',
  templateUrl: './dealer-defined-campaigns.component.html',
  styleUrls: ['./dealer-defined-campaigns.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DealerDefinedCampaignsComponent implements OnInit {

  private readonly subs: SubscriptionList = {};
  selectCampaignsComponentLoading$: Observable<boolean>;
  selectDealerConfigLoading$: Observable<boolean>;
  selectBankConfigLoading$: Observable<boolean>;
  selectSystemConfigLoading$: Observable<boolean>;

  constructor(
    private readonly dealerDefinedCampaignsState: Store<DealerDefinedCampaignsState>,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly vehicleConfigState: Store<VehicleConfigState>,
    private readonly dDCCService: DealerDefinedCampaignsComponentService,
    private readonly dealerInfoService: DealerInfoService,
    private readonly userProfileService: UserProfileService
  ) { }

  ngOnInit() {

    this.selectCampaignsComponentLoading$ = this.dealerDefinedCampaignsState.select(selectCampaignsComponentLoading);
    this.selectDealerConfigLoading$ = this.dealerConfigurationState.select(selectIsLoading);
    this.selectBankConfigLoading$ = this.dealerConfigurationState.select(selectIsLoadingBankConfig);
    this.selectSystemConfigLoading$ = this.dealerConfigurationState.select(selectIsLoadingSystemConfig);
    this.dDCCService.campaignLoading$ = this.dealerDefinedCampaignsState.select(selectCampaignLoading);
    this.dDCCService.vehicleConfigLoading$ = this.vehicleConfigState.select(selectIsVehicleConfigLoading);
    this.subs.selectCreditTierSub = this.dealerConfigurationState.select(selectCreditTier).pipe(filter(t => t?.length > 0)).subscribe(creditTiers => {
      this.dDCCService.creditTiers = creditTiers;
    });
    this.subs.selectLeaseBaserateSub = this.dealerConfigurationState.select(selectLeaseBaserate).subscribe(leaseBaserate => {
      this.dDCCService.leaseBaserate = leaseBaserate;
    });
    this.dealerDefinedCampaignsState.select(selectDdSalesClasses).pipe(take(1)).subscribe(salesClasses => {
      this.dDCCService.salesClasses = salesClasses;
    });
    this.dealerConfigurationState.select(selectDefaultVariances).pipe(filter(t => t?.length > 0), take(1)).subscribe(variances => {
      this.dDCCService.defaultVariances = variances;
    });
    this.subs.selectDealerDefinedCampaignsSub = this.dealerConfigurationState.select(selectLenderList).subscribe(lenders => {
      this.dDCCService.lenderList = lenders;
    });
    this.subs.selectDealerDefinedCampaignsSub = this.dealerDefinedCampaignsState.select(selectDealerDefinedCampaigns).subscribe(campaigns => {
      this.dDCCService.campaignList = campaigns;
    });
    this.subs.selectVehicleConfigSub = this.vehicleConfigState.select(selectVehicleConfig).subscribe(vehicleConfig => {
      this.dDCCService.vehicleConfig = vehicleConfig;
    });

    const dealerCode = this.userProfileService.getProfile().dealerCd;
    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.LoadDealerConfiguration({ dealerCode }));
    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.LoadBankConfiguration({ distributor: this.dealerInfoService.getDealerBankDistributor() }));
    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.LoadSystemConfiguration({ distributor: this.dealerInfoService.getDealerBankDistributor() }));
    this.dealerDefinedCampaignsState.dispatch(new dealerDefinedCampaignsActions.LoadDealerDefinedCampaigns({ dealerCode }));
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

}
