import { Pipe, PipeTransform } from "@angular/core";
import { IDealerDefinedCampaign } from "../../../shared/models/dealer-defined-campaigns.model";

@Pipe({
    name: "getcampaignsbylender"
})
export class GetCampaignsByLenderPipe implements PipeTransform {

    transform(campaigns: IDealerDefinedCampaign[], lenderId: string): IDealerDefinedCampaign[] {
        return campaigns.filter(t => t.lenderId === lenderId).reverse() || [];
    }

}