import { FormGroup } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { IProductConfiguration } from '../../../shared/models/protection-products.model';
import { ProtectionProductsComponentService } from '../protection-products.component.service';
import { ProductDescriptionPipe } from './product-description.pipe';

@Pipe({
    name: 'getpencilenableproducts'
})
export class GetPencilEnabledProductsPipe implements PipeTransform {

    constructor(
        private readonly pPComponentService: ProtectionProductsComponentService,
        private readonly productDescriptionPipe: ProductDescriptionPipe
    ) { }

    transform(productForm: FormGroup, salesClass: string, offerType: string): string {
        let enabledProducts = '';
        const productList: IProductConfiguration[] = (this.pPComponentService.protectionProductsForm.get('products') as FormGroup).getRawValue();
        const productId = productForm.get('id').value;
        const productsPencilDefaults = productList.filter(t => t.id != productId);
        const productsEnabled = productsPencilDefaults.filter(s => s.global.firstPencilDefaults.filter(t => t.offerType === offerType && t.salesClass === salesClass && t.enabled).length > 0);
        if (productsEnabled?.length) {
            enabledProducts = `Maximum of three recommended products are allowed per sales class and offer type.\r\n`
                + `The following products have already been configured for ${salesClass} / ${offerType}.\r\n`
                + productsEnabled.map((t, i) => `${i + 1}. ` + (t.global.productTitle || this.productDescriptionPipe.transform(t.id))).join('\r\n');
        }
        return enabledProducts;
    }
}