import * as fromActions from "./lender-list-config.actions";
import produce from "immer";
import {
  loadInitiateState,
  mapLenderListConfig,
} from "./lender-list-config.adapter";
import { ILenderListConfig } from "../../shared/models/lender/lender-list-config.model";

export interface LenderListConfigState {
  lenderListConfig: ILenderListConfig[];
  loadingLenders: boolean;
  error: string;
}

export const initialState: LenderListConfigState = {} as LenderListConfigState;

export function lenderListConfigReducer(
  state: LenderListConfigState = initialState,
  action: fromActions.LenderListConfigActions
): LenderListConfigState {
  switch (action.type) {
    case fromActions.LenderListConfigActionTypes.LOAD_LENDER_LIST_CONFIG: {
      return produce(state, (draftState) => {
        draftState.loadingLenders = true;
        draftState.error = "";
      });
    }
    case fromActions.LenderListConfigActionTypes
      .LOAD_LENDER_LIST_CONFIG_SUCCESS: {
      return produce(state, (draftState) => {
        draftState.lenderListConfig =
          action instanceof fromActions.LoadLenderListConfigSuccess
            ? mapLenderListConfig(action.payload.lenderListConfig)
            : ([] as ILenderListConfig[]);
        draftState.loadingLenders = false;
        draftState.error = "";
      });
    }
    case fromActions.LenderListConfigActionTypes
      .LOAD_LENDER_LIST_CONFIG_FAILURE: {
      return produce(state, (draftState) => {
        draftState.loadingLenders = false;
        draftState.error =
          action instanceof fromActions.LoadLenderListConfigFailure
            ? action.payload.error
            : "";
      });
    }
    default:
      return state;
  }
}
